import React from 'react';
import { Divider, Box, AppBar, Toolbar, useTheme, Typography, IconButton, Badge } from '@mui/material'; // Material-UI bileşenlerini içeri aktarın
import { bgBlur } from 'src/theme/css';
export const PageLayout = ({ children, header, other, action }: any) => {
  const theme = useTheme();
  return (
    <Box sx={{ mt: 2, flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar
          sx={{
            ...bgBlur({
              color: theme.palette.background.default,
            }),
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {header}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {action}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {other}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: 2 }}>{children}</Box>
      <Divider />
    </Box>
  );
};
