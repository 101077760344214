import { store } from '../../index';
import { actions } from './checkout.slice';
import {
  addToCartThunk, createShipmentAddressThunk,
  decreaseQuantityThunk,
  getCartThunk,
  increaseQuantityThunk,
  removeFromCartThunk,
  setDefaultAddressThunk,
  updateCartItemFeaturesThunk,
  updateCartThunk,
  getOrderThunk,
} from './checkout.fetch';

const clearCart = () => store.dispatch(actions.clearCart());

const getCart = () => store.dispatch(getCartThunk());

const onBackStep = () => store.dispatch(actions.onBackStep());

const onCreateShipment = (shipment: any) => store.dispatch(createShipmentAddressThunk({ shipment }));

const onApplyBilling = (billing: any) => store.dispatch(actions.onApplyBilling({ billing }));

const onApplyShipping = (shipping: number) => store.dispatch(actions.onApplyShipping({ shipping }));

const onDeleteCart = (itemId: number) => store.dispatch(removeFromCartThunk({ itemId }));

const onIncreaseQuantity = (item: any) => store.dispatch(increaseQuantityThunk({ item }));

const onDecreaseQuantity = (item: any) => store.dispatch(decreaseQuantityThunk({ item }));

const onGotoStep = (step: number) => store.dispatch(actions.onGotoStep({ step }));

const onNextStep = () => store.dispatch(actions.onNextStep());


const onReset = () => store.dispatch(actions.onReset());

const addToCart = (item: any) => store.dispatch(addToCartThunk(item));

const setDefaultAddress = ({ address, user }: any) =>
  store.dispatch(setDefaultAddressThunk({ address, user }));

const updateCartItemFeatures = ({ item, features }: any) =>
  store.dispatch(updateCartItemFeaturesThunk({ item, features }));

const updateCart = ({ id, billingAddressId, shippingAddressId , note}: any) =>
  store.dispatch(updateCartThunk({ id, billingAddressId, shippingAddressId, note }));

const orderFindThunk = (addressId: number, userId: number) =>
  store.dispatch(getOrderThunk({ addressId, userId }));

const setNote = (note: any) => store.dispatch(actions.setNote({note}));

export default {
  clearCart,
  getCart,
  onBackStep,
  onCreateShipment,
  onDeleteCart,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onGotoStep,
  onNextStep,
  onApplyShipping,
  onReset,
  addToCart,
  updateCartItemFeatures,
  updateCart,
  onApplyBilling,
  setDefaultAddress,
  orderFindThunk,
  setNote
};
