import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import Typography from '@mui/material/Typography';
import { RouterLink } from 'src/routes/components';
import { IMAGES } from '../../theme/image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          marginTop: 2,
          marginBottom: 2,
          height: 'auto',
          maxWidth: 250,
          width: '100%',
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <img
          src={IMAGES.LOGO}
          alt="logo"
          style={{
            //  filter: 'invert(1)',
            objectFit: 'contain',
          }}
        />
        {/* <Typography variant="h4">Şifreni sıfırla</Typography> */}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
