import { useSelector } from 'react-redux';
import { RootState } from '../../index';
import actions from './checkout.actions';

export const useCheckout = () => {
  const checkoutState = useSelector((state: RootState) => state.checkout);
  return {
    ...checkoutState,
    ...actions,
  };
};
