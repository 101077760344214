import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Iconify from 'src/components/iconify';

const BackButton = () => {
  const handleClick = () => {
    window.history.back();
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button
        onClick={handleClick}
        variant="text"
        startIcon={<Iconify icon="solar:arrow-left-broken" />}
      >
        Geri
      </Button>
    </Stack>
  );
};

export default BackButton;
