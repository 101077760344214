import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  home: icon('solar:home-bold'),
  dashboard: icon('solar:widget-6-bold'),
  user: icon('solar:user-id-bold'),
  order: icon('solar:cart-bold'),
  product: icon('solar:chair-bold'),
  import: icon('solar:import-bold'),
  company: icon('solar:buildings-2-bold'),
  payment: icon('solar:card-2-bold'),
  folder: icon('material-symbols:folder-zip'),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Genel Bakış',
        items: [
          {
            title: 'Bayiye Dön',
            path: paths.bayi,
            icon: ICONS.home,
          },
          {
            title: 'Genel Bakış',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: 'Import',
            path: paths.dashboard.import,
            icon: ICONS.import,
          },
          {
            title: 'Dosya Yönetimi',
            path: paths.dashboard.fileManager.list,
            icon: ICONS.folder,
          },
          {
            title: 'Satış İşlemleri',
            path: paths.dashboard.order.root,
            icon: ICONS.order,
            children: [
              {
                title: 'Özet',
                path: paths.dashboard.order.root,
              },
              {
                title: 'Siparişler',
                path: paths.dashboard.order.list,
              },
            ],
          },
          {
            title: 'Ürün İşlemleri',
            path: paths.dashboard.product.root,
            icon: ICONS.product,
            children: [
              {
                title: 'Özet',
                path: paths.dashboard.product.root,
              },
              {
                title: 'Ürünler',
                path: paths.dashboard.product.list,
              },
              {
                title: 'Kategoriler',
                path: paths.dashboard.product.category,
              },
              {
                title: 'Markalar',
                path: paths.dashboard.product.brand,
              },
              // {
              //   title: 'Ürün Türleri',
              //   path: paths.dashboard.product.type,
              // },
              // {
              //   title: 'Özellikler',
              //   path: paths.dashboard.product.feature,
              // },
              // {
              //   title: 'Ürün Fiyatları',
              //   path: paths.dashboard.product.price,
              // },
              // {
              //   title: 'Stok Kartları',
              //   path: paths.dashboard.product.stock,
              // },
              {
                title: 'Slayt Yönetimi',
                path: paths.dashboard.product.slider.list,
              },
              {
                title: 'Koleksiyonlar',
                path: paths.dashboard.product.collection.list,
              },
            ],
          },
          {
            title: 'Cari İşlemler',
            path: paths.dashboard.company.root,
            icon: ICONS.company,
            children: [
              {
                title: 'Özet',
                path: paths.dashboard.company.summary,
              },
              {
                title: 'Hesaplar',
                path: paths.dashboard.company.account,
              },
              {
                title: 'Adresler',
                path: paths.dashboard.company.address,
              },
            ],
          },
          {
            title: 'BufiPay',
            path: paths.dashboard.bufipay.root,
            icon: ICONS.payment,
            children: [
              {
                title: 'Özet',
                path: paths.dashboard.bufipay.root,
              },
              {
                title: 'Ödemeler',
                path: paths.dashboard.bufipay.payment.list,
              },
              {
                title: 'Pos Hesapları',
                path: paths.dashboard.bufipay.pos,
              },
              {
                title: 'Üye İşyeri Ayarları',
                path: paths.dashboard.bufipay.settings,
              },
              {
                title: 'Banka Listesi',
                path: paths.dashboard.bufipay.bank,
              },
              {
                title: 'İptaller (Yakında)',
                subheader: 'İptal İşlemleri',
                path: paths.dashboard.bufipay.cancel,
              },
              {
                title: 'İadeler (Yakında)',
                path: paths.dashboard.bufipay.refund,
              },
              {
                title: 'Link ile Ödeme (Yakında)',
                path: paths.dashboard.bufipay.link,
              },
              {
                title: 'Raporlar (Yakında)',
                path: paths.dashboard.bufipay.report,
              },
              {
                title: 'Api Yönetimi (Yakında)',
                path: paths.dashboard.bufipay.api,
              },
              {
                title: 'Bin Numaraları',
                path: paths.dashboard.bufipay.binnumber,
              },
            ],
          },
          {
            title: 'Üyelik İşlemleri',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              {
                title: 'Özet',
                path: paths.dashboard.user.summary,
              },
              {
                title: 'Kullanıcılar',
                path: paths.dashboard.user.list,
              },
              {
                title: 'Roller',
                path: paths.dashboard.user.role,
              },
            ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      /* {
         subheader: t('management'),
         items: [
           // USER
           {
             title: t('user'),
             path: paths.dashboard.user.root,
             icon: ICONS.user,
             children: [
               { title: t('profile'), path: paths.dashboard.user.root },
               { title: t('cards'), path: paths.dashboard.user.cards },
               { title: t('list'), path: paths.dashboard.user.list },
               { title: t('create'), path: paths.dashboard.user.new },
               { title: t('edit'), path: paths.dashboard.user.demo.edit },
               { title: t('account'), path: paths.dashboard.user.account },
             ],
           },

           // PRODUCT
           {
             title: t('product'),
             path: paths.dashboard.product.root,
             icon: ICONS.product,
             children: [
               { title: t('list'), path: paths.dashboard.product.root },
               {
                 title: t('details'),
                 path: paths.dashboard.product.demo.details,
               },
               { title: t('create'), path: paths.dashboard.product.new },
               { title: t('edit'), path: paths.dashboard.product.demo.edit },
             ],
           },

           // ORDER
           {
             title: t('order'),
             path: paths.dashboard.order.root,
             icon: ICONS.order,
             children: [
               { title: t('list'), path: paths.dashboard.order.root },
               { title: t('details'), path: paths.dashboard.order.demo.details },
             ],
           },

           // INVOICE
           {
             title: t('invoice'),
             path: paths.dashboard.invoice.root,
             icon: ICONS.invoice,
             children: [
               { title: t('list'), path: paths.dashboard.invoice.root },
               {
                 title: t('details'),
                 path: paths.dashboard.invoice.demo.details,
               },
               { title: t('create'), path: paths.dashboard.invoice.new },
               { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
             ],
           },

           // BLOG
           {
             title: t('blog'),
             path: paths.dashboard.post.root,
             icon: ICONS.blog,
             children: [
               { title: t('list'), path: paths.dashboard.post.root },
               { title: t('details'), path: paths.dashboard.post.demo.details },
               { title: t('create'), path: paths.dashboard.post.new },
               { title: t('edit'), path: paths.dashboard.post.demo.edit },
             ],
           },

           // JOB
           {
             title: t('job'),
             path: paths.dashboard.job.root,
             icon: ICONS.job,
             children: [
               { title: t('list'), path: paths.dashboard.job.root },
               { title: t('details'), path: paths.dashboard.job.demo.details },
               { title: t('create'), path: paths.dashboard.job.new },
               { title: t('edit'), path: paths.dashboard.job.demo.edit },
             ],
           },

           // TOUR
           {
             title: t('tour'),
             path: paths.dashboard.tour.root,
             icon: ICONS.tour,
             children: [
               { title: t('list'), path: paths.dashboard.tour.root },
               { title: t('details'), path: paths.dashboard.tour.demo.details },
               { title: t('create'), path: paths.dashboard.tour.new },
               { title: t('edit'), path: paths.dashboard.tour.demo.edit },
             ],
           },

           // FILE MANAGER
           {
             title: t('file_manager'),
             path: paths.dashboard.fileManager,
             icon: ICONS.folder,
           },

           // MAIL
           {
             title: t('mail'),
             path: paths.dashboard.mail,
             icon: ICONS.mail,
             info: <Label color="error">+32</Label>,
           },

           // CHAT
           {
             title: t('chat'),
             path: paths.dashboard.chat,
             icon: ICONS.chat,
           },

           // CALENDAR
           {
             title: t('calendar'),
             path: paths.dashboard.calendar,
             icon: ICONS.calendar,
           },

           // KANBAN
           {
             title: t('kanban'),
             path: paths.dashboard.kanban,
             icon: ICONS.kanban,
           },
         ],
       },*/

      // DEMO MENU STATES
    ],
    []
  );

  return data;
}
