// routes
import { paths } from 'src/routes/paths';
// config
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Anasayfa',
    icon: <Iconify icon="solar:home-smile-angle-bold" width={24} />,
    path: paths.bayi,
  },
  {
    title: 'Ödemeler',
    icon: <Iconify icon="solar:card-bold" width={24} />,
    path: paths.account.payment.list,
  },
  {
    title: 'Siparişler',
    icon: <Iconify icon="solar:sale-bold" width={24} />,
    path: paths.account.order.list,
   }
];
