import type { ButtonProps } from '@mui/material/Button';
import type { Theme, SxProps } from '@mui/material/styles';

import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

type Props = ButtonProps & {
  sx?: SxProps<Theme>;
  onClose?: () => void;
};

export function SignOutButton({ onClose, ...other }: Props) {
  const router = useRouter();

  const { logout, user } = useAuthContext();

//   const handleLogout = async () => {
//     try {
//       await logout();
//       popover.onClose();
//       router.replace('/auth/jwt/login?returnTo=%2F');
//     } catch (error) {
//       console.error(error);
//       enqueueSnackbar('Unable to logout!', { variant: 'error' });
//     }
//   };

  const handleLogout = useCallback(async () => {
    try {
      await logout();

      onClose?.();
      router.replace('/auth/jwt/login?returnTo=%2F');
    } catch (error) {
      console.error(error);
    }
  }, [logout, onClose, router]);

  return (
    <Button fullWidth variant="soft" size="large" color="error" onClick={handleLogout} {...other}>
      Çıkış Yap
    </Button>
  );
}
