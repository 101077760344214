import { useCallback, useEffect, useReducer, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { ConfirmDialog } from '../../components/custom-dialog';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { generateMutationOp, generateQueryOp } from '../../graphql/generated';
import { gql, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import { useCheckout } from '../../store/slices/checkout/checkout.hooks';
import { useOptions } from '../../store/slices/options/hooks.slice';
import { apolloClient } from '../../graphql/provider';
import { aC } from '@fullcalendar/core/internal-common';
import { Alert, AlertTitle, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  const checkout = useCheckout();
  const options = useOptions();
  const { authenticated, user ,selectedCompany} = useAuthContext();
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    selected: JSON.parse(localStorage.getItem('selectedCompany') || 'null'),
  });
  const company= selectedCompany ? selectedCompany : user?.Company?.[0];
  const { query, variables } = generateQueryOp({
    companies: {
      __args: {
        where: {
          active: {
            equals: true,
          },
        },
      },
      id: true,
      name: true,
      active: true,
      code: true,
      Brand: {
        name: true,
      },
    },
  });
  const { data, loading, error } = useQuery(gql(query), { variables });

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = loginPaths.jwt;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      if (!state.selected) {
       options.setOptions({ open: true });
      }
      setChecked(true);
    }
  }, [authenticated, router]);

  const isAdmin = user?.Role?.name === 'Admin';
  const isMultipleCompany = user?.Company?.length > 1;

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }
  const handleClose = () => {
    options.setOptions({ open: false });
  };

  const confirmSelected = () => {
    if (state.selected) {
      setState({ open: false });
      const { query, variables } = generateMutationOp({
        selectCompany: {
          __args: {
            companyId: state.selected.id,
          },
        },
      });
      apolloClient
        .mutate({
          mutation: gql(query),
          variables,
        })
        .then(() => {
          localStorage.setItem('selectedCompany', JSON.stringify(state.selected));
          options.setOptions({ open: false });
          checkout.getCart();
          window.location.reload();
        });
    }
  };
  if (isAdmin && !isMultipleCompany && (!state.selected || !state.open)) {
    return (
      <>
        <ConfirmDialog
          open={options.open}
          title={'Firma Seç'}
          onClose={options.close}
          action={
            <>{company && <Button onClick={handleClose}>İptal</Button>}
              
              <Button variant="outlined" color="inherit" onClick={confirmSelected}>
                Seç
              </Button>
            </>
          }
          content={
            <div
              style={{
                padding: '6px 0px',
              }}
            >
              {company &&
              <Stack direction="column" alignContent={'center'} spacing={1}>
                <Typography variant="body1" color="textSecondary">
                  {' '}
                  Geçerli Firma{' '}
                </Typography>
                <Alert severity="info">
                <AlertTitle>{`${company?.name?.slice(0,25)} ${company?.name?.length > 30 ? '...' : ''}`}</AlertTitle>
                  {`(${company?.code}) ${company?.Brand?.map(
                    (brand: any) => brand.name
                  ).join(' / ') || ''}`}{' '}
                </Alert>
                <Divider />
              </Stack>}
              <Autocomplete
                freeSolo
                renderInput={(params) => (
                  <TextField  placeholder={'Firma Seç'} {...params} />
                )}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.name.toLowerCase().includes(state.inputValue.toLowerCase())
                  );
                }}
                onChange={(_, value) => setState({ selected: value })}
                options={
                  data?.companies?.sort((a: any, b: any) => a.name.localeCompare(b.name)) || []
                }
                getOptionLabel={(option: any) => option.name}
                loading={loading}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <div>
                        <Typography variant="body1">
                          {`(${option.id}) `}
                          {` ${option.name.slice(0, 30)}${option.name.length > 30 ? '...' : ''}`}
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body2" color="textSecondary">
                            {option.code}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`${option.active ? 'Aktif' : 'Pasif'} `}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="body2" color="textSecondary">
                              {option.Brand?.map((brand: any) => brand?.name).join(' / ') || ''}
                            </Typography>
                          </Stack>
                        </Stack>
                      </div>
                    </li>
                  );
                }}
              />
            </div>
          }
        />
        {children}
      </>
    );
  }
  if (isMultipleCompany && (!state.selected || !state.open)) {
    return (
      <>
        <ConfirmDialog
          open={options.open}
          title={'Şirket Seç'}
          onClose={options.close}
          action={

            <>{company && <Button onClick={handleClose}>İptal</Button>}
              
            <Button variant="outlined" color="inherit" onClick={confirmSelected}>
              Seç
            </Button>
          </>
          }
          content={
            <div
              style={{
                padding: '6px 0px',
              }}
            >{company &&
              <Stack direction="column" alignContent={'center'} spacing={1}>
                <Typography variant="body1" color="textSecondary">
                  {' '}
                  Geçerli Firma{' '}
                </Typography>
                <Alert severity="info">
                <AlertTitle>{`${company?.name?.slice(0,40)} ${company?.name?.length > 40 ? '...' : ''}`}</AlertTitle>
                  {`(${company?.code}) ${company?.Brand?.map(
                    (brand: any) => brand.name
                  ).join(' / ') || ''}`}{' '}
                </Alert>
                <Divider />
              </Stack>}
              <Autocomplete
                renderInput={(params) => <TextField label={'Şirket Seç'} {...params} />}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.name.toLowerCase().includes(state.inputValue.toLowerCase())
                  );
                }}
                onChange={(_, value) => setState({ selected: value })}
                options={
                  user?.Company?.filter((company: any) => company.active)?.sort((a: any, b: any) =>
                    a.name.localeCompare(b.name)
                  ) || []
                }
                getOptionLabel={(option: any) => option.name}
                loading={loading}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      <div>
                        <Typography variant="body1">
                          {`(${option.id}) `}
                          {` ${option.name.slice(0, 30)}${option.name.length > 30 ? '...' : ''}`}
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body2" color="textSecondary">
                            {option.code}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`${option.active ? 'Aktif' : 'Pasif'} `}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="body2" color="textSecondary">
                              {option.Brand?.map((brand: any) => brand.name).join(' / ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </div>
                    </li>
                  );
                }}
              />
            </div>
          }
        />
        {children}
      </>
    );
  }
  return <>{children}</>;
}
