import React, { useState, useRef } from 'react';
import { Button, CircularProgress, tableCellClasses } from '@mui/material';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Container,
} from '@mui/material';
import { Stack } from '@mui/system';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import * as XLSX from 'xlsx';
import { useTheme } from '@mui/material/styles';
import BackButton from 'src/components/router';
import { TableHeadCustom, TableSkeleton, useTable } from 'src/components/table';

const TABLE_HEAD = [
  { id: 'active', label: 'Aktif',width: 20, visible: true },
  { id: 'first_name', label: 'Ad', width: 120, visible: true },
  { id: 'last_name', label: 'Soyad', width: 120, visible: true },
  { id: 'email', label: 'Eposta', width: 120, visible: true },
  { id: 'gsm', label: 'Gsm No', width: 40, visible: true },
  { id: 'role_slug', label: 'Rol', width: 40, visible: true },
  { id: 'password_hash', label: 'Şifre', width: 40, visible: true },
  { id: 'company_code', label: 'Firma Kodu', width: 100, visible: true },
  { id: 'company_name', label: 'Firma Adı', width: 140, visible: true },
  { id: 'status', label: 'Durum', width: 40, visible: true },
];

const UserUpload = () => {
  const [visibleColumns, setVisibleColumns] = useState(TABLE_HEAD);
  const [file, setFile] = useState<File | null>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const table = useTable();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const denseHeight = table.dense ? 58 : 78;

  // Dosya seçildiğinde çağrılır
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      handleFile(selectedFile);
    }
  };

  // Dosyayı okuyup tablo verisi haline getirir
  const handleFile = (file: Blob) => {
    setLoading(true);  // Yükleme başlıyor
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const bstr = event.target?.result;
      if (bstr) {
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
        setTableData(worksheet);
      }
      setLoading(false);  // Yükleme tamamlandı
    };
    reader.readAsBinaryString(file);
  };

  // Dosyayı sunucuya yükler ve yanıtı işler
  const onFileUpload = async () => {
    if (!file) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);

      const response = await axiosInstance.post('/upload-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const checkStatus = await axiosInstance.get('/check-csv-upload/users');
      setUploadStatus(checkStatus.data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
      console.error('Dosya yükleme hatası:', error);
    }
  };

  // Yükle butonuna tıklanıldığında dosya seçici açılır
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Container maxWidth="xl">
      {/* Başlık ve Breadcrumbs */}
      <CustomBreadcrumbs
        heading="Dosyadan Kullanıcı Yükle"
        links={[
          { name: 'Ana Sayfa', href: paths.dashboard.root },
          { name: 'Kullanıcılar', href: paths.dashboard.user.root },
          { name: 'Dosyadan Yükle' },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
        action={
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              onClick={handleButtonClick}
              variant="contained"
              startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            >
              Dosya Yükle
            </Button>
            <input
              type="file"
              accept=".csv,.xls,.xlsx"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={onFileChange}
            />
            <BackButton />
          </Stack>
        }
      />

      {/* Veritabanına Kaydet Butonu */}
      {/* Veritabanına Kaydet Butonu */}
      {tableData.length > 0 && (
        <Box sx={{ mt: 3, textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onFileUpload}
            disabled={uploading} // Yüklenirken butonu devre dışı bırak
            startIcon={uploading ? <CircularProgress size={20} /> : null} // Yükleniyorsa progress ikonu göster
          >
            {uploading ? 'Yükleniyor...' : 'Veritabanına Kaydet'}
          </Button>
        </Box>
 )}
      {/* Tablonun Görüntülenmesi */}
      <TableContainer
        sx={{
          mt: 3,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          boxShadow: theme.shadows[1],
        }}
      >
        <Table
          size={table.dense ? 'small' : 'medium'}
          sx={{
            minWidth: 960,
            borderCollapse: 'separate',
            borderSpacing: '0 16px',
          }}
        >
          {/* Tablo Başlıkları */}
          <TableHeadCustom  headLabel={TABLE_HEAD}  visible={visibleColumns}>
              {tableData.length > 0 &&
                Object.keys(tableData[0]).map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      [`& .${tableCellClasses.head}`]: {
                        '&:first-of-type': {
                          borderTopLeftRadius: 12,
                          borderBottomLeftRadius: 12,
                        },
                        '&:last-of-type': {
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                        },
                      },
                    }}
                  >
                    {key}
                  </TableCell>
                ))}
              <TableCell
                sx={{
                  bgcolor: theme.palette.background.paper,
                  fontWeight: 'bold',
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                }}
              >
                Durum
              </TableCell>
          </TableHeadCustom>

          {/* Tablo Verileri */}
          <TableBody>
            {loading ? (
              [...Array(table.rowsPerPage)].map((_, index) => (
                <TableSkeleton key={`skeleton-${index}`} sx={{ height: denseHeight }} />
              ))
            ) : (
              tableData.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, i) => (
                    <TableCell key={i}>{value}</TableCell>
                  ))}
                  <TableCell sx={{ alignItems: 'center' }}>
                    {uploadStatus[index] !== undefined
                      ? uploadStatus[index]
                      : 'Yüklenmedi'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default UserUpload;
