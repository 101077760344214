export default {
    "scalars": [
        3,
        5,
        8,
        9,
        10,
        11,
        12,
        13,
        21,
        41,
        46,
        52,
        57,
        64,
        67,
        76,
        79,
        81,
        111,
        116,
        134,
        154,
        185,
        188,
        191,
        211,
        227,
        229,
        242,
        247,
        250,
        252,
        258,
        272,
        275,
        280,
        282,
        284,
        288,
        290,
        305,
        307,
        309,
        313,
        318,
        320,
        323,
        325,
        335,
        337,
        340,
        343,
        345,
        349,
        353,
        360,
        363,
        365,
        370,
        372,
        388,
        391,
        394,
        398,
        406,
        408,
        410,
        413,
        415,
        421,
        423,
        428,
        432,
        434,
        436,
        438,
        448,
        451,
        453,
        455,
        457,
        466,
        468,
        478,
        482,
        484,
        486,
        488,
        497,
        500,
        505,
        507,
        511,
        513,
        517,
        519,
        528,
        533,
        535,
        537,
        539,
        541,
        550,
        552,
        554,
        556,
        558,
        560,
        567,
        569,
        573,
        577,
        581,
        583,
        587,
        589,
        595,
        599,
        601,
        636,
        680,
        754,
        821,
        853,
        856,
        918,
        921,
        969,
        972,
        990,
        993,
        1025,
        1028,
        1046,
        1049,
        1081,
        1173,
        1191,
        1194,
        1258,
        1274,
        1306,
        1309,
        1353,
        1427,
        1489,
        1505,
        1569,
        1597,
        1598,
        1607,
        1610,
        1632,
        1635
    ],
    "types": {
        "Query": {
            "aggregateAddress": [
                1,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstAddress": [
                254,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        258,
                        "[AddressScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstAddressOrThrow": [
                254,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        258,
                        "[AddressScalarFieldEnum!]"
                    ]
                }
            ],
            "addresses": [
                254,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        258,
                        "[AddressScalarFieldEnum!]"
                    ]
                }
            ],
            "address": [
                254,
                {
                    "where": [
                        253,
                        "AddressWhereUniqueInput!"
                    ]
                }
            ],
            "getAddress": [
                254,
                {
                    "where": [
                        253,
                        "AddressWhereUniqueInput!"
                    ]
                }
            ],
            "groupByAddress": [
                602,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        603,
                        "[AddressOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        258,
                        "[AddressScalarFieldEnum!]!"
                    ],
                    "having": [
                        609
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateAttribute": [
                629,
                {
                    "where": [
                        169
                    ],
                    "orderBy": [
                        495,
                        "[AttributeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        635
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstAttribute": [
                492,
                {
                    "where": [
                        169
                    ],
                    "orderBy": [
                        495,
                        "[AttributeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        635
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        636,
                        "[AttributeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstAttributeOrThrow": [
                492,
                {
                    "where": [
                        169
                    ],
                    "orderBy": [
                        495,
                        "[AttributeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        635
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        636,
                        "[AttributeScalarFieldEnum!]"
                    ]
                }
            ],
            "attributes": [
                492,
                {
                    "where": [
                        169
                    ],
                    "orderBy": [
                        495,
                        "[AttributeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        635
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        636,
                        "[AttributeScalarFieldEnum!]"
                    ]
                }
            ],
            "attribute": [
                492,
                {
                    "where": [
                        635,
                        "AttributeWhereUniqueInput!"
                    ]
                }
            ],
            "getAttribute": [
                492,
                {
                    "where": [
                        635,
                        "AttributeWhereUniqueInput!"
                    ]
                }
            ],
            "groupByAttribute": [
                637,
                {
                    "where": [
                        169
                    ],
                    "orderBy": [
                        638,
                        "[AttributeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        636,
                        "[AttributeScalarFieldEnum!]!"
                    ],
                    "having": [
                        644
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateAttributeToProductType": [
                645,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstAttributeToProductType": [
                491,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstAttributeToProductTypeOrThrow": [
                491,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "attributeToProductTypes": [
                491,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "attributeToProductType": [
                491,
                {
                    "where": [
                        498,
                        "AttributeToProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "getAttributeToProductType": [
                491,
                {
                    "where": [
                        498,
                        "AttributeToProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "groupByAttributeToProductType": [
                651,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        652,
                        "[AttributeToProductTypeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]!"
                    ],
                    "having": [
                        658
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateAttributeValue": [
                659,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstAttributeValue": [
                501,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        507,
                        "[AttributeValueScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstAttributeValueOrThrow": [
                501,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        507,
                        "[AttributeValueScalarFieldEnum!]"
                    ]
                }
            ],
            "attributeValues": [
                501,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        507,
                        "[AttributeValueScalarFieldEnum!]"
                    ]
                }
            ],
            "attributeValue": [
                501,
                {
                    "where": [
                        506,
                        "AttributeValueWhereUniqueInput!"
                    ]
                }
            ],
            "getAttributeValue": [
                501,
                {
                    "where": [
                        506,
                        "AttributeValueWhereUniqueInput!"
                    ]
                }
            ],
            "groupByAttributeValue": [
                665,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        666,
                        "[AttributeValueOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        507,
                        "[AttributeValueScalarFieldEnum!]!"
                    ],
                    "having": [
                        672
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateBank": [
                673,
                {
                    "where": [
                        84
                    ],
                    "orderBy": [
                        300,
                        "[BankOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        679
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstBank": [
                296,
                {
                    "where": [
                        84
                    ],
                    "orderBy": [
                        300,
                        "[BankOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        679
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        680,
                        "[BankScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstBankOrThrow": [
                296,
                {
                    "where": [
                        84
                    ],
                    "orderBy": [
                        300,
                        "[BankOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        679
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        680,
                        "[BankScalarFieldEnum!]"
                    ]
                }
            ],
            "banks": [
                296,
                {
                    "where": [
                        84
                    ],
                    "orderBy": [
                        300,
                        "[BankOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        679
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        680,
                        "[BankScalarFieldEnum!]"
                    ]
                }
            ],
            "bank": [
                296,
                {
                    "where": [
                        679,
                        "BankWhereUniqueInput!"
                    ]
                }
            ],
            "getBank": [
                296,
                {
                    "where": [
                        679,
                        "BankWhereUniqueInput!"
                    ]
                }
            ],
            "groupByBank": [
                681,
                {
                    "where": [
                        84
                    ],
                    "orderBy": [
                        682,
                        "[BankOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        680,
                        "[BankScalarFieldEnum!]!"
                    ],
                    "having": [
                        688
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateBarcode": [
                691,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        380,
                        "[BarcodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        409
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstBarcode": [
                379,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        380,
                        "[BarcodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        409
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        410,
                        "[BarcodeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstBarcodeOrThrow": [
                379,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        380,
                        "[BarcodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        409
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        410,
                        "[BarcodeScalarFieldEnum!]"
                    ]
                }
            ],
            "barcodes": [
                379,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        380,
                        "[BarcodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        409
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        410,
                        "[BarcodeScalarFieldEnum!]"
                    ]
                }
            ],
            "barcode": [
                379,
                {
                    "where": [
                        409,
                        "BarcodeWhereUniqueInput!"
                    ]
                }
            ],
            "getBarcode": [
                379,
                {
                    "where": [
                        409,
                        "BarcodeWhereUniqueInput!"
                    ]
                }
            ],
            "groupByBarcode": [
                697,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        698,
                        "[BarcodeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        410,
                        "[BarcodeScalarFieldEnum!]!"
                    ],
                    "having": [
                        704
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateBinNumber": [
                705,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        299,
                        "[BinNumberOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        308
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstBinNumber": [
                298,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        299,
                        "[BinNumberOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        308
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        309,
                        "[BinNumberScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstBinNumberOrThrow": [
                298,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        299,
                        "[BinNumberOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        308
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        309,
                        "[BinNumberScalarFieldEnum!]"
                    ]
                }
            ],
            "binNumbers": [
                298,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        299,
                        "[BinNumberOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        308
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        309,
                        "[BinNumberScalarFieldEnum!]"
                    ]
                }
            ],
            "binNumber": [
                298,
                {
                    "where": [
                        308,
                        "BinNumberWhereUniqueInput!"
                    ]
                }
            ],
            "getBinNumber": [
                298,
                {
                    "where": [
                        308,
                        "BinNumberWhereUniqueInput!"
                    ]
                }
            ],
            "groupByBinNumber": [
                711,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        712,
                        "[BinNumberOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        309,
                        "[BinNumberScalarFieldEnum!]!"
                    ],
                    "having": [
                        718
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateBrand": [
                719,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstBrand": [
                439,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        468,
                        "[BrandScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstBrandOrThrow": [
                439,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        468,
                        "[BrandScalarFieldEnum!]"
                    ]
                }
            ],
            "brands": [
                439,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        468,
                        "[BrandScalarFieldEnum!]"
                    ]
                }
            ],
            "brand": [
                439,
                {
                    "where": [
                        467,
                        "BrandWhereUniqueInput!"
                    ]
                }
            ],
            "getBrand": [
                439,
                {
                    "where": [
                        467,
                        "BrandWhereUniqueInput!"
                    ]
                }
            ],
            "groupByBrand": [
                725,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        726,
                        "[BrandOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        468,
                        "[BrandScalarFieldEnum!]!"
                    ],
                    "having": [
                        732
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCampaign": [
                733,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        446,
                        "[CampaignOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        557
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCampaign": [
                443,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        446,
                        "[CampaignOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        557
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        558,
                        "[CampaignScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCampaignOrThrow": [
                443,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        446,
                        "[CampaignOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        557
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        558,
                        "[CampaignScalarFieldEnum!]"
                    ]
                }
            ],
            "campaigns": [
                443,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        446,
                        "[CampaignOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        557
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        558,
                        "[CampaignScalarFieldEnum!]"
                    ]
                }
            ],
            "campaign": [
                443,
                {
                    "where": [
                        557,
                        "CampaignWhereUniqueInput!"
                    ]
                }
            ],
            "getCampaign": [
                443,
                {
                    "where": [
                        557,
                        "CampaignWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCampaign": [
                739,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        740,
                        "[CampaignOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        558,
                        "[CampaignScalarFieldEnum!]!"
                    ],
                    "having": [
                        746
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCargoAgency": [
                747,
                {
                    "where": [
                        157
                    ],
                    "orderBy": [
                        425,
                        "[CargoAgencyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        753
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCargoAgency": [
                475,
                {
                    "where": [
                        157
                    ],
                    "orderBy": [
                        425,
                        "[CargoAgencyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        753
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        754,
                        "[CargoAgencyScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCargoAgencyOrThrow": [
                475,
                {
                    "where": [
                        157
                    ],
                    "orderBy": [
                        425,
                        "[CargoAgencyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        753
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        754,
                        "[CargoAgencyScalarFieldEnum!]"
                    ]
                }
            ],
            "cargoAgencies": [
                475,
                {
                    "where": [
                        157
                    ],
                    "orderBy": [
                        425,
                        "[CargoAgencyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        753
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        754,
                        "[CargoAgencyScalarFieldEnum!]"
                    ]
                }
            ],
            "cargoAgency": [
                475,
                {
                    "where": [
                        753,
                        "CargoAgencyWhereUniqueInput!"
                    ]
                }
            ],
            "getCargoAgency": [
                475,
                {
                    "where": [
                        753,
                        "CargoAgencyWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCargoAgency": [
                755,
                {
                    "where": [
                        157
                    ],
                    "orderBy": [
                        756,
                        "[CargoAgencyOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        754,
                        "[CargoAgencyScalarFieldEnum!]!"
                    ],
                    "having": [
                        762
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCart": [
                763,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCart": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCartOrThrow": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "carts": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "cart": [
                259,
                {
                    "where": [
                        283,
                        "CartWhereUniqueInput!"
                    ]
                }
            ],
            "getCart": [
                769
            ],
            "groupByCart": [
                770,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        771,
                        "[CartOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        284,
                        "[CartScalarFieldEnum!]!"
                    ],
                    "having": [
                        777
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCartItem": [
                784,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCartItem": [
                375,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        415,
                        "[CartItemScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCartItemOrThrow": [
                375,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        415,
                        "[CartItemScalarFieldEnum!]"
                    ]
                }
            ],
            "cartItems": [
                375,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        415,
                        "[CartItemScalarFieldEnum!]"
                    ]
                }
            ],
            "cartItem": [
                375,
                {
                    "where": [
                        414,
                        "CartItemWhereUniqueInput!"
                    ]
                }
            ],
            "getCartItem": [
                375,
                {
                    "where": [
                        414,
                        "CartItemWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCartItem": [
                790,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        791,
                        "[CartItemOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        415,
                        "[CartItemScalarFieldEnum!]!"
                    ],
                    "having": [
                        797
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCategory": [
                800,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        389,
                        "[CategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        485
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCategory": [
                458,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        389,
                        "[CategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        485
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        486,
                        "[CategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCategoryOrThrow": [
                458,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        389,
                        "[CategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        485
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        486,
                        "[CategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "categories": [
                458,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        389,
                        "[CategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        485
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        486,
                        "[CategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "category": [
                458,
                {
                    "where": [
                        485,
                        "CategoryWhereUniqueInput!"
                    ]
                }
            ],
            "getCategory": [
                458,
                {
                    "where": [
                        485,
                        "CategoryWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCategory": [
                806,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        807,
                        "[CategoryOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        486,
                        "[CategoryScalarFieldEnum!]!"
                    ],
                    "having": [
                        813
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCity": [
                814,
                {
                    "where": [
                        222
                    ],
                    "orderBy": [
                        244,
                        "[CityOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        820
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCity": [
                592,
                {
                    "where": [
                        222
                    ],
                    "orderBy": [
                        244,
                        "[CityOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        820
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        821,
                        "[CityScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCityOrThrow": [
                592,
                {
                    "where": [
                        222
                    ],
                    "orderBy": [
                        244,
                        "[CityOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        820
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        821,
                        "[CityScalarFieldEnum!]"
                    ]
                }
            ],
            "cities": [
                592,
                {
                    "where": [
                        222
                    ],
                    "orderBy": [
                        244,
                        "[CityOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        820
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        821,
                        "[CityScalarFieldEnum!]"
                    ]
                }
            ],
            "city": [
                592,
                {
                    "where": [
                        820,
                        "CityWhereUniqueInput!"
                    ]
                }
            ],
            "getCity": [
                592,
                {
                    "where": [
                        820,
                        "CityWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCity": [
                822,
                {
                    "where": [
                        222
                    ],
                    "orderBy": [
                        823,
                        "[CityOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        821,
                        "[CityScalarFieldEnum!]!"
                    ],
                    "having": [
                        829
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCollection": [
                830,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        449,
                        "[CollectionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        559
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCollection": [
                469,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        449,
                        "[CollectionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        559
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        560,
                        "[CollectionScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCollectionOrThrow": [
                469,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        449,
                        "[CollectionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        559
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        560,
                        "[CollectionScalarFieldEnum!]"
                    ]
                }
            ],
            "collections": [
                469,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        449,
                        "[CollectionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        559
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        560,
                        "[CollectionScalarFieldEnum!]"
                    ]
                }
            ],
            "collection": [
                469,
                {
                    "where": [
                        559,
                        "CollectionWhereUniqueInput!"
                    ]
                }
            ],
            "getCollection": [
                469,
                {
                    "where": [
                        559,
                        "CollectionWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCollection": [
                836,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        837,
                        "[CollectionOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        560,
                        "[CollectionScalarFieldEnum!]!"
                    ],
                    "having": [
                        843
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateComment": [
                844,
                {
                    "where": [
                        850
                    ],
                    "orderBy": [
                        851,
                        "[CommentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        854
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstComment": [
                855,
                {
                    "where": [
                        850
                    ],
                    "orderBy": [
                        851,
                        "[CommentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        854
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        856,
                        "[CommentScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCommentOrThrow": [
                855,
                {
                    "where": [
                        850
                    ],
                    "orderBy": [
                        851,
                        "[CommentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        854
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        856,
                        "[CommentScalarFieldEnum!]"
                    ]
                }
            ],
            "comments": [
                855,
                {
                    "where": [
                        850
                    ],
                    "orderBy": [
                        851,
                        "[CommentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        854
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        856,
                        "[CommentScalarFieldEnum!]"
                    ]
                }
            ],
            "comment": [
                855,
                {
                    "where": [
                        854,
                        "CommentWhereUniqueInput!"
                    ]
                }
            ],
            "getComment": [
                855,
                {
                    "where": [
                        854,
                        "CommentWhereUniqueInput!"
                    ]
                }
            ],
            "groupByComment": [
                857,
                {
                    "where": [
                        850
                    ],
                    "orderBy": [
                        858,
                        "[CommentOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        856,
                        "[CommentScalarFieldEnum!]!"
                    ],
                    "having": [
                        864
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCompany": [
                867,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCompany": [
                256,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        488,
                        "[CompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCompanyOrThrow": [
                256,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        488,
                        "[CompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "companies": [
                256,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        488,
                        "[CompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "company": [
                256,
                {
                    "where": [
                        487,
                        "CompanyWhereUniqueInput!"
                    ]
                }
            ],
            "getCompany": [
                256,
                {
                    "where": [
                        487,
                        "CompanyWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCompany": [
                873,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        874,
                        "[CompanyOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        488,
                        "[CompanyScalarFieldEnum!]!"
                    ],
                    "having": [
                        880
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCompanyStatement": [
                881,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        585,
                        "[CompanyStatementOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        588
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCompanyStatement": [
                584,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        585,
                        "[CompanyStatementOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        588
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        589,
                        "[CompanyStatementScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCompanyStatementOrThrow": [
                584,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        585,
                        "[CompanyStatementOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        588
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        589,
                        "[CompanyStatementScalarFieldEnum!]"
                    ]
                }
            ],
            "companyStatements": [
                584,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        585,
                        "[CompanyStatementOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        588
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        589,
                        "[CompanyStatementScalarFieldEnum!]"
                    ]
                }
            ],
            "companyStatement": [
                584,
                {
                    "where": [
                        588,
                        "CompanyStatementWhereUniqueInput!"
                    ]
                }
            ],
            "getCompanyStatement": [
                584,
                {
                    "where": [
                        588,
                        "CompanyStatementWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCompanyStatement": [
                887,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        888,
                        "[CompanyStatementOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        589,
                        "[CompanyStatementScalarFieldEnum!]!"
                    ],
                    "having": [
                        894
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCompanyToPaymentMethod": [
                895,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCompanyToPaymentMethodOrThrow": [
                356,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "companyToPaymentMethods": [
                356,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "companyToPaymentMethod": [
                356,
                {
                    "where": [
                        358,
                        "CompanyToPaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "getCompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        358,
                        "CompanyToPaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCompanyToPaymentMethod": [
                901,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        902,
                        "[CompanyToPaymentMethodOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]!"
                    ],
                    "having": [
                        908
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateConfiguration": [
                909,
                {
                    "where": [
                        915
                    ],
                    "orderBy": [
                        916,
                        "[ConfigurationOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        919
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstConfiguration": [
                920,
                {
                    "where": [
                        915
                    ],
                    "orderBy": [
                        916,
                        "[ConfigurationOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        919
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        921,
                        "[ConfigurationScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstConfigurationOrThrow": [
                920,
                {
                    "where": [
                        915
                    ],
                    "orderBy": [
                        916,
                        "[ConfigurationOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        919
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        921,
                        "[ConfigurationScalarFieldEnum!]"
                    ]
                }
            ],
            "configurations": [
                920,
                {
                    "where": [
                        915
                    ],
                    "orderBy": [
                        916,
                        "[ConfigurationOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        919
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        921,
                        "[ConfigurationScalarFieldEnum!]"
                    ]
                }
            ],
            "configuration": [
                920,
                {
                    "where": [
                        919,
                        "ConfigurationWhereUniqueInput!"
                    ]
                }
            ],
            "getConfiguration": [
                920,
                {
                    "where": [
                        919,
                        "ConfigurationWhereUniqueInput!"
                    ]
                }
            ],
            "groupByConfiguration": [
                922,
                {
                    "where": [
                        915
                    ],
                    "orderBy": [
                        923,
                        "[ConfigurationOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        921,
                        "[ConfigurationScalarFieldEnum!]!"
                    ],
                    "having": [
                        929
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateConfirmCode": [
                930,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        286,
                        "[ConfirmCodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        289
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstConfirmCode": [
                285,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        286,
                        "[ConfirmCodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        289
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        290,
                        "[ConfirmCodeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstConfirmCodeOrThrow": [
                285,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        286,
                        "[ConfirmCodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        289
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        290,
                        "[ConfirmCodeScalarFieldEnum!]"
                    ]
                }
            ],
            "confirmCodes": [
                285,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        286,
                        "[ConfirmCodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        289
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        290,
                        "[ConfirmCodeScalarFieldEnum!]"
                    ]
                }
            ],
            "confirmCode": [
                285,
                {
                    "where": [
                        289,
                        "ConfirmCodeWhereUniqueInput!"
                    ]
                }
            ],
            "getConfirmCode": [
                285,
                {
                    "where": [
                        289,
                        "ConfirmCodeWhereUniqueInput!"
                    ]
                }
            ],
            "groupByConfirmCode": [
                936,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        937,
                        "[ConfirmCodeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        290,
                        "[ConfirmCodeScalarFieldEnum!]!"
                    ],
                    "having": [
                        943
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateCounty": [
                946,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        243,
                        "[CountyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        594
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstCounty": [
                590,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        243,
                        "[CountyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        594
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        595,
                        "[CountyScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstCountyOrThrow": [
                590,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        243,
                        "[CountyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        594
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        595,
                        "[CountyScalarFieldEnum!]"
                    ]
                }
            ],
            "counties": [
                590,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        243,
                        "[CountyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        594
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        595,
                        "[CountyScalarFieldEnum!]"
                    ]
                }
            ],
            "county": [
                590,
                {
                    "where": [
                        594,
                        "CountyWhereUniqueInput!"
                    ]
                }
            ],
            "getCounty": [
                590,
                {
                    "where": [
                        594,
                        "CountyWhereUniqueInput!"
                    ]
                }
            ],
            "groupByCounty": [
                952,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        953,
                        "[CountyOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        595,
                        "[CountyScalarFieldEnum!]!"
                    ],
                    "having": [
                        959
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateDBQuery": [
                960,
                {
                    "where": [
                        966
                    ],
                    "orderBy": [
                        967,
                        "[DBQueryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        970
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstDBQuery": [
                971,
                {
                    "where": [
                        966
                    ],
                    "orderBy": [
                        967,
                        "[DBQueryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        970
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        972,
                        "[DBQueryScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstDBQueryOrThrow": [
                971,
                {
                    "where": [
                        966
                    ],
                    "orderBy": [
                        967,
                        "[DBQueryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        970
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        972,
                        "[DBQueryScalarFieldEnum!]"
                    ]
                }
            ],
            "dBQueries": [
                971,
                {
                    "where": [
                        966
                    ],
                    "orderBy": [
                        967,
                        "[DBQueryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        970
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        972,
                        "[DBQueryScalarFieldEnum!]"
                    ]
                }
            ],
            "dBQuery": [
                971,
                {
                    "where": [
                        970,
                        "DBQueryWhereUniqueInput!"
                    ]
                }
            ],
            "getDBQuery": [
                971,
                {
                    "where": [
                        970,
                        "DBQueryWhereUniqueInput!"
                    ]
                }
            ],
            "groupByDBQuery": [
                973,
                {
                    "where": [
                        966
                    ],
                    "orderBy": [
                        974,
                        "[DBQueryOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        972,
                        "[DBQueryScalarFieldEnum!]!"
                    ],
                    "having": [
                        980
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateDevice": [
                981,
                {
                    "where": [
                        987
                    ],
                    "orderBy": [
                        988,
                        "[DeviceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        991
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstDevice": [
                992,
                {
                    "where": [
                        987
                    ],
                    "orderBy": [
                        988,
                        "[DeviceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        991
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        993,
                        "[DeviceScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstDeviceOrThrow": [
                992,
                {
                    "where": [
                        987
                    ],
                    "orderBy": [
                        988,
                        "[DeviceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        991
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        993,
                        "[DeviceScalarFieldEnum!]"
                    ]
                }
            ],
            "devices": [
                992,
                {
                    "where": [
                        987
                    ],
                    "orderBy": [
                        988,
                        "[DeviceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        991
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        993,
                        "[DeviceScalarFieldEnum!]"
                    ]
                }
            ],
            "device": [
                992,
                {
                    "where": [
                        991,
                        "DeviceWhereUniqueInput!"
                    ]
                }
            ],
            "getDevice": [
                992,
                {
                    "where": [
                        991,
                        "DeviceWhereUniqueInput!"
                    ]
                }
            ],
            "groupByDevice": [
                994,
                {
                    "where": [
                        987
                    ],
                    "orderBy": [
                        995,
                        "[DeviceOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        993,
                        "[DeviceScalarFieldEnum!]!"
                    ],
                    "having": [
                        1001
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateDistrict": [
                1002,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        597,
                        "[DistrictOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        600
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstDistrict": [
                596,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        597,
                        "[DistrictOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        600
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        601,
                        "[DistrictScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstDistrictOrThrow": [
                596,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        597,
                        "[DistrictOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        600
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        601,
                        "[DistrictScalarFieldEnum!]"
                    ]
                }
            ],
            "districts": [
                596,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        597,
                        "[DistrictOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        600
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        601,
                        "[DistrictScalarFieldEnum!]"
                    ]
                }
            ],
            "district": [
                596,
                {
                    "where": [
                        600,
                        "DistrictWhereUniqueInput!"
                    ]
                }
            ],
            "getDistrict": [
                596,
                {
                    "where": [
                        600,
                        "DistrictWhereUniqueInput!"
                    ]
                }
            ],
            "groupByDistrict": [
                1008,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        1009,
                        "[DistrictOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        601,
                        "[DistrictScalarFieldEnum!]!"
                    ],
                    "having": [
                        1015
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateError": [
                1016,
                {
                    "where": [
                        1022
                    ],
                    "orderBy": [
                        1023,
                        "[ErrorOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1026
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstError": [
                1027,
                {
                    "where": [
                        1022
                    ],
                    "orderBy": [
                        1023,
                        "[ErrorOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1026
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1028,
                        "[ErrorScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstErrorOrThrow": [
                1027,
                {
                    "where": [
                        1022
                    ],
                    "orderBy": [
                        1023,
                        "[ErrorOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1026
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1028,
                        "[ErrorScalarFieldEnum!]"
                    ]
                }
            ],
            "errors": [
                1027,
                {
                    "where": [
                        1022
                    ],
                    "orderBy": [
                        1023,
                        "[ErrorOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1026
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1028,
                        "[ErrorScalarFieldEnum!]"
                    ]
                }
            ],
            "error": [
                1027,
                {
                    "where": [
                        1026,
                        "ErrorWhereUniqueInput!"
                    ]
                }
            ],
            "getError": [
                1027,
                {
                    "where": [
                        1026,
                        "ErrorWhereUniqueInput!"
                    ]
                }
            ],
            "groupByError": [
                1029,
                {
                    "where": [
                        1022
                    ],
                    "orderBy": [
                        1030,
                        "[ErrorOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1028,
                        "[ErrorScalarFieldEnum!]!"
                    ],
                    "having": [
                        1036
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateFileManager": [
                1037,
                {
                    "where": [
                        1043
                    ],
                    "orderBy": [
                        1044,
                        "[FileManagerOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1047
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstFileManager": [
                1048,
                {
                    "where": [
                        1043
                    ],
                    "orderBy": [
                        1044,
                        "[FileManagerOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1047
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1049,
                        "[FileManagerScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstFileManagerOrThrow": [
                1048,
                {
                    "where": [
                        1043
                    ],
                    "orderBy": [
                        1044,
                        "[FileManagerOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1047
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1049,
                        "[FileManagerScalarFieldEnum!]"
                    ]
                }
            ],
            "fileManagers": [
                1048,
                {
                    "where": [
                        1043
                    ],
                    "orderBy": [
                        1044,
                        "[FileManagerOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1047
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1049,
                        "[FileManagerScalarFieldEnum!]"
                    ]
                }
            ],
            "fileManager": [
                1048,
                {
                    "where": [
                        1047,
                        "FileManagerWhereUniqueInput!"
                    ]
                }
            ],
            "getFileManager": [
                1048,
                {
                    "where": [
                        1047,
                        "FileManagerWhereUniqueInput!"
                    ]
                }
            ],
            "groupByFileManager": [
                1050,
                {
                    "where": [
                        1043
                    ],
                    "orderBy": [
                        1051,
                        "[FileManagerOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1049,
                        "[FileManagerScalarFieldEnum!]!"
                    ],
                    "having": [
                        1057
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateImage": [
                1058,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstImage": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstImageOrThrow": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "images": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "image": [
                441,
                {
                    "where": [
                        454,
                        "ImageWhereUniqueInput!"
                    ]
                }
            ],
            "getImage": [
                441,
                {
                    "where": [
                        454,
                        "ImageWhereUniqueInput!"
                    ]
                }
            ],
            "groupByImage": [
                1064,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        1065,
                        "[ImageOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        455,
                        "[ImageScalarFieldEnum!]!"
                    ],
                    "having": [
                        1071
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateInstallment": [
                1074,
                {
                    "where": [
                        93
                    ],
                    "orderBy": [
                        333,
                        "[InstallmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1080
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstInstallment": [
                329,
                {
                    "where": [
                        93
                    ],
                    "orderBy": [
                        333,
                        "[InstallmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1080
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1081,
                        "[InstallmentScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstInstallmentOrThrow": [
                329,
                {
                    "where": [
                        93
                    ],
                    "orderBy": [
                        333,
                        "[InstallmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1080
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1081,
                        "[InstallmentScalarFieldEnum!]"
                    ]
                }
            ],
            "installments": [
                329,
                {
                    "where": [
                        93
                    ],
                    "orderBy": [
                        333,
                        "[InstallmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1080
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1081,
                        "[InstallmentScalarFieldEnum!]"
                    ]
                }
            ],
            "installment": [
                329,
                {
                    "where": [
                        1080,
                        "InstallmentWhereUniqueInput!"
                    ]
                }
            ],
            "getInstallment": [
                329,
                {
                    "where": [
                        1080,
                        "InstallmentWhereUniqueInput!"
                    ]
                }
            ],
            "groupByInstallment": [
                1082,
                {
                    "where": [
                        93
                    ],
                    "orderBy": [
                        1083,
                        "[InstallmentOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1081,
                        "[InstallmentScalarFieldEnum!]!"
                    ],
                    "having": [
                        1089
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateInstallmentToCompany": [
                1090,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstInstallmentToCompany": [
                331,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstInstallmentToCompanyOrThrow": [
                331,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "installmentToCompanies": [
                331,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "installmentToCompany": [
                331,
                {
                    "where": [
                        338,
                        "InstallmentToCompanyWhereUniqueInput!"
                    ]
                }
            ],
            "getInstallmentToCompany": [
                331,
                {
                    "where": [
                        338,
                        "InstallmentToCompanyWhereUniqueInput!"
                    ]
                }
            ],
            "groupByInstallmentToCompany": [
                1096,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        1097,
                        "[InstallmentToCompanyOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]!"
                    ],
                    "having": [
                        1103
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateInstallmentToPosAccount": [
                1104,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstInstallmentToPosAccount": [
                328,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstInstallmentToPosAccountOrThrow": [
                328,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "installmentToPosAccounts": [
                328,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "installmentToPosAccount": [
                328,
                {
                    "where": [
                        344,
                        "InstallmentToPosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "getInstallmentToPosAccount": [
                328,
                {
                    "where": [
                        344,
                        "InstallmentToPosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "groupByInstallmentToPosAccount": [
                1110,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        1111,
                        "[InstallmentToPosAccountOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]!"
                    ],
                    "having": [
                        1117
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateOrder": [
                1118,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstOrder": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstOrderOrThrow": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "orders": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "order": [
                291,
                {
                    "where": [
                        568,
                        "OrderWhereUniqueInput!"
                    ]
                }
            ],
            "getOrder": [
                291,
                {
                    "where": [
                        568,
                        "OrderWhereUniqueInput!"
                    ]
                }
            ],
            "groupByOrder": [
                1124,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        1125,
                        "[OrderOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        569,
                        "[OrderScalarFieldEnum!]!"
                    ],
                    "having": [
                        1131
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateOrderItem": [
                1136,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstOrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstOrderItemOrThrow": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "orderItems": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "orderItem": [
                373,
                {
                    "where": [
                        437,
                        "OrderItemWhereUniqueInput!"
                    ]
                }
            ],
            "getOrderItem": [
                373,
                {
                    "where": [
                        437,
                        "OrderItemWhereUniqueInput!"
                    ]
                }
            ],
            "groupByOrderItem": [
                1142,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        1143,
                        "[OrderItemOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        438,
                        "[OrderItemScalarFieldEnum!]!"
                    ],
                    "having": [
                        1149
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateOrderItemStatus": [
                1152,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        417,
                        "[OrderItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        566
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstOrderItemStatus": [
                562,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        417,
                        "[OrderItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        566
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        567,
                        "[OrderItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstOrderItemStatusOrThrow": [
                562,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        417,
                        "[OrderItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        566
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        567,
                        "[OrderItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "orderItemStatuses": [
                562,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        417,
                        "[OrderItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        566
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        567,
                        "[OrderItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "orderItemStatus": [
                562,
                {
                    "where": [
                        566,
                        "OrderItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "getOrderItemStatus": [
                562,
                {
                    "where": [
                        566,
                        "OrderItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "groupByOrderItemStatus": [
                1158,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        1159,
                        "[OrderItemStatusOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        567,
                        "[OrderItemStatusScalarFieldEnum!]!"
                    ],
                    "having": [
                        1165
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateOrderStatus": [
                1166,
                {
                    "where": [
                        206
                    ],
                    "orderBy": [
                        418,
                        "[OrderStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1172
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstOrderStatus": [
                564,
                {
                    "where": [
                        206
                    ],
                    "orderBy": [
                        418,
                        "[OrderStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1172
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1173,
                        "[OrderStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstOrderStatusOrThrow": [
                564,
                {
                    "where": [
                        206
                    ],
                    "orderBy": [
                        418,
                        "[OrderStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1172
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1173,
                        "[OrderStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "orderStatuses": [
                564,
                {
                    "where": [
                        206
                    ],
                    "orderBy": [
                        418,
                        "[OrderStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1172
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1173,
                        "[OrderStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "orderStatus": [
                564,
                {
                    "where": [
                        1172,
                        "OrderStatusWhereUniqueInput!"
                    ]
                }
            ],
            "getOrderStatus": [
                564,
                {
                    "where": [
                        1172,
                        "OrderStatusWhereUniqueInput!"
                    ]
                }
            ],
            "groupByOrderStatus": [
                1174,
                {
                    "where": [
                        206
                    ],
                    "orderBy": [
                        1175,
                        "[OrderStatusOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1173,
                        "[OrderStatusScalarFieldEnum!]!"
                    ],
                    "having": [
                        1181
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateOrigin": [
                1182,
                {
                    "where": [
                        1188
                    ],
                    "orderBy": [
                        1189,
                        "[OriginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1192
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstOrigin": [
                1193,
                {
                    "where": [
                        1188
                    ],
                    "orderBy": [
                        1189,
                        "[OriginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1192
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1194,
                        "[OriginScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstOriginOrThrow": [
                1193,
                {
                    "where": [
                        1188
                    ],
                    "orderBy": [
                        1189,
                        "[OriginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1192
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1194,
                        "[OriginScalarFieldEnum!]"
                    ]
                }
            ],
            "origins": [
                1193,
                {
                    "where": [
                        1188
                    ],
                    "orderBy": [
                        1189,
                        "[OriginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1192
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1194,
                        "[OriginScalarFieldEnum!]"
                    ]
                }
            ],
            "origin": [
                1193,
                {
                    "where": [
                        1192,
                        "OriginWhereUniqueInput!"
                    ]
                }
            ],
            "getOrigin": [
                1193,
                {
                    "where": [
                        1192,
                        "OriginWhereUniqueInput!"
                    ]
                }
            ],
            "groupByOrigin": [
                1195,
                {
                    "where": [
                        1188
                    ],
                    "orderBy": [
                        1196,
                        "[OriginOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1194,
                        "[OriginScalarFieldEnum!]!"
                    ],
                    "having": [
                        1202
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePayment": [
                1203,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPayment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPaymentOrThrow": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "payments": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "payment": [
                294,
                {
                    "where": [
                        324,
                        "PaymentWhereUniqueInput!"
                    ]
                }
            ],
            "getPayment": [
                294,
                {
                    "where": [
                        324,
                        "PaymentWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPayment": [
                1209,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        1210,
                        "[PaymentOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        325,
                        "[PaymentScalarFieldEnum!]!"
                    ],
                    "having": [
                        1216
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePaymentCheck": [
                1221,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        368,
                        "[PaymentCheckOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        371
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPaymentCheck": [
                366,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        368,
                        "[PaymentCheckOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        371
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        372,
                        "[PaymentCheckScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPaymentCheckOrThrow": [
                366,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        368,
                        "[PaymentCheckOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        371
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        372,
                        "[PaymentCheckScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentChecks": [
                366,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        368,
                        "[PaymentCheckOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        371
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        372,
                        "[PaymentCheckScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentCheck": [
                366,
                {
                    "where": [
                        371,
                        "PaymentCheckWhereUniqueInput!"
                    ]
                }
            ],
            "getPaymentCheck": [
                366,
                {
                    "where": [
                        371,
                        "PaymentCheckWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPaymentCheck": [
                1227,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        1228,
                        "[PaymentCheckOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        372,
                        "[PaymentCheckScalarFieldEnum!]!"
                    ],
                    "having": [
                        1234
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePaymentGroup": [
                1237,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPaymentGroup": [
                293,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPaymentGroupOrThrow": [
                293,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentGroups": [
                293,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentGroup": [
                293,
                {
                    "where": [
                        364,
                        "PaymentGroupWhereUniqueInput!"
                    ]
                }
            ],
            "getPaymentGroup": [
                293,
                {
                    "where": [
                        364,
                        "PaymentGroupWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPaymentGroup": [
                1243,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        1244,
                        "[PaymentGroupOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]!"
                    ],
                    "having": [
                        1250
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePaymentMethod": [
                1251,
                {
                    "where": [
                        104
                    ],
                    "orderBy": [
                        311,
                        "[PaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1257
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPaymentMethod": [
                354,
                {
                    "where": [
                        104
                    ],
                    "orderBy": [
                        311,
                        "[PaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1257
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1258,
                        "[PaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPaymentMethodOrThrow": [
                354,
                {
                    "where": [
                        104
                    ],
                    "orderBy": [
                        311,
                        "[PaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1257
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1258,
                        "[PaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentMethods": [
                354,
                {
                    "where": [
                        104
                    ],
                    "orderBy": [
                        311,
                        "[PaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1257
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1258,
                        "[PaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentMethod": [
                354,
                {
                    "where": [
                        1257,
                        "PaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "getPaymentMethod": [
                354,
                {
                    "where": [
                        1257,
                        "PaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPaymentMethod": [
                1259,
                {
                    "where": [
                        104
                    ],
                    "orderBy": [
                        1260,
                        "[PaymentMethodOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1258,
                        "[PaymentMethodScalarFieldEnum!]!"
                    ],
                    "having": [
                        1266
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePaymentSystem": [
                1267,
                {
                    "where": [
                        99
                    ],
                    "orderBy": [
                        316,
                        "[PaymentSystemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1273
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPaymentSystem": [
                346,
                {
                    "where": [
                        99
                    ],
                    "orderBy": [
                        316,
                        "[PaymentSystemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1273
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1274,
                        "[PaymentSystemScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPaymentSystemOrThrow": [
                346,
                {
                    "where": [
                        99
                    ],
                    "orderBy": [
                        316,
                        "[PaymentSystemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1273
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1274,
                        "[PaymentSystemScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentSystems": [
                346,
                {
                    "where": [
                        99
                    ],
                    "orderBy": [
                        316,
                        "[PaymentSystemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1273
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1274,
                        "[PaymentSystemScalarFieldEnum!]"
                    ]
                }
            ],
            "paymentSystem": [
                346,
                {
                    "where": [
                        1273,
                        "PaymentSystemWhereUniqueInput!"
                    ]
                }
            ],
            "getPaymentSystem": [
                346,
                {
                    "where": [
                        1273,
                        "PaymentSystemWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPaymentSystem": [
                1275,
                {
                    "where": [
                        99
                    ],
                    "orderBy": [
                        1276,
                        "[PaymentSystemOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1274,
                        "[PaymentSystemScalarFieldEnum!]!"
                    ],
                    "having": [
                        1282
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePermission": [
                1283,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        578,
                        "[PermissionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        582
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPermission": [
                574,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        578,
                        "[PermissionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        582
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        583,
                        "[PermissionScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPermissionOrThrow": [
                574,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        578,
                        "[PermissionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        582
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        583,
                        "[PermissionScalarFieldEnum!]"
                    ]
                }
            ],
            "permissions": [
                574,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        578,
                        "[PermissionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        582
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        583,
                        "[PermissionScalarFieldEnum!]"
                    ]
                }
            ],
            "permission": [
                574,
                {
                    "where": [
                        582,
                        "PermissionWhereUniqueInput!"
                    ]
                }
            ],
            "getPermission": [
                574,
                {
                    "where": [
                        582,
                        "PermissionWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPermission": [
                1289,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        1290,
                        "[PermissionOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        583,
                        "[PermissionScalarFieldEnum!]!"
                    ],
                    "having": [
                        1296
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePlugin": [
                1297,
                {
                    "where": [
                        1303
                    ],
                    "orderBy": [
                        1304,
                        "[PluginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1307
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPlugin": [
                1308,
                {
                    "where": [
                        1303
                    ],
                    "orderBy": [
                        1304,
                        "[PluginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1307
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1309,
                        "[PluginScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPluginOrThrow": [
                1308,
                {
                    "where": [
                        1303
                    ],
                    "orderBy": [
                        1304,
                        "[PluginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1307
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1309,
                        "[PluginScalarFieldEnum!]"
                    ]
                }
            ],
            "plugins": [
                1308,
                {
                    "where": [
                        1303
                    ],
                    "orderBy": [
                        1304,
                        "[PluginOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1307
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1309,
                        "[PluginScalarFieldEnum!]"
                    ]
                }
            ],
            "plugin": [
                1308,
                {
                    "where": [
                        1307,
                        "PluginWhereUniqueInput!"
                    ]
                }
            ],
            "getPlugin": [
                1308,
                {
                    "where": [
                        1307,
                        "PluginWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPlugin": [
                1310,
                {
                    "where": [
                        1303
                    ],
                    "orderBy": [
                        1311,
                        "[PluginOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1309,
                        "[PluginScalarFieldEnum!]!"
                    ],
                    "having": [
                        1317
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePosAccount": [
                1318,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPosAccount": [
                326,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        349,
                        "[PosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPosAccountOrThrow": [
                326,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        349,
                        "[PosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "posAccounts": [
                326,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        349,
                        "[PosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "posAccount": [
                326,
                {
                    "where": [
                        348,
                        "PosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "getPosAccount": [
                326,
                {
                    "where": [
                        348,
                        "PosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPosAccount": [
                1324,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        1325,
                        "[PosAccountOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        349,
                        "[PosAccountScalarFieldEnum!]!"
                    ],
                    "having": [
                        1331
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePosConfig": [
                1332,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPosConfig": [
                350,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        353,
                        "[PosConfigScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPosConfigOrThrow": [
                350,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        353,
                        "[PosConfigScalarFieldEnum!]"
                    ]
                }
            ],
            "posConfigs": [
                350,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        353,
                        "[PosConfigScalarFieldEnum!]"
                    ]
                }
            ],
            "posConfig": [
                350,
                {
                    "where": [
                        352,
                        "PosConfigWhereUniqueInput!"
                    ]
                }
            ],
            "getPosConfig": [
                350,
                {
                    "where": [
                        352,
                        "PosConfigWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPosConfig": [
                1338,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        1339,
                        "[PosConfigOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        353,
                        "[PosConfigScalarFieldEnum!]!"
                    ],
                    "having": [
                        1345
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePrice": [
                1346,
                {
                    "where": [
                        145
                    ],
                    "orderBy": [
                        392,
                        "[PriceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPrice": [
                461,
                {
                    "where": [
                        145
                    ],
                    "orderBy": [
                        392,
                        "[PriceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1353,
                        "[PriceScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPriceOrThrow": [
                461,
                {
                    "where": [
                        145
                    ],
                    "orderBy": [
                        392,
                        "[PriceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1353,
                        "[PriceScalarFieldEnum!]"
                    ]
                }
            ],
            "prices": [
                461,
                {
                    "where": [
                        145
                    ],
                    "orderBy": [
                        392,
                        "[PriceOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1353,
                        "[PriceScalarFieldEnum!]"
                    ]
                }
            ],
            "price": [
                461,
                {
                    "where": [
                        1352,
                        "PriceWhereUniqueInput!"
                    ]
                }
            ],
            "getPrice": [
                461,
                {
                    "where": [
                        1352,
                        "PriceWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPrice": [
                1354,
                {
                    "where": [
                        145
                    ],
                    "orderBy": [
                        1355,
                        "[PriceOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1353,
                        "[PriceScalarFieldEnum!]!"
                    ],
                    "having": [
                        1361
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregatePriceToCategory": [
                1362,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstPriceToCategory": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstPriceToCategoryOrThrow": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "priceToCategories": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "priceToCategory": [
                460,
                {
                    "where": [
                        464,
                        "PriceToCategoryWhereUniqueInput!"
                    ]
                }
            ],
            "getPriceToCategory": [
                460,
                {
                    "where": [
                        464,
                        "PriceToCategoryWhereUniqueInput!"
                    ]
                }
            ],
            "groupByPriceToCategory": [
                1368,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        1369,
                        "[PriceToCategoryOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]!"
                    ],
                    "having": [
                        1375
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateProduct": [
                1376,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstProduct": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstProductOrThrow": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "products": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "product": [
                377,
                {
                    "where": [
                        456,
                        "ProductWhereUniqueInput!"
                    ]
                }
            ],
            "getProduct": [
                377,
                {
                    "where": [
                        456,
                        "ProductWhereUniqueInput!"
                    ]
                }
            ],
            "groupByProduct": [
                1382,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        1383,
                        "[ProductOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        457,
                        "[ProductScalarFieldEnum!]!"
                    ],
                    "having": [
                        1389
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateProductLot": [
                1392,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        509,
                        "[ProductLotOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        512
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstProductLot": [
                508,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        509,
                        "[ProductLotOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        512
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        513,
                        "[ProductLotScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstProductLotOrThrow": [
                508,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        509,
                        "[ProductLotOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        512
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        513,
                        "[ProductLotScalarFieldEnum!]"
                    ]
                }
            ],
            "productLots": [
                508,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        509,
                        "[ProductLotOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        512
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        513,
                        "[ProductLotScalarFieldEnum!]"
                    ]
                }
            ],
            "productLot": [
                508,
                {
                    "where": [
                        512,
                        "ProductLotWhereUniqueInput!"
                    ]
                }
            ],
            "getProductLot": [
                508,
                {
                    "where": [
                        512,
                        "ProductLotWhereUniqueInput!"
                    ]
                }
            ],
            "groupByProductLot": [
                1398,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        1399,
                        "[ProductLotOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        513,
                        "[ProductLotScalarFieldEnum!]!"
                    ],
                    "having": [
                        1405
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateProductStock": [
                1406,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        515,
                        "[ProductStockOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        518
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstProductStock": [
                514,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        515,
                        "[ProductStockOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        518
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        519,
                        "[ProductStockScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstProductStockOrThrow": [
                514,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        515,
                        "[ProductStockOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        518
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        519,
                        "[ProductStockScalarFieldEnum!]"
                    ]
                }
            ],
            "productStocks": [
                514,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        515,
                        "[ProductStockOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        518
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        519,
                        "[ProductStockScalarFieldEnum!]"
                    ]
                }
            ],
            "productStock": [
                514,
                {
                    "where": [
                        518,
                        "ProductStockWhereUniqueInput!"
                    ]
                }
            ],
            "getProductStock": [
                514,
                {
                    "where": [
                        518,
                        "ProductStockWhereUniqueInput!"
                    ]
                }
            ],
            "groupByProductStock": [
                1412,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        1413,
                        "[ProductStockOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        519,
                        "[ProductStockScalarFieldEnum!]!"
                    ],
                    "having": [
                        1419
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateProductType": [
                1420,
                {
                    "where": [
                        165
                    ],
                    "orderBy": [
                        395,
                        "[ProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1426
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstProductType": [
                489,
                {
                    "where": [
                        165
                    ],
                    "orderBy": [
                        395,
                        "[ProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1426
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1427,
                        "[ProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstProductTypeOrThrow": [
                489,
                {
                    "where": [
                        165
                    ],
                    "orderBy": [
                        395,
                        "[ProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1426
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1427,
                        "[ProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "productTypes": [
                489,
                {
                    "where": [
                        165
                    ],
                    "orderBy": [
                        395,
                        "[ProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1426
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1427,
                        "[ProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "productType": [
                489,
                {
                    "where": [
                        1426,
                        "ProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "getProductType": [
                489,
                {
                    "where": [
                        1426,
                        "ProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "groupByProductType": [
                1428,
                {
                    "where": [
                        165
                    ],
                    "orderBy": [
                        1429,
                        "[ProductTypeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1427,
                        "[ProductTypeScalarFieldEnum!]!"
                    ],
                    "having": [
                        1435
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateRole": [
                1436,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        269,
                        "[RoleOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        576
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstRole": [
                570,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        269,
                        "[RoleOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        576
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        577,
                        "[RoleScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstRoleOrThrow": [
                570,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        269,
                        "[RoleOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        576
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        577,
                        "[RoleScalarFieldEnum!]"
                    ]
                }
            ],
            "roles": [
                570,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        269,
                        "[RoleOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        576
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        577,
                        "[RoleScalarFieldEnum!]"
                    ]
                }
            ],
            "role": [
                570,
                {
                    "where": [
                        576,
                        "RoleWhereUniqueInput!"
                    ]
                }
            ],
            "getRole": [
                570,
                {
                    "where": [
                        576,
                        "RoleWhereUniqueInput!"
                    ]
                }
            ],
            "groupByRole": [
                1442,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        1443,
                        "[RoleOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        577,
                        "[RoleScalarFieldEnum!]!"
                    ],
                    "having": [
                        1449
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateShipment": [
                1452,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstShipment": [
                473,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        478,
                        "[ShipmentScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstShipmentOrThrow": [
                473,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        478,
                        "[ShipmentScalarFieldEnum!]"
                    ]
                }
            ],
            "shipments": [
                473,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        478,
                        "[ShipmentScalarFieldEnum!]"
                    ]
                }
            ],
            "shipment": [
                473,
                {
                    "where": [
                        477,
                        "ShipmentWhereUniqueInput!"
                    ]
                }
            ],
            "getShipment": [
                473,
                {
                    "where": [
                        477,
                        "ShipmentWhereUniqueInput!"
                    ]
                }
            ],
            "groupByShipment": [
                1458,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        1459,
                        "[ShipmentOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        478,
                        "[ShipmentScalarFieldEnum!]!"
                    ],
                    "having": [
                        1465
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateShipmentEvent": [
                1468,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstShipmentEvent": [
                479,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstShipmentEventOrThrow": [
                479,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]"
                    ]
                }
            ],
            "shipmentEvents": [
                479,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]"
                    ]
                }
            ],
            "shipmentEvent": [
                479,
                {
                    "where": [
                        483,
                        "ShipmentEventWhereUniqueInput!"
                    ]
                }
            ],
            "getShipmentEvent": [
                479,
                {
                    "where": [
                        483,
                        "ShipmentEventWhereUniqueInput!"
                    ]
                }
            ],
            "groupByShipmentEvent": [
                1474,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        1475,
                        "[ShipmentEventOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]!"
                    ],
                    "having": [
                        1481
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateShipmentStatus": [
                1482,
                {
                    "where": [
                        150
                    ],
                    "orderBy": [
                        429,
                        "[ShipmentStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1488
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstShipmentStatus": [
                471,
                {
                    "where": [
                        150
                    ],
                    "orderBy": [
                        429,
                        "[ShipmentStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1488
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1489,
                        "[ShipmentStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstShipmentStatusOrThrow": [
                471,
                {
                    "where": [
                        150
                    ],
                    "orderBy": [
                        429,
                        "[ShipmentStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1488
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1489,
                        "[ShipmentStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "shipmentStatuses": [
                471,
                {
                    "where": [
                        150
                    ],
                    "orderBy": [
                        429,
                        "[ShipmentStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1488
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1489,
                        "[ShipmentStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "shipmentStatus": [
                471,
                {
                    "where": [
                        1488,
                        "ShipmentStatusWhereUniqueInput!"
                    ]
                }
            ],
            "getShipmentStatus": [
                471,
                {
                    "where": [
                        1488,
                        "ShipmentStatusWhereUniqueInput!"
                    ]
                }
            ],
            "groupByShipmentStatus": [
                1490,
                {
                    "where": [
                        150
                    ],
                    "orderBy": [
                        1491,
                        "[ShipmentStatusOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1489,
                        "[ShipmentStatusScalarFieldEnum!]!"
                    ],
                    "having": [
                        1497
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupport": [
                1498,
                {
                    "where": [
                        183
                    ],
                    "orderBy": [
                        526,
                        "[SupportOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1504
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupport": [
                523,
                {
                    "where": [
                        183
                    ],
                    "orderBy": [
                        526,
                        "[SupportOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1504
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1505,
                        "[SupportScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportOrThrow": [
                523,
                {
                    "where": [
                        183
                    ],
                    "orderBy": [
                        526,
                        "[SupportOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1504
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1505,
                        "[SupportScalarFieldEnum!]"
                    ]
                }
            ],
            "supports": [
                523,
                {
                    "where": [
                        183
                    ],
                    "orderBy": [
                        526,
                        "[SupportOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1504
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1505,
                        "[SupportScalarFieldEnum!]"
                    ]
                }
            ],
            "support": [
                523,
                {
                    "where": [
                        1504,
                        "SupportWhereUniqueInput!"
                    ]
                }
            ],
            "getSupport": [
                523,
                {
                    "where": [
                        1504,
                        "SupportWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupport": [
                1506,
                {
                    "where": [
                        183
                    ],
                    "orderBy": [
                        1507,
                        "[SupportOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1505,
                        "[SupportScalarFieldEnum!]!"
                    ],
                    "having": [
                        1513
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupportAttachment": [
                1520,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupportAttachment": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportAttachmentOrThrow": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "supportAttachments": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "supportAttachment": [
                522,
                {
                    "where": [
                        538,
                        "SupportAttachmentWhereUniqueInput!"
                    ]
                }
            ],
            "getSupportAttachment": [
                522,
                {
                    "where": [
                        538,
                        "SupportAttachmentWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupportAttachment": [
                1526,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        1527,
                        "[SupportAttachmentOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]!"
                    ],
                    "having": [
                        1533
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupportEvent": [
                1534,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupportEvent": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportEventOrThrow": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "supportEvents": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "supportEvent": [
                544,
                {
                    "where": [
                        553,
                        "SupportEventWhereUniqueInput!"
                    ]
                }
            ],
            "getSupportEvent": [
                544,
                {
                    "where": [
                        553,
                        "SupportEventWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupportEvent": [
                1540,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        1541,
                        "[SupportEventOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        554,
                        "[SupportEventScalarFieldEnum!]!"
                    ],
                    "having": [
                        1547
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupportItem": [
                1548,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupportItem": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportItemOrThrow": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "supportItems": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "supportItem": [
                520,
                {
                    "where": [
                        540,
                        "SupportItemWhereUniqueInput!"
                    ]
                }
            ],
            "getSupportItem": [
                520,
                {
                    "where": [
                        540,
                        "SupportItemWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupportItem": [
                1554,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        1555,
                        "[SupportItemOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        541,
                        "[SupportItemScalarFieldEnum!]!"
                    ],
                    "having": [
                        1561
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupportItemStatus": [
                1562,
                {
                    "where": [
                        199
                    ],
                    "orderBy": [
                        531,
                        "[SupportItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupportItemStatus": [
                545,
                {
                    "where": [
                        199
                    ],
                    "orderBy": [
                        531,
                        "[SupportItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1569,
                        "[SupportItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportItemStatusOrThrow": [
                545,
                {
                    "where": [
                        199
                    ],
                    "orderBy": [
                        531,
                        "[SupportItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1569,
                        "[SupportItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "supportItemStatuses": [
                545,
                {
                    "where": [
                        199
                    ],
                    "orderBy": [
                        531,
                        "[SupportItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1569,
                        "[SupportItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "supportItemStatus": [
                545,
                {
                    "where": [
                        1568,
                        "SupportItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "getSupportItemStatus": [
                545,
                {
                    "where": [
                        1568,
                        "SupportItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupportItemStatus": [
                1570,
                {
                    "where": [
                        199
                    ],
                    "orderBy": [
                        1571,
                        "[SupportItemStatusOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1569,
                        "[SupportItemStatusScalarFieldEnum!]!"
                    ],
                    "having": [
                        1577
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSupportMessage": [
                1578,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSupportMessage": [
                542,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        556,
                        "[SupportMessageScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSupportMessageOrThrow": [
                542,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        556,
                        "[SupportMessageScalarFieldEnum!]"
                    ]
                }
            ],
            "supportMessages": [
                542,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        556,
                        "[SupportMessageScalarFieldEnum!]"
                    ]
                }
            ],
            "supportMessage": [
                542,
                {
                    "where": [
                        555,
                        "SupportMessageWhereUniqueInput!"
                    ]
                }
            ],
            "getSupportMessage": [
                542,
                {
                    "where": [
                        555,
                        "SupportMessageWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySupportMessage": [
                1584,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        1585,
                        "[SupportMessageOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        556,
                        "[SupportMessageScalarFieldEnum!]!"
                    ],
                    "having": [
                        1591
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateSyncronize": [
                1592,
                {
                    "where": [
                        1600
                    ],
                    "orderBy": [
                        1605,
                        "[SyncronizeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1608
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstSyncronize": [
                1609,
                {
                    "where": [
                        1600
                    ],
                    "orderBy": [
                        1605,
                        "[SyncronizeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1608
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1610,
                        "[SyncronizeScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstSyncronizeOrThrow": [
                1609,
                {
                    "where": [
                        1600
                    ],
                    "orderBy": [
                        1605,
                        "[SyncronizeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1608
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1610,
                        "[SyncronizeScalarFieldEnum!]"
                    ]
                }
            ],
            "syncronizes": [
                1609,
                {
                    "where": [
                        1600
                    ],
                    "orderBy": [
                        1605,
                        "[SyncronizeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1608
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1610,
                        "[SyncronizeScalarFieldEnum!]"
                    ]
                }
            ],
            "syncronize": [
                1609,
                {
                    "where": [
                        1608,
                        "SyncronizeWhereUniqueInput!"
                    ]
                }
            ],
            "getSyncronize": [
                1609,
                {
                    "where": [
                        1608,
                        "SyncronizeWhereUniqueInput!"
                    ]
                }
            ],
            "groupBySyncronize": [
                1611,
                {
                    "where": [
                        1600
                    ],
                    "orderBy": [
                        1612,
                        "[SyncronizeOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1610,
                        "[SyncronizeScalarFieldEnum!]!"
                    ],
                    "having": [
                        1618
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateTenant": [
                1623,
                {
                    "where": [
                        1629
                    ],
                    "orderBy": [
                        1630,
                        "[TenantOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1633
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstTenant": [
                1634,
                {
                    "where": [
                        1629
                    ],
                    "orderBy": [
                        1630,
                        "[TenantOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1633
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1635,
                        "[TenantScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstTenantOrThrow": [
                1634,
                {
                    "where": [
                        1629
                    ],
                    "orderBy": [
                        1630,
                        "[TenantOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1633
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1635,
                        "[TenantScalarFieldEnum!]"
                    ]
                }
            ],
            "tenants": [
                1634,
                {
                    "where": [
                        1629
                    ],
                    "orderBy": [
                        1630,
                        "[TenantOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        1633
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        1635,
                        "[TenantScalarFieldEnum!]"
                    ]
                }
            ],
            "tenant": [
                1634,
                {
                    "where": [
                        1633,
                        "TenantWhereUniqueInput!"
                    ]
                }
            ],
            "getTenant": [
                1634,
                {
                    "where": [
                        1633,
                        "TenantWhereUniqueInput!"
                    ]
                }
            ],
            "groupByTenant": [
                1636,
                {
                    "where": [
                        1629
                    ],
                    "orderBy": [
                        1637,
                        "[TenantOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        1635,
                        "[TenantScalarFieldEnum!]!"
                    ],
                    "having": [
                        1643
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "aggregateUser": [
                1644,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "findFirstUser": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "findFirstUserOrThrow": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "users": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "user": [
                261,
                {
                    "where": [
                        572,
                        "UserWhereUniqueInput!"
                    ]
                }
            ],
            "getUser": [
                261,
                {
                    "where": [
                        572,
                        "UserWhereUniqueInput!"
                    ]
                }
            ],
            "groupByUser": [
                1650,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        1651,
                        "[UserOrderByWithAggregationInput!]"
                    ],
                    "by": [
                        573,
                        "[UserScalarFieldEnum!]!"
                    ],
                    "having": [
                        1657
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ]
                }
            ],
            "me": [
                261
            ],
            "getLots": [
                9,
                {
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getStock": [
                9,
                {
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getCategoryStats": [
                1660,
                {
                    "userId": [
                        5
                    ],
                    "companyId": [
                        3,
                        "[Int!]"
                    ]
                }
            ],
            "getFnCompanyStatement": [
                9,
                {
                    "endDate": [
                        10,
                        "DateTimeISO!"
                    ],
                    "startDate": [
                        10,
                        "DateTimeISO!"
                    ],
                    "projectCode": [
                        9,
                        "String!"
                    ],
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getFnCompanyBalance": [
                9,
                {
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getFnCompanyBalanceWithProject": [
                9,
                {
                    "project_id": [
                        9,
                        "String!"
                    ],
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getFnOrderPending": [
                9,
                {
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "getFnOrderTodayShipped": [
                9,
                {
                    "id": [
                        9,
                        "String!"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AggregateAddress": {
            "_count": [
                2
            ],
            "_avg": [
                4
            ],
            "_sum": [
                6
            ],
            "_min": [
                7
            ],
            "_max": [
                14
            ],
            "__typename": [
                9
            ]
        },
        "AddressCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                3
            ],
            "type": [
                3
            ],
            "address": [
                3
            ],
            "title": [
                3
            ],
            "email": [
                3
            ],
            "gsm": [
                3
            ],
            "phone1": [
                3
            ],
            "phone2": [
                3
            ],
            "tax_number": [
                3
            ],
            "tax_office": [
                3
            ],
            "website": [
                3
            ],
            "is_deleted": [
                3
            ],
            "subCompany_code": [
                3
            ],
            "subCompany_name": [
                3
            ],
            "subCompany_out_ref": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "Int": {},
        "AddressAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "companyId": [
                5
            ],
            "countyId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "Float": {},
        "AddressSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AddressMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "Boolean": {},
        "String": {},
        "DateTimeISO": {},
        "Source": {},
        "Platform": {},
        "AddressType": {},
        "AddressMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AddressWhereInput": {
            "AND": [
                15
            ],
            "OR": [
                15
            ],
            "NOT": [
                15
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "companyId": [
                23
            ],
            "countyId": [
                23
            ],
            "platform": [
                29
            ],
            "type": [
                31
            ],
            "address": [
                20
            ],
            "title": [
                20
            ],
            "email": [
                20
            ],
            "gsm": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "tax_number": [
                20
            ],
            "tax_office": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "subCompany_code": [
                20
            ],
            "subCompany_name": [
                20
            ],
            "subCompany_out_ref": [
                20
            ],
            "Company": [
                35
            ],
            "County": [
                219
            ],
            "CartBilling": [
                38
            ],
            "CartShipment": [
                38
            ],
            "OrderBilling": [
                59
            ],
            "OrderShipment": [
                59
            ],
            "User": [
                213
            ],
            "__typename": [
                9
            ]
        },
        "IntFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                17
            ],
            "__typename": [
                9
            ]
        },
        "NestedIntFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                17
            ],
            "__typename": [
                9
            ]
        },
        "BoolFilter": {
            "equals": [
                8
            ],
            "not": [
                19
            ],
            "__typename": [
                9
            ]
        },
        "NestedBoolFilter": {
            "equals": [
                8
            ],
            "not": [
                19
            ],
            "__typename": [
                9
            ]
        },
        "StringNullableFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "mode": [
                21
            ],
            "not": [
                22
            ],
            "__typename": [
                9
            ]
        },
        "QueryMode": {},
        "NestedStringNullableFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "not": [
                22
            ],
            "__typename": [
                9
            ]
        },
        "IntNullableFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                24
            ],
            "__typename": [
                9
            ]
        },
        "NestedIntNullableFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                24
            ],
            "__typename": [
                9
            ]
        },
        "DateTimeFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                26
            ],
            "__typename": [
                9
            ]
        },
        "NestedDateTimeFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                26
            ],
            "__typename": [
                9
            ]
        },
        "EnumSourceFilter": {
            "equals": [
                11
            ],
            "in": [
                11
            ],
            "notIn": [
                11
            ],
            "not": [
                28
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSourceFilter": {
            "equals": [
                11
            ],
            "in": [
                11
            ],
            "notIn": [
                11
            ],
            "not": [
                28
            ],
            "__typename": [
                9
            ]
        },
        "EnumPlatformNullableFilter": {
            "equals": [
                12
            ],
            "in": [
                12
            ],
            "notIn": [
                12
            ],
            "not": [
                30
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumPlatformNullableFilter": {
            "equals": [
                12
            ],
            "in": [
                12
            ],
            "notIn": [
                12
            ],
            "not": [
                30
            ],
            "__typename": [
                9
            ]
        },
        "EnumAddressTypeNullableFilter": {
            "equals": [
                13
            ],
            "in": [
                13
            ],
            "notIn": [
                13
            ],
            "not": [
                32
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumAddressTypeNullableFilter": {
            "equals": [
                13
            ],
            "in": [
                13
            ],
            "notIn": [
                13
            ],
            "not": [
                32
            ],
            "__typename": [
                9
            ]
        },
        "BoolNullableFilter": {
            "equals": [
                8
            ],
            "not": [
                34
            ],
            "__typename": [
                9
            ]
        },
        "NestedBoolNullableFilter": {
            "equals": [
                8
            ],
            "not": [
                34
            ],
            "__typename": [
                9
            ]
        },
        "CompanyNullableRelationFilter": {
            "is": [
                36
            ],
            "isNot": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyWhereInput": {
            "AND": [
                36
            ],
            "OR": [
                36
            ],
            "NOT": [
                36
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "email": [
                20
            ],
            "title": [
                20
            ],
            "tax_office": [
                20
            ],
            "tax_number": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "gsm": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Address": [
                37
            ],
            "Cart": [
                38
            ],
            "CompanyStatement": [
                217
            ],
            "CompanyToPaymentMethod": [
                105
            ],
            "InstallmentToCompany": [
                94
            ],
            "Order": [
                59
            ],
            "Payment": [
                87
            ],
            "PriceToCategory": [
                141
            ],
            "Brand": [
                146
            ],
            "User": [
                213
            ],
            "__typename": [
                9
            ]
        },
        "AddressListRelationFilter": {
            "every": [
                15
            ],
            "some": [
                15
            ],
            "none": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "CartListRelationFilter": {
            "every": [
                39
            ],
            "some": [
                39
            ],
            "none": [
                39
            ],
            "__typename": [
                9
            ]
        },
        "CartWhereInput": {
            "AND": [
                39
            ],
            "OR": [
                39
            ],
            "NOT": [
                39
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "userId": [
                23
            ],
            "status": [
                40
            ],
            "billing_address_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "approved_by": [
                23
            ],
            "aprroved_at": [
                43
            ],
            "note": [
                20
            ],
            "order_key": [
                20
            ],
            "total_discount": [
                45
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "company_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "BillingAddress": [
                48
            ],
            "Company": [
                35
            ],
            "ShipmentAddress": [
                48
            ],
            "User": [
                49
            ],
            "CartItem": [
                128
            ],
            "Order": [
                72
            ],
            "__typename": [
                9
            ]
        },
        "EnumCartStatusNullableFilter": {
            "equals": [
                41
            ],
            "in": [
                41
            ],
            "notIn": [
                41
            ],
            "not": [
                42
            ],
            "__typename": [
                9
            ]
        },
        "CartStatus": {},
        "NestedEnumCartStatusNullableFilter": {
            "equals": [
                41
            ],
            "in": [
                41
            ],
            "notIn": [
                41
            ],
            "not": [
                42
            ],
            "__typename": [
                9
            ]
        },
        "DateTimeNullableFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                44
            ],
            "__typename": [
                9
            ]
        },
        "NestedDateTimeNullableFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                44
            ],
            "__typename": [
                9
            ]
        },
        "DecimalNullableFilter": {
            "equals": [
                46
            ],
            "in": [
                46
            ],
            "notIn": [
                46
            ],
            "lt": [
                46
            ],
            "lte": [
                46
            ],
            "gt": [
                46
            ],
            "gte": [
                46
            ],
            "not": [
                47
            ],
            "__typename": [
                9
            ]
        },
        "Decimal": {},
        "NestedDecimalNullableFilter": {
            "equals": [
                46
            ],
            "in": [
                46
            ],
            "notIn": [
                46
            ],
            "lt": [
                46
            ],
            "lte": [
                46
            ],
            "gt": [
                46
            ],
            "gte": [
                46
            ],
            "not": [
                47
            ],
            "__typename": [
                9
            ]
        },
        "AddressNullableRelationFilter": {
            "is": [
                15
            ],
            "isNot": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "UserNullableRelationFilter": {
            "is": [
                50
            ],
            "isNot": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserWhereInput": {
            "AND": [
                50
            ],
            "OR": [
                50
            ],
            "NOT": [
                50
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "provider_id": [
                20
            ],
            "first_name": [
                20
            ],
            "last_name": [
                20
            ],
            "gender": [
                51
            ],
            "default_address_id": [
                23
            ],
            "role_id": [
                23
            ],
            "email": [
                20
            ],
            "gsm": [
                20
            ],
            "password": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Cart": [
                38
            ],
            "ConfirmCode": [
                54
            ],
            "Order": [
                59
            ],
            "OrderItem": [
                113
            ],
            "Payment": [
                87
            ],
            "SupportAttachment": [
                180
            ],
            "SupportMessage": [
                193
            ],
            "Address": [
                48
            ],
            "Role": [
                208
            ],
            "Company": [
                162
            ],
            "__typename": [
                9
            ]
        },
        "EnumGenderNullableFilter": {
            "equals": [
                52
            ],
            "in": [
                52
            ],
            "notIn": [
                52
            ],
            "not": [
                53
            ],
            "__typename": [
                9
            ]
        },
        "Gender": {},
        "NestedEnumGenderNullableFilter": {
            "equals": [
                52
            ],
            "in": [
                52
            ],
            "notIn": [
                52
            ],
            "not": [
                53
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeListRelationFilter": {
            "every": [
                55
            ],
            "some": [
                55
            ],
            "none": [
                55
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeWhereInput": {
            "AND": [
                55
            ],
            "OR": [
                55
            ],
            "NOT": [
                55
            ],
            "id": [
                16
            ],
            "code": [
                20
            ],
            "expire_at": [
                43
            ],
            "confirmed_at": [
                43
            ],
            "user_id": [
                23
            ],
            "type": [
                56
            ],
            "gsm_no": [
                20
            ],
            "email": [
                20
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "EnumConfirmCodeTypeNullableFilter": {
            "equals": [
                57
            ],
            "in": [
                57
            ],
            "notIn": [
                57
            ],
            "not": [
                58
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeType": {},
        "NestedEnumConfirmCodeTypeNullableFilter": {
            "equals": [
                57
            ],
            "in": [
                57
            ],
            "notIn": [
                57
            ],
            "not": [
                58
            ],
            "__typename": [
                9
            ]
        },
        "OrderListRelationFilter": {
            "every": [
                60
            ],
            "some": [
                60
            ],
            "none": [
                60
            ],
            "__typename": [
                9
            ]
        },
        "OrderWhereInput": {
            "AND": [
                60
            ],
            "OR": [
                60
            ],
            "NOT": [
                60
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "ordered_at": [
                43
            ],
            "order_key": [
                61
            ],
            "outer_order_key": [
                20
            ],
            "company_id": [
                23
            ],
            "user_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "billing_address_id": [
                23
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "total_discount": [
                45
            ],
            "total_quantity": [
                23
            ],
            "order_note": [
                20
            ],
            "is_approved": [
                33
            ],
            "approved_by": [
                23
            ],
            "approved_at": [
                43
            ],
            "is_sent_erp": [
                33
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                23
            ],
            "company_name": [
                20
            ],
            "is_deleted": [
                33
            ],
            "status": [
                20
            ],
            "payment_status": [
                63
            ],
            "cartId": [
                23
            ],
            "warehouse": [
                20
            ],
            "is_sent_status": [
                66
            ],
            "BillingAddress": [
                48
            ],
            "Cart": [
                69
            ],
            "Company": [
                35
            ],
            "ShipmentAddress": [
                48
            ],
            "User": [
                49
            ],
            "PaymentGroup": [
                70
            ],
            "OrderItem": [
                113
            ],
            "__typename": [
                9
            ]
        },
        "StringFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "mode": [
                21
            ],
            "not": [
                62
            ],
            "__typename": [
                9
            ]
        },
        "NestedStringFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "not": [
                62
            ],
            "__typename": [
                9
            ]
        },
        "EnumOrderPaymentStatusNullableFilter": {
            "equals": [
                64
            ],
            "in": [
                64
            ],
            "notIn": [
                64
            ],
            "not": [
                65
            ],
            "__typename": [
                9
            ]
        },
        "OrderPaymentStatus": {},
        "NestedEnumOrderPaymentStatusNullableFilter": {
            "equals": [
                64
            ],
            "in": [
                64
            ],
            "notIn": [
                64
            ],
            "not": [
                65
            ],
            "__typename": [
                9
            ]
        },
        "EnumIsSendStatusNullableFilter": {
            "equals": [
                67
            ],
            "in": [
                67
            ],
            "notIn": [
                67
            ],
            "not": [
                68
            ],
            "__typename": [
                9
            ]
        },
        "IsSendStatus": {},
        "NestedEnumIsSendStatusNullableFilter": {
            "equals": [
                67
            ],
            "in": [
                67
            ],
            "notIn": [
                67
            ],
            "not": [
                68
            ],
            "__typename": [
                9
            ]
        },
        "CartNullableRelationFilter": {
            "is": [
                39
            ],
            "isNot": [
                39
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupListRelationFilter": {
            "every": [
                71
            ],
            "some": [
                71
            ],
            "none": [
                71
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupWhereInput": {
            "AND": [
                71
            ],
            "OR": [
                71
            ],
            "NOT": [
                71
            ],
            "id": [
                16
            ],
            "order_id": [
                23
            ],
            "paid_price": [
                45
            ],
            "payment_id": [
                23
            ],
            "description": [
                20
            ],
            "Order": [
                72
            ],
            "Payment": [
                73
            ],
            "__typename": [
                9
            ]
        },
        "OrderNullableRelationFilter": {
            "is": [
                60
            ],
            "isNot": [
                60
            ],
            "__typename": [
                9
            ]
        },
        "PaymentNullableRelationFilter": {
            "is": [
                74
            ],
            "isNot": [
                74
            ],
            "__typename": [
                9
            ]
        },
        "PaymentWhereInput": {
            "AND": [
                74
            ],
            "OR": [
                74
            ],
            "NOT": [
                74
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "order_key": [
                20
            ],
            "price": [
                45
            ],
            "installment": [
                23
            ],
            "fraud_status": [
                23
            ],
            "payment_id": [
                20
            ],
            "payment_status": [
                75
            ],
            "payment_desc": [
                20
            ],
            "description": [
                20
            ],
            "card_owner": [
                20
            ],
            "card_number": [
                20
            ],
            "card_type": [
                20
            ],
            "card_association": [
                20
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                20
            ],
            "user_id": [
                23
            ],
            "method_id": [
                23
            ],
            "pos_account_id": [
                23
            ],
            "company_id": [
                23
            ],
            "auth_code": [
                20
            ],
            "bankId": [
                23
            ],
            "bank_message": [
                20
            ],
            "is_sent_erp": [
                33
            ],
            "bank_response": [
                78
            ],
            "ip": [
                20
            ],
            "req_absolute_path": [
                20
            ],
            "gateway_type": [
                80
            ],
            "temp_field": [
                78
            ],
            "is_sent_status": [
                66
            ],
            "payment_key": [
                20
            ],
            "payment_event_id": [
                23
            ],
            "outer_payment_key": [
                20
            ],
            "card_bank": [
                83
            ],
            "Company": [
                35
            ],
            "Method": [
                103
            ],
            "PosAccount": [
                102
            ],
            "User": [
                49
            ],
            "PaymentGroup": [
                70
            ],
            "PaymentCheck": [
                108
            ],
            "__typename": [
                9
            ]
        },
        "EnumPaymentStatusNullableFilter": {
            "equals": [
                76
            ],
            "in": [
                76
            ],
            "notIn": [
                76
            ],
            "not": [
                77
            ],
            "__typename": [
                9
            ]
        },
        "PaymentStatus": {},
        "NestedEnumPaymentStatusNullableFilter": {
            "equals": [
                76
            ],
            "in": [
                76
            ],
            "notIn": [
                76
            ],
            "not": [
                77
            ],
            "__typename": [
                9
            ]
        },
        "JsonNullableFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "JSON": {},
        "EnumGatewayTypeNullableFilter": {
            "equals": [
                81
            ],
            "in": [
                81
            ],
            "notIn": [
                81
            ],
            "not": [
                82
            ],
            "__typename": [
                9
            ]
        },
        "GatewayType": {},
        "NestedEnumGatewayTypeNullableFilter": {
            "equals": [
                81
            ],
            "in": [
                81
            ],
            "notIn": [
                81
            ],
            "not": [
                82
            ],
            "__typename": [
                9
            ]
        },
        "BankNullableRelationFilter": {
            "is": [
                84
            ],
            "isNot": [
                84
            ],
            "__typename": [
                9
            ]
        },
        "BankWhereInput": {
            "AND": [
                84
            ],
            "OR": [
                84
            ],
            "NOT": [
                84
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "image": [
                20
            ],
            "iban": [
                20
            ],
            "office": [
                20
            ],
            "account_number": [
                20
            ],
            "eft_code": [
                20
            ],
            "pos_defaults": [
                78
            ],
            "family": [
                78
            ],
            "is_pos": [
                33
            ],
            "pos_category": [
                20
            ],
            "selected_pos_id": [
                23
            ],
            "is_payment_system": [
                33
            ],
            "is_deleted": [
                33
            ],
            "bin_numbers": [
                85
            ],
            "Payment": [
                87
            ],
            "PosAccount": [
                88
            ],
            "PosConfig": [
                100
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberListRelationFilter": {
            "every": [
                86
            ],
            "some": [
                86
            ],
            "none": [
                86
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberWhereInput": {
            "AND": [
                86
            ],
            "OR": [
                86
            ],
            "NOT": [
                86
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "is_bussiness_card": [
                33
            ],
            "network": [
                20
            ],
            "family": [
                20
            ],
            "bank_id": [
                23
            ],
            "binnumber": [
                20
            ],
            "cardtype": [
                20
            ],
            "Bank": [
                83
            ],
            "__typename": [
                9
            ]
        },
        "PaymentListRelationFilter": {
            "every": [
                74
            ],
            "some": [
                74
            ],
            "none": [
                74
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountListRelationFilter": {
            "every": [
                89
            ],
            "some": [
                89
            ],
            "none": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountWhereInput": {
            "AND": [
                89
            ],
            "OR": [
                89
            ],
            "NOT": [
                89
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "bank_id": [
                23
            ],
            "is_default": [
                33
            ],
            "image": [
                20
            ],
            "pos_props": [
                78
            ],
            "credentials": [
                78
            ],
            "agencies": [
                78
            ],
            "Payment_system_id": [
                23
            ],
            "maximum": [
                45
            ],
            "minimum": [
                45
            ],
            "InstallmentToPosAccount": [
                90
            ],
            "Payment": [
                87
            ],
            "PaymentSystem": [
                98
            ],
            "Bank": [
                83
            ],
            "PosConfig": [
                100
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountListRelationFilter": {
            "every": [
                91
            ],
            "some": [
                91
            ],
            "none": [
                91
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountWhereInput": {
            "AND": [
                91
            ],
            "OR": [
                91
            ],
            "NOT": [
                91
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "pos_account_id": [
                16
            ],
            "id": [
                16
            ],
            "Installment": [
                92
            ],
            "PosAccount": [
                97
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentRelationFilter": {
            "is": [
                93
            ],
            "isNot": [
                93
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentWhereInput": {
            "AND": [
                93
            ],
            "OR": [
                93
            ],
            "NOT": [
                93
            ],
            "id": [
                16
            ],
            "code": [
                20
            ],
            "name": [
                20
            ],
            "out_ref": [
                20
            ],
            "count": [
                23
            ],
            "InstallmentToCompany": [
                94
            ],
            "InstallmentToPosAccount": [
                90
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyListRelationFilter": {
            "every": [
                95
            ],
            "some": [
                95
            ],
            "none": [
                95
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyWhereInput": {
            "AND": [
                95
            ],
            "OR": [
                95
            ],
            "NOT": [
                95
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "company_id": [
                16
            ],
            "id": [
                16
            ],
            "Company": [
                96
            ],
            "Installment": [
                92
            ],
            "__typename": [
                9
            ]
        },
        "CompanyRelationFilter": {
            "is": [
                36
            ],
            "isNot": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountRelationFilter": {
            "is": [
                89
            ],
            "isNot": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemNullableRelationFilter": {
            "is": [
                99
            ],
            "isNot": [
                99
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemWhereInput": {
            "AND": [
                99
            ],
            "OR": [
                99
            ],
            "NOT": [
                99
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "PosAccount": [
                88
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigListRelationFilter": {
            "every": [
                101
            ],
            "some": [
                101
            ],
            "none": [
                101
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigWhereInput": {
            "AND": [
                101
            ],
            "OR": [
                101
            ],
            "NOT": [
                101
            ],
            "id": [
                16
            ],
            "bank_id": [
                23
            ],
            "pos_id": [
                23
            ],
            "is_default": [
                33
            ],
            "Bank": [
                83
            ],
            "PosAccount": [
                102
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountNullableRelationFilter": {
            "is": [
                89
            ],
            "isNot": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodNullableRelationFilter": {
            "is": [
                104
            ],
            "isNot": [
                104
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodWhereInput": {
            "AND": [
                104
            ],
            "OR": [
                104
            ],
            "NOT": [
                104
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "minimum": [
                45
            ],
            "maximum": [
                45
            ],
            "title": [
                20
            ],
            "is_default": [
                33
            ],
            "CompanyToPaymentMethod": [
                105
            ],
            "Payment": [
                87
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodListRelationFilter": {
            "every": [
                106
            ],
            "some": [
                106
            ],
            "none": [
                106
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodWhereInput": {
            "AND": [
                106
            ],
            "OR": [
                106
            ],
            "NOT": [
                106
            ],
            "company_id": [
                16
            ],
            "payment_methodId": [
                16
            ],
            "minimum_amount": [
                45
            ],
            "maximum_amount": [
                45
            ],
            "end_date": [
                43
            ],
            "start_date": [
                43
            ],
            "total_amount": [
                45
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "Company": [
                96
            ],
            "PaymentMethod": [
                107
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodRelationFilter": {
            "is": [
                104
            ],
            "isNot": [
                104
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckListRelationFilter": {
            "every": [
                109
            ],
            "some": [
                109
            ],
            "none": [
                109
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckWhereInput": {
            "AND": [
                109
            ],
            "OR": [
                109
            ],
            "NOT": [
                109
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "amount": [
                45
            ],
            "bank_response": [
                78
            ],
            "payment_id": [
                23
            ],
            "reference_number": [
                20
            ],
            "check_status": [
                110
            ],
            "payment_status": [
                75
            ],
            "Payment": [
                87
            ],
            "__typename": [
                9
            ]
        },
        "EnumPaymentCheckStatusNullableFilter": {
            "equals": [
                111
            ],
            "in": [
                111
            ],
            "notIn": [
                111
            ],
            "not": [
                112
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckStatus": {},
        "NestedEnumPaymentCheckStatusNullableFilter": {
            "equals": [
                111
            ],
            "in": [
                111
            ],
            "notIn": [
                111
            ],
            "not": [
                112
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemListRelationFilter": {
            "every": [
                114
            ],
            "some": [
                114
            ],
            "none": [
                114
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemWhereInput": {
            "AND": [
                114
            ],
            "OR": [
                114
            ],
            "NOT": [
                114
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "status_name": [
                20
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "quantity2": [
                45
            ],
            "discount_rate": [
                45
            ],
            "vat": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "unit": [
                45
            ],
            "unit_shipment_cost": [
                45
            ],
            "sent_unit": [
                45
            ],
            "waiting_unit": [
                45
            ],
            "features": [
                78
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "userId": [
                23
            ],
            "productId": [
                23
            ],
            "orderItemStatusId": [
                23
            ],
            "shipment_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "shipment_method": [
                115
            ],
            "cart_id": [
                23
            ],
            "orderId": [
                23
            ],
            "CartItem": [
                118
            ],
            "OrderItemStatus": [
                203
            ],
            "Product": [
                121
            ],
            "Shipment": [
                160
            ],
            "User": [
                49
            ],
            "Order": [
                59
            ],
            "__typename": [
                9
            ]
        },
        "EnumShipmentMethodNullableFilter": {
            "equals": [
                116
            ],
            "in": [
                116
            ],
            "notIn": [
                116
            ],
            "not": [
                117
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentMethod": {},
        "NestedEnumShipmentMethodNullableFilter": {
            "equals": [
                116
            ],
            "in": [
                116
            ],
            "notIn": [
                116
            ],
            "not": [
                117
            ],
            "__typename": [
                9
            ]
        },
        "CartItemNullableRelationFilter": {
            "is": [
                119
            ],
            "isNot": [
                119
            ],
            "__typename": [
                9
            ]
        },
        "CartItemWhereInput": {
            "AND": [
                119
            ],
            "OR": [
                119
            ],
            "NOT": [
                119
            ],
            "id": [
                16
            ],
            "cart_id": [
                23
            ],
            "product_id": [
                23
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "vat": [
                45
            ],
            "unit_code": [
                20
            ],
            "unit": [
                45
            ],
            "quantity2": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "features": [
                120
            ],
            "discount_rate": [
                45
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Cart": [
                69
            ],
            "Product": [
                121
            ],
            "OrderItem": [
                113
            ],
            "__typename": [
                9
            ]
        },
        "JsonFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "ProductNullableRelationFilter": {
            "is": [
                122
            ],
            "isNot": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductWhereInput": {
            "AND": [
                122
            ],
            "OR": [
                122
            ],
            "NOT": [
                122
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "group": [
                20
            ],
            "brand_id": [
                23
            ],
            "category_id": [
                23
            ],
            "negative_sale": [
                33
            ],
            "on_sale": [
                33
            ],
            "rating": [
                123
            ],
            "vat": [
                45
            ],
            "sold_count": [
                23
            ],
            "sold_quantity": [
                23
            ],
            "review_count": [
                23
            ],
            "description": [
                20
            ],
            "favorite_count": [
                33
            ],
            "in_stock": [
                33
            ],
            "unit": [
                45
            ],
            "unit_ref": [
                20
            ],
            "unit_code": [
                20
            ],
            "is_shipping_required": [
                33
            ],
            "tag": [
                20
            ],
            "cargo_fee": [
                45
            ],
            "features": [
                125
            ],
            "platform": [
                29
            ],
            "type_id": [
                23
            ],
            "unit_name": [
                20
            ],
            "title": [
                20
            ],
            "has_features": [
                33
            ],
            "priceId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "out_name": [
                20
            ],
            "Barcode": [
                126
            ],
            "CartItem": [
                128
            ],
            "OrderItem": [
                113
            ],
            "Brand": [
                129
            ],
            "Category": [
                139
            ],
            "Price": [
                163
            ],
            "ProductType": [
                164
            ],
            "ProductLot": [
                174
            ],
            "ProductStock": [
                176
            ],
            "SupportItem": [
                178
            ],
            "AttributeValues": [
                170
            ],
            "Campaign": [
                201
            ],
            "Collection": [
                202
            ],
            "images": [
                131
            ],
            "__typename": [
                9
            ]
        },
        "FloatNullableFilter": {
            "equals": [
                5
            ],
            "in": [
                5
            ],
            "notIn": [
                5
            ],
            "lt": [
                5
            ],
            "lte": [
                5
            ],
            "gt": [
                5
            ],
            "gte": [
                5
            ],
            "not": [
                124
            ],
            "__typename": [
                9
            ]
        },
        "NestedFloatNullableFilter": {
            "equals": [
                5
            ],
            "in": [
                5
            ],
            "notIn": [
                5
            ],
            "lt": [
                5
            ],
            "lte": [
                5
            ],
            "gt": [
                5
            ],
            "gte": [
                5
            ],
            "not": [
                124
            ],
            "__typename": [
                9
            ]
        },
        "JsonNullableListFilter": {
            "equals": [
                79
            ],
            "has": [
                79
            ],
            "hasEvery": [
                79
            ],
            "hasSome": [
                79
            ],
            "isEmpty": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeListRelationFilter": {
            "every": [
                127
            ],
            "some": [
                127
            ],
            "none": [
                127
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeWhereInput": {
            "AND": [
                127
            ],
            "OR": [
                127
            ],
            "NOT": [
                127
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "module": [
                20
            ],
            "module_out_ref": [
                23
            ],
            "product_id": [
                23
            ],
            "barcode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "CartItemListRelationFilter": {
            "every": [
                119
            ],
            "some": [
                119
            ],
            "none": [
                119
            ],
            "__typename": [
                9
            ]
        },
        "BrandNullableRelationFilter": {
            "is": [
                130
            ],
            "isNot": [
                130
            ],
            "__typename": [
                9
            ]
        },
        "BrandWhereInput": {
            "AND": [
                130
            ],
            "OR": [
                130
            ],
            "NOT": [
                130
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "image": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "Categories": [
                161
            ],
            "Company": [
                162
            ],
            "__typename": [
                9
            ]
        },
        "ImageListRelationFilter": {
            "every": [
                132
            ],
            "some": [
                132
            ],
            "none": [
                132
            ],
            "__typename": [
                9
            ]
        },
        "ImageWhereInput": {
            "AND": [
                132
            ],
            "OR": [
                132
            ],
            "NOT": [
                132
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "out_ref": [
                20
            ],
            "url": [
                20
            ],
            "store_at": [
                20
            ],
            "alt": [
                20
            ],
            "title": [
                20
            ],
            "categoryId": [
                23
            ],
            "brandId": [
                23
            ],
            "campaignId": [
                23
            ],
            "collectionId": [
                23
            ],
            "folder_type": [
                133
            ],
            "shipmentStatusId": [
                23
            ],
            "index": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Brand": [
                129
            ],
            "Campaign": [
                136
            ],
            "Category": [
                139
            ],
            "Collection": [
                147
            ],
            "shipmentStatus": [
                149
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "EnumFolderTypeNullableFilter": {
            "equals": [
                134
            ],
            "in": [
                134
            ],
            "notIn": [
                134
            ],
            "not": [
                135
            ],
            "__typename": [
                9
            ]
        },
        "FolderType": {},
        "NestedEnumFolderTypeNullableFilter": {
            "equals": [
                134
            ],
            "in": [
                134
            ],
            "notIn": [
                134
            ],
            "not": [
                135
            ],
            "__typename": [
                9
            ]
        },
        "CampaignNullableRelationFilter": {
            "is": [
                137
            ],
            "isNot": [
                137
            ],
            "__typename": [
                9
            ]
        },
        "CampaignWhereInput": {
            "AND": [
                137
            ],
            "OR": [
                137
            ],
            "NOT": [
                137
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "ProductListRelationFilter": {
            "every": [
                122
            ],
            "some": [
                122
            ],
            "none": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "CategoryNullableRelationFilter": {
            "is": [
                140
            ],
            "isNot": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "CategoryWhereInput": {
            "AND": [
                140
            ],
            "OR": [
                140
            ],
            "NOT": [
                140
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "parent_id": [
                23
            ],
            "is_parent": [
                33
            ],
            "level": [
                23
            ],
            "description": [
                20
            ],
            "background_img": [
                20
            ],
            "is_corporate": [
                33
            ],
            "link_url": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Image": [
                131
            ],
            "PriceToCategory": [
                141
            ],
            "Product": [
                138
            ],
            "Brand": [
                146
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryListRelationFilter": {
            "every": [
                142
            ],
            "some": [
                142
            ],
            "none": [
                142
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryWhereInput": {
            "AND": [
                142
            ],
            "OR": [
                142
            ],
            "NOT": [
                142
            ],
            "price_id": [
                16
            ],
            "category_id": [
                16
            ],
            "companyId": [
                23
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Category": [
                143
            ],
            "Company": [
                35
            ],
            "Price": [
                144
            ],
            "__typename": [
                9
            ]
        },
        "CategoryRelationFilter": {
            "is": [
                140
            ],
            "isNot": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "PriceRelationFilter": {
            "is": [
                145
            ],
            "isNot": [
                145
            ],
            "__typename": [
                9
            ]
        },
        "PriceWhereInput": {
            "AND": [
                145
            ],
            "OR": [
                145
            ],
            "NOT": [
                145
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "price": [
                45
            ],
            "price_discounted": [
                45
            ],
            "retail": [
                45
            ],
            "project_name": [
                20
            ],
            "project_code": [
                20
            ],
            "discount_rate": [
                45
            ],
            "price_type": [
                20
            ],
            "is_deleted": [
                33
            ],
            "PriceToCategory": [
                141
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "BrandListRelationFilter": {
            "every": [
                130
            ],
            "some": [
                130
            ],
            "none": [
                130
            ],
            "__typename": [
                9
            ]
        },
        "CollectionNullableRelationFilter": {
            "is": [
                148
            ],
            "isNot": [
                148
            ],
            "__typename": [
                9
            ]
        },
        "CollectionWhereInput": {
            "AND": [
                148
            ],
            "OR": [
                148
            ],
            "NOT": [
                148
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusNullableRelationFilter": {
            "is": [
                150
            ],
            "isNot": [
                150
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusWhereInput": {
            "AND": [
                150
            ],
            "OR": [
                150
            ],
            "NOT": [
                150
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "description": [
                20
            ],
            "color": [
                20
            ],
            "is_deleted": [
                33
            ],
            "image": [
                131
            ],
            "Shipment": [
                151
            ],
            "ShipmentEvent": [
                158
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentListRelationFilter": {
            "every": [
                152
            ],
            "some": [
                152
            ],
            "none": [
                152
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentWhereInput": {
            "AND": [
                152
            ],
            "OR": [
                152
            ],
            "NOT": [
                152
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "cargo_agency_id": [
                23
            ],
            "tracking_code": [
                20
            ],
            "tracking_url": [
                20
            ],
            "date_of_shipment": [
                43
            ],
            "date_of_delivery": [
                43
            ],
            "other_info": [
                20
            ],
            "cargo_order": [
                23
            ],
            "shipment_status_id": [
                23
            ],
            "shipment_cost": [
                45
            ],
            "shipment_cost_status": [
                153
            ],
            "shipment_method": [
                115
            ],
            "quantity": [
                45
            ],
            "referance_code": [
                20
            ],
            "is_deleted": [
                33
            ],
            "OrderItem": [
                113
            ],
            "CargoAgency": [
                156
            ],
            "ShipmentStatus": [
                149
            ],
            "ShipmentEvent": [
                158
            ],
            "__typename": [
                9
            ]
        },
        "EnumShipmentCostStatusNullableFilter": {
            "equals": [
                154
            ],
            "in": [
                154
            ],
            "notIn": [
                154
            ],
            "not": [
                155
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCostStatus": {},
        "NestedEnumShipmentCostStatusNullableFilter": {
            "equals": [
                154
            ],
            "in": [
                154
            ],
            "notIn": [
                154
            ],
            "not": [
                155
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyNullableRelationFilter": {
            "is": [
                157
            ],
            "isNot": [
                157
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyWhereInput": {
            "AND": [
                157
            ],
            "OR": [
                157
            ],
            "NOT": [
                157
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "phone": [
                20
            ],
            "email": [
                20
            ],
            "address": [
                20
            ],
            "is_deleted": [
                33
            ],
            "creadentials": [
                78
            ],
            "Shipment": [
                151
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventListRelationFilter": {
            "every": [
                159
            ],
            "some": [
                159
            ],
            "none": [
                159
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventWhereInput": {
            "AND": [
                159
            ],
            "OR": [
                159
            ],
            "NOT": [
                159
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "eventDateTime": [
                43
            ],
            "deliveryDateTime": [
                43
            ],
            "deliveryTo": [
                20
            ],
            "description": [
                20
            ],
            "pieceTotal": [
                23
            ],
            "location": [
                20
            ],
            "locationPhone": [
                20
            ],
            "private": [
                33
            ],
            "shipmentId": [
                23
            ],
            "statusId": [
                23
            ],
            "isRead": [
                33
            ],
            "locationAddress": [
                20
            ],
            "trackingCode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Shipment": [
                160
            ],
            "ShipmentStatus": [
                149
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentNullableRelationFilter": {
            "is": [
                152
            ],
            "isNot": [
                152
            ],
            "__typename": [
                9
            ]
        },
        "CategoryListRelationFilter": {
            "every": [
                140
            ],
            "some": [
                140
            ],
            "none": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "CompanyListRelationFilter": {
            "every": [
                36
            ],
            "some": [
                36
            ],
            "none": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "PriceNullableRelationFilter": {
            "is": [
                145
            ],
            "isNot": [
                145
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeNullableRelationFilter": {
            "is": [
                165
            ],
            "isNot": [
                165
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeWhereInput": {
            "AND": [
                165
            ],
            "OR": [
                165
            ],
            "NOT": [
                165
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "AttributeToProductType": [
                166
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeListRelationFilter": {
            "every": [
                167
            ],
            "some": [
                167
            ],
            "none": [
                167
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeWhereInput": {
            "AND": [
                167
            ],
            "OR": [
                167
            ],
            "NOT": [
                167
            ],
            "attribute_id": [
                16
            ],
            "product_type_id": [
                16
            ],
            "is_variant": [
                33
            ],
            "group_hierarchy": [
                23
            ],
            "is_group": [
                33
            ],
            "variant_hierarchy": [
                23
            ],
            "Attribute": [
                168
            ],
            "ProductType": [
                173
            ],
            "__typename": [
                9
            ]
        },
        "AttributeRelationFilter": {
            "is": [
                169
            ],
            "isNot": [
                169
            ],
            "__typename": [
                9
            ]
        },
        "AttributeWhereInput": {
            "AND": [
                169
            ],
            "OR": [
                169
            ],
            "NOT": [
                169
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "AttributeToProductType": [
                166
            ],
            "AttributeValue": [
                170
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueListRelationFilter": {
            "every": [
                171
            ],
            "some": [
                171
            ],
            "none": [
                171
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueWhereInput": {
            "AND": [
                171
            ],
            "OR": [
                171
            ],
            "NOT": [
                171
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "attributeId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Attribute": [
                172
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "AttributeNullableRelationFilter": {
            "is": [
                169
            ],
            "isNot": [
                169
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeRelationFilter": {
            "is": [
                165
            ],
            "isNot": [
                165
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotListRelationFilter": {
            "every": [
                175
            ],
            "some": [
                175
            ],
            "none": [
                175
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotWhereInput": {
            "AND": [
                175
            ],
            "OR": [
                175
            ],
            "NOT": [
                175
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockListRelationFilter": {
            "every": [
                177
            ],
            "some": [
                177
            ],
            "none": [
                177
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockWhereInput": {
            "AND": [
                177
            ],
            "OR": [
                177
            ],
            "NOT": [
                177
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemListRelationFilter": {
            "every": [
                179
            ],
            "some": [
                179
            ],
            "none": [
                179
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemWhereInput": {
            "AND": [
                179
            ],
            "OR": [
                179
            ],
            "NOT": [
                179
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "productId": [
                23
            ],
            "supportId": [
                23
            ],
            "statusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportAttachment": [
                180
            ],
            "SupportEvent": [
                195
            ],
            "Product": [
                121
            ],
            "SupportItemStatus": [
                198
            ],
            "Support": [
                182
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentListRelationFilter": {
            "every": [
                181
            ],
            "some": [
                181
            ],
            "none": [
                181
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentWhereInput": {
            "AND": [
                181
            ],
            "OR": [
                181
            ],
            "NOT": [
                181
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "path": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "supportItemId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Support": [
                182
            ],
            "SupportItem": [
                200
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "SupportNullableRelationFilter": {
            "is": [
                183
            ],
            "isNot": [
                183
            ],
            "__typename": [
                9
            ]
        },
        "SupportWhereInput": {
            "AND": [
                183
            ],
            "OR": [
                183
            ],
            "NOT": [
                183
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "status": [
                184
            ],
            "type": [
                187
            ],
            "priority": [
                190
            ],
            "companyId": [
                23
            ],
            "assignedToId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportAttachment": [
                180
            ],
            "SupportItem": [
                178
            ],
            "SupportMessage": [
                193
            ],
            "__typename": [
                9
            ]
        },
        "EnumSupportStatusNullableFilter": {
            "equals": [
                185
            ],
            "in": [
                185
            ],
            "notIn": [
                185
            ],
            "not": [
                186
            ],
            "__typename": [
                9
            ]
        },
        "SupportStatus": {},
        "NestedEnumSupportStatusNullableFilter": {
            "equals": [
                185
            ],
            "in": [
                185
            ],
            "notIn": [
                185
            ],
            "not": [
                186
            ],
            "__typename": [
                9
            ]
        },
        "EnumSupportTypeNullableFilter": {
            "equals": [
                188
            ],
            "in": [
                188
            ],
            "notIn": [
                188
            ],
            "not": [
                189
            ],
            "__typename": [
                9
            ]
        },
        "SupportType": {},
        "NestedEnumSupportTypeNullableFilter": {
            "equals": [
                188
            ],
            "in": [
                188
            ],
            "notIn": [
                188
            ],
            "not": [
                189
            ],
            "__typename": [
                9
            ]
        },
        "EnumPriorityNullableFilter": {
            "equals": [
                191
            ],
            "in": [
                191
            ],
            "notIn": [
                191
            ],
            "not": [
                192
            ],
            "__typename": [
                9
            ]
        },
        "Priority": {},
        "NestedEnumPriorityNullableFilter": {
            "equals": [
                191
            ],
            "in": [
                191
            ],
            "notIn": [
                191
            ],
            "not": [
                192
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageListRelationFilter": {
            "every": [
                194
            ],
            "some": [
                194
            ],
            "none": [
                194
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageWhereInput": {
            "AND": [
                194
            ],
            "OR": [
                194
            ],
            "NOT": [
                194
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "message": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportEvent": [
                195
            ],
            "Support": [
                182
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventListRelationFilter": {
            "every": [
                196
            ],
            "some": [
                196
            ],
            "none": [
                196
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventWhereInput": {
            "AND": [
                196
            ],
            "OR": [
                196
            ],
            "NOT": [
                196
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_read": [
                33
            ],
            "private": [
                33
            ],
            "supportItemId": [
                23
            ],
            "statusId": [
                23
            ],
            "messageId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Message": [
                197
            ],
            "Status": [
                198
            ],
            "Item": [
                200
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageNullableRelationFilter": {
            "is": [
                194
            ],
            "isNot": [
                194
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusNullableRelationFilter": {
            "is": [
                199
            ],
            "isNot": [
                199
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusWhereInput": {
            "AND": [
                199
            ],
            "OR": [
                199
            ],
            "NOT": [
                199
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "status": [
                184
            ],
            "SupportEvent": [
                195
            ],
            "SupportItem": [
                178
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemNullableRelationFilter": {
            "is": [
                179
            ],
            "isNot": [
                179
            ],
            "__typename": [
                9
            ]
        },
        "CampaignListRelationFilter": {
            "every": [
                137
            ],
            "some": [
                137
            ],
            "none": [
                137
            ],
            "__typename": [
                9
            ]
        },
        "CollectionListRelationFilter": {
            "every": [
                148
            ],
            "some": [
                148
            ],
            "none": [
                148
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusNullableRelationFilter": {
            "is": [
                204
            ],
            "isNot": [
                204
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusWhereInput": {
            "AND": [
                204
            ],
            "OR": [
                204
            ],
            "NOT": [
                204
            ],
            "id": [
                16
            ],
            "hierarchy": [
                16
            ],
            "color": [
                20
            ],
            "active": [
                18
            ],
            "code": [
                20
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "name": [
                20
            ],
            "out_ref": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "orderStatusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "OrderItem": [
                113
            ],
            "OrderStatus": [
                205
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusNullableRelationFilter": {
            "is": [
                206
            ],
            "isNot": [
                206
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusWhereInput": {
            "AND": [
                206
            ],
            "OR": [
                206
            ],
            "NOT": [
                206
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "arrangement": [
                16
            ],
            "created_at": [
                25
            ],
            "name": [
                20
            ],
            "slug": [
                20
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "OrderItemStatus": [
                207
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusListRelationFilter": {
            "every": [
                204
            ],
            "some": [
                204
            ],
            "none": [
                204
            ],
            "__typename": [
                9
            ]
        },
        "RoleNullableRelationFilter": {
            "is": [
                209
            ],
            "isNot": [
                209
            ],
            "__typename": [
                9
            ]
        },
        "RoleWhereInput": {
            "AND": [
                209
            ],
            "OR": [
                209
            ],
            "NOT": [
                209
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "type": [
                210
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "User": [
                213
            ],
            "Permissions": [
                214
            ],
            "__typename": [
                9
            ]
        },
        "EnumRolesFilter": {
            "equals": [
                211
            ],
            "in": [
                211
            ],
            "notIn": [
                211
            ],
            "not": [
                212
            ],
            "__typename": [
                9
            ]
        },
        "Roles": {},
        "NestedEnumRolesFilter": {
            "equals": [
                211
            ],
            "in": [
                211
            ],
            "notIn": [
                211
            ],
            "not": [
                212
            ],
            "__typename": [
                9
            ]
        },
        "UserListRelationFilter": {
            "every": [
                50
            ],
            "some": [
                50
            ],
            "none": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "PermissionListRelationFilter": {
            "every": [
                215
            ],
            "some": [
                215
            ],
            "none": [
                215
            ],
            "__typename": [
                9
            ]
        },
        "PermissionWhereInput": {
            "AND": [
                215
            ],
            "OR": [
                215
            ],
            "NOT": [
                215
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "Role": [
                216
            ],
            "__typename": [
                9
            ]
        },
        "RoleListRelationFilter": {
            "every": [
                209
            ],
            "some": [
                209
            ],
            "none": [
                209
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementListRelationFilter": {
            "every": [
                218
            ],
            "some": [
                218
            ],
            "none": [
                218
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementWhereInput": {
            "AND": [
                218
            ],
            "OR": [
                218
            ],
            "NOT": [
                218
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "document_type": [
                20
            ],
            "document_number": [
                20
            ],
            "description": [
                20
            ],
            "debit": [
                45
            ],
            "credit": [
                45
            ],
            "balance": [
                45
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "company_id": [
                23
            ],
            "document_date": [
                43
            ],
            "data_number": [
                23
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Company": [
                35
            ],
            "__typename": [
                9
            ]
        },
        "CountyNullableRelationFilter": {
            "is": [
                220
            ],
            "isNot": [
                220
            ],
            "__typename": [
                9
            ]
        },
        "CountyWhereInput": {
            "AND": [
                220
            ],
            "OR": [
                220
            ],
            "NOT": [
                220
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "cityId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Address": [
                37
            ],
            "City": [
                221
            ],
            "District": [
                224
            ],
            "__typename": [
                9
            ]
        },
        "CityNullableRelationFilter": {
            "is": [
                222
            ],
            "isNot": [
                222
            ],
            "__typename": [
                9
            ]
        },
        "CityWhereInput": {
            "AND": [
                222
            ],
            "OR": [
                222
            ],
            "NOT": [
                222
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "County": [
                223
            ],
            "__typename": [
                9
            ]
        },
        "CountyListRelationFilter": {
            "every": [
                220
            ],
            "some": [
                220
            ],
            "none": [
                220
            ],
            "__typename": [
                9
            ]
        },
        "DistrictListRelationFilter": {
            "every": [
                225
            ],
            "some": [
                225
            ],
            "none": [
                225
            ],
            "__typename": [
                9
            ]
        },
        "DistrictWhereInput": {
            "AND": [
                225
            ],
            "OR": [
                225
            ],
            "NOT": [
                225
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "countyId": [
                23
            ],
            "County": [
                219
            ],
            "__typename": [
                9
            ]
        },
        "AddressOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "companyId": [
                228
            ],
            "countyId": [
                228
            ],
            "platform": [
                228
            ],
            "type": [
                228
            ],
            "address": [
                228
            ],
            "title": [
                228
            ],
            "email": [
                228
            ],
            "gsm": [
                228
            ],
            "phone1": [
                228
            ],
            "phone2": [
                228
            ],
            "tax_number": [
                228
            ],
            "tax_office": [
                228
            ],
            "website": [
                228
            ],
            "is_deleted": [
                228
            ],
            "subCompany_code": [
                228
            ],
            "subCompany_name": [
                228
            ],
            "subCompany_out_ref": [
                228
            ],
            "Company": [
                230
            ],
            "County": [
                243
            ],
            "CartBilling": [
                232
            ],
            "CartShipment": [
                232
            ],
            "OrderBilling": [
                236
            ],
            "OrderShipment": [
                236
            ],
            "User": [
                240
            ],
            "_relevance": [
                251
            ],
            "__typename": [
                9
            ]
        },
        "SortOrder": {},
        "SortOrderInput": {
            "sort": [
                227
            ],
            "nulls": [
                229
            ],
            "__typename": [
                9
            ]
        },
        "NullsOrder": {},
        "CompanyOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "email": [
                228
            ],
            "title": [
                228
            ],
            "tax_office": [
                228
            ],
            "tax_number": [
                228
            ],
            "phone1": [
                228
            ],
            "phone2": [
                228
            ],
            "gsm": [
                228
            ],
            "website": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Address": [
                231
            ],
            "Cart": [
                232
            ],
            "CompanyStatement": [
                233
            ],
            "CompanyToPaymentMethod": [
                234
            ],
            "InstallmentToCompany": [
                235
            ],
            "Order": [
                236
            ],
            "Payment": [
                237
            ],
            "PriceToCategory": [
                238
            ],
            "Brand": [
                239
            ],
            "User": [
                240
            ],
            "_relevance": [
                241
            ],
            "__typename": [
                9
            ]
        },
        "AddressOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyOrderByRelevanceInput": {
            "fields": [
                242
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CompanyOrderByRelevanceFieldEnum": {},
        "CountyOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "cityId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Address": [
                231
            ],
            "City": [
                244
            ],
            "District": [
                248
            ],
            "_relevance": [
                249
            ],
            "__typename": [
                9
            ]
        },
        "CityOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "County": [
                245
            ],
            "_relevance": [
                246
            ],
            "__typename": [
                9
            ]
        },
        "CountyOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CityOrderByRelevanceInput": {
            "fields": [
                247
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CityOrderByRelevanceFieldEnum": {},
        "DistrictOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountyOrderByRelevanceInput": {
            "fields": [
                250
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CountyOrderByRelevanceFieldEnum": {},
        "AddressOrderByRelevanceInput": {
            "fields": [
                252
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AddressOrderByRelevanceFieldEnum": {},
        "AddressWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                15
            ],
            "OR": [
                15
            ],
            "NOT": [
                15
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "companyId": [
                23
            ],
            "countyId": [
                23
            ],
            "platform": [
                29
            ],
            "type": [
                31
            ],
            "address": [
                20
            ],
            "title": [
                20
            ],
            "email": [
                20
            ],
            "gsm": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "tax_number": [
                20
            ],
            "tax_office": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "subCompany_code": [
                20
            ],
            "subCompany_name": [
                20
            ],
            "subCompany_out_ref": [
                20
            ],
            "Company": [
                35
            ],
            "County": [
                219
            ],
            "CartBilling": [
                38
            ],
            "CartShipment": [
                38
            ],
            "OrderBilling": [
                59
            ],
            "OrderShipment": [
                59
            ],
            "User": [
                213
            ],
            "__typename": [
                9
            ]
        },
        "Address": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "_count": [
                255
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "County": [
                590,
                {
                    "where": [
                        220
                    ]
                }
            ],
            "CartBilling": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "CartShipment": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderBilling": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderShipment": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AddressCount": {
            "CartBilling": [
                3,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "CartShipment": [
                3,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "OrderBilling": [
                3,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "OrderShipment": [
                3,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "User": [
                3,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Company": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                257
            ],
            "Address": [
                254,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        258,
                        "[AddressScalarFieldEnum!]"
                    ]
                }
            ],
            "Cart": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "CompanyStatement": [
                584,
                {
                    "where": [
                        218
                    ],
                    "orderBy": [
                        585,
                        "[CompanyStatementOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        588
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        589,
                        "[CompanyStatementScalarFieldEnum!]"
                    ]
                }
            ],
            "CompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "InstallmentToCompany": [
                331,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "Order": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "PriceToCategory": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "Brand": [
                439,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        468,
                        "[BrandScalarFieldEnum!]"
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCount": {
            "Address": [
                3,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "Cart": [
                3,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "CompanyStatement": [
                3,
                {
                    "where": [
                        218
                    ]
                }
            ],
            "CompanyToPaymentMethod": [
                3,
                {
                    "where": [
                        106
                    ]
                }
            ],
            "InstallmentToCompany": [
                3,
                {
                    "where": [
                        95
                    ]
                }
            ],
            "Order": [
                3,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "PriceToCategory": [
                3,
                {
                    "where": [
                        142
                    ]
                }
            ],
            "Brand": [
                3,
                {
                    "where": [
                        130
                    ]
                }
            ],
            "User": [
                3,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AddressScalarFieldEnum": {},
        "Cart": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                260
            ],
            "BillingAddress": [
                254,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "ShipmentAddress": [
                254,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "CartItem": [
                375,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        415,
                        "[CartItemScalarFieldEnum!]"
                    ]
                }
            ],
            "Order": [
                291,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CartCount": {
            "CartItem": [
                3,
                {
                    "where": [
                        119
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "User": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                262
            ],
            "Cart": [
                259,
                {
                    "where": [
                        39
                    ],
                    "orderBy": [
                        263,
                        "[CartOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        283
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        284,
                        "[CartScalarFieldEnum!]"
                    ]
                }
            ],
            "ConfirmCode": [
                285,
                {
                    "where": [
                        55
                    ],
                    "orderBy": [
                        286,
                        "[ConfirmCodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        289
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        290,
                        "[ConfirmCodeScalarFieldEnum!]"
                    ]
                }
            ],
            "Order": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportAttachment": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportMessage": [
                542,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        556,
                        "[SupportMessageScalarFieldEnum!]"
                    ]
                }
            ],
            "Address": [
                254,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "Role": [
                570,
                {
                    "where": [
                        209
                    ]
                }
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        488,
                        "[CompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "UserCount": {
            "Cart": [
                3,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "ConfirmCode": [
                3,
                {
                    "where": [
                        55
                    ]
                }
            ],
            "Order": [
                3,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "SupportAttachment": [
                3,
                {
                    "where": [
                        181
                    ]
                }
            ],
            "SupportMessage": [
                3,
                {
                    "where": [
                        194
                    ]
                }
            ],
            "Company": [
                3,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CartOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "userId": [
                228
            ],
            "status": [
                228
            ],
            "billing_address_id": [
                228
            ],
            "shipment_address_id": [
                228
            ],
            "approved_by": [
                228
            ],
            "aprroved_at": [
                228
            ],
            "note": [
                228
            ],
            "order_key": [
                228
            ],
            "total_discount": [
                228
            ],
            "total_price": [
                228
            ],
            "total_price_net": [
                228
            ],
            "total_shipment_cost": [
                228
            ],
            "total_vat_cost": [
                228
            ],
            "company_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "BillingAddress": [
                226
            ],
            "Company": [
                230
            ],
            "ShipmentAddress": [
                226
            ],
            "User": [
                264
            ],
            "CartItem": [
                276
            ],
            "Order": [
                277
            ],
            "_relevance": [
                281
            ],
            "__typename": [
                9
            ]
        },
        "UserOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "provider_id": [
                228
            ],
            "first_name": [
                228
            ],
            "last_name": [
                228
            ],
            "gender": [
                228
            ],
            "default_address_id": [
                228
            ],
            "role_id": [
                228
            ],
            "email": [
                228
            ],
            "gsm": [
                228
            ],
            "password": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Cart": [
                232
            ],
            "ConfirmCode": [
                265
            ],
            "Order": [
                236
            ],
            "OrderItem": [
                266
            ],
            "Payment": [
                237
            ],
            "SupportAttachment": [
                267
            ],
            "SupportMessage": [
                268
            ],
            "Address": [
                226
            ],
            "Role": [
                269
            ],
            "Company": [
                273
            ],
            "_relevance": [
                274
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "type": [
                227
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "User": [
                240
            ],
            "Permissions": [
                270
            ],
            "_relevance": [
                271
            ],
            "__typename": [
                9
            ]
        },
        "PermissionOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleOrderByRelevanceInput": {
            "fields": [
                272
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "RoleOrderByRelevanceFieldEnum": {},
        "CompanyOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserOrderByRelevanceInput": {
            "fields": [
                275
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "UserOrderByRelevanceFieldEnum": {},
        "CartItemOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "ordered_at": [
                228
            ],
            "order_key": [
                227
            ],
            "outer_order_key": [
                228
            ],
            "company_id": [
                228
            ],
            "user_id": [
                228
            ],
            "shipment_address_id": [
                228
            ],
            "billing_address_id": [
                228
            ],
            "total_price": [
                228
            ],
            "total_price_net": [
                228
            ],
            "total_shipment_cost": [
                228
            ],
            "total_vat_cost": [
                228
            ],
            "total_discount": [
                228
            ],
            "total_quantity": [
                228
            ],
            "order_note": [
                228
            ],
            "is_approved": [
                228
            ],
            "approved_by": [
                228
            ],
            "approved_at": [
                228
            ],
            "is_sent_erp": [
                228
            ],
            "sent_at": [
                228
            ],
            "sent_by": [
                228
            ],
            "company_name": [
                228
            ],
            "is_deleted": [
                228
            ],
            "status": [
                228
            ],
            "payment_status": [
                228
            ],
            "cartId": [
                228
            ],
            "warehouse": [
                228
            ],
            "is_sent_status": [
                228
            ],
            "BillingAddress": [
                226
            ],
            "Cart": [
                263
            ],
            "Company": [
                230
            ],
            "ShipmentAddress": [
                226
            ],
            "User": [
                264
            ],
            "PaymentGroup": [
                278
            ],
            "OrderItem": [
                266
            ],
            "_relevance": [
                279
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderOrderByRelevanceInput": {
            "fields": [
                280
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderOrderByRelevanceFieldEnum": {},
        "CartOrderByRelevanceInput": {
            "fields": [
                282
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CartOrderByRelevanceFieldEnum": {},
        "CartWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "order_key": [
                9
            ],
            "AND": [
                39
            ],
            "OR": [
                39
            ],
            "NOT": [
                39
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "userId": [
                23
            ],
            "status": [
                40
            ],
            "billing_address_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "approved_by": [
                23
            ],
            "aprroved_at": [
                43
            ],
            "note": [
                20
            ],
            "total_discount": [
                45
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "company_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "BillingAddress": [
                48
            ],
            "Company": [
                35
            ],
            "ShipmentAddress": [
                48
            ],
            "User": [
                49
            ],
            "CartItem": [
                128
            ],
            "Order": [
                72
            ],
            "__typename": [
                9
            ]
        },
        "CartScalarFieldEnum": {},
        "ConfirmCode": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "user_id": [
                3
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "code": [
                228
            ],
            "expire_at": [
                228
            ],
            "confirmed_at": [
                228
            ],
            "user_id": [
                228
            ],
            "type": [
                228
            ],
            "gsm_no": [
                228
            ],
            "email": [
                228
            ],
            "User": [
                264
            ],
            "_relevance": [
                287
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeOrderByRelevanceInput": {
            "fields": [
                288
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeOrderByRelevanceFieldEnum": {},
        "ConfirmCodeWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                55
            ],
            "OR": [
                55
            ],
            "NOT": [
                55
            ],
            "code": [
                20
            ],
            "expire_at": [
                43
            ],
            "confirmed_at": [
                43
            ],
            "user_id": [
                23
            ],
            "type": [
                56
            ],
            "gsm_no": [
                20
            ],
            "email": [
                20
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeScalarFieldEnum": {},
        "Order": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "_count": [
                292
            ],
            "BillingAddress": [
                254,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "Cart": [
                259,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "ShipmentAddress": [
                254,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "PaymentGroup": [
                293,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderCount": {
            "PaymentGroup": [
                3,
                {
                    "where": [
                        71
                    ]
                }
            ],
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroup": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "Order": [
                291,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Payment": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "_count": [
                295
            ],
            "card_bank": [
                296,
                {
                    "where": [
                        84
                    ]
                }
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "Method": [
                354,
                {
                    "where": [
                        104
                    ]
                }
            ],
            "PosAccount": [
                326,
                {
                    "where": [
                        89
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "PaymentGroup": [
                293,
                {
                    "where": [
                        71
                    ],
                    "orderBy": [
                        361,
                        "[PaymentGroupOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        364
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        365,
                        "[PaymentGroupScalarFieldEnum!]"
                    ]
                }
            ],
            "PaymentCheck": [
                366,
                {
                    "where": [
                        109
                    ],
                    "orderBy": [
                        368,
                        "[PaymentCheckOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        371
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        372,
                        "[PaymentCheckScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCount": {
            "PaymentGroup": [
                3,
                {
                    "where": [
                        71
                    ]
                }
            ],
            "PaymentCheck": [
                3,
                {
                    "where": [
                        109
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Bank": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                297
            ],
            "bin_numbers": [
                298,
                {
                    "where": [
                        86
                    ],
                    "orderBy": [
                        299,
                        "[BinNumberOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        308
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        309,
                        "[BinNumberScalarFieldEnum!]"
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "PosAccount": [
                326,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        349,
                        "[PosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "PosConfig": [
                350,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        353,
                        "[PosConfigScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "BankCount": {
            "bin_numbers": [
                3,
                {
                    "where": [
                        86
                    ]
                }
            ],
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "PosAccount": [
                3,
                {
                    "where": [
                        89
                    ]
                }
            ],
            "PosConfig": [
                3,
                {
                    "where": [
                        101
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "BinNumber": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "Bank": [
                296,
                {
                    "where": [
                        84
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "is_bussiness_card": [
                228
            ],
            "network": [
                228
            ],
            "family": [
                228
            ],
            "bank_id": [
                228
            ],
            "binnumber": [
                228
            ],
            "cardtype": [
                228
            ],
            "Bank": [
                300
            ],
            "_relevance": [
                306
            ],
            "__typename": [
                9
            ]
        },
        "BankOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "image": [
                228
            ],
            "iban": [
                228
            ],
            "office": [
                228
            ],
            "account_number": [
                228
            ],
            "eft_code": [
                228
            ],
            "pos_defaults": [
                228
            ],
            "family": [
                228
            ],
            "is_pos": [
                228
            ],
            "pos_category": [
                228
            ],
            "selected_pos_id": [
                228
            ],
            "is_payment_system": [
                228
            ],
            "is_deleted": [
                228
            ],
            "bin_numbers": [
                301
            ],
            "Payment": [
                237
            ],
            "PosAccount": [
                302
            ],
            "PosConfig": [
                303
            ],
            "_relevance": [
                304
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankOrderByRelevanceInput": {
            "fields": [
                305
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BankOrderByRelevanceFieldEnum": {},
        "BinNumberOrderByRelevanceInput": {
            "fields": [
                307
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberOrderByRelevanceFieldEnum": {},
        "BinNumberWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                86
            ],
            "OR": [
                86
            ],
            "NOT": [
                86
            ],
            "active": [
                18
            ],
            "is_bussiness_card": [
                33
            ],
            "network": [
                20
            ],
            "family": [
                20
            ],
            "bank_id": [
                23
            ],
            "binnumber": [
                20
            ],
            "cardtype": [
                20
            ],
            "Bank": [
                83
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberScalarFieldEnum": {},
        "PaymentOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "order_key": [
                228
            ],
            "price": [
                228
            ],
            "installment": [
                228
            ],
            "fraud_status": [
                228
            ],
            "payment_id": [
                228
            ],
            "payment_status": [
                228
            ],
            "payment_desc": [
                228
            ],
            "description": [
                228
            ],
            "card_owner": [
                228
            ],
            "card_number": [
                228
            ],
            "card_type": [
                228
            ],
            "card_association": [
                228
            ],
            "sent_at": [
                228
            ],
            "sent_by": [
                228
            ],
            "user_id": [
                228
            ],
            "method_id": [
                228
            ],
            "pos_account_id": [
                228
            ],
            "company_id": [
                228
            ],
            "auth_code": [
                228
            ],
            "bankId": [
                228
            ],
            "bank_message": [
                228
            ],
            "is_sent_erp": [
                228
            ],
            "bank_response": [
                228
            ],
            "ip": [
                228
            ],
            "req_absolute_path": [
                228
            ],
            "gateway_type": [
                228
            ],
            "temp_field": [
                228
            ],
            "is_sent_status": [
                228
            ],
            "payment_key": [
                228
            ],
            "payment_event_id": [
                228
            ],
            "outer_payment_key": [
                228
            ],
            "card_bank": [
                300
            ],
            "Company": [
                230
            ],
            "Method": [
                311
            ],
            "PosAccount": [
                314
            ],
            "User": [
                264
            ],
            "PaymentGroup": [
                278
            ],
            "PaymentCheck": [
                321
            ],
            "_relevance": [
                322
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "minimum": [
                228
            ],
            "maximum": [
                228
            ],
            "title": [
                228
            ],
            "is_default": [
                228
            ],
            "CompanyToPaymentMethod": [
                234
            ],
            "Payment": [
                237
            ],
            "_relevance": [
                312
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodOrderByRelevanceInput": {
            "fields": [
                313
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodOrderByRelevanceFieldEnum": {},
        "PosAccountOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "bank_id": [
                228
            ],
            "is_default": [
                228
            ],
            "image": [
                228
            ],
            "pos_props": [
                228
            ],
            "credentials": [
                228
            ],
            "agencies": [
                228
            ],
            "Payment_system_id": [
                228
            ],
            "maximum": [
                228
            ],
            "minimum": [
                228
            ],
            "InstallmentToPosAccount": [
                315
            ],
            "Payment": [
                237
            ],
            "PaymentSystem": [
                316
            ],
            "Bank": [
                300
            ],
            "PosConfig": [
                303
            ],
            "_relevance": [
                319
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "PosAccount": [
                302
            ],
            "_relevance": [
                317
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemOrderByRelevanceInput": {
            "fields": [
                318
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemOrderByRelevanceFieldEnum": {},
        "PosAccountOrderByRelevanceInput": {
            "fields": [
                320
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountOrderByRelevanceFieldEnum": {},
        "PaymentCheckOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentOrderByRelevanceInput": {
            "fields": [
                323
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentOrderByRelevanceFieldEnum": {},
        "PaymentWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "payment_key": [
                9
            ],
            "AND": [
                74
            ],
            "OR": [
                74
            ],
            "NOT": [
                74
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "order_key": [
                20
            ],
            "price": [
                45
            ],
            "installment": [
                23
            ],
            "fraud_status": [
                23
            ],
            "payment_id": [
                20
            ],
            "payment_status": [
                75
            ],
            "payment_desc": [
                20
            ],
            "description": [
                20
            ],
            "card_owner": [
                20
            ],
            "card_number": [
                20
            ],
            "card_type": [
                20
            ],
            "card_association": [
                20
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                20
            ],
            "user_id": [
                23
            ],
            "method_id": [
                23
            ],
            "pos_account_id": [
                23
            ],
            "company_id": [
                23
            ],
            "auth_code": [
                20
            ],
            "bankId": [
                23
            ],
            "bank_message": [
                20
            ],
            "is_sent_erp": [
                33
            ],
            "bank_response": [
                78
            ],
            "ip": [
                20
            ],
            "req_absolute_path": [
                20
            ],
            "gateway_type": [
                80
            ],
            "temp_field": [
                78
            ],
            "is_sent_status": [
                66
            ],
            "payment_event_id": [
                23
            ],
            "outer_payment_key": [
                20
            ],
            "card_bank": [
                83
            ],
            "Company": [
                35
            ],
            "Method": [
                103
            ],
            "PosAccount": [
                102
            ],
            "User": [
                49
            ],
            "PaymentGroup": [
                70
            ],
            "PaymentCheck": [
                108
            ],
            "__typename": [
                9
            ]
        },
        "PaymentScalarFieldEnum": {},
        "PosAccount": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "_count": [
                327
            ],
            "InstallmentToPosAccount": [
                328,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "PaymentSystem": [
                346,
                {
                    "where": [
                        99
                    ]
                }
            ],
            "Bank": [
                296,
                {
                    "where": [
                        84
                    ]
                }
            ],
            "PosConfig": [
                350,
                {
                    "where": [
                        101
                    ],
                    "orderBy": [
                        351,
                        "[PosConfigOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        352
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        353,
                        "[PosConfigScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCount": {
            "InstallmentToPosAccount": [
                3,
                {
                    "where": [
                        91
                    ]
                }
            ],
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "PosConfig": [
                3,
                {
                    "where": [
                        101
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccount": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "Installment": [
                329
            ],
            "PosAccount": [
                326
            ],
            "__typename": [
                9
            ]
        },
        "Installment": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "_count": [
                330
            ],
            "InstallmentToCompany": [
                331,
                {
                    "where": [
                        95
                    ],
                    "orderBy": [
                        332,
                        "[InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        338
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        340,
                        "[InstallmentToCompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "InstallmentToPosAccount": [
                328,
                {
                    "where": [
                        91
                    ],
                    "orderBy": [
                        341,
                        "[InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        344
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        345,
                        "[InstallmentToPosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCount": {
            "InstallmentToCompany": [
                3,
                {
                    "where": [
                        95
                    ]
                }
            ],
            "InstallmentToPosAccount": [
                3,
                {
                    "where": [
                        91
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompany": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "Company": [
                256
            ],
            "Installment": [
                329
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyOrderByWithRelationAndSearchRelevanceInput": {
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "status": [
                228
            ],
            "blockageDay": [
                228
            ],
            "bankForiegnCardCommisionRate": [
                228
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                228
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                228
            ],
            "bankOnUsCreditCardCommisionRate": [
                228
            ],
            "bankOnUsDebitCardCommisionRate": [
                228
            ],
            "cardBrandName": [
                228
            ],
            "mininum": [
                228
            ],
            "maximum": [
                228
            ],
            "additiveInstallment": [
                228
            ],
            "merchantCommissionRate": [
                228
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "Company": [
                230
            ],
            "Installment": [
                333
            ],
            "_relevance": [
                336
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "code": [
                228
            ],
            "name": [
                228
            ],
            "out_ref": [
                228
            ],
            "count": [
                228
            ],
            "InstallmentToCompany": [
                235
            ],
            "InstallmentToPosAccount": [
                315
            ],
            "_relevance": [
                334
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentOrderByRelevanceInput": {
            "fields": [
                335
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentOrderByRelevanceFieldEnum": {},
        "InstallmentToCompanyOrderByRelevanceInput": {
            "fields": [
                337
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyOrderByRelevanceFieldEnum": {},
        "InstallmentToCompanyWhereUniqueInput": {
            "out_ref": [
                9
            ],
            "id": [
                3
            ],
            "installment_id_company_id": [
                339
            ],
            "AND": [
                95
            ],
            "OR": [
                95
            ],
            "NOT": [
                95
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "company_id": [
                16
            ],
            "Company": [
                96
            ],
            "Installment": [
                92
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyInstallment_idCompany_idCompoundUniqueInput": {
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyScalarFieldEnum": {},
        "InstallmentToPosAccountOrderByWithRelationAndSearchRelevanceInput": {
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "status": [
                228
            ],
            "blockageDay": [
                228
            ],
            "bankForiegnCardCommisionRate": [
                228
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                228
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                228
            ],
            "bankOnUsCreditCardCommisionRate": [
                228
            ],
            "bankOnUsDebitCardCommisionRate": [
                228
            ],
            "cardBrandName": [
                228
            ],
            "mininum": [
                228
            ],
            "maximum": [
                228
            ],
            "additiveInstallment": [
                228
            ],
            "merchantCommissionRate": [
                228
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "Installment": [
                333
            ],
            "PosAccount": [
                314
            ],
            "_relevance": [
                342
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountOrderByRelevanceInput": {
            "fields": [
                343
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountOrderByRelevanceFieldEnum": {},
        "InstallmentToPosAccountWhereUniqueInput": {
            "out_ref": [
                9
            ],
            "id": [
                3
            ],
            "AND": [
                91
            ],
            "OR": [
                91
            ],
            "NOT": [
                91
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "pos_account_id": [
                16
            ],
            "Installment": [
                92
            ],
            "PosAccount": [
                97
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountScalarFieldEnum": {},
        "PaymentSystem": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "_count": [
                347
            ],
            "PosAccount": [
                326,
                {
                    "where": [
                        89
                    ],
                    "orderBy": [
                        314,
                        "[PosAccountOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        348
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        349,
                        "[PosAccountScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCount": {
            "PosAccount": [
                3,
                {
                    "where": [
                        89
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                89
            ],
            "OR": [
                89
            ],
            "NOT": [
                89
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "bank_id": [
                23
            ],
            "is_default": [
                33
            ],
            "image": [
                20
            ],
            "pos_props": [
                78
            ],
            "credentials": [
                78
            ],
            "agencies": [
                78
            ],
            "Payment_system_id": [
                23
            ],
            "maximum": [
                45
            ],
            "minimum": [
                45
            ],
            "InstallmentToPosAccount": [
                90
            ],
            "Payment": [
                87
            ],
            "PaymentSystem": [
                98
            ],
            "Bank": [
                83
            ],
            "PosConfig": [
                100
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountScalarFieldEnum": {},
        "PosConfig": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "Bank": [
                296,
                {
                    "where": [
                        84
                    ]
                }
            ],
            "PosAccount": [
                326,
                {
                    "where": [
                        89
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "bank_id": [
                228
            ],
            "pos_id": [
                228
            ],
            "is_default": [
                228
            ],
            "Bank": [
                300
            ],
            "PosAccount": [
                314
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                101
            ],
            "OR": [
                101
            ],
            "NOT": [
                101
            ],
            "bank_id": [
                23
            ],
            "pos_id": [
                23
            ],
            "is_default": [
                33
            ],
            "Bank": [
                83
            ],
            "PosAccount": [
                102
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigScalarFieldEnum": {},
        "PaymentMethod": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "_count": [
                355
            ],
            "CompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        106
                    ],
                    "orderBy": [
                        357,
                        "[CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        358
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        360,
                        "[CompanyToPaymentMethodScalarFieldEnum!]"
                    ]
                }
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCount": {
            "CompanyToPaymentMethod": [
                3,
                {
                    "where": [
                        106
                    ]
                }
            ],
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethod": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "Company": [
                256
            ],
            "PaymentMethod": [
                354
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodOrderByWithRelationAndSearchRelevanceInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                228
            ],
            "maximum_amount": [
                228
            ],
            "end_date": [
                228
            ],
            "start_date": [
                228
            ],
            "total_amount": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "Company": [
                230
            ],
            "PaymentMethod": [
                311
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodWhereUniqueInput": {
            "company_id_payment_methodId": [
                359
            ],
            "AND": [
                106
            ],
            "OR": [
                106
            ],
            "NOT": [
                106
            ],
            "company_id": [
                16
            ],
            "payment_methodId": [
                16
            ],
            "minimum_amount": [
                45
            ],
            "maximum_amount": [
                45
            ],
            "end_date": [
                43
            ],
            "start_date": [
                43
            ],
            "total_amount": [
                45
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "Company": [
                96
            ],
            "PaymentMethod": [
                107
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCompany_idPayment_methodIdCompoundUniqueInput": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodScalarFieldEnum": {},
        "PaymentGroupOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "order_id": [
                228
            ],
            "paid_price": [
                228
            ],
            "payment_id": [
                228
            ],
            "description": [
                228
            ],
            "Order": [
                277
            ],
            "Payment": [
                310
            ],
            "_relevance": [
                362
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupOrderByRelevanceInput": {
            "fields": [
                363
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupOrderByRelevanceFieldEnum": {},
        "PaymentGroupWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                71
            ],
            "OR": [
                71
            ],
            "NOT": [
                71
            ],
            "order_id": [
                23
            ],
            "paid_price": [
                45
            ],
            "payment_id": [
                23
            ],
            "description": [
                20
            ],
            "Order": [
                72
            ],
            "Payment": [
                73
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupScalarFieldEnum": {},
        "PaymentCheck": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "_count": [
                367
            ],
            "Payment": [
                294,
                {
                    "where": [
                        74
                    ],
                    "orderBy": [
                        310,
                        "[PaymentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        324
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        325,
                        "[PaymentScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCount": {
            "Payment": [
                3,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                228
            ],
            "amount": [
                228
            ],
            "bank_response": [
                228
            ],
            "payment_id": [
                228
            ],
            "reference_number": [
                228
            ],
            "check_status": [
                228
            ],
            "payment_status": [
                228
            ],
            "Payment": [
                237
            ],
            "_relevance": [
                369
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckOrderByRelevanceInput": {
            "fields": [
                370
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckOrderByRelevanceFieldEnum": {},
        "PaymentCheckWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                109
            ],
            "OR": [
                109
            ],
            "NOT": [
                109
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "amount": [
                45
            ],
            "bank_response": [
                78
            ],
            "payment_id": [
                23
            ],
            "reference_number": [
                20
            ],
            "check_status": [
                110
            ],
            "payment_status": [
                75
            ],
            "Payment": [
                87
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckScalarFieldEnum": {},
        "OrderItem": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "_count": [
                374
            ],
            "CartItem": [
                375,
                {
                    "where": [
                        119
                    ]
                }
            ],
            "OrderItemStatus": [
                562,
                {
                    "where": [
                        204
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "Shipment": [
                473,
                {
                    "where": [
                        152
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "Order": [
                291,
                {
                    "where": [
                        60
                    ],
                    "orderBy": [
                        277,
                        "[OrderOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        568
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        569,
                        "[OrderScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCount": {
            "Order": [
                3,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CartItem": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                376
            ],
            "Cart": [
                259,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCount": {
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Product": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                79
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "_count": [
                378
            ],
            "Barcode": [
                379,
                {
                    "where": [
                        127
                    ],
                    "orderBy": [
                        380,
                        "[BarcodeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        409
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        410,
                        "[BarcodeScalarFieldEnum!]"
                    ]
                }
            ],
            "CartItem": [
                375,
                {
                    "where": [
                        119
                    ],
                    "orderBy": [
                        411,
                        "[CartItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        414
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        415,
                        "[CartItemScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "Brand": [
                439,
                {
                    "where": [
                        130
                    ]
                }
            ],
            "Category": [
                458,
                {
                    "where": [
                        140
                    ]
                }
            ],
            "Price": [
                461,
                {
                    "where": [
                        145
                    ]
                }
            ],
            "ProductType": [
                489,
                {
                    "where": [
                        165
                    ]
                }
            ],
            "ProductLot": [
                508,
                {
                    "where": [
                        175
                    ],
                    "orderBy": [
                        509,
                        "[ProductLotOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        512
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        513,
                        "[ProductLotScalarFieldEnum!]"
                    ]
                }
            ],
            "ProductStock": [
                514,
                {
                    "where": [
                        177
                    ],
                    "orderBy": [
                        515,
                        "[ProductStockOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        518
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        519,
                        "[ProductStockScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportItem": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "AttributeValues": [
                501,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        507,
                        "[AttributeValueScalarFieldEnum!]"
                    ]
                }
            ],
            "Campaign": [
                443,
                {
                    "where": [
                        137
                    ],
                    "orderBy": [
                        446,
                        "[CampaignOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        557
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        558,
                        "[CampaignScalarFieldEnum!]"
                    ]
                }
            ],
            "Collection": [
                469,
                {
                    "where": [
                        148
                    ],
                    "orderBy": [
                        449,
                        "[CollectionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        559
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        560,
                        "[CollectionScalarFieldEnum!]"
                    ]
                }
            ],
            "images": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "SellPrice": [
                561
            ],
            "attributes": [
                9
            ],
            "variant": [
                9
            ],
            "productType": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductCount": {
            "Barcode": [
                3,
                {
                    "where": [
                        127
                    ]
                }
            ],
            "CartItem": [
                3,
                {
                    "where": [
                        119
                    ]
                }
            ],
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "ProductLot": [
                3,
                {
                    "where": [
                        175
                    ]
                }
            ],
            "ProductStock": [
                3,
                {
                    "where": [
                        177
                    ]
                }
            ],
            "SupportItem": [
                3,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "AttributeValues": [
                3,
                {
                    "where": [
                        171
                    ]
                }
            ],
            "Campaign": [
                3,
                {
                    "where": [
                        137
                    ]
                }
            ],
            "Collection": [
                3,
                {
                    "where": [
                        148
                    ]
                }
            ],
            "images": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Barcode": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "module": [
                228
            ],
            "module_out_ref": [
                228
            ],
            "product_id": [
                228
            ],
            "barcode": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Product": [
                381
            ],
            "_relevance": [
                407
            ],
            "__typename": [
                9
            ]
        },
        "ProductOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "group": [
                228
            ],
            "brand_id": [
                228
            ],
            "category_id": [
                228
            ],
            "negative_sale": [
                228
            ],
            "on_sale": [
                228
            ],
            "rating": [
                228
            ],
            "vat": [
                228
            ],
            "sold_count": [
                228
            ],
            "sold_quantity": [
                228
            ],
            "review_count": [
                228
            ],
            "description": [
                228
            ],
            "favorite_count": [
                228
            ],
            "in_stock": [
                228
            ],
            "unit": [
                228
            ],
            "unit_ref": [
                228
            ],
            "unit_code": [
                228
            ],
            "is_shipping_required": [
                228
            ],
            "tag": [
                228
            ],
            "cargo_fee": [
                228
            ],
            "features": [
                227
            ],
            "platform": [
                228
            ],
            "type_id": [
                228
            ],
            "unit_name": [
                228
            ],
            "title": [
                228
            ],
            "has_features": [
                228
            ],
            "priceId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "out_name": [
                228
            ],
            "Barcode": [
                382
            ],
            "CartItem": [
                276
            ],
            "OrderItem": [
                266
            ],
            "Brand": [
                383
            ],
            "Category": [
                389
            ],
            "Price": [
                392
            ],
            "ProductType": [
                395
            ],
            "ProductLot": [
                399
            ],
            "ProductStock": [
                400
            ],
            "SupportItem": [
                401
            ],
            "AttributeValues": [
                402
            ],
            "Campaign": [
                403
            ],
            "Collection": [
                404
            ],
            "images": [
                384
            ],
            "_relevance": [
                405
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "image": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "Image": [
                384
            ],
            "Product": [
                385
            ],
            "Categories": [
                386
            ],
            "Company": [
                273
            ],
            "_relevance": [
                387
            ],
            "__typename": [
                9
            ]
        },
        "ImageOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategoryOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandOrderByRelevanceInput": {
            "fields": [
                388
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BrandOrderByRelevanceFieldEnum": {},
        "CategoryOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "parent_id": [
                228
            ],
            "is_parent": [
                228
            ],
            "level": [
                228
            ],
            "description": [
                228
            ],
            "background_img": [
                228
            ],
            "is_corporate": [
                228
            ],
            "link_url": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "Image": [
                384
            ],
            "PriceToCategory": [
                238
            ],
            "Product": [
                385
            ],
            "Brand": [
                239
            ],
            "_relevance": [
                390
            ],
            "__typename": [
                9
            ]
        },
        "CategoryOrderByRelevanceInput": {
            "fields": [
                391
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CategoryOrderByRelevanceFieldEnum": {},
        "PriceOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "price": [
                228
            ],
            "price_discounted": [
                228
            ],
            "retail": [
                228
            ],
            "project_name": [
                228
            ],
            "project_code": [
                228
            ],
            "discount_rate": [
                228
            ],
            "price_type": [
                228
            ],
            "is_deleted": [
                228
            ],
            "PriceToCategory": [
                238
            ],
            "Product": [
                385
            ],
            "_relevance": [
                393
            ],
            "__typename": [
                9
            ]
        },
        "PriceOrderByRelevanceInput": {
            "fields": [
                394
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PriceOrderByRelevanceFieldEnum": {},
        "ProductTypeOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "AttributeToProductType": [
                396
            ],
            "Product": [
                385
            ],
            "_relevance": [
                397
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeOrderByRelevanceInput": {
            "fields": [
                398
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeOrderByRelevanceFieldEnum": {},
        "ProductLotOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductOrderByRelevanceInput": {
            "fields": [
                406
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductOrderByRelevanceFieldEnum": {},
        "BarcodeOrderByRelevanceInput": {
            "fields": [
                408
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeOrderByRelevanceFieldEnum": {},
        "BarcodeWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                127
            ],
            "OR": [
                127
            ],
            "NOT": [
                127
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "module": [
                20
            ],
            "module_out_ref": [
                23
            ],
            "product_id": [
                23
            ],
            "barcode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeScalarFieldEnum": {},
        "CartItemOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "cart_id": [
                228
            ],
            "product_id": [
                228
            ],
            "price": [
                228
            ],
            "quantity": [
                228
            ],
            "vat": [
                228
            ],
            "unit_code": [
                228
            ],
            "unit": [
                228
            ],
            "quantity2": [
                228
            ],
            "total_price": [
                228
            ],
            "discount_price": [
                228
            ],
            "vat_price": [
                228
            ],
            "total_net_price": [
                228
            ],
            "features": [
                227
            ],
            "discount_rate": [
                228
            ],
            "notes1": [
                228
            ],
            "notes2": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Cart": [
                263
            ],
            "Product": [
                381
            ],
            "OrderItem": [
                266
            ],
            "_relevance": [
                412
            ],
            "__typename": [
                9
            ]
        },
        "CartItemOrderByRelevanceInput": {
            "fields": [
                413
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CartItemOrderByRelevanceFieldEnum": {},
        "CartItemWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                119
            ],
            "OR": [
                119
            ],
            "NOT": [
                119
            ],
            "cart_id": [
                23
            ],
            "product_id": [
                23
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "vat": [
                45
            ],
            "unit_code": [
                20
            ],
            "unit": [
                45
            ],
            "quantity2": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "features": [
                120
            ],
            "discount_rate": [
                45
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Cart": [
                69
            ],
            "Product": [
                121
            ],
            "OrderItem": [
                113
            ],
            "__typename": [
                9
            ]
        },
        "CartItemScalarFieldEnum": {},
        "OrderItemOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "status_name": [
                228
            ],
            "price": [
                228
            ],
            "quantity": [
                228
            ],
            "quantity2": [
                228
            ],
            "discount_rate": [
                228
            ],
            "vat": [
                228
            ],
            "total_price": [
                228
            ],
            "discount_price": [
                228
            ],
            "vat_price": [
                228
            ],
            "total_net_price": [
                228
            ],
            "unit": [
                228
            ],
            "unit_shipment_cost": [
                228
            ],
            "sent_unit": [
                228
            ],
            "waiting_unit": [
                228
            ],
            "features": [
                228
            ],
            "notes1": [
                228
            ],
            "notes2": [
                228
            ],
            "userId": [
                228
            ],
            "productId": [
                228
            ],
            "orderItemStatusId": [
                228
            ],
            "shipment_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "shipment_method": [
                228
            ],
            "cart_id": [
                228
            ],
            "orderId": [
                228
            ],
            "CartItem": [
                411
            ],
            "OrderItemStatus": [
                417
            ],
            "Product": [
                381
            ],
            "Shipment": [
                424
            ],
            "User": [
                264
            ],
            "Order": [
                236
            ],
            "_relevance": [
                435
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "color": [
                228
            ],
            "active": [
                227
            ],
            "code": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "name": [
                228
            ],
            "out_ref": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "orderStatusId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "OrderItem": [
                266
            ],
            "OrderStatus": [
                418
            ],
            "_relevance": [
                422
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "arrangement": [
                227
            ],
            "created_at": [
                227
            ],
            "name": [
                228
            ],
            "slug": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "OrderItemStatus": [
                419
            ],
            "_relevance": [
                420
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusOrderByRelevanceInput": {
            "fields": [
                421
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusOrderByRelevanceFieldEnum": {},
        "OrderItemStatusOrderByRelevanceInput": {
            "fields": [
                423
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusOrderByRelevanceFieldEnum": {},
        "ShipmentOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "cargo_agency_id": [
                228
            ],
            "tracking_code": [
                228
            ],
            "tracking_url": [
                228
            ],
            "date_of_shipment": [
                228
            ],
            "date_of_delivery": [
                228
            ],
            "other_info": [
                228
            ],
            "cargo_order": [
                228
            ],
            "shipment_status_id": [
                228
            ],
            "shipment_cost": [
                228
            ],
            "shipment_cost_status": [
                228
            ],
            "shipment_method": [
                228
            ],
            "quantity": [
                228
            ],
            "referance_code": [
                228
            ],
            "is_deleted": [
                228
            ],
            "OrderItem": [
                266
            ],
            "CargoAgency": [
                425
            ],
            "ShipmentStatus": [
                429
            ],
            "ShipmentEvent": [
                430
            ],
            "_relevance": [
                433
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "phone": [
                228
            ],
            "email": [
                228
            ],
            "address": [
                228
            ],
            "is_deleted": [
                228
            ],
            "creadentials": [
                228
            ],
            "Shipment": [
                426
            ],
            "_relevance": [
                427
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyOrderByRelevanceInput": {
            "fields": [
                428
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyOrderByRelevanceFieldEnum": {},
        "ShipmentStatusOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "description": [
                228
            ],
            "color": [
                228
            ],
            "is_deleted": [
                228
            ],
            "image": [
                384
            ],
            "Shipment": [
                426
            ],
            "ShipmentEvent": [
                430
            ],
            "_relevance": [
                431
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusOrderByRelevanceInput": {
            "fields": [
                432
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusOrderByRelevanceFieldEnum": {},
        "ShipmentOrderByRelevanceInput": {
            "fields": [
                434
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentOrderByRelevanceFieldEnum": {},
        "OrderItemOrderByRelevanceInput": {
            "fields": [
                436
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemOrderByRelevanceFieldEnum": {},
        "OrderItemWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                114
            ],
            "OR": [
                114
            ],
            "NOT": [
                114
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "status_name": [
                20
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "quantity2": [
                45
            ],
            "discount_rate": [
                45
            ],
            "vat": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "unit": [
                45
            ],
            "unit_shipment_cost": [
                45
            ],
            "sent_unit": [
                45
            ],
            "waiting_unit": [
                45
            ],
            "features": [
                78
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "userId": [
                23
            ],
            "productId": [
                23
            ],
            "orderItemStatusId": [
                23
            ],
            "shipment_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "shipment_method": [
                115
            ],
            "cart_id": [
                23
            ],
            "orderId": [
                23
            ],
            "CartItem": [
                118
            ],
            "OrderItemStatus": [
                203
            ],
            "Product": [
                121
            ],
            "Shipment": [
                160
            ],
            "User": [
                49
            ],
            "Order": [
                59
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemScalarFieldEnum": {},
        "Brand": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                440
            ],
            "Image": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "Categories": [
                458,
                {
                    "where": [
                        140
                    ],
                    "orderBy": [
                        389,
                        "[CategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        485
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        486,
                        "[CategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ],
                    "orderBy": [
                        230,
                        "[CompanyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        487
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        488,
                        "[CompanyScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "BrandCount": {
            "Image": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "Categories": [
                3,
                {
                    "where": [
                        140
                    ]
                }
            ],
            "Company": [
                3,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Image": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                442
            ],
            "Brand": [
                439,
                {
                    "where": [
                        130
                    ]
                }
            ],
            "Campaign": [
                443,
                {
                    "where": [
                        137
                    ]
                }
            ],
            "Category": [
                458,
                {
                    "where": [
                        140
                    ]
                }
            ],
            "Collection": [
                469,
                {
                    "where": [
                        148
                    ]
                }
            ],
            "shipmentStatus": [
                471,
                {
                    "where": [
                        150
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ImageCount": {
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Campaign": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                444
            ],
            "Image": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCount": {
            "Image": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ImageOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "out_ref": [
                228
            ],
            "url": [
                228
            ],
            "store_at": [
                228
            ],
            "alt": [
                228
            ],
            "title": [
                228
            ],
            "categoryId": [
                228
            ],
            "brandId": [
                228
            ],
            "campaignId": [
                228
            ],
            "collectionId": [
                228
            ],
            "folder_type": [
                228
            ],
            "shipmentStatusId": [
                228
            ],
            "index": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Brand": [
                383
            ],
            "Campaign": [
                446
            ],
            "Category": [
                389
            ],
            "Collection": [
                449
            ],
            "shipmentStatus": [
                429
            ],
            "Product": [
                385
            ],
            "_relevance": [
                452
            ],
            "__typename": [
                9
            ]
        },
        "CampaignOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Image": [
                384
            ],
            "Product": [
                385
            ],
            "_relevance": [
                447
            ],
            "__typename": [
                9
            ]
        },
        "CampaignOrderByRelevanceInput": {
            "fields": [
                448
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CampaignOrderByRelevanceFieldEnum": {},
        "CollectionOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Image": [
                384
            ],
            "Product": [
                385
            ],
            "_relevance": [
                450
            ],
            "__typename": [
                9
            ]
        },
        "CollectionOrderByRelevanceInput": {
            "fields": [
                451
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CollectionOrderByRelevanceFieldEnum": {},
        "ImageOrderByRelevanceInput": {
            "fields": [
                453
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ImageOrderByRelevanceFieldEnum": {},
        "ImageWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                132
            ],
            "OR": [
                132
            ],
            "NOT": [
                132
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "url": [
                20
            ],
            "store_at": [
                20
            ],
            "alt": [
                20
            ],
            "title": [
                20
            ],
            "categoryId": [
                23
            ],
            "brandId": [
                23
            ],
            "campaignId": [
                23
            ],
            "collectionId": [
                23
            ],
            "folder_type": [
                133
            ],
            "shipmentStatusId": [
                23
            ],
            "index": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Brand": [
                129
            ],
            "Campaign": [
                136
            ],
            "Category": [
                139
            ],
            "Collection": [
                147
            ],
            "shipmentStatus": [
                149
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "ImageScalarFieldEnum": {},
        "ProductWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                122
            ],
            "OR": [
                122
            ],
            "NOT": [
                122
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "group": [
                20
            ],
            "brand_id": [
                23
            ],
            "category_id": [
                23
            ],
            "negative_sale": [
                33
            ],
            "on_sale": [
                33
            ],
            "rating": [
                123
            ],
            "vat": [
                45
            ],
            "sold_count": [
                23
            ],
            "sold_quantity": [
                23
            ],
            "review_count": [
                23
            ],
            "description": [
                20
            ],
            "favorite_count": [
                33
            ],
            "in_stock": [
                33
            ],
            "unit": [
                45
            ],
            "unit_ref": [
                20
            ],
            "unit_code": [
                20
            ],
            "is_shipping_required": [
                33
            ],
            "tag": [
                20
            ],
            "cargo_fee": [
                45
            ],
            "features": [
                125
            ],
            "platform": [
                29
            ],
            "type_id": [
                23
            ],
            "unit_name": [
                20
            ],
            "title": [
                20
            ],
            "has_features": [
                33
            ],
            "priceId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "out_name": [
                20
            ],
            "Barcode": [
                126
            ],
            "CartItem": [
                128
            ],
            "OrderItem": [
                113
            ],
            "Brand": [
                129
            ],
            "Category": [
                139
            ],
            "Price": [
                163
            ],
            "ProductType": [
                164
            ],
            "ProductLot": [
                174
            ],
            "ProductStock": [
                176
            ],
            "SupportItem": [
                178
            ],
            "AttributeValues": [
                170
            ],
            "Campaign": [
                201
            ],
            "Collection": [
                202
            ],
            "images": [
                131
            ],
            "__typename": [
                9
            ]
        },
        "ProductScalarFieldEnum": {},
        "Category": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                459
            ],
            "Image": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "PriceToCategory": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "Brand": [
                439,
                {
                    "where": [
                        130
                    ],
                    "orderBy": [
                        383,
                        "[BrandOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        467
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        468,
                        "[BrandScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCount": {
            "Image": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "PriceToCategory": [
                3,
                {
                    "where": [
                        142
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "Brand": [
                3,
                {
                    "where": [
                        130
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategory": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Category": [
                458
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "Price": [
                461
            ],
            "__typename": [
                9
            ]
        },
        "Price": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                462
            ],
            "PriceToCategory": [
                460,
                {
                    "where": [
                        142
                    ],
                    "orderBy": [
                        463,
                        "[PriceToCategoryOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        464
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        466,
                        "[PriceToCategoryScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PriceCount": {
            "PriceToCategory": [
                3,
                {
                    "where": [
                        142
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryOrderByWithRelationAndSearchRelevanceInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                228
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Category": [
                389
            ],
            "Company": [
                230
            ],
            "Price": [
                392
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryWhereUniqueInput": {
            "price_id_category_id": [
                465
            ],
            "AND": [
                142
            ],
            "OR": [
                142
            ],
            "NOT": [
                142
            ],
            "price_id": [
                16
            ],
            "category_id": [
                16
            ],
            "companyId": [
                23
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Category": [
                143
            ],
            "Company": [
                35
            ],
            "Price": [
                144
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryPrice_idCategory_idCompoundUniqueInput": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryScalarFieldEnum": {},
        "BrandWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                130
            ],
            "OR": [
                130
            ],
            "NOT": [
                130
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "image": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "Categories": [
                161
            ],
            "Company": [
                162
            ],
            "__typename": [
                9
            ]
        },
        "BrandScalarFieldEnum": {},
        "Collection": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                470
            ],
            "Image": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCount": {
            "Image": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatus": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                472
            ],
            "image": [
                441,
                {
                    "where": [
                        132
                    ],
                    "orderBy": [
                        445,
                        "[ImageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        454
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        455,
                        "[ImageScalarFieldEnum!]"
                    ]
                }
            ],
            "Shipment": [
                473,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        478,
                        "[ShipmentScalarFieldEnum!]"
                    ]
                }
            ],
            "ShipmentEvent": [
                479,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCount": {
            "image": [
                3,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "Shipment": [
                3,
                {
                    "where": [
                        152
                    ]
                }
            ],
            "ShipmentEvent": [
                3,
                {
                    "where": [
                        159
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Shipment": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                474
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "CargoAgency": [
                475,
                {
                    "where": [
                        157
                    ]
                }
            ],
            "ShipmentStatus": [
                471,
                {
                    "where": [
                        150
                    ]
                }
            ],
            "ShipmentEvent": [
                479,
                {
                    "where": [
                        159
                    ],
                    "orderBy": [
                        480,
                        "[ShipmentEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        483
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        484,
                        "[ShipmentEventScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCount": {
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "ShipmentEvent": [
                3,
                {
                    "where": [
                        159
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgency": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "creadentials": [
                79
            ],
            "_count": [
                476
            ],
            "Shipment": [
                473,
                {
                    "where": [
                        152
                    ],
                    "orderBy": [
                        424,
                        "[ShipmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        477
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        478,
                        "[ShipmentScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCount": {
            "Shipment": [
                3,
                {
                    "where": [
                        152
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentWhereUniqueInput": {
            "id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "AND": [
                152
            ],
            "OR": [
                152
            ],
            "NOT": [
                152
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "cargo_agency_id": [
                23
            ],
            "tracking_url": [
                20
            ],
            "date_of_shipment": [
                43
            ],
            "date_of_delivery": [
                43
            ],
            "other_info": [
                20
            ],
            "cargo_order": [
                23
            ],
            "shipment_status_id": [
                23
            ],
            "shipment_cost": [
                45
            ],
            "shipment_cost_status": [
                153
            ],
            "shipment_method": [
                115
            ],
            "quantity": [
                45
            ],
            "referance_code": [
                20
            ],
            "is_deleted": [
                33
            ],
            "OrderItem": [
                113
            ],
            "CargoAgency": [
                156
            ],
            "ShipmentStatus": [
                149
            ],
            "ShipmentEvent": [
                158
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentScalarFieldEnum": {},
        "ShipmentEvent": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Shipment": [
                473,
                {
                    "where": [
                        152
                    ]
                }
            ],
            "ShipmentStatus": [
                471,
                {
                    "where": [
                        150
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "eventDateTime": [
                228
            ],
            "deliveryDateTime": [
                228
            ],
            "deliveryTo": [
                228
            ],
            "description": [
                228
            ],
            "pieceTotal": [
                228
            ],
            "location": [
                228
            ],
            "locationPhone": [
                228
            ],
            "private": [
                228
            ],
            "shipmentId": [
                228
            ],
            "statusId": [
                228
            ],
            "isRead": [
                228
            ],
            "locationAddress": [
                228
            ],
            "trackingCode": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Shipment": [
                424
            ],
            "ShipmentStatus": [
                429
            ],
            "_relevance": [
                481
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventOrderByRelevanceInput": {
            "fields": [
                482
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventOrderByRelevanceFieldEnum": {},
        "ShipmentEventWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                159
            ],
            "OR": [
                159
            ],
            "NOT": [
                159
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "eventDateTime": [
                43
            ],
            "deliveryDateTime": [
                43
            ],
            "deliveryTo": [
                20
            ],
            "description": [
                20
            ],
            "pieceTotal": [
                23
            ],
            "location": [
                20
            ],
            "locationPhone": [
                20
            ],
            "private": [
                33
            ],
            "shipmentId": [
                23
            ],
            "statusId": [
                23
            ],
            "isRead": [
                33
            ],
            "locationAddress": [
                20
            ],
            "trackingCode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Shipment": [
                160
            ],
            "ShipmentStatus": [
                149
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventScalarFieldEnum": {},
        "CategoryWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                140
            ],
            "OR": [
                140
            ],
            "NOT": [
                140
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "parent_id": [
                23
            ],
            "is_parent": [
                33
            ],
            "level": [
                23
            ],
            "description": [
                20
            ],
            "background_img": [
                20
            ],
            "is_corporate": [
                33
            ],
            "link_url": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Image": [
                131
            ],
            "PriceToCategory": [
                141
            ],
            "Product": [
                138
            ],
            "Brand": [
                146
            ],
            "__typename": [
                9
            ]
        },
        "CategoryScalarFieldEnum": {},
        "CompanyWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                36
            ],
            "OR": [
                36
            ],
            "NOT": [
                36
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "email": [
                20
            ],
            "title": [
                20
            ],
            "tax_office": [
                20
            ],
            "tax_number": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "gsm": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Address": [
                37
            ],
            "Cart": [
                38
            ],
            "CompanyStatement": [
                217
            ],
            "CompanyToPaymentMethod": [
                105
            ],
            "InstallmentToCompany": [
                94
            ],
            "Order": [
                59
            ],
            "Payment": [
                87
            ],
            "PriceToCategory": [
                141
            ],
            "Brand": [
                146
            ],
            "User": [
                213
            ],
            "__typename": [
                9
            ]
        },
        "CompanyScalarFieldEnum": {},
        "ProductType": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                490
            ],
            "AttributeToProductType": [
                491,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCount": {
            "AttributeToProductType": [
                3,
                {
                    "where": [
                        167
                    ]
                }
            ],
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductType": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "Attribute": [
                492
            ],
            "ProductType": [
                489
            ],
            "__typename": [
                9
            ]
        },
        "Attribute": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                493
            ],
            "AttributeToProductType": [
                491,
                {
                    "where": [
                        167
                    ],
                    "orderBy": [
                        494,
                        "[AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        498
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        500,
                        "[AttributeToProductTypeScalarFieldEnum!]"
                    ]
                }
            ],
            "AttributeValue": [
                501,
                {
                    "where": [
                        171
                    ],
                    "orderBy": [
                        503,
                        "[AttributeValueOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        506
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        507,
                        "[AttributeValueScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCount": {
            "AttributeToProductType": [
                3,
                {
                    "where": [
                        167
                    ]
                }
            ],
            "AttributeValue": [
                3,
                {
                    "where": [
                        171
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeOrderByWithRelationAndSearchRelevanceInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "is_variant": [
                228
            ],
            "group_hierarchy": [
                228
            ],
            "is_group": [
                228
            ],
            "variant_hierarchy": [
                228
            ],
            "Attribute": [
                495
            ],
            "ProductType": [
                395
            ],
            "__typename": [
                9
            ]
        },
        "AttributeOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "AttributeToProductType": [
                396
            ],
            "AttributeValue": [
                402
            ],
            "_relevance": [
                496
            ],
            "__typename": [
                9
            ]
        },
        "AttributeOrderByRelevanceInput": {
            "fields": [
                497
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AttributeOrderByRelevanceFieldEnum": {},
        "AttributeToProductTypeWhereUniqueInput": {
            "attribute_id_product_type_id": [
                499
            ],
            "AND": [
                167
            ],
            "OR": [
                167
            ],
            "NOT": [
                167
            ],
            "attribute_id": [
                16
            ],
            "product_type_id": [
                16
            ],
            "is_variant": [
                33
            ],
            "group_hierarchy": [
                23
            ],
            "is_group": [
                33
            ],
            "variant_hierarchy": [
                23
            ],
            "Attribute": [
                168
            ],
            "ProductType": [
                173
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeAttribute_idProduct_type_idCompoundUniqueInput": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeScalarFieldEnum": {},
        "AttributeValue": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                502
            ],
            "Attribute": [
                492,
                {
                    "where": [
                        169
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ],
                    "orderBy": [
                        381,
                        "[ProductOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        456
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        457,
                        "[ProductScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCount": {
            "Product": [
                3,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "attributeId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "Attribute": [
                495
            ],
            "Product": [
                385
            ],
            "_relevance": [
                504
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueOrderByRelevanceInput": {
            "fields": [
                505
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueOrderByRelevanceFieldEnum": {},
        "AttributeValueWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                171
            ],
            "OR": [
                171
            ],
            "NOT": [
                171
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "attributeId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "Attribute": [
                172
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueScalarFieldEnum": {},
        "ProductLot": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "product_id": [
                228
            ],
            "quantity": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Product": [
                381
            ],
            "_relevance": [
                510
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotOrderByRelevanceInput": {
            "fields": [
                511
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotOrderByRelevanceFieldEnum": {},
        "ProductLotWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                175
            ],
            "OR": [
                175
            ],
            "NOT": [
                175
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotScalarFieldEnum": {},
        "ProductStock": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "product_id": [
                228
            ],
            "quantity": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Product": [
                381
            ],
            "_relevance": [
                516
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockOrderByRelevanceInput": {
            "fields": [
                517
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockOrderByRelevanceFieldEnum": {},
        "ProductStockWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                177
            ],
            "OR": [
                177
            ],
            "NOT": [
                177
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "Product": [
                121
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockScalarFieldEnum": {},
        "SupportItem": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                521
            ],
            "SupportAttachment": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportEvent": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "Product": [
                377,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "SupportItemStatus": [
                545,
                {
                    "where": [
                        199
                    ]
                }
            ],
            "Support": [
                523,
                {
                    "where": [
                        183
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCount": {
            "SupportAttachment": [
                3,
                {
                    "where": [
                        181
                    ]
                }
            ],
            "SupportEvent": [
                3,
                {
                    "where": [
                        196
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachment": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Support": [
                523,
                {
                    "where": [
                        183
                    ]
                }
            ],
            "SupportItem": [
                520,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "Support": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                524
            ],
            "SupportAttachment": [
                522,
                {
                    "where": [
                        181
                    ],
                    "orderBy": [
                        525,
                        "[SupportAttachmentOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        538
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        539,
                        "[SupportAttachmentScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportItem": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportMessage": [
                542,
                {
                    "where": [
                        194
                    ],
                    "orderBy": [
                        548,
                        "[SupportMessageOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        555
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        556,
                        "[SupportMessageScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportCount": {
            "SupportAttachment": [
                3,
                {
                    "where": [
                        181
                    ]
                }
            ],
            "SupportItem": [
                3,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "SupportMessage": [
                3,
                {
                    "where": [
                        194
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "path": [
                228
            ],
            "supportId": [
                228
            ],
            "userId": [
                228
            ],
            "supportItemId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Support": [
                526
            ],
            "SupportItem": [
                529
            ],
            "User": [
                264
            ],
            "_relevance": [
                536
            ],
            "__typename": [
                9
            ]
        },
        "SupportOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "status": [
                228
            ],
            "type": [
                228
            ],
            "priority": [
                228
            ],
            "companyId": [
                228
            ],
            "assignedToId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "SupportAttachment": [
                267
            ],
            "SupportItem": [
                401
            ],
            "SupportMessage": [
                268
            ],
            "_relevance": [
                527
            ],
            "__typename": [
                9
            ]
        },
        "SupportOrderByRelevanceInput": {
            "fields": [
                528
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportOrderByRelevanceFieldEnum": {},
        "SupportItemOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                228
            ],
            "productId": [
                228
            ],
            "supportId": [
                228
            ],
            "statusId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "SupportAttachment": [
                267
            ],
            "SupportEvent": [
                530
            ],
            "Product": [
                381
            ],
            "SupportItemStatus": [
                531
            ],
            "Support": [
                526
            ],
            "_relevance": [
                534
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "status": [
                228
            ],
            "SupportEvent": [
                530
            ],
            "SupportItem": [
                401
            ],
            "_relevance": [
                532
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusOrderByRelevanceInput": {
            "fields": [
                533
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusOrderByRelevanceFieldEnum": {},
        "SupportItemOrderByRelevanceInput": {
            "fields": [
                535
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemOrderByRelevanceFieldEnum": {},
        "SupportAttachmentOrderByRelevanceInput": {
            "fields": [
                537
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentOrderByRelevanceFieldEnum": {},
        "SupportAttachmentWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                181
            ],
            "OR": [
                181
            ],
            "NOT": [
                181
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "path": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "supportItemId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Support": [
                182
            ],
            "SupportItem": [
                200
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentScalarFieldEnum": {},
        "SupportItemWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                179
            ],
            "OR": [
                179
            ],
            "NOT": [
                179
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "productId": [
                23
            ],
            "supportId": [
                23
            ],
            "statusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportAttachment": [
                180
            ],
            "SupportEvent": [
                195
            ],
            "Product": [
                121
            ],
            "SupportItemStatus": [
                198
            ],
            "Support": [
                182
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemScalarFieldEnum": {},
        "SupportMessage": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                543
            ],
            "SupportEvent": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "Support": [
                523,
                {
                    "where": [
                        183
                    ]
                }
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCount": {
            "SupportEvent": [
                3,
                {
                    "where": [
                        196
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportEvent": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Message": [
                542,
                {
                    "where": [
                        194
                    ]
                }
            ],
            "Status": [
                545,
                {
                    "where": [
                        199
                    ]
                }
            ],
            "Item": [
                520,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatus": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "_count": [
                546
            ],
            "SupportEvent": [
                544,
                {
                    "where": [
                        196
                    ],
                    "orderBy": [
                        547,
                        "[SupportEventOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        553
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        554,
                        "[SupportEventScalarFieldEnum!]"
                    ]
                }
            ],
            "SupportItem": [
                520,
                {
                    "where": [
                        179
                    ],
                    "orderBy": [
                        529,
                        "[SupportItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        540
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        541,
                        "[SupportItemScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCount": {
            "SupportEvent": [
                3,
                {
                    "where": [
                        196
                    ]
                }
            ],
            "SupportItem": [
                3,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_read": [
                228
            ],
            "private": [
                228
            ],
            "supportItemId": [
                228
            ],
            "statusId": [
                228
            ],
            "messageId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Message": [
                548
            ],
            "Status": [
                531
            ],
            "Item": [
                529
            ],
            "_relevance": [
                551
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "message": [
                228
            ],
            "supportId": [
                228
            ],
            "userId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "SupportEvent": [
                530
            ],
            "Support": [
                526
            ],
            "User": [
                264
            ],
            "_relevance": [
                549
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageOrderByRelevanceInput": {
            "fields": [
                550
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageOrderByRelevanceFieldEnum": {},
        "SupportEventOrderByRelevanceInput": {
            "fields": [
                552
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventOrderByRelevanceFieldEnum": {},
        "SupportEventWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                196
            ],
            "OR": [
                196
            ],
            "NOT": [
                196
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_read": [
                33
            ],
            "private": [
                33
            ],
            "supportItemId": [
                23
            ],
            "statusId": [
                23
            ],
            "messageId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Message": [
                197
            ],
            "Status": [
                198
            ],
            "Item": [
                200
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventScalarFieldEnum": {},
        "SupportMessageWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                194
            ],
            "OR": [
                194
            ],
            "NOT": [
                194
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "message": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportEvent": [
                195
            ],
            "Support": [
                182
            ],
            "User": [
                49
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageScalarFieldEnum": {},
        "CampaignWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                137
            ],
            "OR": [
                137
            ],
            "NOT": [
                137
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "CampaignScalarFieldEnum": {},
        "CollectionWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                148
            ],
            "OR": [
                148
            ],
            "NOT": [
                148
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Image": [
                131
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "CollectionScalarFieldEnum": {},
        "ProductPrice": {
            "price": [
                5
            ],
            "price_discounted": [
                5
            ],
            "retail": [
                5
            ],
            "total": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatus": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                563
            ],
            "OrderItem": [
                373,
                {
                    "where": [
                        114
                    ],
                    "orderBy": [
                        416,
                        "[OrderItemOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        437
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        438,
                        "[OrderItemScalarFieldEnum!]"
                    ]
                }
            ],
            "OrderStatus": [
                564,
                {
                    "where": [
                        206
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCount": {
            "OrderItem": [
                3,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatus": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                565
            ],
            "OrderItemStatus": [
                562,
                {
                    "where": [
                        204
                    ],
                    "orderBy": [
                        417,
                        "[OrderItemStatusOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        566
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        567,
                        "[OrderItemStatusScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCount": {
            "OrderItemStatus": [
                3,
                {
                    "where": [
                        204
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                204
            ],
            "OR": [
                204
            ],
            "NOT": [
                204
            ],
            "hierarchy": [
                16
            ],
            "color": [
                20
            ],
            "active": [
                18
            ],
            "code": [
                20
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "name": [
                20
            ],
            "source": [
                27
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "orderStatusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "OrderItem": [
                113
            ],
            "OrderStatus": [
                205
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusScalarFieldEnum": {},
        "OrderWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "order_key": [
                9
            ],
            "cartId": [
                3
            ],
            "AND": [
                60
            ],
            "OR": [
                60
            ],
            "NOT": [
                60
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "ordered_at": [
                43
            ],
            "outer_order_key": [
                20
            ],
            "company_id": [
                23
            ],
            "user_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "billing_address_id": [
                23
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "total_discount": [
                45
            ],
            "total_quantity": [
                23
            ],
            "order_note": [
                20
            ],
            "is_approved": [
                33
            ],
            "approved_by": [
                23
            ],
            "approved_at": [
                43
            ],
            "is_sent_erp": [
                33
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                23
            ],
            "company_name": [
                20
            ],
            "is_deleted": [
                33
            ],
            "status": [
                20
            ],
            "payment_status": [
                63
            ],
            "warehouse": [
                20
            ],
            "is_sent_status": [
                66
            ],
            "BillingAddress": [
                48
            ],
            "Cart": [
                69
            ],
            "Company": [
                35
            ],
            "ShipmentAddress": [
                48
            ],
            "User": [
                49
            ],
            "PaymentGroup": [
                70
            ],
            "OrderItem": [
                113
            ],
            "__typename": [
                9
            ]
        },
        "OrderScalarFieldEnum": {},
        "Role": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                571
            ],
            "User": [
                261,
                {
                    "where": [
                        50
                    ],
                    "orderBy": [
                        264,
                        "[UserOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        572
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        573,
                        "[UserScalarFieldEnum!]"
                    ]
                }
            ],
            "Permissions": [
                574,
                {
                    "where": [
                        215
                    ],
                    "orderBy": [
                        578,
                        "[PermissionOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        582
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        583,
                        "[PermissionScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "RoleCount": {
            "User": [
                3,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "Permissions": [
                3,
                {
                    "where": [
                        215
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "UserWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "provider_id": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "AND": [
                50
            ],
            "OR": [
                50
            ],
            "NOT": [
                50
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "first_name": [
                20
            ],
            "last_name": [
                20
            ],
            "gender": [
                51
            ],
            "default_address_id": [
                23
            ],
            "role_id": [
                23
            ],
            "password": [
                20
            ],
            "is_deleted": [
                33
            ],
            "Cart": [
                38
            ],
            "ConfirmCode": [
                54
            ],
            "Order": [
                59
            ],
            "OrderItem": [
                113
            ],
            "Payment": [
                87
            ],
            "SupportAttachment": [
                180
            ],
            "SupportMessage": [
                193
            ],
            "Address": [
                48
            ],
            "Role": [
                208
            ],
            "Company": [
                162
            ],
            "__typename": [
                9
            ]
        },
        "UserScalarFieldEnum": {},
        "Permission": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                575
            ],
            "Role": [
                570,
                {
                    "where": [
                        209
                    ],
                    "orderBy": [
                        269,
                        "[RoleOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        576
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        577,
                        "[RoleScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCount": {
            "Role": [
                3,
                {
                    "where": [
                        209
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "RoleWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                209
            ],
            "OR": [
                209
            ],
            "NOT": [
                209
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "type": [
                210
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "User": [
                213
            ],
            "Permissions": [
                214
            ],
            "__typename": [
                9
            ]
        },
        "RoleScalarFieldEnum": {},
        "PermissionOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "Role": [
                579
            ],
            "_relevance": [
                580
            ],
            "__typename": [
                9
            ]
        },
        "RoleOrderByRelationAggregateInput": {
            "_count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionOrderByRelevanceInput": {
            "fields": [
                581
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PermissionOrderByRelevanceFieldEnum": {},
        "PermissionWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                215
            ],
            "OR": [
                215
            ],
            "NOT": [
                215
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "Role": [
                216
            ],
            "__typename": [
                9
            ]
        },
        "PermissionScalarFieldEnum": {},
        "CompanyStatement": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "company_id": [
                3
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Company": [
                256,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "document_type": [
                228
            ],
            "document_number": [
                228
            ],
            "description": [
                228
            ],
            "debit": [
                228
            ],
            "credit": [
                228
            ],
            "balance": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "company_id": [
                228
            ],
            "document_date": [
                228
            ],
            "data_number": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "Company": [
                230
            ],
            "_relevance": [
                586
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementOrderByRelevanceInput": {
            "fields": [
                587
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementOrderByRelevanceFieldEnum": {},
        "CompanyStatementWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                218
            ],
            "OR": [
                218
            ],
            "NOT": [
                218
            ],
            "active": [
                18
            ],
            "document_type": [
                20
            ],
            "document_number": [
                20
            ],
            "description": [
                20
            ],
            "debit": [
                45
            ],
            "credit": [
                45
            ],
            "balance": [
                45
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "company_id": [
                23
            ],
            "document_date": [
                43
            ],
            "data_number": [
                23
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Company": [
                35
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementScalarFieldEnum": {},
        "County": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                591
            ],
            "Address": [
                254,
                {
                    "where": [
                        15
                    ],
                    "orderBy": [
                        226,
                        "[AddressOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        253
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        258,
                        "[AddressScalarFieldEnum!]"
                    ]
                }
            ],
            "City": [
                592,
                {
                    "where": [
                        222
                    ]
                }
            ],
            "District": [
                596,
                {
                    "where": [
                        225
                    ],
                    "orderBy": [
                        597,
                        "[DistrictOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        600
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        601,
                        "[DistrictScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CountyCount": {
            "Address": [
                3,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "District": [
                3,
                {
                    "where": [
                        225
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "City": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                593
            ],
            "County": [
                590,
                {
                    "where": [
                        220
                    ],
                    "orderBy": [
                        243,
                        "[CountyOrderByWithRelationAndSearchRelevanceInput!]"
                    ],
                    "cursor": [
                        594
                    ],
                    "take": [
                        3
                    ],
                    "skip": [
                        3
                    ],
                    "distinct": [
                        595,
                        "[CountyScalarFieldEnum!]"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CityCount": {
            "County": [
                3,
                {
                    "where": [
                        220
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "CountyWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                220
            ],
            "OR": [
                220
            ],
            "NOT": [
                220
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "cityId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "Address": [
                37
            ],
            "City": [
                221
            ],
            "District": [
                224
            ],
            "__typename": [
                9
            ]
        },
        "CountyScalarFieldEnum": {},
        "District": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "County": [
                590,
                {
                    "where": [
                        220
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "DistrictOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "countyId": [
                228
            ],
            "County": [
                243
            ],
            "_relevance": [
                598
            ],
            "__typename": [
                9
            ]
        },
        "DistrictOrderByRelevanceInput": {
            "fields": [
                599
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DistrictOrderByRelevanceFieldEnum": {},
        "DistrictWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                225
            ],
            "OR": [
                225
            ],
            "NOT": [
                225
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "countyId": [
                23
            ],
            "County": [
                219
            ],
            "__typename": [
                9
            ]
        },
        "DistrictScalarFieldEnum": {},
        "AddressGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "_count": [
                2
            ],
            "_avg": [
                4
            ],
            "_sum": [
                6
            ],
            "_min": [
                7
            ],
            "_max": [
                14
            ],
            "__typename": [
                9
            ]
        },
        "AddressOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "companyId": [
                228
            ],
            "countyId": [
                228
            ],
            "platform": [
                228
            ],
            "type": [
                228
            ],
            "address": [
                228
            ],
            "title": [
                228
            ],
            "email": [
                228
            ],
            "gsm": [
                228
            ],
            "phone1": [
                228
            ],
            "phone2": [
                228
            ],
            "tax_number": [
                228
            ],
            "tax_office": [
                228
            ],
            "website": [
                228
            ],
            "is_deleted": [
                228
            ],
            "subCompany_code": [
                228
            ],
            "subCompany_name": [
                228
            ],
            "subCompany_out_ref": [
                228
            ],
            "_count": [
                604
            ],
            "_avg": [
                605
            ],
            "_max": [
                606
            ],
            "_min": [
                607
            ],
            "_sum": [
                608
            ],
            "__typename": [
                9
            ]
        },
        "AddressCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "companyId": [
                227
            ],
            "countyId": [
                227
            ],
            "platform": [
                227
            ],
            "type": [
                227
            ],
            "address": [
                227
            ],
            "title": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "tax_number": [
                227
            ],
            "tax_office": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "subCompany_code": [
                227
            ],
            "subCompany_name": [
                227
            ],
            "subCompany_out_ref": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AddressAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "companyId": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AddressMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "companyId": [
                227
            ],
            "countyId": [
                227
            ],
            "platform": [
                227
            ],
            "type": [
                227
            ],
            "address": [
                227
            ],
            "title": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "tax_number": [
                227
            ],
            "tax_office": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "subCompany_code": [
                227
            ],
            "subCompany_name": [
                227
            ],
            "subCompany_out_ref": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AddressMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "companyId": [
                227
            ],
            "countyId": [
                227
            ],
            "platform": [
                227
            ],
            "type": [
                227
            ],
            "address": [
                227
            ],
            "title": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "tax_number": [
                227
            ],
            "tax_office": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "subCompany_code": [
                227
            ],
            "subCompany_name": [
                227
            ],
            "subCompany_out_ref": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AddressSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "companyId": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AddressScalarWhereWithAggregatesInput": {
            "AND": [
                609
            ],
            "OR": [
                609
            ],
            "NOT": [
                609
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "companyId": [
                617
            ],
            "countyId": [
                617
            ],
            "platform": [
                623
            ],
            "type": [
                625
            ],
            "address": [
                615
            ],
            "title": [
                615
            ],
            "email": [
                615
            ],
            "gsm": [
                615
            ],
            "phone1": [
                615
            ],
            "phone2": [
                615
            ],
            "tax_number": [
                615
            ],
            "tax_office": [
                615
            ],
            "website": [
                615
            ],
            "is_deleted": [
                627
            ],
            "subCompany_code": [
                615
            ],
            "subCompany_name": [
                615
            ],
            "subCompany_out_ref": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "IntWithAggregatesFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                611
            ],
            "_count": [
                17
            ],
            "_avg": [
                612
            ],
            "_sum": [
                17
            ],
            "_min": [
                17
            ],
            "_max": [
                17
            ],
            "__typename": [
                9
            ]
        },
        "NestedIntWithAggregatesFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                611
            ],
            "_count": [
                17
            ],
            "_avg": [
                612
            ],
            "_sum": [
                17
            ],
            "_min": [
                17
            ],
            "_max": [
                17
            ],
            "__typename": [
                9
            ]
        },
        "NestedFloatFilter": {
            "equals": [
                5
            ],
            "in": [
                5
            ],
            "notIn": [
                5
            ],
            "lt": [
                5
            ],
            "lte": [
                5
            ],
            "gt": [
                5
            ],
            "gte": [
                5
            ],
            "not": [
                612
            ],
            "__typename": [
                9
            ]
        },
        "BoolWithAggregatesFilter": {
            "equals": [
                8
            ],
            "not": [
                614
            ],
            "_count": [
                17
            ],
            "_min": [
                19
            ],
            "_max": [
                19
            ],
            "__typename": [
                9
            ]
        },
        "NestedBoolWithAggregatesFilter": {
            "equals": [
                8
            ],
            "not": [
                614
            ],
            "_count": [
                17
            ],
            "_min": [
                19
            ],
            "_max": [
                19
            ],
            "__typename": [
                9
            ]
        },
        "StringNullableWithAggregatesFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "mode": [
                21
            ],
            "not": [
                616
            ],
            "_count": [
                24
            ],
            "_min": [
                22
            ],
            "_max": [
                22
            ],
            "__typename": [
                9
            ]
        },
        "NestedStringNullableWithAggregatesFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "not": [
                616
            ],
            "_count": [
                24
            ],
            "_min": [
                22
            ],
            "_max": [
                22
            ],
            "__typename": [
                9
            ]
        },
        "IntNullableWithAggregatesFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                618
            ],
            "_count": [
                24
            ],
            "_avg": [
                124
            ],
            "_sum": [
                24
            ],
            "_min": [
                24
            ],
            "_max": [
                24
            ],
            "__typename": [
                9
            ]
        },
        "NestedIntNullableWithAggregatesFilter": {
            "equals": [
                3
            ],
            "in": [
                3
            ],
            "notIn": [
                3
            ],
            "lt": [
                3
            ],
            "lte": [
                3
            ],
            "gt": [
                3
            ],
            "gte": [
                3
            ],
            "not": [
                618
            ],
            "_count": [
                24
            ],
            "_avg": [
                124
            ],
            "_sum": [
                24
            ],
            "_min": [
                24
            ],
            "_max": [
                24
            ],
            "__typename": [
                9
            ]
        },
        "DateTimeWithAggregatesFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                620
            ],
            "_count": [
                17
            ],
            "_min": [
                26
            ],
            "_max": [
                26
            ],
            "__typename": [
                9
            ]
        },
        "NestedDateTimeWithAggregatesFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                620
            ],
            "_count": [
                17
            ],
            "_min": [
                26
            ],
            "_max": [
                26
            ],
            "__typename": [
                9
            ]
        },
        "EnumSourceWithAggregatesFilter": {
            "equals": [
                11
            ],
            "in": [
                11
            ],
            "notIn": [
                11
            ],
            "not": [
                622
            ],
            "_count": [
                17
            ],
            "_min": [
                28
            ],
            "_max": [
                28
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSourceWithAggregatesFilter": {
            "equals": [
                11
            ],
            "in": [
                11
            ],
            "notIn": [
                11
            ],
            "not": [
                622
            ],
            "_count": [
                17
            ],
            "_min": [
                28
            ],
            "_max": [
                28
            ],
            "__typename": [
                9
            ]
        },
        "EnumPlatformNullableWithAggregatesFilter": {
            "equals": [
                12
            ],
            "in": [
                12
            ],
            "notIn": [
                12
            ],
            "not": [
                624
            ],
            "_count": [
                24
            ],
            "_min": [
                30
            ],
            "_max": [
                30
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumPlatformNullableWithAggregatesFilter": {
            "equals": [
                12
            ],
            "in": [
                12
            ],
            "notIn": [
                12
            ],
            "not": [
                624
            ],
            "_count": [
                24
            ],
            "_min": [
                30
            ],
            "_max": [
                30
            ],
            "__typename": [
                9
            ]
        },
        "EnumAddressTypeNullableWithAggregatesFilter": {
            "equals": [
                13
            ],
            "in": [
                13
            ],
            "notIn": [
                13
            ],
            "not": [
                626
            ],
            "_count": [
                24
            ],
            "_min": [
                32
            ],
            "_max": [
                32
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumAddressTypeNullableWithAggregatesFilter": {
            "equals": [
                13
            ],
            "in": [
                13
            ],
            "notIn": [
                13
            ],
            "not": [
                626
            ],
            "_count": [
                24
            ],
            "_min": [
                32
            ],
            "_max": [
                32
            ],
            "__typename": [
                9
            ]
        },
        "BoolNullableWithAggregatesFilter": {
            "equals": [
                8
            ],
            "not": [
                628
            ],
            "_count": [
                24
            ],
            "_min": [
                34
            ],
            "_max": [
                34
            ],
            "__typename": [
                9
            ]
        },
        "NestedBoolNullableWithAggregatesFilter": {
            "equals": [
                8
            ],
            "not": [
                628
            ],
            "_count": [
                24
            ],
            "_min": [
                34
            ],
            "_max": [
                34
            ],
            "__typename": [
                9
            ]
        },
        "AggregateAttribute": {
            "_count": [
                630
            ],
            "_avg": [
                631
            ],
            "_sum": [
                632
            ],
            "_min": [
                633
            ],
            "_max": [
                634
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "description": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "AttributeSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                169
            ],
            "OR": [
                169
            ],
            "NOT": [
                169
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "AttributeToProductType": [
                166
            ],
            "AttributeValue": [
                170
            ],
            "__typename": [
                9
            ]
        },
        "AttributeScalarFieldEnum": {},
        "AttributeGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                630
            ],
            "_avg": [
                631
            ],
            "_sum": [
                632
            ],
            "_min": [
                633
            ],
            "_max": [
                634
            ],
            "__typename": [
                9
            ]
        },
        "AttributeOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                639
            ],
            "_avg": [
                640
            ],
            "_max": [
                641
            ],
            "_min": [
                642
            ],
            "_sum": [
                643
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeScalarWhereWithAggregatesInput": {
            "AND": [
                644
            ],
            "OR": [
                644
            ],
            "NOT": [
                644
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "description": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateAttributeToProductType": {
            "_count": [
                646
            ],
            "_avg": [
                647
            ],
            "_sum": [
                648
            ],
            "_min": [
                649
            ],
            "_max": [
                650
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCountAggregate": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                3
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                3
            ],
            "variant_hierarchy": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeAvgAggregate": {
            "attribute_id": [
                5
            ],
            "product_type_id": [
                5
            ],
            "group_hierarchy": [
                5
            ],
            "variant_hierarchy": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeSumAggregate": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "group_hierarchy": [
                3
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeMinAggregate": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeMaxAggregate": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeGroupBy": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "_count": [
                646
            ],
            "_avg": [
                647
            ],
            "_sum": [
                648
            ],
            "_min": [
                649
            ],
            "_max": [
                650
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeOrderByWithAggregationInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "is_variant": [
                228
            ],
            "group_hierarchy": [
                228
            ],
            "is_group": [
                228
            ],
            "variant_hierarchy": [
                228
            ],
            "_count": [
                653
            ],
            "_avg": [
                654
            ],
            "_max": [
                655
            ],
            "_min": [
                656
            ],
            "_sum": [
                657
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCountOrderByAggregateInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "is_variant": [
                227
            ],
            "group_hierarchy": [
                227
            ],
            "is_group": [
                227
            ],
            "variant_hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeAvgOrderByAggregateInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "group_hierarchy": [
                227
            ],
            "variant_hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeMaxOrderByAggregateInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "is_variant": [
                227
            ],
            "group_hierarchy": [
                227
            ],
            "is_group": [
                227
            ],
            "variant_hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeMinOrderByAggregateInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "is_variant": [
                227
            ],
            "group_hierarchy": [
                227
            ],
            "is_group": [
                227
            ],
            "variant_hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeSumOrderByAggregateInput": {
            "attribute_id": [
                227
            ],
            "product_type_id": [
                227
            ],
            "group_hierarchy": [
                227
            ],
            "variant_hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeScalarWhereWithAggregatesInput": {
            "AND": [
                658
            ],
            "OR": [
                658
            ],
            "NOT": [
                658
            ],
            "attribute_id": [
                610
            ],
            "product_type_id": [
                610
            ],
            "is_variant": [
                627
            ],
            "group_hierarchy": [
                617
            ],
            "is_group": [
                627
            ],
            "variant_hierarchy": [
                617
            ],
            "__typename": [
                9
            ]
        },
        "AggregateAttributeValue": {
            "_count": [
                660
            ],
            "_avg": [
                661
            ],
            "_sum": [
                662
            ],
            "_min": [
                663
            ],
            "_max": [
                664
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "platform": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "attributeId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "attributeId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                660
            ],
            "_avg": [
                661
            ],
            "_sum": [
                662
            ],
            "_min": [
                663
            ],
            "_max": [
                664
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "attributeId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "_count": [
                667
            ],
            "_avg": [
                668
            ],
            "_max": [
                669
            ],
            "_min": [
                670
            ],
            "_sum": [
                671
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "attributeId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "attributeId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "attributeId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "attributeId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "attributeId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueScalarWhereWithAggregatesInput": {
            "AND": [
                672
            ],
            "OR": [
                672
            ],
            "NOT": [
                672
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "attributeId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "platform": [
                623
            ],
            "__typename": [
                9
            ]
        },
        "AggregateBank": {
            "_count": [
                674
            ],
            "_avg": [
                675
            ],
            "_sum": [
                676
            ],
            "_min": [
                677
            ],
            "_max": [
                678
            ],
            "__typename": [
                9
            ]
        },
        "BankCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "image": [
                3
            ],
            "iban": [
                3
            ],
            "office": [
                3
            ],
            "account_number": [
                3
            ],
            "eft_code": [
                3
            ],
            "pos_defaults": [
                3
            ],
            "family": [
                3
            ],
            "is_pos": [
                3
            ],
            "pos_category": [
                3
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BankAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "selected_pos_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "BankSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "selected_pos_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BankMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BankMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BankWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                84
            ],
            "OR": [
                84
            ],
            "NOT": [
                84
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "image": [
                20
            ],
            "iban": [
                20
            ],
            "office": [
                20
            ],
            "account_number": [
                20
            ],
            "eft_code": [
                20
            ],
            "pos_defaults": [
                78
            ],
            "family": [
                78
            ],
            "is_pos": [
                33
            ],
            "pos_category": [
                20
            ],
            "selected_pos_id": [
                23
            ],
            "is_payment_system": [
                33
            ],
            "is_deleted": [
                33
            ],
            "bin_numbers": [
                85
            ],
            "Payment": [
                87
            ],
            "PosAccount": [
                88
            ],
            "PosConfig": [
                100
            ],
            "__typename": [
                9
            ]
        },
        "BankScalarFieldEnum": {},
        "BankGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                674
            ],
            "_avg": [
                675
            ],
            "_sum": [
                676
            ],
            "_min": [
                677
            ],
            "_max": [
                678
            ],
            "__typename": [
                9
            ]
        },
        "BankOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "image": [
                228
            ],
            "iban": [
                228
            ],
            "office": [
                228
            ],
            "account_number": [
                228
            ],
            "eft_code": [
                228
            ],
            "pos_defaults": [
                228
            ],
            "family": [
                228
            ],
            "is_pos": [
                228
            ],
            "pos_category": [
                228
            ],
            "selected_pos_id": [
                228
            ],
            "is_payment_system": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                683
            ],
            "_avg": [
                684
            ],
            "_max": [
                685
            ],
            "_min": [
                686
            ],
            "_sum": [
                687
            ],
            "__typename": [
                9
            ]
        },
        "BankCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "iban": [
                227
            ],
            "office": [
                227
            ],
            "account_number": [
                227
            ],
            "eft_code": [
                227
            ],
            "pos_defaults": [
                227
            ],
            "family": [
                227
            ],
            "is_pos": [
                227
            ],
            "pos_category": [
                227
            ],
            "selected_pos_id": [
                227
            ],
            "is_payment_system": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "selected_pos_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "iban": [
                227
            ],
            "office": [
                227
            ],
            "account_number": [
                227
            ],
            "eft_code": [
                227
            ],
            "is_pos": [
                227
            ],
            "pos_category": [
                227
            ],
            "selected_pos_id": [
                227
            ],
            "is_payment_system": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "iban": [
                227
            ],
            "office": [
                227
            ],
            "account_number": [
                227
            ],
            "eft_code": [
                227
            ],
            "is_pos": [
                227
            ],
            "pos_category": [
                227
            ],
            "selected_pos_id": [
                227
            ],
            "is_payment_system": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "selected_pos_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BankScalarWhereWithAggregatesInput": {
            "AND": [
                688
            ],
            "OR": [
                688
            ],
            "NOT": [
                688
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "image": [
                615
            ],
            "iban": [
                615
            ],
            "office": [
                615
            ],
            "account_number": [
                615
            ],
            "eft_code": [
                615
            ],
            "pos_defaults": [
                689
            ],
            "family": [
                689
            ],
            "is_pos": [
                627
            ],
            "pos_category": [
                615
            ],
            "selected_pos_id": [
                617
            ],
            "is_payment_system": [
                627
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "JsonNullableWithAggregatesFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "_count": [
                24
            ],
            "_min": [
                690
            ],
            "_max": [
                690
            ],
            "__typename": [
                9
            ]
        },
        "NestedJsonNullableFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "AggregateBarcode": {
            "_count": [
                692
            ],
            "_avg": [
                693
            ],
            "_sum": [
                694
            ],
            "_min": [
                695
            ],
            "_max": [
                696
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "module": [
                3
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "module_out_ref": [
                5
            ],
            "product_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                692
            ],
            "_avg": [
                693
            ],
            "_sum": [
                694
            ],
            "_min": [
                695
            ],
            "_max": [
                696
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "module": [
                228
            ],
            "module_out_ref": [
                228
            ],
            "product_id": [
                228
            ],
            "barcode": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                699
            ],
            "_avg": [
                700
            ],
            "_max": [
                701
            ],
            "_min": [
                702
            ],
            "_sum": [
                703
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "module": [
                227
            ],
            "module_out_ref": [
                227
            ],
            "product_id": [
                227
            ],
            "barcode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "module_out_ref": [
                227
            ],
            "product_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "module": [
                227
            ],
            "module_out_ref": [
                227
            ],
            "product_id": [
                227
            ],
            "barcode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "module": [
                227
            ],
            "module_out_ref": [
                227
            ],
            "product_id": [
                227
            ],
            "barcode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "module_out_ref": [
                227
            ],
            "product_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeScalarWhereWithAggregatesInput": {
            "AND": [
                704
            ],
            "OR": [
                704
            ],
            "NOT": [
                704
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "module": [
                615
            ],
            "module_out_ref": [
                617
            ],
            "product_id": [
                617
            ],
            "barcode": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateBinNumber": {
            "_count": [
                706
            ],
            "_avg": [
                707
            ],
            "_sum": [
                708
            ],
            "_min": [
                709
            ],
            "_max": [
                710
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "is_bussiness_card": [
                3
            ],
            "network": [
                3
            ],
            "family": [
                3
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                3
            ],
            "cardtype": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberAvgAggregate": {
            "id": [
                5
            ],
            "bank_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberSumAggregate": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "_count": [
                706
            ],
            "_avg": [
                707
            ],
            "_sum": [
                708
            ],
            "_min": [
                709
            ],
            "_max": [
                710
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "is_bussiness_card": [
                228
            ],
            "network": [
                228
            ],
            "family": [
                228
            ],
            "bank_id": [
                228
            ],
            "binnumber": [
                228
            ],
            "cardtype": [
                228
            ],
            "_count": [
                713
            ],
            "_avg": [
                714
            ],
            "_max": [
                715
            ],
            "_min": [
                716
            ],
            "_sum": [
                717
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "is_bussiness_card": [
                227
            ],
            "network": [
                227
            ],
            "family": [
                227
            ],
            "bank_id": [
                227
            ],
            "binnumber": [
                227
            ],
            "cardtype": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "is_bussiness_card": [
                227
            ],
            "network": [
                227
            ],
            "family": [
                227
            ],
            "bank_id": [
                227
            ],
            "binnumber": [
                227
            ],
            "cardtype": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "is_bussiness_card": [
                227
            ],
            "network": [
                227
            ],
            "family": [
                227
            ],
            "bank_id": [
                227
            ],
            "binnumber": [
                227
            ],
            "cardtype": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberScalarWhereWithAggregatesInput": {
            "AND": [
                718
            ],
            "OR": [
                718
            ],
            "NOT": [
                718
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "is_bussiness_card": [
                627
            ],
            "network": [
                615
            ],
            "family": [
                615
            ],
            "bank_id": [
                617
            ],
            "binnumber": [
                615
            ],
            "cardtype": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregateBrand": {
            "_count": [
                720
            ],
            "_avg": [
                721
            ],
            "_sum": [
                722
            ],
            "_min": [
                723
            ],
            "_max": [
                724
            ],
            "__typename": [
                9
            ]
        },
        "BrandCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "image": [
                3
            ],
            "is_deleted": [
                3
            ],
            "platform": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BrandAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "BrandSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "BrandMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "BrandMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "BrandGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                720
            ],
            "_avg": [
                721
            ],
            "_sum": [
                722
            ],
            "_min": [
                723
            ],
            "_max": [
                724
            ],
            "__typename": [
                9
            ]
        },
        "BrandOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "image": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "_count": [
                727
            ],
            "_avg": [
                728
            ],
            "_max": [
                729
            ],
            "_min": [
                730
            ],
            "_sum": [
                731
            ],
            "__typename": [
                9
            ]
        },
        "BrandCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "image": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "BrandScalarWhereWithAggregatesInput": {
            "AND": [
                732
            ],
            "OR": [
                732
            ],
            "NOT": [
                732
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "image": [
                615
            ],
            "is_deleted": [
                627
            ],
            "platform": [
                623
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCampaign": {
            "_count": [
                734
            ],
            "_avg": [
                735
            ],
            "_sum": [
                736
            ],
            "_min": [
                737
            ],
            "_max": [
                738
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "start_date": [
                3
            ],
            "end_date": [
                3
            ],
            "url": [
                3
            ],
            "description": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CampaignAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CampaignSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CampaignMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CampaignMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CampaignGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                734
            ],
            "_avg": [
                735
            ],
            "_sum": [
                736
            ],
            "_min": [
                737
            ],
            "_max": [
                738
            ],
            "__typename": [
                9
            ]
        },
        "CampaignOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                741
            ],
            "_avg": [
                742
            ],
            "_max": [
                743
            ],
            "_min": [
                744
            ],
            "_sum": [
                745
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CampaignScalarWhereWithAggregatesInput": {
            "AND": [
                746
            ],
            "OR": [
                746
            ],
            "NOT": [
                746
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "start_date": [
                619
            ],
            "end_date": [
                619
            ],
            "url": [
                615
            ],
            "description": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCargoAgency": {
            "_count": [
                748
            ],
            "_avg": [
                749
            ],
            "_sum": [
                750
            ],
            "_min": [
                751
            ],
            "_max": [
                752
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "phone": [
                3
            ],
            "email": [
                3
            ],
            "address": [
                3
            ],
            "is_deleted": [
                3
            ],
            "creadentials": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencySumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                157
            ],
            "OR": [
                157
            ],
            "NOT": [
                157
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "phone": [
                20
            ],
            "email": [
                20
            ],
            "address": [
                20
            ],
            "is_deleted": [
                33
            ],
            "creadentials": [
                78
            ],
            "Shipment": [
                151
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyScalarFieldEnum": {},
        "CargoAgencyGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "creadentials": [
                79
            ],
            "_count": [
                748
            ],
            "_avg": [
                749
            ],
            "_sum": [
                750
            ],
            "_min": [
                751
            ],
            "_max": [
                752
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "phone": [
                228
            ],
            "email": [
                228
            ],
            "address": [
                228
            ],
            "is_deleted": [
                228
            ],
            "creadentials": [
                228
            ],
            "_count": [
                757
            ],
            "_avg": [
                758
            ],
            "_max": [
                759
            ],
            "_min": [
                760
            ],
            "_sum": [
                761
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "phone": [
                227
            ],
            "email": [
                227
            ],
            "address": [
                227
            ],
            "is_deleted": [
                227
            ],
            "creadentials": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "phone": [
                227
            ],
            "email": [
                227
            ],
            "address": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "phone": [
                227
            ],
            "email": [
                227
            ],
            "address": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyScalarWhereWithAggregatesInput": {
            "AND": [
                762
            ],
            "OR": [
                762
            ],
            "NOT": [
                762
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "phone": [
                615
            ],
            "email": [
                615
            ],
            "address": [
                615
            ],
            "is_deleted": [
                627
            ],
            "creadentials": [
                689
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCart": {
            "_count": [
                764
            ],
            "_avg": [
                765
            ],
            "_sum": [
                766
            ],
            "_min": [
                767
            ],
            "_max": [
                768
            ],
            "__typename": [
                9
            ]
        },
        "CartCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "userId": [
                3
            ],
            "status": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                3
            ],
            "note": [
                3
            ],
            "order_key": [
                3
            ],
            "total_discount": [
                3
            ],
            "total_price": [
                3
            ],
            "total_price_net": [
                3
            ],
            "total_shipment_cost": [
                3
            ],
            "total_vat_cost": [
                3
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CartAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "userId": [
                5
            ],
            "billing_address_id": [
                5
            ],
            "shipment_address_id": [
                5
            ],
            "approved_by": [
                5
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CartSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "userId": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CartMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "GetCartResponse": {
            "CartItems": [
                375
            ],
            "cart": [
                259
            ],
            "__typename": [
                9
            ]
        },
        "CartGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                764
            ],
            "_avg": [
                765
            ],
            "_sum": [
                766
            ],
            "_min": [
                767
            ],
            "_max": [
                768
            ],
            "__typename": [
                9
            ]
        },
        "CartOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "userId": [
                228
            ],
            "status": [
                228
            ],
            "billing_address_id": [
                228
            ],
            "shipment_address_id": [
                228
            ],
            "approved_by": [
                228
            ],
            "aprroved_at": [
                228
            ],
            "note": [
                228
            ],
            "order_key": [
                228
            ],
            "total_discount": [
                228
            ],
            "total_price": [
                228
            ],
            "total_price_net": [
                228
            ],
            "total_shipment_cost": [
                228
            ],
            "total_vat_cost": [
                228
            ],
            "company_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                772
            ],
            "_avg": [
                773
            ],
            "_max": [
                774
            ],
            "_min": [
                775
            ],
            "_sum": [
                776
            ],
            "__typename": [
                9
            ]
        },
        "CartCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "userId": [
                227
            ],
            "status": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "approved_by": [
                227
            ],
            "aprroved_at": [
                227
            ],
            "note": [
                227
            ],
            "order_key": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "company_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "userId": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "approved_by": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "company_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "userId": [
                227
            ],
            "status": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "approved_by": [
                227
            ],
            "aprroved_at": [
                227
            ],
            "note": [
                227
            ],
            "order_key": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "company_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "userId": [
                227
            ],
            "status": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "approved_by": [
                227
            ],
            "aprroved_at": [
                227
            ],
            "note": [
                227
            ],
            "order_key": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "company_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "userId": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "approved_by": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "company_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartScalarWhereWithAggregatesInput": {
            "AND": [
                777
            ],
            "OR": [
                777
            ],
            "NOT": [
                777
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "userId": [
                617
            ],
            "status": [
                778
            ],
            "billing_address_id": [
                617
            ],
            "shipment_address_id": [
                617
            ],
            "approved_by": [
                617
            ],
            "aprroved_at": [
                780
            ],
            "note": [
                615
            ],
            "order_key": [
                615
            ],
            "total_discount": [
                782
            ],
            "total_price": [
                782
            ],
            "total_price_net": [
                782
            ],
            "total_shipment_cost": [
                782
            ],
            "total_vat_cost": [
                782
            ],
            "company_id": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumCartStatusNullableWithAggregatesFilter": {
            "equals": [
                41
            ],
            "in": [
                41
            ],
            "notIn": [
                41
            ],
            "not": [
                779
            ],
            "_count": [
                24
            ],
            "_min": [
                42
            ],
            "_max": [
                42
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumCartStatusNullableWithAggregatesFilter": {
            "equals": [
                41
            ],
            "in": [
                41
            ],
            "notIn": [
                41
            ],
            "not": [
                779
            ],
            "_count": [
                24
            ],
            "_min": [
                42
            ],
            "_max": [
                42
            ],
            "__typename": [
                9
            ]
        },
        "DateTimeNullableWithAggregatesFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                781
            ],
            "_count": [
                24
            ],
            "_min": [
                44
            ],
            "_max": [
                44
            ],
            "__typename": [
                9
            ]
        },
        "NestedDateTimeNullableWithAggregatesFilter": {
            "equals": [
                10
            ],
            "in": [
                10
            ],
            "notIn": [
                10
            ],
            "lt": [
                10
            ],
            "lte": [
                10
            ],
            "gt": [
                10
            ],
            "gte": [
                10
            ],
            "not": [
                781
            ],
            "_count": [
                24
            ],
            "_min": [
                44
            ],
            "_max": [
                44
            ],
            "__typename": [
                9
            ]
        },
        "DecimalNullableWithAggregatesFilter": {
            "equals": [
                46
            ],
            "in": [
                46
            ],
            "notIn": [
                46
            ],
            "lt": [
                46
            ],
            "lte": [
                46
            ],
            "gt": [
                46
            ],
            "gte": [
                46
            ],
            "not": [
                783
            ],
            "_count": [
                24
            ],
            "_avg": [
                47
            ],
            "_sum": [
                47
            ],
            "_min": [
                47
            ],
            "_max": [
                47
            ],
            "__typename": [
                9
            ]
        },
        "NestedDecimalNullableWithAggregatesFilter": {
            "equals": [
                46
            ],
            "in": [
                46
            ],
            "notIn": [
                46
            ],
            "lt": [
                46
            ],
            "lte": [
                46
            ],
            "gt": [
                46
            ],
            "gte": [
                46
            ],
            "not": [
                783
            ],
            "_count": [
                24
            ],
            "_avg": [
                47
            ],
            "_sum": [
                47
            ],
            "_min": [
                47
            ],
            "_max": [
                47
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCartItem": {
            "_count": [
                785
            ],
            "_avg": [
                786
            ],
            "_sum": [
                787
            ],
            "_min": [
                788
            ],
            "_max": [
                789
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCountAggregate": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                3
            ],
            "quantity": [
                3
            ],
            "vat": [
                3
            ],
            "unit_code": [
                3
            ],
            "unit": [
                3
            ],
            "quantity2": [
                3
            ],
            "total_price": [
                3
            ],
            "discount_price": [
                3
            ],
            "vat_price": [
                3
            ],
            "total_net_price": [
                3
            ],
            "features": [
                3
            ],
            "discount_rate": [
                3
            ],
            "notes1": [
                3
            ],
            "notes2": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CartItemAvgAggregate": {
            "id": [
                5
            ],
            "cart_id": [
                5
            ],
            "product_id": [
                5
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "discount_rate": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "CartItemSumAggregate": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "discount_rate": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "CartItemMinAggregate": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartItemMaxAggregate": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartItemGroupBy": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                785
            ],
            "_avg": [
                786
            ],
            "_sum": [
                787
            ],
            "_min": [
                788
            ],
            "_max": [
                789
            ],
            "__typename": [
                9
            ]
        },
        "CartItemOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "cart_id": [
                228
            ],
            "product_id": [
                228
            ],
            "price": [
                228
            ],
            "quantity": [
                228
            ],
            "vat": [
                228
            ],
            "unit_code": [
                228
            ],
            "unit": [
                228
            ],
            "quantity2": [
                228
            ],
            "total_price": [
                228
            ],
            "discount_price": [
                228
            ],
            "vat_price": [
                228
            ],
            "total_net_price": [
                228
            ],
            "features": [
                227
            ],
            "discount_rate": [
                228
            ],
            "notes1": [
                228
            ],
            "notes2": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                792
            ],
            "_avg": [
                793
            ],
            "_max": [
                794
            ],
            "_min": [
                795
            ],
            "_sum": [
                796
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "cart_id": [
                227
            ],
            "product_id": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "vat": [
                227
            ],
            "unit_code": [
                227
            ],
            "unit": [
                227
            ],
            "quantity2": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "features": [
                227
            ],
            "discount_rate": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartItemAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "cart_id": [
                227
            ],
            "product_id": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "vat": [
                227
            ],
            "unit": [
                227
            ],
            "quantity2": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "discount_rate": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartItemMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "cart_id": [
                227
            ],
            "product_id": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "vat": [
                227
            ],
            "unit_code": [
                227
            ],
            "unit": [
                227
            ],
            "quantity2": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "discount_rate": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartItemMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "cart_id": [
                227
            ],
            "product_id": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "vat": [
                227
            ],
            "unit_code": [
                227
            ],
            "unit": [
                227
            ],
            "quantity2": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "discount_rate": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartItemSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "cart_id": [
                227
            ],
            "product_id": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "vat": [
                227
            ],
            "unit": [
                227
            ],
            "quantity2": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "discount_rate": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CartItemScalarWhereWithAggregatesInput": {
            "AND": [
                797
            ],
            "OR": [
                797
            ],
            "NOT": [
                797
            ],
            "id": [
                610
            ],
            "cart_id": [
                617
            ],
            "product_id": [
                617
            ],
            "price": [
                782
            ],
            "quantity": [
                782
            ],
            "vat": [
                782
            ],
            "unit_code": [
                615
            ],
            "unit": [
                782
            ],
            "quantity2": [
                782
            ],
            "total_price": [
                782
            ],
            "discount_price": [
                782
            ],
            "vat_price": [
                782
            ],
            "total_net_price": [
                782
            ],
            "features": [
                798
            ],
            "discount_rate": [
                782
            ],
            "notes1": [
                615
            ],
            "notes2": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "JsonWithAggregatesFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "_count": [
                17
            ],
            "_min": [
                799
            ],
            "_max": [
                799
            ],
            "__typename": [
                9
            ]
        },
        "NestedJsonFilter": {
            "equals": [
                79
            ],
            "path": [
                9
            ],
            "string_contains": [
                9
            ],
            "string_starts_with": [
                9
            ],
            "string_ends_with": [
                9
            ],
            "array_contains": [
                79
            ],
            "array_starts_with": [
                79
            ],
            "array_ends_with": [
                79
            ],
            "lt": [
                79
            ],
            "lte": [
                79
            ],
            "gt": [
                79
            ],
            "gte": [
                79
            ],
            "not": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCategory": {
            "_count": [
                801
            ],
            "_avg": [
                802
            ],
            "_sum": [
                803
            ],
            "_min": [
                804
            ],
            "_max": [
                805
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                3
            ],
            "level": [
                3
            ],
            "description": [
                3
            ],
            "background_img": [
                3
            ],
            "is_corporate": [
                3
            ],
            "link_url": [
                3
            ],
            "is_deleted": [
                3
            ],
            "platform": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CategoryAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "parent_id": [
                5
            ],
            "level": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CategorySumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "parent_id": [
                3
            ],
            "level": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CategoryMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "CategoryMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "CategoryGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "_count": [
                801
            ],
            "_avg": [
                802
            ],
            "_sum": [
                803
            ],
            "_min": [
                804
            ],
            "_max": [
                805
            ],
            "__typename": [
                9
            ]
        },
        "CategoryOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "parent_id": [
                228
            ],
            "is_parent": [
                228
            ],
            "level": [
                228
            ],
            "description": [
                228
            ],
            "background_img": [
                228
            ],
            "is_corporate": [
                228
            ],
            "link_url": [
                228
            ],
            "is_deleted": [
                228
            ],
            "platform": [
                228
            ],
            "_count": [
                808
            ],
            "_avg": [
                809
            ],
            "_max": [
                810
            ],
            "_min": [
                811
            ],
            "_sum": [
                812
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "parent_id": [
                227
            ],
            "is_parent": [
                227
            ],
            "level": [
                227
            ],
            "description": [
                227
            ],
            "background_img": [
                227
            ],
            "is_corporate": [
                227
            ],
            "link_url": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategoryAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "parent_id": [
                227
            ],
            "level": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategoryMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "parent_id": [
                227
            ],
            "is_parent": [
                227
            ],
            "level": [
                227
            ],
            "description": [
                227
            ],
            "background_img": [
                227
            ],
            "is_corporate": [
                227
            ],
            "link_url": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategoryMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "parent_id": [
                227
            ],
            "is_parent": [
                227
            ],
            "level": [
                227
            ],
            "description": [
                227
            ],
            "background_img": [
                227
            ],
            "is_corporate": [
                227
            ],
            "link_url": [
                227
            ],
            "is_deleted": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategorySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "parent_id": [
                227
            ],
            "level": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CategoryScalarWhereWithAggregatesInput": {
            "AND": [
                813
            ],
            "OR": [
                813
            ],
            "NOT": [
                813
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "parent_id": [
                617
            ],
            "is_parent": [
                627
            ],
            "level": [
                617
            ],
            "description": [
                615
            ],
            "background_img": [
                615
            ],
            "is_corporate": [
                627
            ],
            "link_url": [
                615
            ],
            "is_deleted": [
                627
            ],
            "platform": [
                623
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCity": {
            "_count": [
                815
            ],
            "_avg": [
                816
            ],
            "_sum": [
                817
            ],
            "_min": [
                818
            ],
            "_max": [
                819
            ],
            "__typename": [
                9
            ]
        },
        "CityCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "is_deleted": [
                3
            ],
            "source": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CityAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CitySumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CityMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "CityMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "CityWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                222
            ],
            "OR": [
                222
            ],
            "NOT": [
                222
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "County": [
                223
            ],
            "__typename": [
                9
            ]
        },
        "CityScalarFieldEnum": {},
        "CityGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                815
            ],
            "_avg": [
                816
            ],
            "_sum": [
                817
            ],
            "_min": [
                818
            ],
            "_max": [
                819
            ],
            "__typename": [
                9
            ]
        },
        "CityOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "_count": [
                824
            ],
            "_avg": [
                825
            ],
            "_max": [
                826
            ],
            "_min": [
                827
            ],
            "_sum": [
                828
            ],
            "__typename": [
                9
            ]
        },
        "CityCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CityAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CityMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CityMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CitySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CityScalarWhereWithAggregatesInput": {
            "AND": [
                829
            ],
            "OR": [
                829
            ],
            "NOT": [
                829
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "is_deleted": [
                627
            ],
            "source": [
                621
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCollection": {
            "_count": [
                831
            ],
            "_avg": [
                832
            ],
            "_sum": [
                833
            ],
            "_min": [
                834
            ],
            "_max": [
                835
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "start_date": [
                3
            ],
            "end_date": [
                3
            ],
            "url": [
                3
            ],
            "description": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CollectionAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CollectionSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CollectionMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CollectionMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CollectionGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                831
            ],
            "_avg": [
                832
            ],
            "_sum": [
                833
            ],
            "_min": [
                834
            ],
            "_max": [
                835
            ],
            "__typename": [
                9
            ]
        },
        "CollectionOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                838
            ],
            "_avg": [
                839
            ],
            "_max": [
                840
            ],
            "_min": [
                841
            ],
            "_sum": [
                842
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "url": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CollectionScalarWhereWithAggregatesInput": {
            "AND": [
                843
            ],
            "OR": [
                843
            ],
            "NOT": [
                843
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "start_date": [
                619
            ],
            "end_date": [
                619
            ],
            "url": [
                615
            ],
            "description": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateComment": {
            "_count": [
                845
            ],
            "_avg": [
                846
            ],
            "_sum": [
                847
            ],
            "_min": [
                848
            ],
            "_max": [
                849
            ],
            "__typename": [
                9
            ]
        },
        "CommentCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "userId": [
                3
            ],
            "comment_on": [
                3
            ],
            "comment_to": [
                3
            ],
            "comment": [
                3
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CommentAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "userId": [
                5
            ],
            "rating": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CommentSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "userId": [
                3
            ],
            "rating": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CommentMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CommentMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CommentWhereInput": {
            "AND": [
                850
            ],
            "OR": [
                850
            ],
            "NOT": [
                850
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "userId": [
                16
            ],
            "comment_on": [
                61
            ],
            "comment_to": [
                61
            ],
            "comment": [
                20
            ],
            "rating": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CommentOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "userId": [
                227
            ],
            "comment_on": [
                227
            ],
            "comment_to": [
                227
            ],
            "comment": [
                228
            ],
            "rating": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_relevance": [
                852
            ],
            "__typename": [
                9
            ]
        },
        "CommentOrderByRelevanceInput": {
            "fields": [
                853
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CommentOrderByRelevanceFieldEnum": {},
        "CommentWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                850
            ],
            "OR": [
                850
            ],
            "NOT": [
                850
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "userId": [
                16
            ],
            "comment_on": [
                61
            ],
            "comment_to": [
                61
            ],
            "comment": [
                20
            ],
            "rating": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "Comment": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CommentScalarFieldEnum": {},
        "CommentGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                845
            ],
            "_avg": [
                846
            ],
            "_sum": [
                847
            ],
            "_min": [
                848
            ],
            "_max": [
                849
            ],
            "__typename": [
                9
            ]
        },
        "CommentOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "userId": [
                227
            ],
            "comment_on": [
                227
            ],
            "comment_to": [
                227
            ],
            "comment": [
                228
            ],
            "rating": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                859
            ],
            "_avg": [
                860
            ],
            "_max": [
                861
            ],
            "_min": [
                862
            ],
            "_sum": [
                863
            ],
            "__typename": [
                9
            ]
        },
        "CommentCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "userId": [
                227
            ],
            "comment_on": [
                227
            ],
            "comment_to": [
                227
            ],
            "comment": [
                227
            ],
            "rating": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CommentAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "userId": [
                227
            ],
            "rating": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CommentMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "userId": [
                227
            ],
            "comment_on": [
                227
            ],
            "comment_to": [
                227
            ],
            "comment": [
                227
            ],
            "rating": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CommentMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "userId": [
                227
            ],
            "comment_on": [
                227
            ],
            "comment_to": [
                227
            ],
            "comment": [
                227
            ],
            "rating": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CommentSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "userId": [
                227
            ],
            "rating": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CommentScalarWhereWithAggregatesInput": {
            "AND": [
                864
            ],
            "OR": [
                864
            ],
            "NOT": [
                864
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "userId": [
                610
            ],
            "comment_on": [
                865
            ],
            "comment_to": [
                865
            ],
            "comment": [
                615
            ],
            "rating": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "StringWithAggregatesFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "mode": [
                21
            ],
            "not": [
                866
            ],
            "_count": [
                17
            ],
            "_min": [
                62
            ],
            "_max": [
                62
            ],
            "__typename": [
                9
            ]
        },
        "NestedStringWithAggregatesFilter": {
            "equals": [
                9
            ],
            "in": [
                9
            ],
            "notIn": [
                9
            ],
            "lt": [
                9
            ],
            "lte": [
                9
            ],
            "gt": [
                9
            ],
            "gte": [
                9
            ],
            "contains": [
                9
            ],
            "startsWith": [
                9
            ],
            "endsWith": [
                9
            ],
            "search": [
                9
            ],
            "not": [
                866
            ],
            "_count": [
                17
            ],
            "_min": [
                62
            ],
            "_max": [
                62
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCompany": {
            "_count": [
                868
            ],
            "_avg": [
                869
            ],
            "_sum": [
                870
            ],
            "_min": [
                871
            ],
            "_max": [
                872
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "email": [
                3
            ],
            "title": [
                3
            ],
            "tax_office": [
                3
            ],
            "tax_number": [
                3
            ],
            "phone1": [
                3
            ],
            "phone2": [
                3
            ],
            "gsm": [
                3
            ],
            "website": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CompanySumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                868
            ],
            "_avg": [
                869
            ],
            "_sum": [
                870
            ],
            "_min": [
                871
            ],
            "_max": [
                872
            ],
            "__typename": [
                9
            ]
        },
        "CompanyOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "email": [
                228
            ],
            "title": [
                228
            ],
            "tax_office": [
                228
            ],
            "tax_number": [
                228
            ],
            "phone1": [
                228
            ],
            "phone2": [
                228
            ],
            "gsm": [
                228
            ],
            "website": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                875
            ],
            "_avg": [
                876
            ],
            "_max": [
                877
            ],
            "_min": [
                878
            ],
            "_sum": [
                879
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "email": [
                227
            ],
            "title": [
                227
            ],
            "tax_office": [
                227
            ],
            "tax_number": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "gsm": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "email": [
                227
            ],
            "title": [
                227
            ],
            "tax_office": [
                227
            ],
            "tax_number": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "gsm": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "email": [
                227
            ],
            "title": [
                227
            ],
            "tax_office": [
                227
            ],
            "tax_number": [
                227
            ],
            "phone1": [
                227
            ],
            "phone2": [
                227
            ],
            "gsm": [
                227
            ],
            "website": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyScalarWhereWithAggregatesInput": {
            "AND": [
                880
            ],
            "OR": [
                880
            ],
            "NOT": [
                880
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "email": [
                615
            ],
            "title": [
                615
            ],
            "tax_office": [
                615
            ],
            "tax_number": [
                615
            ],
            "phone1": [
                615
            ],
            "phone2": [
                615
            ],
            "gsm": [
                615
            ],
            "website": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCompanyStatement": {
            "_count": [
                882
            ],
            "_avg": [
                883
            ],
            "_sum": [
                884
            ],
            "_min": [
                885
            ],
            "_max": [
                886
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "document_type": [
                3
            ],
            "document_number": [
                3
            ],
            "description": [
                3
            ],
            "debit": [
                3
            ],
            "credit": [
                3
            ],
            "balance": [
                3
            ],
            "code": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "company_id": [
                3
            ],
            "document_date": [
                3
            ],
            "data_number": [
                3
            ],
            "created_at": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                3
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementAvgAggregate": {
            "id": [
                5
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "company_id": [
                5
            ],
            "data_number": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementSumAggregate": {
            "id": [
                3
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "company_id": [
                3
            ],
            "data_number": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "company_id": [
                3
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "company_id": [
                3
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "company_id": [
                3
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                882
            ],
            "_avg": [
                883
            ],
            "_sum": [
                884
            ],
            "_min": [
                885
            ],
            "_max": [
                886
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "document_type": [
                228
            ],
            "document_number": [
                228
            ],
            "description": [
                228
            ],
            "debit": [
                228
            ],
            "credit": [
                228
            ],
            "balance": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "company_id": [
                228
            ],
            "document_date": [
                228
            ],
            "data_number": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                889
            ],
            "_avg": [
                890
            ],
            "_max": [
                891
            ],
            "_min": [
                892
            ],
            "_sum": [
                893
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "document_type": [
                227
            ],
            "document_number": [
                227
            ],
            "description": [
                227
            ],
            "debit": [
                227
            ],
            "credit": [
                227
            ],
            "balance": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "company_id": [
                227
            ],
            "document_date": [
                227
            ],
            "data_number": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "debit": [
                227
            ],
            "credit": [
                227
            ],
            "balance": [
                227
            ],
            "company_id": [
                227
            ],
            "data_number": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "document_type": [
                227
            ],
            "document_number": [
                227
            ],
            "description": [
                227
            ],
            "debit": [
                227
            ],
            "credit": [
                227
            ],
            "balance": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "company_id": [
                227
            ],
            "document_date": [
                227
            ],
            "data_number": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "document_type": [
                227
            ],
            "document_number": [
                227
            ],
            "description": [
                227
            ],
            "debit": [
                227
            ],
            "credit": [
                227
            ],
            "balance": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "company_id": [
                227
            ],
            "document_date": [
                227
            ],
            "data_number": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "debit": [
                227
            ],
            "credit": [
                227
            ],
            "balance": [
                227
            ],
            "company_id": [
                227
            ],
            "data_number": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementScalarWhereWithAggregatesInput": {
            "AND": [
                894
            ],
            "OR": [
                894
            ],
            "NOT": [
                894
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "document_type": [
                615
            ],
            "document_number": [
                615
            ],
            "description": [
                615
            ],
            "debit": [
                782
            ],
            "credit": [
                782
            ],
            "balance": [
                782
            ],
            "code": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "company_id": [
                617
            ],
            "document_date": [
                780
            ],
            "data_number": [
                617
            ],
            "created_at": [
                619
            ],
            "created_by": [
                617
            ],
            "updated_at": [
                619
            ],
            "updated_by": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCompanyToPaymentMethod": {
            "_count": [
                896
            ],
            "_avg": [
                897
            ],
            "_sum": [
                898
            ],
            "_min": [
                899
            ],
            "_max": [
                900
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCountAggregate": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                3
            ],
            "maximum_amount": [
                3
            ],
            "end_date": [
                3
            ],
            "start_date": [
                3
            ],
            "total_amount": [
                3
            ],
            "created_at": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                3
            ],
            "updated_by": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodAvgAggregate": {
            "company_id": [
                5
            ],
            "payment_methodId": [
                5
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "total_amount": [
                46
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodSumAggregate": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "total_amount": [
                46
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodMinAggregate": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodMaxAggregate": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodGroupBy": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "_count": [
                896
            ],
            "_avg": [
                897
            ],
            "_sum": [
                898
            ],
            "_min": [
                899
            ],
            "_max": [
                900
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodOrderByWithAggregationInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                228
            ],
            "maximum_amount": [
                228
            ],
            "end_date": [
                228
            ],
            "start_date": [
                228
            ],
            "total_amount": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "_count": [
                903
            ],
            "_avg": [
                904
            ],
            "_max": [
                905
            ],
            "_min": [
                906
            ],
            "_sum": [
                907
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCountOrderByAggregateInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                227
            ],
            "maximum_amount": [
                227
            ],
            "end_date": [
                227
            ],
            "start_date": [
                227
            ],
            "total_amount": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodAvgOrderByAggregateInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                227
            ],
            "maximum_amount": [
                227
            ],
            "total_amount": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodMaxOrderByAggregateInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                227
            ],
            "maximum_amount": [
                227
            ],
            "end_date": [
                227
            ],
            "start_date": [
                227
            ],
            "total_amount": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodMinOrderByAggregateInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                227
            ],
            "maximum_amount": [
                227
            ],
            "end_date": [
                227
            ],
            "start_date": [
                227
            ],
            "total_amount": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodSumOrderByAggregateInput": {
            "company_id": [
                227
            ],
            "payment_methodId": [
                227
            ],
            "minimum_amount": [
                227
            ],
            "maximum_amount": [
                227
            ],
            "total_amount": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodScalarWhereWithAggregatesInput": {
            "AND": [
                908
            ],
            "OR": [
                908
            ],
            "NOT": [
                908
            ],
            "company_id": [
                610
            ],
            "payment_methodId": [
                610
            ],
            "minimum_amount": [
                782
            ],
            "maximum_amount": [
                782
            ],
            "end_date": [
                780
            ],
            "start_date": [
                780
            ],
            "total_amount": [
                782
            ],
            "created_at": [
                619
            ],
            "created_by": [
                617
            ],
            "updated_at": [
                619
            ],
            "updated_by": [
                617
            ],
            "__typename": [
                9
            ]
        },
        "AggregateConfiguration": {
            "_count": [
                910
            ],
            "_avg": [
                911
            ],
            "_sum": [
                912
            ],
            "_min": [
                913
            ],
            "_max": [
                914
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "module": [
                3
            ],
            "category": [
                3
            ],
            "key": [
                3
            ],
            "value": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationWhereInput": {
            "AND": [
                915
            ],
            "OR": [
                915
            ],
            "NOT": [
                915
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "module": [
                20
            ],
            "category": [
                20
            ],
            "key": [
                20
            ],
            "value": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "module": [
                228
            ],
            "category": [
                228
            ],
            "key": [
                228
            ],
            "value": [
                228
            ],
            "_relevance": [
                917
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationOrderByRelevanceInput": {
            "fields": [
                918
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationOrderByRelevanceFieldEnum": {},
        "ConfigurationWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                915
            ],
            "OR": [
                915
            ],
            "NOT": [
                915
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "module": [
                20
            ],
            "category": [
                20
            ],
            "key": [
                20
            ],
            "value": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "Configuration": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationScalarFieldEnum": {},
        "ConfigurationGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "_count": [
                910
            ],
            "_avg": [
                911
            ],
            "_sum": [
                912
            ],
            "_min": [
                913
            ],
            "_max": [
                914
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "module": [
                228
            ],
            "category": [
                228
            ],
            "key": [
                228
            ],
            "value": [
                228
            ],
            "_count": [
                924
            ],
            "_avg": [
                925
            ],
            "_max": [
                926
            ],
            "_min": [
                927
            ],
            "_sum": [
                928
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "module": [
                227
            ],
            "category": [
                227
            ],
            "key": [
                227
            ],
            "value": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "module": [
                227
            ],
            "category": [
                227
            ],
            "key": [
                227
            ],
            "value": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "module": [
                227
            ],
            "category": [
                227
            ],
            "key": [
                227
            ],
            "value": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationScalarWhereWithAggregatesInput": {
            "AND": [
                929
            ],
            "OR": [
                929
            ],
            "NOT": [
                929
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "module": [
                615
            ],
            "category": [
                615
            ],
            "key": [
                615
            ],
            "value": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregateConfirmCode": {
            "_count": [
                931
            ],
            "_avg": [
                932
            ],
            "_sum": [
                933
            ],
            "_min": [
                934
            ],
            "_max": [
                935
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCountAggregate": {
            "id": [
                3
            ],
            "code": [
                3
            ],
            "expire_at": [
                3
            ],
            "confirmed_at": [
                3
            ],
            "user_id": [
                3
            ],
            "type": [
                3
            ],
            "gsm_no": [
                3
            ],
            "email": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeAvgAggregate": {
            "id": [
                5
            ],
            "user_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeSumAggregate": {
            "id": [
                3
            ],
            "user_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeMinAggregate": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "user_id": [
                3
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeMaxAggregate": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "user_id": [
                3
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeGroupBy": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "user_id": [
                3
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "_count": [
                931
            ],
            "_avg": [
                932
            ],
            "_sum": [
                933
            ],
            "_min": [
                934
            ],
            "_max": [
                935
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "code": [
                228
            ],
            "expire_at": [
                228
            ],
            "confirmed_at": [
                228
            ],
            "user_id": [
                228
            ],
            "type": [
                228
            ],
            "gsm_no": [
                228
            ],
            "email": [
                228
            ],
            "_count": [
                938
            ],
            "_avg": [
                939
            ],
            "_max": [
                940
            ],
            "_min": [
                941
            ],
            "_sum": [
                942
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "expire_at": [
                227
            ],
            "confirmed_at": [
                227
            ],
            "user_id": [
                227
            ],
            "type": [
                227
            ],
            "gsm_no": [
                227
            ],
            "email": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "user_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "expire_at": [
                227
            ],
            "confirmed_at": [
                227
            ],
            "user_id": [
                227
            ],
            "type": [
                227
            ],
            "gsm_no": [
                227
            ],
            "email": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "expire_at": [
                227
            ],
            "confirmed_at": [
                227
            ],
            "user_id": [
                227
            ],
            "type": [
                227
            ],
            "gsm_no": [
                227
            ],
            "email": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "user_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeScalarWhereWithAggregatesInput": {
            "AND": [
                943
            ],
            "OR": [
                943
            ],
            "NOT": [
                943
            ],
            "id": [
                610
            ],
            "code": [
                615
            ],
            "expire_at": [
                780
            ],
            "confirmed_at": [
                780
            ],
            "user_id": [
                617
            ],
            "type": [
                944
            ],
            "gsm_no": [
                615
            ],
            "email": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "EnumConfirmCodeTypeNullableWithAggregatesFilter": {
            "equals": [
                57
            ],
            "in": [
                57
            ],
            "notIn": [
                57
            ],
            "not": [
                945
            ],
            "_count": [
                24
            ],
            "_min": [
                58
            ],
            "_max": [
                58
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumConfirmCodeTypeNullableWithAggregatesFilter": {
            "equals": [
                57
            ],
            "in": [
                57
            ],
            "notIn": [
                57
            ],
            "not": [
                945
            ],
            "_count": [
                24
            ],
            "_min": [
                58
            ],
            "_max": [
                58
            ],
            "__typename": [
                9
            ]
        },
        "AggregateCounty": {
            "_count": [
                947
            ],
            "_avg": [
                948
            ],
            "_sum": [
                949
            ],
            "_min": [
                950
            ],
            "_max": [
                951
            ],
            "__typename": [
                9
            ]
        },
        "CountyCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CountyAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "cityId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "CountySumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "cityId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CountyMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CountyMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CountyGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                947
            ],
            "_avg": [
                948
            ],
            "_sum": [
                949
            ],
            "_min": [
                950
            ],
            "_max": [
                951
            ],
            "__typename": [
                9
            ]
        },
        "CountyOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "cityId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                954
            ],
            "_avg": [
                955
            ],
            "_max": [
                956
            ],
            "_min": [
                957
            ],
            "_sum": [
                958
            ],
            "__typename": [
                9
            ]
        },
        "CountyCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "cityId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountyAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "cityId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountyMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "cityId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountyMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "cityId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "cityId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "CountyScalarWhereWithAggregatesInput": {
            "AND": [
                959
            ],
            "OR": [
                959
            ],
            "NOT": [
                959
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "cityId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateDBQuery": {
            "_count": [
                961
            ],
            "_avg": [
                962
            ],
            "_sum": [
                963
            ],
            "_min": [
                964
            ],
            "_max": [
                965
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryCountAggregate": {
            "id": [
                3
            ],
            "method": [
                3
            ],
            "erp_name": [
                3
            ],
            "erp_sync_type": [
                3
            ],
            "name": [
                3
            ],
            "query": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryAvgAggregate": {
            "id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "DBQuerySumAggregate": {
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryMinAggregate": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryMaxAggregate": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryWhereInput": {
            "AND": [
                966
            ],
            "OR": [
                966
            ],
            "NOT": [
                966
            ],
            "id": [
                16
            ],
            "method": [
                20
            ],
            "erp_name": [
                20
            ],
            "erp_sync_type": [
                20
            ],
            "name": [
                20
            ],
            "query": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "method": [
                228
            ],
            "erp_name": [
                228
            ],
            "erp_sync_type": [
                228
            ],
            "name": [
                228
            ],
            "query": [
                228
            ],
            "_relevance": [
                968
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryOrderByRelevanceInput": {
            "fields": [
                969
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryOrderByRelevanceFieldEnum": {},
        "DBQueryWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                966
            ],
            "OR": [
                966
            ],
            "NOT": [
                966
            ],
            "method": [
                20
            ],
            "erp_name": [
                20
            ],
            "erp_sync_type": [
                20
            ],
            "name": [
                20
            ],
            "query": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "DBQuery": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryScalarFieldEnum": {},
        "DBQueryGroupBy": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "_count": [
                961
            ],
            "_avg": [
                962
            ],
            "_sum": [
                963
            ],
            "_min": [
                964
            ],
            "_max": [
                965
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "method": [
                228
            ],
            "erp_name": [
                228
            ],
            "erp_sync_type": [
                228
            ],
            "name": [
                228
            ],
            "query": [
                228
            ],
            "_count": [
                975
            ],
            "_avg": [
                976
            ],
            "_max": [
                977
            ],
            "_min": [
                978
            ],
            "_sum": [
                979
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "erp_name": [
                227
            ],
            "erp_sync_type": [
                227
            ],
            "name": [
                227
            ],
            "query": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "erp_name": [
                227
            ],
            "erp_sync_type": [
                227
            ],
            "name": [
                227
            ],
            "query": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "erp_name": [
                227
            ],
            "erp_sync_type": [
                227
            ],
            "name": [
                227
            ],
            "query": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DBQuerySumOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryScalarWhereWithAggregatesInput": {
            "AND": [
                980
            ],
            "OR": [
                980
            ],
            "NOT": [
                980
            ],
            "id": [
                610
            ],
            "method": [
                615
            ],
            "erp_name": [
                615
            ],
            "erp_sync_type": [
                615
            ],
            "name": [
                615
            ],
            "query": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregateDevice": {
            "_count": [
                982
            ],
            "_avg": [
                983
            ],
            "_sum": [
                984
            ],
            "_min": [
                985
            ],
            "_max": [
                986
            ],
            "__typename": [
                9
            ]
        },
        "DeviceCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "user_id": [
                3
            ],
            "app_version": [
                3
            ],
            "device_id": [
                3
            ],
            "device_name": [
                3
            ],
            "notification_id": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DeviceAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "user_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "DeviceSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "user_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DeviceMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DeviceMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DeviceWhereInput": {
            "AND": [
                987
            ],
            "OR": [
                987
            ],
            "NOT": [
                987
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "user_id": [
                23
            ],
            "app_version": [
                20
            ],
            "device_id": [
                20
            ],
            "device_name": [
                20
            ],
            "notification_id": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "DeviceOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "user_id": [
                228
            ],
            "app_version": [
                228
            ],
            "device_id": [
                228
            ],
            "device_name": [
                228
            ],
            "notification_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_relevance": [
                989
            ],
            "__typename": [
                9
            ]
        },
        "DeviceOrderByRelevanceInput": {
            "fields": [
                990
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DeviceOrderByRelevanceFieldEnum": {},
        "DeviceWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                987
            ],
            "OR": [
                987
            ],
            "NOT": [
                987
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "user_id": [
                23
            ],
            "app_version": [
                20
            ],
            "device_id": [
                20
            ],
            "device_name": [
                20
            ],
            "notification_id": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "Device": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DeviceScalarFieldEnum": {},
        "DeviceGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                982
            ],
            "_avg": [
                983
            ],
            "_sum": [
                984
            ],
            "_min": [
                985
            ],
            "_max": [
                986
            ],
            "__typename": [
                9
            ]
        },
        "DeviceOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "user_id": [
                228
            ],
            "app_version": [
                228
            ],
            "device_id": [
                228
            ],
            "device_name": [
                228
            ],
            "notification_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                996
            ],
            "_avg": [
                997
            ],
            "_max": [
                998
            ],
            "_min": [
                999
            ],
            "_sum": [
                1000
            ],
            "__typename": [
                9
            ]
        },
        "DeviceCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "user_id": [
                227
            ],
            "app_version": [
                227
            ],
            "device_id": [
                227
            ],
            "device_name": [
                227
            ],
            "notification_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DeviceAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "user_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DeviceMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "user_id": [
                227
            ],
            "app_version": [
                227
            ],
            "device_id": [
                227
            ],
            "device_name": [
                227
            ],
            "notification_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DeviceMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "user_id": [
                227
            ],
            "app_version": [
                227
            ],
            "device_id": [
                227
            ],
            "device_name": [
                227
            ],
            "notification_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DeviceSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "user_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DeviceScalarWhereWithAggregatesInput": {
            "AND": [
                1001
            ],
            "OR": [
                1001
            ],
            "NOT": [
                1001
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "user_id": [
                617
            ],
            "app_version": [
                615
            ],
            "device_id": [
                615
            ],
            "device_name": [
                615
            ],
            "notification_id": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateDistrict": {
            "_count": [
                1003
            ],
            "_avg": [
                1004
            ],
            "_sum": [
                1005
            ],
            "_min": [
                1006
            ],
            "_max": [
                1007
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "is_deleted": [
                3
            ],
            "source": [
                3
            ],
            "countyId": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DistrictAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "countyId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "DistrictSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "countyId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DistrictMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DistrictMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DistrictGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "_count": [
                1003
            ],
            "_avg": [
                1004
            ],
            "_sum": [
                1005
            ],
            "_min": [
                1006
            ],
            "_max": [
                1007
            ],
            "__typename": [
                9
            ]
        },
        "DistrictOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "countyId": [
                228
            ],
            "_count": [
                1010
            ],
            "_avg": [
                1011
            ],
            "_max": [
                1012
            ],
            "_min": [
                1013
            ],
            "_sum": [
                1014
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DistrictAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DistrictMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DistrictMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DistrictSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "countyId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "DistrictScalarWhereWithAggregatesInput": {
            "AND": [
                1015
            ],
            "OR": [
                1015
            ],
            "NOT": [
                1015
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "is_deleted": [
                627
            ],
            "source": [
                621
            ],
            "countyId": [
                617
            ],
            "__typename": [
                9
            ]
        },
        "AggregateError": {
            "_count": [
                1017
            ],
            "_avg": [
                1018
            ],
            "_sum": [
                1019
            ],
            "_min": [
                1020
            ],
            "_max": [
                1021
            ],
            "__typename": [
                9
            ]
        },
        "ErrorCountAggregate": {
            "id": [
                3
            ],
            "method": [
                3
            ],
            "name": [
                3
            ],
            "message": [
                3
            ],
            "stack": [
                3
            ],
            "created_at": [
                3
            ],
            "reference_id": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ErrorAvgAggregate": {
            "id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ErrorSumAggregate": {
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ErrorMinAggregate": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorMaxAggregate": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorWhereInput": {
            "AND": [
                1022
            ],
            "OR": [
                1022
            ],
            "NOT": [
                1022
            ],
            "id": [
                16
            ],
            "method": [
                20
            ],
            "name": [
                20
            ],
            "message": [
                20
            ],
            "stack": [
                20
            ],
            "created_at": [
                25
            ],
            "reference_id": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "ErrorOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "method": [
                228
            ],
            "name": [
                228
            ],
            "message": [
                228
            ],
            "stack": [
                228
            ],
            "created_at": [
                227
            ],
            "reference_id": [
                228
            ],
            "_relevance": [
                1024
            ],
            "__typename": [
                9
            ]
        },
        "ErrorOrderByRelevanceInput": {
            "fields": [
                1025
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorOrderByRelevanceFieldEnum": {},
        "ErrorWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                1022
            ],
            "OR": [
                1022
            ],
            "NOT": [
                1022
            ],
            "method": [
                20
            ],
            "name": [
                20
            ],
            "message": [
                20
            ],
            "stack": [
                20
            ],
            "created_at": [
                25
            ],
            "reference_id": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "Error": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorScalarFieldEnum": {},
        "ErrorGroupBy": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "_count": [
                1017
            ],
            "_avg": [
                1018
            ],
            "_sum": [
                1019
            ],
            "_min": [
                1020
            ],
            "_max": [
                1021
            ],
            "__typename": [
                9
            ]
        },
        "ErrorOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "method": [
                228
            ],
            "name": [
                228
            ],
            "message": [
                228
            ],
            "stack": [
                228
            ],
            "created_at": [
                227
            ],
            "reference_id": [
                228
            ],
            "_count": [
                1031
            ],
            "_avg": [
                1032
            ],
            "_max": [
                1033
            ],
            "_min": [
                1034
            ],
            "_sum": [
                1035
            ],
            "__typename": [
                9
            ]
        },
        "ErrorCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "name": [
                227
            ],
            "message": [
                227
            ],
            "stack": [
                227
            ],
            "created_at": [
                227
            ],
            "reference_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ErrorAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ErrorMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "name": [
                227
            ],
            "message": [
                227
            ],
            "stack": [
                227
            ],
            "created_at": [
                227
            ],
            "reference_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ErrorMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "method": [
                227
            ],
            "name": [
                227
            ],
            "message": [
                227
            ],
            "stack": [
                227
            ],
            "created_at": [
                227
            ],
            "reference_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ErrorSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ErrorScalarWhereWithAggregatesInput": {
            "AND": [
                1036
            ],
            "OR": [
                1036
            ],
            "NOT": [
                1036
            ],
            "id": [
                610
            ],
            "method": [
                615
            ],
            "name": [
                615
            ],
            "message": [
                615
            ],
            "stack": [
                615
            ],
            "created_at": [
                619
            ],
            "reference_id": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregateFileManager": {
            "_count": [
                1038
            ],
            "_avg": [
                1039
            ],
            "_sum": [
                1040
            ],
            "_min": [
                1041
            ],
            "_max": [
                1042
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "path": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerAvgAggregate": {
            "id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerSumAggregate": {
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerWhereInput": {
            "AND": [
                1043
            ],
            "OR": [
                1043
            ],
            "NOT": [
                1043
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "path": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "path": [
                228
            ],
            "_relevance": [
                1045
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerOrderByRelevanceInput": {
            "fields": [
                1046
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerOrderByRelevanceFieldEnum": {},
        "FileManagerWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                1043
            ],
            "OR": [
                1043
            ],
            "NOT": [
                1043
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "path": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "FileManager": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerScalarFieldEnum": {},
        "FileManagerGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "_count": [
                1038
            ],
            "_avg": [
                1039
            ],
            "_sum": [
                1040
            ],
            "_min": [
                1041
            ],
            "_max": [
                1042
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "path": [
                228
            ],
            "_count": [
                1052
            ],
            "_avg": [
                1053
            ],
            "_max": [
                1054
            ],
            "_min": [
                1055
            ],
            "_sum": [
                1056
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerScalarWhereWithAggregatesInput": {
            "AND": [
                1057
            ],
            "OR": [
                1057
            ],
            "NOT": [
                1057
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "path": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregateImage": {
            "_count": [
                1059
            ],
            "_avg": [
                1060
            ],
            "_sum": [
                1061
            ],
            "_min": [
                1062
            ],
            "_max": [
                1063
            ],
            "__typename": [
                9
            ]
        },
        "ImageCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "out_ref": [
                3
            ],
            "url": [
                3
            ],
            "store_at": [
                3
            ],
            "alt": [
                3
            ],
            "title": [
                3
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                3
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ImageAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "categoryId": [
                5
            ],
            "brandId": [
                5
            ],
            "campaignId": [
                5
            ],
            "collectionId": [
                5
            ],
            "shipmentStatusId": [
                5
            ],
            "index": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ImageSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ImageMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1059
            ],
            "_avg": [
                1060
            ],
            "_sum": [
                1061
            ],
            "_min": [
                1062
            ],
            "_max": [
                1063
            ],
            "__typename": [
                9
            ]
        },
        "ImageOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "out_ref": [
                228
            ],
            "url": [
                228
            ],
            "store_at": [
                228
            ],
            "alt": [
                228
            ],
            "title": [
                228
            ],
            "categoryId": [
                228
            ],
            "brandId": [
                228
            ],
            "campaignId": [
                228
            ],
            "collectionId": [
                228
            ],
            "folder_type": [
                228
            ],
            "shipmentStatusId": [
                228
            ],
            "index": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1066
            ],
            "_avg": [
                1067
            ],
            "_max": [
                1068
            ],
            "_min": [
                1069
            ],
            "_sum": [
                1070
            ],
            "__typename": [
                9
            ]
        },
        "ImageCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "out_ref": [
                227
            ],
            "url": [
                227
            ],
            "store_at": [
                227
            ],
            "alt": [
                227
            ],
            "title": [
                227
            ],
            "categoryId": [
                227
            ],
            "brandId": [
                227
            ],
            "campaignId": [
                227
            ],
            "collectionId": [
                227
            ],
            "folder_type": [
                227
            ],
            "shipmentStatusId": [
                227
            ],
            "index": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ImageAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "categoryId": [
                227
            ],
            "brandId": [
                227
            ],
            "campaignId": [
                227
            ],
            "collectionId": [
                227
            ],
            "shipmentStatusId": [
                227
            ],
            "index": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ImageMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "out_ref": [
                227
            ],
            "url": [
                227
            ],
            "store_at": [
                227
            ],
            "alt": [
                227
            ],
            "title": [
                227
            ],
            "categoryId": [
                227
            ],
            "brandId": [
                227
            ],
            "campaignId": [
                227
            ],
            "collectionId": [
                227
            ],
            "folder_type": [
                227
            ],
            "shipmentStatusId": [
                227
            ],
            "index": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ImageMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "out_ref": [
                227
            ],
            "url": [
                227
            ],
            "store_at": [
                227
            ],
            "alt": [
                227
            ],
            "title": [
                227
            ],
            "categoryId": [
                227
            ],
            "brandId": [
                227
            ],
            "campaignId": [
                227
            ],
            "collectionId": [
                227
            ],
            "folder_type": [
                227
            ],
            "shipmentStatusId": [
                227
            ],
            "index": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ImageSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "categoryId": [
                227
            ],
            "brandId": [
                227
            ],
            "campaignId": [
                227
            ],
            "collectionId": [
                227
            ],
            "shipmentStatusId": [
                227
            ],
            "index": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ImageScalarWhereWithAggregatesInput": {
            "AND": [
                1071
            ],
            "OR": [
                1071
            ],
            "NOT": [
                1071
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "out_ref": [
                615
            ],
            "url": [
                615
            ],
            "store_at": [
                615
            ],
            "alt": [
                615
            ],
            "title": [
                615
            ],
            "categoryId": [
                617
            ],
            "brandId": [
                617
            ],
            "campaignId": [
                617
            ],
            "collectionId": [
                617
            ],
            "folder_type": [
                1072
            ],
            "shipmentStatusId": [
                617
            ],
            "index": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumFolderTypeNullableWithAggregatesFilter": {
            "equals": [
                134
            ],
            "in": [
                134
            ],
            "notIn": [
                134
            ],
            "not": [
                1073
            ],
            "_count": [
                24
            ],
            "_min": [
                135
            ],
            "_max": [
                135
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumFolderTypeNullableWithAggregatesFilter": {
            "equals": [
                134
            ],
            "in": [
                134
            ],
            "notIn": [
                134
            ],
            "not": [
                1073
            ],
            "_count": [
                24
            ],
            "_min": [
                135
            ],
            "_max": [
                135
            ],
            "__typename": [
                9
            ]
        },
        "AggregateInstallment": {
            "_count": [
                1075
            ],
            "_avg": [
                1076
            ],
            "_sum": [
                1077
            ],
            "_min": [
                1078
            ],
            "_max": [
                1079
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCountAggregate": {
            "id": [
                3
            ],
            "code": [
                3
            ],
            "name": [
                3
            ],
            "out_ref": [
                3
            ],
            "count": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentAvgAggregate": {
            "id": [
                5
            ],
            "count": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentSumAggregate": {
            "id": [
                3
            ],
            "count": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentMinAggregate": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentMaxAggregate": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                93
            ],
            "OR": [
                93
            ],
            "NOT": [
                93
            ],
            "code": [
                20
            ],
            "name": [
                20
            ],
            "count": [
                23
            ],
            "InstallmentToCompany": [
                94
            ],
            "InstallmentToPosAccount": [
                90
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentScalarFieldEnum": {},
        "InstallmentGroupBy": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "_count": [
                1075
            ],
            "_avg": [
                1076
            ],
            "_sum": [
                1077
            ],
            "_min": [
                1078
            ],
            "_max": [
                1079
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "code": [
                228
            ],
            "name": [
                228
            ],
            "out_ref": [
                228
            ],
            "count": [
                228
            ],
            "_count": [
                1084
            ],
            "_avg": [
                1085
            ],
            "_max": [
                1086
            ],
            "_min": [
                1087
            ],
            "_sum": [
                1088
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "code": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "count": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentScalarWhereWithAggregatesInput": {
            "AND": [
                1089
            ],
            "OR": [
                1089
            ],
            "NOT": [
                1089
            ],
            "id": [
                610
            ],
            "code": [
                615
            ],
            "name": [
                615
            ],
            "out_ref": [
                615
            ],
            "count": [
                617
            ],
            "__typename": [
                9
            ]
        },
        "AggregateInstallmentToCompany": {
            "_count": [
                1091
            ],
            "_avg": [
                1092
            ],
            "_sum": [
                1093
            ],
            "_min": [
                1094
            ],
            "_max": [
                1095
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCountAggregate": {
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "status": [
                3
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                3
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                3
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                3
            ],
            "bankOnUsCreditCardCommisionRate": [
                3
            ],
            "bankOnUsDebitCardCommisionRate": [
                3
            ],
            "cardBrandName": [
                3
            ],
            "mininum": [
                3
            ],
            "maximum": [
                3
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                3
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyAvgAggregate": {
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "blockageDay": [
                5
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                5
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                5
            ],
            "company_id": [
                5
            ],
            "id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanySumAggregate": {
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyMinAggregate": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyMaxAggregate": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyGroupBy": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "_count": [
                1091
            ],
            "_avg": [
                1092
            ],
            "_sum": [
                1093
            ],
            "_min": [
                1094
            ],
            "_max": [
                1095
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyOrderByWithAggregationInput": {
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "status": [
                228
            ],
            "blockageDay": [
                228
            ],
            "bankForiegnCardCommisionRate": [
                228
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                228
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                228
            ],
            "bankOnUsCreditCardCommisionRate": [
                228
            ],
            "bankOnUsDebitCardCommisionRate": [
                228
            ],
            "cardBrandName": [
                228
            ],
            "mininum": [
                228
            ],
            "maximum": [
                228
            ],
            "additiveInstallment": [
                228
            ],
            "merchantCommissionRate": [
                228
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "_count": [
                1098
            ],
            "_avg": [
                1099
            ],
            "_max": [
                1100
            ],
            "_min": [
                1101
            ],
            "_sum": [
                1102
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCountOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyAvgOrderByAggregateInput": {
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyMaxOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyMinOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanySumOrderByAggregateInput": {
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "company_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyScalarWhereWithAggregatesInput": {
            "AND": [
                1103
            ],
            "OR": [
                1103
            ],
            "NOT": [
                1103
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "status": [
                627
            ],
            "blockageDay": [
                617
            ],
            "bankForiegnCardCommisionRate": [
                782
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                782
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                782
            ],
            "bankOnUsCreditCardCommisionRate": [
                782
            ],
            "bankOnUsDebitCardCommisionRate": [
                782
            ],
            "cardBrandName": [
                615
            ],
            "mininum": [
                782
            ],
            "maximum": [
                782
            ],
            "additiveInstallment": [
                617
            ],
            "merchantCommissionRate": [
                782
            ],
            "installment_id": [
                610
            ],
            "company_id": [
                610
            ],
            "id": [
                610
            ],
            "__typename": [
                9
            ]
        },
        "AggregateInstallmentToPosAccount": {
            "_count": [
                1105
            ],
            "_avg": [
                1106
            ],
            "_sum": [
                1107
            ],
            "_min": [
                1108
            ],
            "_max": [
                1109
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCountAggregate": {
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "status": [
                3
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                3
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                3
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                3
            ],
            "bankOnUsCreditCardCommisionRate": [
                3
            ],
            "bankOnUsDebitCardCommisionRate": [
                3
            ],
            "cardBrandName": [
                3
            ],
            "mininum": [
                3
            ],
            "maximum": [
                3
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                3
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountAvgAggregate": {
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "blockageDay": [
                5
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                5
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                5
            ],
            "pos_account_id": [
                5
            ],
            "id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountSumAggregate": {
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountMinAggregate": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountMaxAggregate": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountGroupBy": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "_count": [
                1105
            ],
            "_avg": [
                1106
            ],
            "_sum": [
                1107
            ],
            "_min": [
                1108
            ],
            "_max": [
                1109
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountOrderByWithAggregationInput": {
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "status": [
                228
            ],
            "blockageDay": [
                228
            ],
            "bankForiegnCardCommisionRate": [
                228
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                228
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                228
            ],
            "bankOnUsCreditCardCommisionRate": [
                228
            ],
            "bankOnUsDebitCardCommisionRate": [
                228
            ],
            "cardBrandName": [
                228
            ],
            "mininum": [
                228
            ],
            "maximum": [
                228
            ],
            "additiveInstallment": [
                228
            ],
            "merchantCommissionRate": [
                228
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "_count": [
                1112
            ],
            "_avg": [
                1113
            ],
            "_max": [
                1114
            ],
            "_min": [
                1115
            ],
            "_sum": [
                1116
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCountOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountAvgOrderByAggregateInput": {
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountMaxOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountMinOrderByAggregateInput": {
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "status": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "cardBrandName": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountSumOrderByAggregateInput": {
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "blockageDay": [
                227
            ],
            "bankForiegnCardCommisionRate": [
                227
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                227
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                227
            ],
            "bankOnUsCreditCardCommisionRate": [
                227
            ],
            "bankOnUsDebitCardCommisionRate": [
                227
            ],
            "mininum": [
                227
            ],
            "maximum": [
                227
            ],
            "additiveInstallment": [
                227
            ],
            "merchantCommissionRate": [
                227
            ],
            "installment_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountScalarWhereWithAggregatesInput": {
            "AND": [
                1117
            ],
            "OR": [
                1117
            ],
            "NOT": [
                1117
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "status": [
                627
            ],
            "blockageDay": [
                617
            ],
            "bankForiegnCardCommisionRate": [
                782
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                782
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                782
            ],
            "bankOnUsCreditCardCommisionRate": [
                782
            ],
            "bankOnUsDebitCardCommisionRate": [
                782
            ],
            "cardBrandName": [
                615
            ],
            "mininum": [
                782
            ],
            "maximum": [
                782
            ],
            "additiveInstallment": [
                617
            ],
            "merchantCommissionRate": [
                782
            ],
            "installment_id": [
                610
            ],
            "pos_account_id": [
                610
            ],
            "id": [
                610
            ],
            "__typename": [
                9
            ]
        },
        "AggregateOrder": {
            "_count": [
                1119
            ],
            "_avg": [
                1120
            ],
            "_sum": [
                1121
            ],
            "_min": [
                1122
            ],
            "_max": [
                1123
            ],
            "__typename": [
                9
            ]
        },
        "OrderCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "ordered_at": [
                3
            ],
            "order_key": [
                3
            ],
            "outer_order_key": [
                3
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                3
            ],
            "total_price_net": [
                3
            ],
            "total_shipment_cost": [
                3
            ],
            "total_vat_cost": [
                3
            ],
            "total_discount": [
                3
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                3
            ],
            "is_approved": [
                3
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                3
            ],
            "is_sent_erp": [
                3
            ],
            "sent_at": [
                3
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                3
            ],
            "is_deleted": [
                3
            ],
            "status": [
                3
            ],
            "payment_status": [
                3
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                3
            ],
            "is_sent_status": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "company_id": [
                5
            ],
            "user_id": [
                5
            ],
            "shipment_address_id": [
                5
            ],
            "billing_address_id": [
                5
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                5
            ],
            "approved_by": [
                5
            ],
            "sent_by": [
                5
            ],
            "cartId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OrderSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "approved_by": [
                3
            ],
            "sent_by": [
                3
            ],
            "cartId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "OrderMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "OrderGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "_count": [
                1119
            ],
            "_avg": [
                1120
            ],
            "_sum": [
                1121
            ],
            "_min": [
                1122
            ],
            "_max": [
                1123
            ],
            "__typename": [
                9
            ]
        },
        "OrderOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "ordered_at": [
                228
            ],
            "order_key": [
                227
            ],
            "outer_order_key": [
                228
            ],
            "company_id": [
                228
            ],
            "user_id": [
                228
            ],
            "shipment_address_id": [
                228
            ],
            "billing_address_id": [
                228
            ],
            "total_price": [
                228
            ],
            "total_price_net": [
                228
            ],
            "total_shipment_cost": [
                228
            ],
            "total_vat_cost": [
                228
            ],
            "total_discount": [
                228
            ],
            "total_quantity": [
                228
            ],
            "order_note": [
                228
            ],
            "is_approved": [
                228
            ],
            "approved_by": [
                228
            ],
            "approved_at": [
                228
            ],
            "is_sent_erp": [
                228
            ],
            "sent_at": [
                228
            ],
            "sent_by": [
                228
            ],
            "company_name": [
                228
            ],
            "is_deleted": [
                228
            ],
            "status": [
                228
            ],
            "payment_status": [
                228
            ],
            "cartId": [
                228
            ],
            "warehouse": [
                228
            ],
            "is_sent_status": [
                228
            ],
            "_count": [
                1126
            ],
            "_avg": [
                1127
            ],
            "_max": [
                1128
            ],
            "_min": [
                1129
            ],
            "_sum": [
                1130
            ],
            "__typename": [
                9
            ]
        },
        "OrderCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "ordered_at": [
                227
            ],
            "order_key": [
                227
            ],
            "outer_order_key": [
                227
            ],
            "company_id": [
                227
            ],
            "user_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_quantity": [
                227
            ],
            "order_note": [
                227
            ],
            "is_approved": [
                227
            ],
            "approved_by": [
                227
            ],
            "approved_at": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "company_name": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "payment_status": [
                227
            ],
            "cartId": [
                227
            ],
            "warehouse": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "company_id": [
                227
            ],
            "user_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_quantity": [
                227
            ],
            "approved_by": [
                227
            ],
            "sent_by": [
                227
            ],
            "cartId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "ordered_at": [
                227
            ],
            "order_key": [
                227
            ],
            "outer_order_key": [
                227
            ],
            "company_id": [
                227
            ],
            "user_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_quantity": [
                227
            ],
            "order_note": [
                227
            ],
            "is_approved": [
                227
            ],
            "approved_by": [
                227
            ],
            "approved_at": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "company_name": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "payment_status": [
                227
            ],
            "cartId": [
                227
            ],
            "warehouse": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "ordered_at": [
                227
            ],
            "order_key": [
                227
            ],
            "outer_order_key": [
                227
            ],
            "company_id": [
                227
            ],
            "user_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_quantity": [
                227
            ],
            "order_note": [
                227
            ],
            "is_approved": [
                227
            ],
            "approved_by": [
                227
            ],
            "approved_at": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "company_name": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "payment_status": [
                227
            ],
            "cartId": [
                227
            ],
            "warehouse": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "company_id": [
                227
            ],
            "user_id": [
                227
            ],
            "shipment_address_id": [
                227
            ],
            "billing_address_id": [
                227
            ],
            "total_price": [
                227
            ],
            "total_price_net": [
                227
            ],
            "total_shipment_cost": [
                227
            ],
            "total_vat_cost": [
                227
            ],
            "total_discount": [
                227
            ],
            "total_quantity": [
                227
            ],
            "approved_by": [
                227
            ],
            "sent_by": [
                227
            ],
            "cartId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderScalarWhereWithAggregatesInput": {
            "AND": [
                1131
            ],
            "OR": [
                1131
            ],
            "NOT": [
                1131
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "ordered_at": [
                780
            ],
            "order_key": [
                865
            ],
            "outer_order_key": [
                615
            ],
            "company_id": [
                617
            ],
            "user_id": [
                617
            ],
            "shipment_address_id": [
                617
            ],
            "billing_address_id": [
                617
            ],
            "total_price": [
                782
            ],
            "total_price_net": [
                782
            ],
            "total_shipment_cost": [
                782
            ],
            "total_vat_cost": [
                782
            ],
            "total_discount": [
                782
            ],
            "total_quantity": [
                617
            ],
            "order_note": [
                615
            ],
            "is_approved": [
                627
            ],
            "approved_by": [
                617
            ],
            "approved_at": [
                780
            ],
            "is_sent_erp": [
                627
            ],
            "sent_at": [
                780
            ],
            "sent_by": [
                617
            ],
            "company_name": [
                615
            ],
            "is_deleted": [
                627
            ],
            "status": [
                615
            ],
            "payment_status": [
                1132
            ],
            "cartId": [
                617
            ],
            "warehouse": [
                615
            ],
            "is_sent_status": [
                1134
            ],
            "__typename": [
                9
            ]
        },
        "EnumOrderPaymentStatusNullableWithAggregatesFilter": {
            "equals": [
                64
            ],
            "in": [
                64
            ],
            "notIn": [
                64
            ],
            "not": [
                1133
            ],
            "_count": [
                24
            ],
            "_min": [
                65
            ],
            "_max": [
                65
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumOrderPaymentStatusNullableWithAggregatesFilter": {
            "equals": [
                64
            ],
            "in": [
                64
            ],
            "notIn": [
                64
            ],
            "not": [
                1133
            ],
            "_count": [
                24
            ],
            "_min": [
                65
            ],
            "_max": [
                65
            ],
            "__typename": [
                9
            ]
        },
        "EnumIsSendStatusNullableWithAggregatesFilter": {
            "equals": [
                67
            ],
            "in": [
                67
            ],
            "notIn": [
                67
            ],
            "not": [
                1135
            ],
            "_count": [
                24
            ],
            "_min": [
                68
            ],
            "_max": [
                68
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumIsSendStatusNullableWithAggregatesFilter": {
            "equals": [
                67
            ],
            "in": [
                67
            ],
            "notIn": [
                67
            ],
            "not": [
                1135
            ],
            "_count": [
                24
            ],
            "_min": [
                68
            ],
            "_max": [
                68
            ],
            "__typename": [
                9
            ]
        },
        "AggregateOrderItem": {
            "_count": [
                1137
            ],
            "_avg": [
                1138
            ],
            "_sum": [
                1139
            ],
            "_min": [
                1140
            ],
            "_max": [
                1141
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "status_name": [
                3
            ],
            "price": [
                3
            ],
            "quantity": [
                3
            ],
            "quantity2": [
                3
            ],
            "discount_rate": [
                3
            ],
            "vat": [
                3
            ],
            "total_price": [
                3
            ],
            "discount_price": [
                3
            ],
            "vat_price": [
                3
            ],
            "total_net_price": [
                3
            ],
            "unit": [
                3
            ],
            "unit_shipment_cost": [
                3
            ],
            "sent_unit": [
                3
            ],
            "waiting_unit": [
                3
            ],
            "features": [
                3
            ],
            "notes1": [
                3
            ],
            "notes2": [
                3
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                3
            ],
            "shipment_method": [
                3
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "userId": [
                5
            ],
            "productId": [
                5
            ],
            "orderItemStatusId": [
                5
            ],
            "shipment_id": [
                5
            ],
            "cart_id": [
                5
            ],
            "orderId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "_count": [
                1137
            ],
            "_avg": [
                1138
            ],
            "_sum": [
                1139
            ],
            "_min": [
                1140
            ],
            "_max": [
                1141
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "status_name": [
                228
            ],
            "price": [
                228
            ],
            "quantity": [
                228
            ],
            "quantity2": [
                228
            ],
            "discount_rate": [
                228
            ],
            "vat": [
                228
            ],
            "total_price": [
                228
            ],
            "discount_price": [
                228
            ],
            "vat_price": [
                228
            ],
            "total_net_price": [
                228
            ],
            "unit": [
                228
            ],
            "unit_shipment_cost": [
                228
            ],
            "sent_unit": [
                228
            ],
            "waiting_unit": [
                228
            ],
            "features": [
                228
            ],
            "notes1": [
                228
            ],
            "notes2": [
                228
            ],
            "userId": [
                228
            ],
            "productId": [
                228
            ],
            "orderItemStatusId": [
                228
            ],
            "shipment_id": [
                228
            ],
            "is_deleted": [
                228
            ],
            "shipment_method": [
                228
            ],
            "cart_id": [
                228
            ],
            "orderId": [
                228
            ],
            "_count": [
                1144
            ],
            "_avg": [
                1145
            ],
            "_max": [
                1146
            ],
            "_min": [
                1147
            ],
            "_sum": [
                1148
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "status_name": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "quantity2": [
                227
            ],
            "discount_rate": [
                227
            ],
            "vat": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "unit": [
                227
            ],
            "unit_shipment_cost": [
                227
            ],
            "sent_unit": [
                227
            ],
            "waiting_unit": [
                227
            ],
            "features": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "userId": [
                227
            ],
            "productId": [
                227
            ],
            "orderItemStatusId": [
                227
            ],
            "shipment_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "shipment_method": [
                227
            ],
            "cart_id": [
                227
            ],
            "orderId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "quantity2": [
                227
            ],
            "discount_rate": [
                227
            ],
            "vat": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "unit": [
                227
            ],
            "unit_shipment_cost": [
                227
            ],
            "sent_unit": [
                227
            ],
            "waiting_unit": [
                227
            ],
            "userId": [
                227
            ],
            "productId": [
                227
            ],
            "orderItemStatusId": [
                227
            ],
            "shipment_id": [
                227
            ],
            "cart_id": [
                227
            ],
            "orderId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "status_name": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "quantity2": [
                227
            ],
            "discount_rate": [
                227
            ],
            "vat": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "unit": [
                227
            ],
            "unit_shipment_cost": [
                227
            ],
            "sent_unit": [
                227
            ],
            "waiting_unit": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "userId": [
                227
            ],
            "productId": [
                227
            ],
            "orderItemStatusId": [
                227
            ],
            "shipment_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "shipment_method": [
                227
            ],
            "cart_id": [
                227
            ],
            "orderId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "status_name": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "quantity2": [
                227
            ],
            "discount_rate": [
                227
            ],
            "vat": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "unit": [
                227
            ],
            "unit_shipment_cost": [
                227
            ],
            "sent_unit": [
                227
            ],
            "waiting_unit": [
                227
            ],
            "notes1": [
                227
            ],
            "notes2": [
                227
            ],
            "userId": [
                227
            ],
            "productId": [
                227
            ],
            "orderItemStatusId": [
                227
            ],
            "shipment_id": [
                227
            ],
            "is_deleted": [
                227
            ],
            "shipment_method": [
                227
            ],
            "cart_id": [
                227
            ],
            "orderId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "quantity": [
                227
            ],
            "quantity2": [
                227
            ],
            "discount_rate": [
                227
            ],
            "vat": [
                227
            ],
            "total_price": [
                227
            ],
            "discount_price": [
                227
            ],
            "vat_price": [
                227
            ],
            "total_net_price": [
                227
            ],
            "unit": [
                227
            ],
            "unit_shipment_cost": [
                227
            ],
            "sent_unit": [
                227
            ],
            "waiting_unit": [
                227
            ],
            "userId": [
                227
            ],
            "productId": [
                227
            ],
            "orderItemStatusId": [
                227
            ],
            "shipment_id": [
                227
            ],
            "cart_id": [
                227
            ],
            "orderId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemScalarWhereWithAggregatesInput": {
            "AND": [
                1149
            ],
            "OR": [
                1149
            ],
            "NOT": [
                1149
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "status_name": [
                615
            ],
            "price": [
                782
            ],
            "quantity": [
                782
            ],
            "quantity2": [
                782
            ],
            "discount_rate": [
                782
            ],
            "vat": [
                782
            ],
            "total_price": [
                782
            ],
            "discount_price": [
                782
            ],
            "vat_price": [
                782
            ],
            "total_net_price": [
                782
            ],
            "unit": [
                782
            ],
            "unit_shipment_cost": [
                782
            ],
            "sent_unit": [
                782
            ],
            "waiting_unit": [
                782
            ],
            "features": [
                689
            ],
            "notes1": [
                615
            ],
            "notes2": [
                615
            ],
            "userId": [
                617
            ],
            "productId": [
                617
            ],
            "orderItemStatusId": [
                617
            ],
            "shipment_id": [
                617
            ],
            "is_deleted": [
                627
            ],
            "shipment_method": [
                1150
            ],
            "cart_id": [
                617
            ],
            "orderId": [
                617
            ],
            "__typename": [
                9
            ]
        },
        "EnumShipmentMethodNullableWithAggregatesFilter": {
            "equals": [
                116
            ],
            "in": [
                116
            ],
            "notIn": [
                116
            ],
            "not": [
                1151
            ],
            "_count": [
                24
            ],
            "_min": [
                117
            ],
            "_max": [
                117
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumShipmentMethodNullableWithAggregatesFilter": {
            "equals": [
                116
            ],
            "in": [
                116
            ],
            "notIn": [
                116
            ],
            "not": [
                1151
            ],
            "_count": [
                24
            ],
            "_min": [
                117
            ],
            "_max": [
                117
            ],
            "__typename": [
                9
            ]
        },
        "AggregateOrderItemStatus": {
            "_count": [
                1153
            ],
            "_avg": [
                1154
            ],
            "_sum": [
                1155
            ],
            "_min": [
                1156
            ],
            "_max": [
                1157
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCountAggregate": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                3
            ],
            "active": [
                3
            ],
            "code": [
                3
            ],
            "created_at": [
                3
            ],
            "created_by": [
                3
            ],
            "name": [
                3
            ],
            "out_ref": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "updated_at": [
                3
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusAvgAggregate": {
            "id": [
                5
            ],
            "hierarchy": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "orderStatusId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusSumAggregate": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusMinAggregate": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusMaxAggregate": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusGroupBy": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1153
            ],
            "_avg": [
                1154
            ],
            "_sum": [
                1155
            ],
            "_min": [
                1156
            ],
            "_max": [
                1157
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "color": [
                228
            ],
            "active": [
                227
            ],
            "code": [
                228
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "name": [
                228
            ],
            "out_ref": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "orderStatusId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1160
            ],
            "_avg": [
                1161
            ],
            "_max": [
                1162
            ],
            "_min": [
                1163
            ],
            "_sum": [
                1164
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "color": [
                227
            ],
            "active": [
                227
            ],
            "code": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "orderStatusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "orderStatusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "color": [
                227
            ],
            "active": [
                227
            ],
            "code": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "orderStatusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "color": [
                227
            ],
            "active": [
                227
            ],
            "code": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "name": [
                227
            ],
            "out_ref": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "orderStatusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "orderStatusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusScalarWhereWithAggregatesInput": {
            "AND": [
                1165
            ],
            "OR": [
                1165
            ],
            "NOT": [
                1165
            ],
            "id": [
                610
            ],
            "hierarchy": [
                610
            ],
            "color": [
                615
            ],
            "active": [
                613
            ],
            "code": [
                615
            ],
            "created_at": [
                619
            ],
            "created_by": [
                617
            ],
            "name": [
                615
            ],
            "out_ref": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "updated_at": [
                619
            ],
            "updated_by": [
                617
            ],
            "orderStatusId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateOrderStatus": {
            "_count": [
                1167
            ],
            "_avg": [
                1168
            ],
            "_sum": [
                1169
            ],
            "_min": [
                1170
            ],
            "_max": [
                1171
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                3
            ],
            "name": [
                3
            ],
            "slug": [
                3
            ],
            "updated_at": [
                3
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusAvgAggregate": {
            "id": [
                5
            ],
            "arrangement": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusSumAggregate": {
            "id": [
                3
            ],
            "arrangement": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                206
            ],
            "OR": [
                206
            ],
            "NOT": [
                206
            ],
            "active": [
                18
            ],
            "arrangement": [
                16
            ],
            "created_at": [
                25
            ],
            "name": [
                20
            ],
            "slug": [
                20
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "OrderItemStatus": [
                207
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusScalarFieldEnum": {},
        "OrderStatusGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1167
            ],
            "_avg": [
                1168
            ],
            "_sum": [
                1169
            ],
            "_min": [
                1170
            ],
            "_max": [
                1171
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "arrangement": [
                227
            ],
            "created_at": [
                227
            ],
            "name": [
                228
            ],
            "slug": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1176
            ],
            "_avg": [
                1177
            ],
            "_max": [
                1178
            ],
            "_min": [
                1179
            ],
            "_sum": [
                1180
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "arrangement": [
                227
            ],
            "created_at": [
                227
            ],
            "name": [
                227
            ],
            "slug": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "arrangement": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "arrangement": [
                227
            ],
            "created_at": [
                227
            ],
            "name": [
                227
            ],
            "slug": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "arrangement": [
                227
            ],
            "created_at": [
                227
            ],
            "name": [
                227
            ],
            "slug": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "arrangement": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusScalarWhereWithAggregatesInput": {
            "AND": [
                1181
            ],
            "OR": [
                1181
            ],
            "NOT": [
                1181
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "arrangement": [
                610
            ],
            "created_at": [
                619
            ],
            "name": [
                615
            ],
            "slug": [
                615
            ],
            "updated_at": [
                619
            ],
            "updated_by": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateOrigin": {
            "_count": [
                1183
            ],
            "_avg": [
                1184
            ],
            "_sum": [
                1185
            ],
            "_min": [
                1186
            ],
            "_max": [
                1187
            ],
            "__typename": [
                9
            ]
        },
        "OriginCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "domain": [
                3
            ],
            "description": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OriginAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "OriginSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OriginMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginWhereInput": {
            "AND": [
                1188
            ],
            "OR": [
                1188
            ],
            "NOT": [
                1188
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "domain": [
                20
            ],
            "description": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "OriginOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "domain": [
                228
            ],
            "description": [
                228
            ],
            "_relevance": [
                1190
            ],
            "__typename": [
                9
            ]
        },
        "OriginOrderByRelevanceInput": {
            "fields": [
                1191
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginOrderByRelevanceFieldEnum": {},
        "OriginWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                1188
            ],
            "OR": [
                1188
            ],
            "NOT": [
                1188
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "domain": [
                20
            ],
            "description": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "Origin": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginScalarFieldEnum": {},
        "OriginGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "_count": [
                1183
            ],
            "_avg": [
                1184
            ],
            "_sum": [
                1185
            ],
            "_min": [
                1186
            ],
            "_max": [
                1187
            ],
            "__typename": [
                9
            ]
        },
        "OriginOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "domain": [
                228
            ],
            "description": [
                228
            ],
            "_count": [
                1197
            ],
            "_avg": [
                1198
            ],
            "_max": [
                1199
            ],
            "_min": [
                1200
            ],
            "_sum": [
                1201
            ],
            "__typename": [
                9
            ]
        },
        "OriginCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "domain": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OriginAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OriginMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "domain": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OriginMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "domain": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OriginSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "OriginScalarWhereWithAggregatesInput": {
            "AND": [
                1202
            ],
            "OR": [
                1202
            ],
            "NOT": [
                1202
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "domain": [
                615
            ],
            "description": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePayment": {
            "_count": [
                1204
            ],
            "_avg": [
                1205
            ],
            "_sum": [
                1206
            ],
            "_min": [
                1207
            ],
            "_max": [
                1208
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "order_key": [
                3
            ],
            "price": [
                3
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                3
            ],
            "payment_status": [
                3
            ],
            "payment_desc": [
                3
            ],
            "description": [
                3
            ],
            "card_owner": [
                3
            ],
            "card_number": [
                3
            ],
            "card_type": [
                3
            ],
            "card_association": [
                3
            ],
            "sent_at": [
                3
            ],
            "sent_by": [
                3
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                3
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                3
            ],
            "is_sent_erp": [
                3
            ],
            "bank_response": [
                3
            ],
            "ip": [
                3
            ],
            "req_absolute_path": [
                3
            ],
            "gateway_type": [
                3
            ],
            "temp_field": [
                3
            ],
            "is_sent_status": [
                3
            ],
            "payment_key": [
                3
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "price": [
                46
            ],
            "installment": [
                5
            ],
            "fraud_status": [
                5
            ],
            "user_id": [
                5
            ],
            "method_id": [
                5
            ],
            "pos_account_id": [
                5
            ],
            "company_id": [
                5
            ],
            "bankId": [
                5
            ],
            "payment_event_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "bankId": [
                3
            ],
            "payment_event_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "_count": [
                1204
            ],
            "_avg": [
                1205
            ],
            "_sum": [
                1206
            ],
            "_min": [
                1207
            ],
            "_max": [
                1208
            ],
            "__typename": [
                9
            ]
        },
        "PaymentOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "order_key": [
                228
            ],
            "price": [
                228
            ],
            "installment": [
                228
            ],
            "fraud_status": [
                228
            ],
            "payment_id": [
                228
            ],
            "payment_status": [
                228
            ],
            "payment_desc": [
                228
            ],
            "description": [
                228
            ],
            "card_owner": [
                228
            ],
            "card_number": [
                228
            ],
            "card_type": [
                228
            ],
            "card_association": [
                228
            ],
            "sent_at": [
                228
            ],
            "sent_by": [
                228
            ],
            "user_id": [
                228
            ],
            "method_id": [
                228
            ],
            "pos_account_id": [
                228
            ],
            "company_id": [
                228
            ],
            "auth_code": [
                228
            ],
            "bankId": [
                228
            ],
            "bank_message": [
                228
            ],
            "is_sent_erp": [
                228
            ],
            "bank_response": [
                228
            ],
            "ip": [
                228
            ],
            "req_absolute_path": [
                228
            ],
            "gateway_type": [
                228
            ],
            "temp_field": [
                228
            ],
            "is_sent_status": [
                228
            ],
            "payment_key": [
                228
            ],
            "payment_event_id": [
                228
            ],
            "outer_payment_key": [
                228
            ],
            "_count": [
                1211
            ],
            "_avg": [
                1212
            ],
            "_max": [
                1213
            ],
            "_min": [
                1214
            ],
            "_sum": [
                1215
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "order_key": [
                227
            ],
            "price": [
                227
            ],
            "installment": [
                227
            ],
            "fraud_status": [
                227
            ],
            "payment_id": [
                227
            ],
            "payment_status": [
                227
            ],
            "payment_desc": [
                227
            ],
            "description": [
                227
            ],
            "card_owner": [
                227
            ],
            "card_number": [
                227
            ],
            "card_type": [
                227
            ],
            "card_association": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "user_id": [
                227
            ],
            "method_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "company_id": [
                227
            ],
            "auth_code": [
                227
            ],
            "bankId": [
                227
            ],
            "bank_message": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "bank_response": [
                227
            ],
            "ip": [
                227
            ],
            "req_absolute_path": [
                227
            ],
            "gateway_type": [
                227
            ],
            "temp_field": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "payment_key": [
                227
            ],
            "payment_event_id": [
                227
            ],
            "outer_payment_key": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "installment": [
                227
            ],
            "fraud_status": [
                227
            ],
            "user_id": [
                227
            ],
            "method_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "company_id": [
                227
            ],
            "bankId": [
                227
            ],
            "payment_event_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "order_key": [
                227
            ],
            "price": [
                227
            ],
            "installment": [
                227
            ],
            "fraud_status": [
                227
            ],
            "payment_id": [
                227
            ],
            "payment_status": [
                227
            ],
            "payment_desc": [
                227
            ],
            "description": [
                227
            ],
            "card_owner": [
                227
            ],
            "card_number": [
                227
            ],
            "card_type": [
                227
            ],
            "card_association": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "user_id": [
                227
            ],
            "method_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "company_id": [
                227
            ],
            "auth_code": [
                227
            ],
            "bankId": [
                227
            ],
            "bank_message": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "ip": [
                227
            ],
            "req_absolute_path": [
                227
            ],
            "gateway_type": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "payment_key": [
                227
            ],
            "payment_event_id": [
                227
            ],
            "outer_payment_key": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "order_key": [
                227
            ],
            "price": [
                227
            ],
            "installment": [
                227
            ],
            "fraud_status": [
                227
            ],
            "payment_id": [
                227
            ],
            "payment_status": [
                227
            ],
            "payment_desc": [
                227
            ],
            "description": [
                227
            ],
            "card_owner": [
                227
            ],
            "card_number": [
                227
            ],
            "card_type": [
                227
            ],
            "card_association": [
                227
            ],
            "sent_at": [
                227
            ],
            "sent_by": [
                227
            ],
            "user_id": [
                227
            ],
            "method_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "company_id": [
                227
            ],
            "auth_code": [
                227
            ],
            "bankId": [
                227
            ],
            "bank_message": [
                227
            ],
            "is_sent_erp": [
                227
            ],
            "ip": [
                227
            ],
            "req_absolute_path": [
                227
            ],
            "gateway_type": [
                227
            ],
            "is_sent_status": [
                227
            ],
            "payment_key": [
                227
            ],
            "payment_event_id": [
                227
            ],
            "outer_payment_key": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "installment": [
                227
            ],
            "fraud_status": [
                227
            ],
            "user_id": [
                227
            ],
            "method_id": [
                227
            ],
            "pos_account_id": [
                227
            ],
            "company_id": [
                227
            ],
            "bankId": [
                227
            ],
            "payment_event_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentScalarWhereWithAggregatesInput": {
            "AND": [
                1216
            ],
            "OR": [
                1216
            ],
            "NOT": [
                1216
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "order_key": [
                615
            ],
            "price": [
                782
            ],
            "installment": [
                617
            ],
            "fraud_status": [
                617
            ],
            "payment_id": [
                615
            ],
            "payment_status": [
                1217
            ],
            "payment_desc": [
                615
            ],
            "description": [
                615
            ],
            "card_owner": [
                615
            ],
            "card_number": [
                615
            ],
            "card_type": [
                615
            ],
            "card_association": [
                615
            ],
            "sent_at": [
                780
            ],
            "sent_by": [
                615
            ],
            "user_id": [
                617
            ],
            "method_id": [
                617
            ],
            "pos_account_id": [
                617
            ],
            "company_id": [
                617
            ],
            "auth_code": [
                615
            ],
            "bankId": [
                617
            ],
            "bank_message": [
                615
            ],
            "is_sent_erp": [
                627
            ],
            "bank_response": [
                689
            ],
            "ip": [
                615
            ],
            "req_absolute_path": [
                615
            ],
            "gateway_type": [
                1219
            ],
            "temp_field": [
                689
            ],
            "is_sent_status": [
                1134
            ],
            "payment_key": [
                615
            ],
            "payment_event_id": [
                617
            ],
            "outer_payment_key": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "EnumPaymentStatusNullableWithAggregatesFilter": {
            "equals": [
                76
            ],
            "in": [
                76
            ],
            "notIn": [
                76
            ],
            "not": [
                1218
            ],
            "_count": [
                24
            ],
            "_min": [
                77
            ],
            "_max": [
                77
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumPaymentStatusNullableWithAggregatesFilter": {
            "equals": [
                76
            ],
            "in": [
                76
            ],
            "notIn": [
                76
            ],
            "not": [
                1218
            ],
            "_count": [
                24
            ],
            "_min": [
                77
            ],
            "_max": [
                77
            ],
            "__typename": [
                9
            ]
        },
        "EnumGatewayTypeNullableWithAggregatesFilter": {
            "equals": [
                81
            ],
            "in": [
                81
            ],
            "notIn": [
                81
            ],
            "not": [
                1220
            ],
            "_count": [
                24
            ],
            "_min": [
                82
            ],
            "_max": [
                82
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumGatewayTypeNullableWithAggregatesFilter": {
            "equals": [
                81
            ],
            "in": [
                81
            ],
            "notIn": [
                81
            ],
            "not": [
                1220
            ],
            "_count": [
                24
            ],
            "_min": [
                82
            ],
            "_max": [
                82
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePaymentCheck": {
            "_count": [
                1222
            ],
            "_avg": [
                1223
            ],
            "_sum": [
                1224
            ],
            "_min": [
                1225
            ],
            "_max": [
                1226
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "description": [
                3
            ],
            "amount": [
                3
            ],
            "bank_response": [
                3
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                3
            ],
            "check_status": [
                3
            ],
            "payment_status": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "amount": [
                46
            ],
            "payment_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "amount": [
                46
            ],
            "payment_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "_count": [
                1222
            ],
            "_avg": [
                1223
            ],
            "_sum": [
                1224
            ],
            "_min": [
                1225
            ],
            "_max": [
                1226
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                228
            ],
            "amount": [
                228
            ],
            "bank_response": [
                228
            ],
            "payment_id": [
                228
            ],
            "reference_number": [
                228
            ],
            "check_status": [
                228
            ],
            "payment_status": [
                228
            ],
            "_count": [
                1229
            ],
            "_avg": [
                1230
            ],
            "_max": [
                1231
            ],
            "_min": [
                1232
            ],
            "_sum": [
                1233
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "amount": [
                227
            ],
            "bank_response": [
                227
            ],
            "payment_id": [
                227
            ],
            "reference_number": [
                227
            ],
            "check_status": [
                227
            ],
            "payment_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "amount": [
                227
            ],
            "payment_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "amount": [
                227
            ],
            "payment_id": [
                227
            ],
            "reference_number": [
                227
            ],
            "check_status": [
                227
            ],
            "payment_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "amount": [
                227
            ],
            "payment_id": [
                227
            ],
            "reference_number": [
                227
            ],
            "check_status": [
                227
            ],
            "payment_status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "amount": [
                227
            ],
            "payment_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckScalarWhereWithAggregatesInput": {
            "AND": [
                1234
            ],
            "OR": [
                1234
            ],
            "NOT": [
                1234
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "description": [
                615
            ],
            "amount": [
                782
            ],
            "bank_response": [
                689
            ],
            "payment_id": [
                617
            ],
            "reference_number": [
                615
            ],
            "check_status": [
                1235
            ],
            "payment_status": [
                1217
            ],
            "__typename": [
                9
            ]
        },
        "EnumPaymentCheckStatusNullableWithAggregatesFilter": {
            "equals": [
                111
            ],
            "in": [
                111
            ],
            "notIn": [
                111
            ],
            "not": [
                1236
            ],
            "_count": [
                24
            ],
            "_min": [
                112
            ],
            "_max": [
                112
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumPaymentCheckStatusNullableWithAggregatesFilter": {
            "equals": [
                111
            ],
            "in": [
                111
            ],
            "notIn": [
                111
            ],
            "not": [
                1236
            ],
            "_count": [
                24
            ],
            "_min": [
                112
            ],
            "_max": [
                112
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePaymentGroup": {
            "_count": [
                1238
            ],
            "_avg": [
                1239
            ],
            "_sum": [
                1240
            ],
            "_min": [
                1241
            ],
            "_max": [
                1242
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCountAggregate": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                3
            ],
            "payment_id": [
                3
            ],
            "description": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupAvgAggregate": {
            "id": [
                5
            ],
            "order_id": [
                5
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupSumAggregate": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupMinAggregate": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupMaxAggregate": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupGroupBy": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "_count": [
                1238
            ],
            "_avg": [
                1239
            ],
            "_sum": [
                1240
            ],
            "_min": [
                1241
            ],
            "_max": [
                1242
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "order_id": [
                228
            ],
            "paid_price": [
                228
            ],
            "payment_id": [
                228
            ],
            "description": [
                228
            ],
            "_count": [
                1245
            ],
            "_avg": [
                1246
            ],
            "_max": [
                1247
            ],
            "_min": [
                1248
            ],
            "_sum": [
                1249
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "order_id": [
                227
            ],
            "paid_price": [
                227
            ],
            "payment_id": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "order_id": [
                227
            ],
            "paid_price": [
                227
            ],
            "payment_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "order_id": [
                227
            ],
            "paid_price": [
                227
            ],
            "payment_id": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "order_id": [
                227
            ],
            "paid_price": [
                227
            ],
            "payment_id": [
                227
            ],
            "description": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "order_id": [
                227
            ],
            "paid_price": [
                227
            ],
            "payment_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupScalarWhereWithAggregatesInput": {
            "AND": [
                1250
            ],
            "OR": [
                1250
            ],
            "NOT": [
                1250
            ],
            "id": [
                610
            ],
            "order_id": [
                617
            ],
            "paid_price": [
                782
            ],
            "payment_id": [
                617
            ],
            "description": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePaymentMethod": {
            "_count": [
                1252
            ],
            "_avg": [
                1253
            ],
            "_sum": [
                1254
            ],
            "_min": [
                1255
            ],
            "_max": [
                1256
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "minimum": [
                3
            ],
            "maximum": [
                3
            ],
            "title": [
                3
            ],
            "is_default": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                104
            ],
            "OR": [
                104
            ],
            "NOT": [
                104
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "minimum": [
                45
            ],
            "maximum": [
                45
            ],
            "title": [
                20
            ],
            "is_default": [
                33
            ],
            "CompanyToPaymentMethod": [
                105
            ],
            "Payment": [
                87
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodScalarFieldEnum": {},
        "PaymentMethodGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "_count": [
                1252
            ],
            "_avg": [
                1253
            ],
            "_sum": [
                1254
            ],
            "_min": [
                1255
            ],
            "_max": [
                1256
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "minimum": [
                228
            ],
            "maximum": [
                228
            ],
            "title": [
                228
            ],
            "is_default": [
                228
            ],
            "_count": [
                1261
            ],
            "_avg": [
                1262
            ],
            "_max": [
                1263
            ],
            "_min": [
                1264
            ],
            "_sum": [
                1265
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "minimum": [
                227
            ],
            "maximum": [
                227
            ],
            "title": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "minimum": [
                227
            ],
            "maximum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "minimum": [
                227
            ],
            "maximum": [
                227
            ],
            "title": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "minimum": [
                227
            ],
            "maximum": [
                227
            ],
            "title": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "minimum": [
                227
            ],
            "maximum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodScalarWhereWithAggregatesInput": {
            "AND": [
                1266
            ],
            "OR": [
                1266
            ],
            "NOT": [
                1266
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "minimum": [
                782
            ],
            "maximum": [
                782
            ],
            "title": [
                615
            ],
            "is_default": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePaymentSystem": {
            "_count": [
                1268
            ],
            "_avg": [
                1269
            ],
            "_sum": [
                1270
            ],
            "_min": [
                1271
            ],
            "_max": [
                1272
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                99
            ],
            "OR": [
                99
            ],
            "NOT": [
                99
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "PosAccount": [
                88
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemScalarFieldEnum": {},
        "PaymentSystemGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "_count": [
                1268
            ],
            "_avg": [
                1269
            ],
            "_sum": [
                1270
            ],
            "_min": [
                1271
            ],
            "_max": [
                1272
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "_count": [
                1277
            ],
            "_avg": [
                1278
            ],
            "_max": [
                1279
            ],
            "_min": [
                1280
            ],
            "_sum": [
                1281
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemScalarWhereWithAggregatesInput": {
            "AND": [
                1282
            ],
            "OR": [
                1282
            ],
            "NOT": [
                1282
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePermission": {
            "_count": [
                1284
            ],
            "_avg": [
                1285
            ],
            "_sum": [
                1286
            ],
            "_min": [
                1287
            ],
            "_max": [
                1288
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "is_deleted": [
                3
            ],
            "source": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PermissionAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PermissionSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PermissionMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "PermissionMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "PermissionGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                1284
            ],
            "_avg": [
                1285
            ],
            "_sum": [
                1286
            ],
            "_min": [
                1287
            ],
            "_max": [
                1288
            ],
            "__typename": [
                9
            ]
        },
        "PermissionOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "_count": [
                1291
            ],
            "_avg": [
                1292
            ],
            "_max": [
                1293
            ],
            "_min": [
                1294
            ],
            "_sum": [
                1295
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PermissionScalarWhereWithAggregatesInput": {
            "AND": [
                1296
            ],
            "OR": [
                1296
            ],
            "NOT": [
                1296
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "is_deleted": [
                627
            ],
            "source": [
                621
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePlugin": {
            "_count": [
                1298
            ],
            "_avg": [
                1299
            ],
            "_sum": [
                1300
            ],
            "_min": [
                1301
            ],
            "_max": [
                1302
            ],
            "__typename": [
                9
            ]
        },
        "PluginCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "start_date": [
                3
            ],
            "end_date": [
                3
            ],
            "image": [
                3
            ],
            "repo": [
                3
            ],
            "firm": [
                3
            ],
            "credentials": [
                3
            ],
            "hierarchy": [
                3
            ],
            "description": [
                3
            ],
            "category": [
                3
            ],
            "module": [
                3
            ],
            "props": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PluginAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "hierarchy": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PluginSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PluginMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PluginMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PluginWhereInput": {
            "AND": [
                1303
            ],
            "OR": [
                1303
            ],
            "NOT": [
                1303
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "start_date": [
                43
            ],
            "end_date": [
                43
            ],
            "image": [
                20
            ],
            "repo": [
                20
            ],
            "firm": [
                20
            ],
            "credentials": [
                78
            ],
            "hierarchy": [
                23
            ],
            "description": [
                20
            ],
            "category": [
                20
            ],
            "module": [
                20
            ],
            "props": [
                78
            ],
            "__typename": [
                9
            ]
        },
        "PluginOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "start_date": [
                228
            ],
            "end_date": [
                228
            ],
            "image": [
                228
            ],
            "repo": [
                228
            ],
            "firm": [
                228
            ],
            "credentials": [
                228
            ],
            "hierarchy": [
                228
            ],
            "description": [
                228
            ],
            "category": [
                228
            ],
            "module": [
                228
            ],
            "props": [
                228
            ],
            "_relevance": [
                1305
            ],
            "__typename": [
                9
            ]
        },
        "PluginOrderByRelevanceInput": {
            "fields": [
                1306
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PluginOrderByRelevanceFieldEnum": {},
        "PluginWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                1303
            ],
            "OR": [
                1303
            ],
            "NOT": [
                1303
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "start_date": [
                43
            ],
            "end_date": [
                43
            ],
            "image": [
                20
            ],
            "repo": [
                20
            ],
            "firm": [
                20
            ],
            "credentials": [
                78
            ],
            "hierarchy": [
                23
            ],
            "description": [
                20
            ],
            "category": [
                20
            ],
            "module": [
                20
            ],
            "props": [
                78
            ],
            "__typename": [
                9
            ]
        },
        "Plugin": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "props": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "PluginScalarFieldEnum": {},
        "PluginGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "props": [
                79
            ],
            "_count": [
                1298
            ],
            "_avg": [
                1299
            ],
            "_sum": [
                1300
            ],
            "_min": [
                1301
            ],
            "_max": [
                1302
            ],
            "__typename": [
                9
            ]
        },
        "PluginOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "start_date": [
                228
            ],
            "end_date": [
                228
            ],
            "image": [
                228
            ],
            "repo": [
                228
            ],
            "firm": [
                228
            ],
            "credentials": [
                228
            ],
            "hierarchy": [
                228
            ],
            "description": [
                228
            ],
            "category": [
                228
            ],
            "module": [
                228
            ],
            "props": [
                228
            ],
            "_count": [
                1312
            ],
            "_avg": [
                1313
            ],
            "_max": [
                1314
            ],
            "_min": [
                1315
            ],
            "_sum": [
                1316
            ],
            "__typename": [
                9
            ]
        },
        "PluginCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "image": [
                227
            ],
            "repo": [
                227
            ],
            "firm": [
                227
            ],
            "credentials": [
                227
            ],
            "hierarchy": [
                227
            ],
            "description": [
                227
            ],
            "category": [
                227
            ],
            "module": [
                227
            ],
            "props": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PluginAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PluginMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "image": [
                227
            ],
            "repo": [
                227
            ],
            "firm": [
                227
            ],
            "hierarchy": [
                227
            ],
            "description": [
                227
            ],
            "category": [
                227
            ],
            "module": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PluginMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "start_date": [
                227
            ],
            "end_date": [
                227
            ],
            "image": [
                227
            ],
            "repo": [
                227
            ],
            "firm": [
                227
            ],
            "hierarchy": [
                227
            ],
            "description": [
                227
            ],
            "category": [
                227
            ],
            "module": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PluginSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "hierarchy": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PluginScalarWhereWithAggregatesInput": {
            "AND": [
                1317
            ],
            "OR": [
                1317
            ],
            "NOT": [
                1317
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "start_date": [
                780
            ],
            "end_date": [
                780
            ],
            "image": [
                615
            ],
            "repo": [
                615
            ],
            "firm": [
                615
            ],
            "credentials": [
                689
            ],
            "hierarchy": [
                617
            ],
            "description": [
                615
            ],
            "category": [
                615
            ],
            "module": [
                615
            ],
            "props": [
                689
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePosAccount": {
            "_count": [
                1319
            ],
            "_avg": [
                1320
            ],
            "_sum": [
                1321
            ],
            "_min": [
                1322
            ],
            "_max": [
                1323
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                3
            ],
            "image": [
                3
            ],
            "pos_props": [
                3
            ],
            "credentials": [
                3
            ],
            "agencies": [
                3
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                3
            ],
            "minimum": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "bank_id": [
                5
            ],
            "Payment_system_id": [
                5
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "bank_id": [
                3
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "_count": [
                1319
            ],
            "_avg": [
                1320
            ],
            "_sum": [
                1321
            ],
            "_min": [
                1322
            ],
            "_max": [
                1323
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "bank_id": [
                228
            ],
            "is_default": [
                228
            ],
            "image": [
                228
            ],
            "pos_props": [
                228
            ],
            "credentials": [
                228
            ],
            "agencies": [
                228
            ],
            "Payment_system_id": [
                228
            ],
            "maximum": [
                228
            ],
            "minimum": [
                228
            ],
            "_count": [
                1326
            ],
            "_avg": [
                1327
            ],
            "_max": [
                1328
            ],
            "_min": [
                1329
            ],
            "_sum": [
                1330
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "bank_id": [
                227
            ],
            "is_default": [
                227
            ],
            "image": [
                227
            ],
            "pos_props": [
                227
            ],
            "credentials": [
                227
            ],
            "agencies": [
                227
            ],
            "Payment_system_id": [
                227
            ],
            "maximum": [
                227
            ],
            "minimum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "bank_id": [
                227
            ],
            "Payment_system_id": [
                227
            ],
            "maximum": [
                227
            ],
            "minimum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "bank_id": [
                227
            ],
            "is_default": [
                227
            ],
            "image": [
                227
            ],
            "Payment_system_id": [
                227
            ],
            "maximum": [
                227
            ],
            "minimum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "bank_id": [
                227
            ],
            "is_default": [
                227
            ],
            "image": [
                227
            ],
            "Payment_system_id": [
                227
            ],
            "maximum": [
                227
            ],
            "minimum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "bank_id": [
                227
            ],
            "Payment_system_id": [
                227
            ],
            "maximum": [
                227
            ],
            "minimum": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountScalarWhereWithAggregatesInput": {
            "AND": [
                1331
            ],
            "OR": [
                1331
            ],
            "NOT": [
                1331
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "bank_id": [
                617
            ],
            "is_default": [
                627
            ],
            "image": [
                615
            ],
            "pos_props": [
                689
            ],
            "credentials": [
                689
            ],
            "agencies": [
                689
            ],
            "Payment_system_id": [
                617
            ],
            "maximum": [
                782
            ],
            "minimum": [
                782
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePosConfig": {
            "_count": [
                1333
            ],
            "_avg": [
                1334
            ],
            "_sum": [
                1335
            ],
            "_min": [
                1336
            ],
            "_max": [
                1337
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCountAggregate": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigAvgAggregate": {
            "id": [
                5
            ],
            "bank_id": [
                5
            ],
            "pos_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigSumAggregate": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigMinAggregate": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigMaxAggregate": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigGroupBy": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "_count": [
                1333
            ],
            "_avg": [
                1334
            ],
            "_sum": [
                1335
            ],
            "_min": [
                1336
            ],
            "_max": [
                1337
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "bank_id": [
                228
            ],
            "pos_id": [
                228
            ],
            "is_default": [
                228
            ],
            "_count": [
                1340
            ],
            "_avg": [
                1341
            ],
            "_max": [
                1342
            ],
            "_min": [
                1343
            ],
            "_sum": [
                1344
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "pos_id": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "pos_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "pos_id": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "pos_id": [
                227
            ],
            "is_default": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "bank_id": [
                227
            ],
            "pos_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigScalarWhereWithAggregatesInput": {
            "AND": [
                1345
            ],
            "OR": [
                1345
            ],
            "NOT": [
                1345
            ],
            "id": [
                610
            ],
            "bank_id": [
                617
            ],
            "pos_id": [
                617
            ],
            "is_default": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePrice": {
            "_count": [
                1347
            ],
            "_avg": [
                1348
            ],
            "_sum": [
                1349
            ],
            "_min": [
                1350
            ],
            "_max": [
                1351
            ],
            "__typename": [
                9
            ]
        },
        "PriceCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "price": [
                3
            ],
            "price_discounted": [
                3
            ],
            "retail": [
                3
            ],
            "project_name": [
                3
            ],
            "project_code": [
                3
            ],
            "discount_rate": [
                3
            ],
            "price_type": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PriceAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "discount_rate": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PriceSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "discount_rate": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PriceMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                145
            ],
            "OR": [
                145
            ],
            "NOT": [
                145
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "price": [
                45
            ],
            "price_discounted": [
                45
            ],
            "retail": [
                45
            ],
            "project_name": [
                20
            ],
            "project_code": [
                20
            ],
            "discount_rate": [
                45
            ],
            "price_type": [
                20
            ],
            "is_deleted": [
                33
            ],
            "PriceToCategory": [
                141
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "PriceScalarFieldEnum": {},
        "PriceGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1347
            ],
            "_avg": [
                1348
            ],
            "_sum": [
                1349
            ],
            "_min": [
                1350
            ],
            "_max": [
                1351
            ],
            "__typename": [
                9
            ]
        },
        "PriceOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "price": [
                228
            ],
            "price_discounted": [
                228
            ],
            "retail": [
                228
            ],
            "project_name": [
                228
            ],
            "project_code": [
                228
            ],
            "discount_rate": [
                228
            ],
            "price_type": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1356
            ],
            "_avg": [
                1357
            ],
            "_max": [
                1358
            ],
            "_min": [
                1359
            ],
            "_sum": [
                1360
            ],
            "__typename": [
                9
            ]
        },
        "PriceCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "price": [
                227
            ],
            "price_discounted": [
                227
            ],
            "retail": [
                227
            ],
            "project_name": [
                227
            ],
            "project_code": [
                227
            ],
            "discount_rate": [
                227
            ],
            "price_type": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "price_discounted": [
                227
            ],
            "retail": [
                227
            ],
            "discount_rate": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "price": [
                227
            ],
            "price_discounted": [
                227
            ],
            "retail": [
                227
            ],
            "project_name": [
                227
            ],
            "project_code": [
                227
            ],
            "discount_rate": [
                227
            ],
            "price_type": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "price": [
                227
            ],
            "price_discounted": [
                227
            ],
            "retail": [
                227
            ],
            "project_name": [
                227
            ],
            "project_code": [
                227
            ],
            "discount_rate": [
                227
            ],
            "price_type": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "price": [
                227
            ],
            "price_discounted": [
                227
            ],
            "retail": [
                227
            ],
            "discount_rate": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceScalarWhereWithAggregatesInput": {
            "AND": [
                1361
            ],
            "OR": [
                1361
            ],
            "NOT": [
                1361
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "price": [
                782
            ],
            "price_discounted": [
                782
            ],
            "retail": [
                782
            ],
            "project_name": [
                615
            ],
            "project_code": [
                615
            ],
            "discount_rate": [
                782
            ],
            "price_type": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregatePriceToCategory": {
            "_count": [
                1363
            ],
            "_avg": [
                1364
            ],
            "_sum": [
                1365
            ],
            "_min": [
                1366
            ],
            "_max": [
                1367
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCountAggregate": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                3
            ],
            "created_at": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                3
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryAvgAggregate": {
            "price_id": [
                5
            ],
            "category_id": [
                5
            ],
            "companyId": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategorySumAggregate": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryMinAggregate": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryMaxAggregate": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryGroupBy": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1363
            ],
            "_avg": [
                1364
            ],
            "_sum": [
                1365
            ],
            "_min": [
                1366
            ],
            "_max": [
                1367
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryOrderByWithAggregationInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                228
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                228
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1370
            ],
            "_avg": [
                1371
            ],
            "_max": [
                1372
            ],
            "_min": [
                1373
            ],
            "_sum": [
                1374
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCountOrderByAggregateInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryAvgOrderByAggregateInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryMaxOrderByAggregateInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryMinOrderByAggregateInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_at": [
                227
            ],
            "updated_by": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategorySumOrderByAggregateInput": {
            "price_id": [
                227
            ],
            "category_id": [
                227
            ],
            "companyId": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryScalarWhereWithAggregatesInput": {
            "AND": [
                1375
            ],
            "OR": [
                1375
            ],
            "NOT": [
                1375
            ],
            "price_id": [
                610
            ],
            "category_id": [
                610
            ],
            "companyId": [
                617
            ],
            "active": [
                613
            ],
            "created_at": [
                619
            ],
            "created_by": [
                617
            ],
            "updated_at": [
                619
            ],
            "updated_by": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateProduct": {
            "_count": [
                1377
            ],
            "_avg": [
                1378
            ],
            "_sum": [
                1379
            ],
            "_min": [
                1380
            ],
            "_max": [
                1381
            ],
            "__typename": [
                9
            ]
        },
        "ProductCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "group": [
                3
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                3
            ],
            "on_sale": [
                3
            ],
            "rating": [
                3
            ],
            "vat": [
                3
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                3
            ],
            "favorite_count": [
                3
            ],
            "in_stock": [
                3
            ],
            "unit": [
                3
            ],
            "unit_ref": [
                3
            ],
            "unit_code": [
                3
            ],
            "is_shipping_required": [
                3
            ],
            "tag": [
                3
            ],
            "cargo_fee": [
                3
            ],
            "features": [
                3
            ],
            "platform": [
                3
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                3
            ],
            "title": [
                3
            ],
            "has_features": [
                3
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "out_name": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "brand_id": [
                5
            ],
            "category_id": [
                5
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                5
            ],
            "sold_quantity": [
                5
            ],
            "review_count": [
                5
            ],
            "unit": [
                46
            ],
            "cargo_fee": [
                46
            ],
            "type_id": [
                5
            ],
            "priceId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ProductSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "unit": [
                46
            ],
            "cargo_fee": [
                46
            ],
            "type_id": [
                3
            ],
            "priceId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                79
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "_count": [
                1377
            ],
            "_avg": [
                1378
            ],
            "_sum": [
                1379
            ],
            "_min": [
                1380
            ],
            "_max": [
                1381
            ],
            "__typename": [
                9
            ]
        },
        "ProductOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "group": [
                228
            ],
            "brand_id": [
                228
            ],
            "category_id": [
                228
            ],
            "negative_sale": [
                228
            ],
            "on_sale": [
                228
            ],
            "rating": [
                228
            ],
            "vat": [
                228
            ],
            "sold_count": [
                228
            ],
            "sold_quantity": [
                228
            ],
            "review_count": [
                228
            ],
            "description": [
                228
            ],
            "favorite_count": [
                228
            ],
            "in_stock": [
                228
            ],
            "unit": [
                228
            ],
            "unit_ref": [
                228
            ],
            "unit_code": [
                228
            ],
            "is_shipping_required": [
                228
            ],
            "tag": [
                228
            ],
            "cargo_fee": [
                228
            ],
            "features": [
                227
            ],
            "platform": [
                228
            ],
            "type_id": [
                228
            ],
            "unit_name": [
                228
            ],
            "title": [
                228
            ],
            "has_features": [
                228
            ],
            "priceId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "out_name": [
                228
            ],
            "_count": [
                1384
            ],
            "_avg": [
                1385
            ],
            "_max": [
                1386
            ],
            "_min": [
                1387
            ],
            "_sum": [
                1388
            ],
            "__typename": [
                9
            ]
        },
        "ProductCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "group": [
                227
            ],
            "brand_id": [
                227
            ],
            "category_id": [
                227
            ],
            "negative_sale": [
                227
            ],
            "on_sale": [
                227
            ],
            "rating": [
                227
            ],
            "vat": [
                227
            ],
            "sold_count": [
                227
            ],
            "sold_quantity": [
                227
            ],
            "review_count": [
                227
            ],
            "description": [
                227
            ],
            "favorite_count": [
                227
            ],
            "in_stock": [
                227
            ],
            "unit": [
                227
            ],
            "unit_ref": [
                227
            ],
            "unit_code": [
                227
            ],
            "is_shipping_required": [
                227
            ],
            "tag": [
                227
            ],
            "cargo_fee": [
                227
            ],
            "features": [
                227
            ],
            "platform": [
                227
            ],
            "type_id": [
                227
            ],
            "unit_name": [
                227
            ],
            "title": [
                227
            ],
            "has_features": [
                227
            ],
            "priceId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "out_name": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "brand_id": [
                227
            ],
            "category_id": [
                227
            ],
            "rating": [
                227
            ],
            "vat": [
                227
            ],
            "sold_count": [
                227
            ],
            "sold_quantity": [
                227
            ],
            "review_count": [
                227
            ],
            "unit": [
                227
            ],
            "cargo_fee": [
                227
            ],
            "type_id": [
                227
            ],
            "priceId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "group": [
                227
            ],
            "brand_id": [
                227
            ],
            "category_id": [
                227
            ],
            "negative_sale": [
                227
            ],
            "on_sale": [
                227
            ],
            "rating": [
                227
            ],
            "vat": [
                227
            ],
            "sold_count": [
                227
            ],
            "sold_quantity": [
                227
            ],
            "review_count": [
                227
            ],
            "description": [
                227
            ],
            "favorite_count": [
                227
            ],
            "in_stock": [
                227
            ],
            "unit": [
                227
            ],
            "unit_ref": [
                227
            ],
            "unit_code": [
                227
            ],
            "is_shipping_required": [
                227
            ],
            "tag": [
                227
            ],
            "cargo_fee": [
                227
            ],
            "platform": [
                227
            ],
            "type_id": [
                227
            ],
            "unit_name": [
                227
            ],
            "title": [
                227
            ],
            "has_features": [
                227
            ],
            "priceId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "out_name": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "group": [
                227
            ],
            "brand_id": [
                227
            ],
            "category_id": [
                227
            ],
            "negative_sale": [
                227
            ],
            "on_sale": [
                227
            ],
            "rating": [
                227
            ],
            "vat": [
                227
            ],
            "sold_count": [
                227
            ],
            "sold_quantity": [
                227
            ],
            "review_count": [
                227
            ],
            "description": [
                227
            ],
            "favorite_count": [
                227
            ],
            "in_stock": [
                227
            ],
            "unit": [
                227
            ],
            "unit_ref": [
                227
            ],
            "unit_code": [
                227
            ],
            "is_shipping_required": [
                227
            ],
            "tag": [
                227
            ],
            "cargo_fee": [
                227
            ],
            "platform": [
                227
            ],
            "type_id": [
                227
            ],
            "unit_name": [
                227
            ],
            "title": [
                227
            ],
            "has_features": [
                227
            ],
            "priceId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "out_name": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "brand_id": [
                227
            ],
            "category_id": [
                227
            ],
            "rating": [
                227
            ],
            "vat": [
                227
            ],
            "sold_count": [
                227
            ],
            "sold_quantity": [
                227
            ],
            "review_count": [
                227
            ],
            "unit": [
                227
            ],
            "cargo_fee": [
                227
            ],
            "type_id": [
                227
            ],
            "priceId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductScalarWhereWithAggregatesInput": {
            "AND": [
                1389
            ],
            "OR": [
                1389
            ],
            "NOT": [
                1389
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "group": [
                615
            ],
            "brand_id": [
                617
            ],
            "category_id": [
                617
            ],
            "negative_sale": [
                627
            ],
            "on_sale": [
                627
            ],
            "rating": [
                1390
            ],
            "vat": [
                782
            ],
            "sold_count": [
                617
            ],
            "sold_quantity": [
                617
            ],
            "review_count": [
                617
            ],
            "description": [
                615
            ],
            "favorite_count": [
                627
            ],
            "in_stock": [
                627
            ],
            "unit": [
                782
            ],
            "unit_ref": [
                615
            ],
            "unit_code": [
                615
            ],
            "is_shipping_required": [
                627
            ],
            "tag": [
                615
            ],
            "cargo_fee": [
                782
            ],
            "features": [
                125
            ],
            "platform": [
                623
            ],
            "type_id": [
                617
            ],
            "unit_name": [
                615
            ],
            "title": [
                615
            ],
            "has_features": [
                627
            ],
            "priceId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "out_name": [
                615
            ],
            "__typename": [
                9
            ]
        },
        "FloatNullableWithAggregatesFilter": {
            "equals": [
                5
            ],
            "in": [
                5
            ],
            "notIn": [
                5
            ],
            "lt": [
                5
            ],
            "lte": [
                5
            ],
            "gt": [
                5
            ],
            "gte": [
                5
            ],
            "not": [
                1391
            ],
            "_count": [
                24
            ],
            "_avg": [
                124
            ],
            "_sum": [
                124
            ],
            "_min": [
                124
            ],
            "_max": [
                124
            ],
            "__typename": [
                9
            ]
        },
        "NestedFloatNullableWithAggregatesFilter": {
            "equals": [
                5
            ],
            "in": [
                5
            ],
            "notIn": [
                5
            ],
            "lt": [
                5
            ],
            "lte": [
                5
            ],
            "gt": [
                5
            ],
            "gte": [
                5
            ],
            "not": [
                1391
            ],
            "_count": [
                24
            ],
            "_avg": [
                124
            ],
            "_sum": [
                124
            ],
            "_min": [
                124
            ],
            "_max": [
                124
            ],
            "__typename": [
                9
            ]
        },
        "AggregateProductLot": {
            "_count": [
                1393
            ],
            "_avg": [
                1394
            ],
            "_sum": [
                1395
            ],
            "_min": [
                1396
            ],
            "_max": [
                1397
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "product_id": [
                3
            ],
            "quantity": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "product_id": [
                5
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1393
            ],
            "_avg": [
                1394
            ],
            "_sum": [
                1395
            ],
            "_min": [
                1396
            ],
            "_max": [
                1397
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "product_id": [
                228
            ],
            "quantity": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1400
            ],
            "_avg": [
                1401
            ],
            "_max": [
                1402
            ],
            "_min": [
                1403
            ],
            "_sum": [
                1404
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotScalarWhereWithAggregatesInput": {
            "AND": [
                1405
            ],
            "OR": [
                1405
            ],
            "NOT": [
                1405
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "product_id": [
                617
            ],
            "quantity": [
                782
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateProductStock": {
            "_count": [
                1407
            ],
            "_avg": [
                1408
            ],
            "_sum": [
                1409
            ],
            "_min": [
                1410
            ],
            "_max": [
                1411
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "product_id": [
                3
            ],
            "quantity": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "product_id": [
                5
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1407
            ],
            "_avg": [
                1408
            ],
            "_sum": [
                1409
            ],
            "_min": [
                1410
            ],
            "_max": [
                1411
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "product_id": [
                228
            ],
            "quantity": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1414
            ],
            "_avg": [
                1415
            ],
            "_max": [
                1416
            ],
            "_min": [
                1417
            ],
            "_sum": [
                1418
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "product_id": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockScalarWhereWithAggregatesInput": {
            "AND": [
                1419
            ],
            "OR": [
                1419
            ],
            "NOT": [
                1419
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "product_id": [
                617
            ],
            "quantity": [
                782
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateProductType": {
            "_count": [
                1421
            ],
            "_avg": [
                1422
            ],
            "_sum": [
                1423
            ],
            "_min": [
                1424
            ],
            "_max": [
                1425
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "is_deleted": [
                3
            ],
            "source": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "AND": [
                165
            ],
            "OR": [
                165
            ],
            "NOT": [
                165
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "AttributeToProductType": [
                166
            ],
            "Product": [
                138
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeScalarFieldEnum": {},
        "ProductTypeGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "_count": [
                1421
            ],
            "_avg": [
                1422
            ],
            "_sum": [
                1423
            ],
            "_min": [
                1424
            ],
            "_max": [
                1425
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "is_deleted": [
                228
            ],
            "source": [
                227
            ],
            "_count": [
                1430
            ],
            "_avg": [
                1431
            ],
            "_max": [
                1432
            ],
            "_min": [
                1433
            ],
            "_sum": [
                1434
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "is_deleted": [
                227
            ],
            "source": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeScalarWhereWithAggregatesInput": {
            "AND": [
                1435
            ],
            "OR": [
                1435
            ],
            "NOT": [
                1435
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "is_deleted": [
                627
            ],
            "source": [
                621
            ],
            "__typename": [
                9
            ]
        },
        "AggregateRole": {
            "_count": [
                1437
            ],
            "_avg": [
                1438
            ],
            "_sum": [
                1439
            ],
            "_min": [
                1440
            ],
            "_max": [
                1441
            ],
            "__typename": [
                9
            ]
        },
        "RoleCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "type": [
                3
            ],
            "description": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "RoleAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "RoleSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "RoleMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "RoleMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "RoleGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1437
            ],
            "_avg": [
                1438
            ],
            "_sum": [
                1439
            ],
            "_min": [
                1440
            ],
            "_max": [
                1441
            ],
            "__typename": [
                9
            ]
        },
        "RoleOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "type": [
                227
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1444
            ],
            "_avg": [
                1445
            ],
            "_max": [
                1446
            ],
            "_min": [
                1447
            ],
            "_sum": [
                1448
            ],
            "__typename": [
                9
            ]
        },
        "RoleCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "type": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "type": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "type": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "RoleScalarWhereWithAggregatesInput": {
            "AND": [
                1449
            ],
            "OR": [
                1449
            ],
            "NOT": [
                1449
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "type": [
                1450
            ],
            "description": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumRolesWithAggregatesFilter": {
            "equals": [
                211
            ],
            "in": [
                211
            ],
            "notIn": [
                211
            ],
            "not": [
                1451
            ],
            "_count": [
                17
            ],
            "_min": [
                212
            ],
            "_max": [
                212
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumRolesWithAggregatesFilter": {
            "equals": [
                211
            ],
            "in": [
                211
            ],
            "notIn": [
                211
            ],
            "not": [
                1451
            ],
            "_count": [
                17
            ],
            "_min": [
                212
            ],
            "_max": [
                212
            ],
            "__typename": [
                9
            ]
        },
        "AggregateShipment": {
            "_count": [
                1453
            ],
            "_avg": [
                1454
            ],
            "_sum": [
                1455
            ],
            "_min": [
                1456
            ],
            "_max": [
                1457
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                3
            ],
            "tracking_url": [
                3
            ],
            "date_of_shipment": [
                3
            ],
            "date_of_delivery": [
                3
            ],
            "other_info": [
                3
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                3
            ],
            "shipment_cost_status": [
                3
            ],
            "shipment_method": [
                3
            ],
            "quantity": [
                3
            ],
            "referance_code": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "cargo_agency_id": [
                5
            ],
            "cargo_order": [
                5
            ],
            "shipment_status_id": [
                5
            ],
            "shipment_cost": [
                46
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "cargo_agency_id": [
                3
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "quantity": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1453
            ],
            "_avg": [
                1454
            ],
            "_sum": [
                1455
            ],
            "_min": [
                1456
            ],
            "_max": [
                1457
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "cargo_agency_id": [
                228
            ],
            "tracking_code": [
                228
            ],
            "tracking_url": [
                228
            ],
            "date_of_shipment": [
                228
            ],
            "date_of_delivery": [
                228
            ],
            "other_info": [
                228
            ],
            "cargo_order": [
                228
            ],
            "shipment_status_id": [
                228
            ],
            "shipment_cost": [
                228
            ],
            "shipment_cost_status": [
                228
            ],
            "shipment_method": [
                228
            ],
            "quantity": [
                228
            ],
            "referance_code": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1460
            ],
            "_avg": [
                1461
            ],
            "_max": [
                1462
            ],
            "_min": [
                1463
            ],
            "_sum": [
                1464
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "cargo_agency_id": [
                227
            ],
            "tracking_code": [
                227
            ],
            "tracking_url": [
                227
            ],
            "date_of_shipment": [
                227
            ],
            "date_of_delivery": [
                227
            ],
            "other_info": [
                227
            ],
            "cargo_order": [
                227
            ],
            "shipment_status_id": [
                227
            ],
            "shipment_cost": [
                227
            ],
            "shipment_cost_status": [
                227
            ],
            "shipment_method": [
                227
            ],
            "quantity": [
                227
            ],
            "referance_code": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "cargo_agency_id": [
                227
            ],
            "cargo_order": [
                227
            ],
            "shipment_status_id": [
                227
            ],
            "shipment_cost": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "cargo_agency_id": [
                227
            ],
            "tracking_code": [
                227
            ],
            "tracking_url": [
                227
            ],
            "date_of_shipment": [
                227
            ],
            "date_of_delivery": [
                227
            ],
            "other_info": [
                227
            ],
            "cargo_order": [
                227
            ],
            "shipment_status_id": [
                227
            ],
            "shipment_cost": [
                227
            ],
            "shipment_cost_status": [
                227
            ],
            "shipment_method": [
                227
            ],
            "quantity": [
                227
            ],
            "referance_code": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "cargo_agency_id": [
                227
            ],
            "tracking_code": [
                227
            ],
            "tracking_url": [
                227
            ],
            "date_of_shipment": [
                227
            ],
            "date_of_delivery": [
                227
            ],
            "other_info": [
                227
            ],
            "cargo_order": [
                227
            ],
            "shipment_status_id": [
                227
            ],
            "shipment_cost": [
                227
            ],
            "shipment_cost_status": [
                227
            ],
            "shipment_method": [
                227
            ],
            "quantity": [
                227
            ],
            "referance_code": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "cargo_agency_id": [
                227
            ],
            "cargo_order": [
                227
            ],
            "shipment_status_id": [
                227
            ],
            "shipment_cost": [
                227
            ],
            "quantity": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentScalarWhereWithAggregatesInput": {
            "AND": [
                1465
            ],
            "OR": [
                1465
            ],
            "NOT": [
                1465
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "cargo_agency_id": [
                617
            ],
            "tracking_code": [
                615
            ],
            "tracking_url": [
                615
            ],
            "date_of_shipment": [
                780
            ],
            "date_of_delivery": [
                780
            ],
            "other_info": [
                615
            ],
            "cargo_order": [
                617
            ],
            "shipment_status_id": [
                617
            ],
            "shipment_cost": [
                782
            ],
            "shipment_cost_status": [
                1466
            ],
            "shipment_method": [
                1150
            ],
            "quantity": [
                782
            ],
            "referance_code": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumShipmentCostStatusNullableWithAggregatesFilter": {
            "equals": [
                154
            ],
            "in": [
                154
            ],
            "notIn": [
                154
            ],
            "not": [
                1467
            ],
            "_count": [
                24
            ],
            "_min": [
                155
            ],
            "_max": [
                155
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumShipmentCostStatusNullableWithAggregatesFilter": {
            "equals": [
                154
            ],
            "in": [
                154
            ],
            "notIn": [
                154
            ],
            "not": [
                1467
            ],
            "_count": [
                24
            ],
            "_min": [
                155
            ],
            "_max": [
                155
            ],
            "__typename": [
                9
            ]
        },
        "AggregateShipmentEvent": {
            "_count": [
                1469
            ],
            "_avg": [
                1470
            ],
            "_sum": [
                1471
            ],
            "_min": [
                1472
            ],
            "_max": [
                1473
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "eventDateTime": [
                3
            ],
            "deliveryDateTime": [
                3
            ],
            "deliveryTo": [
                3
            ],
            "description": [
                3
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                3
            ],
            "locationPhone": [
                3
            ],
            "private": [
                3
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                3
            ],
            "locationAddress": [
                3
            ],
            "trackingCode": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "pieceTotal": [
                5
            ],
            "shipmentId": [
                5
            ],
            "statusId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "pieceTotal": [
                3
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1469
            ],
            "_avg": [
                1470
            ],
            "_sum": [
                1471
            ],
            "_min": [
                1472
            ],
            "_max": [
                1473
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "eventDateTime": [
                228
            ],
            "deliveryDateTime": [
                228
            ],
            "deliveryTo": [
                228
            ],
            "description": [
                228
            ],
            "pieceTotal": [
                228
            ],
            "location": [
                228
            ],
            "locationPhone": [
                228
            ],
            "private": [
                228
            ],
            "shipmentId": [
                228
            ],
            "statusId": [
                228
            ],
            "isRead": [
                228
            ],
            "locationAddress": [
                228
            ],
            "trackingCode": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1476
            ],
            "_avg": [
                1477
            ],
            "_max": [
                1478
            ],
            "_min": [
                1479
            ],
            "_sum": [
                1480
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "eventDateTime": [
                227
            ],
            "deliveryDateTime": [
                227
            ],
            "deliveryTo": [
                227
            ],
            "description": [
                227
            ],
            "pieceTotal": [
                227
            ],
            "location": [
                227
            ],
            "locationPhone": [
                227
            ],
            "private": [
                227
            ],
            "shipmentId": [
                227
            ],
            "statusId": [
                227
            ],
            "isRead": [
                227
            ],
            "locationAddress": [
                227
            ],
            "trackingCode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "pieceTotal": [
                227
            ],
            "shipmentId": [
                227
            ],
            "statusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "eventDateTime": [
                227
            ],
            "deliveryDateTime": [
                227
            ],
            "deliveryTo": [
                227
            ],
            "description": [
                227
            ],
            "pieceTotal": [
                227
            ],
            "location": [
                227
            ],
            "locationPhone": [
                227
            ],
            "private": [
                227
            ],
            "shipmentId": [
                227
            ],
            "statusId": [
                227
            ],
            "isRead": [
                227
            ],
            "locationAddress": [
                227
            ],
            "trackingCode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "eventDateTime": [
                227
            ],
            "deliveryDateTime": [
                227
            ],
            "deliveryTo": [
                227
            ],
            "description": [
                227
            ],
            "pieceTotal": [
                227
            ],
            "location": [
                227
            ],
            "locationPhone": [
                227
            ],
            "private": [
                227
            ],
            "shipmentId": [
                227
            ],
            "statusId": [
                227
            ],
            "isRead": [
                227
            ],
            "locationAddress": [
                227
            ],
            "trackingCode": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "pieceTotal": [
                227
            ],
            "shipmentId": [
                227
            ],
            "statusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventScalarWhereWithAggregatesInput": {
            "AND": [
                1481
            ],
            "OR": [
                1481
            ],
            "NOT": [
                1481
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "eventDateTime": [
                780
            ],
            "deliveryDateTime": [
                780
            ],
            "deliveryTo": [
                615
            ],
            "description": [
                615
            ],
            "pieceTotal": [
                617
            ],
            "location": [
                615
            ],
            "locationPhone": [
                615
            ],
            "private": [
                627
            ],
            "shipmentId": [
                617
            ],
            "statusId": [
                617
            ],
            "isRead": [
                627
            ],
            "locationAddress": [
                615
            ],
            "trackingCode": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateShipmentStatus": {
            "_count": [
                1483
            ],
            "_avg": [
                1484
            ],
            "_sum": [
                1485
            ],
            "_min": [
                1486
            ],
            "_max": [
                1487
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "description": [
                3
            ],
            "color": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                150
            ],
            "OR": [
                150
            ],
            "NOT": [
                150
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "description": [
                20
            ],
            "color": [
                20
            ],
            "is_deleted": [
                33
            ],
            "image": [
                131
            ],
            "Shipment": [
                151
            ],
            "ShipmentEvent": [
                158
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusScalarFieldEnum": {},
        "ShipmentStatusGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1483
            ],
            "_avg": [
                1484
            ],
            "_sum": [
                1485
            ],
            "_min": [
                1486
            ],
            "_max": [
                1487
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "description": [
                228
            ],
            "color": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1492
            ],
            "_avg": [
                1493
            ],
            "_max": [
                1494
            ],
            "_min": [
                1495
            ],
            "_sum": [
                1496
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "description": [
                227
            ],
            "color": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "description": [
                227
            ],
            "color": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "description": [
                227
            ],
            "color": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusScalarWhereWithAggregatesInput": {
            "AND": [
                1497
            ],
            "OR": [
                1497
            ],
            "NOT": [
                1497
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "description": [
                615
            ],
            "color": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupport": {
            "_count": [
                1499
            ],
            "_avg": [
                1500
            ],
            "_sum": [
                1501
            ],
            "_min": [
                1502
            ],
            "_max": [
                1503
            ],
            "__typename": [
                9
            ]
        },
        "SupportCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "description": [
                3
            ],
            "status": [
                3
            ],
            "type": [
                3
            ],
            "priority": [
                3
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "companyId": [
                5
            ],
            "assignedToId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                183
            ],
            "OR": [
                183
            ],
            "NOT": [
                183
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "status": [
                184
            ],
            "type": [
                187
            ],
            "priority": [
                190
            ],
            "companyId": [
                23
            ],
            "assignedToId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "SupportAttachment": [
                180
            ],
            "SupportItem": [
                178
            ],
            "SupportMessage": [
                193
            ],
            "__typename": [
                9
            ]
        },
        "SupportScalarFieldEnum": {},
        "SupportGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1499
            ],
            "_avg": [
                1500
            ],
            "_sum": [
                1501
            ],
            "_min": [
                1502
            ],
            "_max": [
                1503
            ],
            "__typename": [
                9
            ]
        },
        "SupportOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "status": [
                228
            ],
            "type": [
                228
            ],
            "priority": [
                228
            ],
            "companyId": [
                228
            ],
            "assignedToId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1508
            ],
            "_avg": [
                1509
            ],
            "_max": [
                1510
            ],
            "_min": [
                1511
            ],
            "_sum": [
                1512
            ],
            "__typename": [
                9
            ]
        },
        "SupportCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "status": [
                227
            ],
            "type": [
                227
            ],
            "priority": [
                227
            ],
            "companyId": [
                227
            ],
            "assignedToId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "companyId": [
                227
            ],
            "assignedToId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "status": [
                227
            ],
            "type": [
                227
            ],
            "priority": [
                227
            ],
            "companyId": [
                227
            ],
            "assignedToId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "status": [
                227
            ],
            "type": [
                227
            ],
            "priority": [
                227
            ],
            "companyId": [
                227
            ],
            "assignedToId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "companyId": [
                227
            ],
            "assignedToId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportScalarWhereWithAggregatesInput": {
            "AND": [
                1513
            ],
            "OR": [
                1513
            ],
            "NOT": [
                1513
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "description": [
                615
            ],
            "status": [
                1514
            ],
            "type": [
                1516
            ],
            "priority": [
                1518
            ],
            "companyId": [
                617
            ],
            "assignedToId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumSupportStatusNullableWithAggregatesFilter": {
            "equals": [
                185
            ],
            "in": [
                185
            ],
            "notIn": [
                185
            ],
            "not": [
                1515
            ],
            "_count": [
                24
            ],
            "_min": [
                186
            ],
            "_max": [
                186
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSupportStatusNullableWithAggregatesFilter": {
            "equals": [
                185
            ],
            "in": [
                185
            ],
            "notIn": [
                185
            ],
            "not": [
                1515
            ],
            "_count": [
                24
            ],
            "_min": [
                186
            ],
            "_max": [
                186
            ],
            "__typename": [
                9
            ]
        },
        "EnumSupportTypeNullableWithAggregatesFilter": {
            "equals": [
                188
            ],
            "in": [
                188
            ],
            "notIn": [
                188
            ],
            "not": [
                1517
            ],
            "_count": [
                24
            ],
            "_min": [
                189
            ],
            "_max": [
                189
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSupportTypeNullableWithAggregatesFilter": {
            "equals": [
                188
            ],
            "in": [
                188
            ],
            "notIn": [
                188
            ],
            "not": [
                1517
            ],
            "_count": [
                24
            ],
            "_min": [
                189
            ],
            "_max": [
                189
            ],
            "__typename": [
                9
            ]
        },
        "EnumPriorityNullableWithAggregatesFilter": {
            "equals": [
                191
            ],
            "in": [
                191
            ],
            "notIn": [
                191
            ],
            "not": [
                1519
            ],
            "_count": [
                24
            ],
            "_min": [
                192
            ],
            "_max": [
                192
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumPriorityNullableWithAggregatesFilter": {
            "equals": [
                191
            ],
            "in": [
                191
            ],
            "notIn": [
                191
            ],
            "not": [
                1519
            ],
            "_count": [
                24
            ],
            "_min": [
                192
            ],
            "_max": [
                192
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupportAttachment": {
            "_count": [
                1521
            ],
            "_avg": [
                1522
            ],
            "_sum": [
                1523
            ],
            "_min": [
                1524
            ],
            "_max": [
                1525
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "path": [
                3
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "supportId": [
                5
            ],
            "userId": [
                5
            ],
            "supportItemId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1521
            ],
            "_avg": [
                1522
            ],
            "_sum": [
                1523
            ],
            "_min": [
                1524
            ],
            "_max": [
                1525
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "path": [
                228
            ],
            "supportId": [
                228
            ],
            "userId": [
                228
            ],
            "supportItemId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1528
            ],
            "_avg": [
                1529
            ],
            "_max": [
                1530
            ],
            "_min": [
                1531
            ],
            "_sum": [
                1532
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "supportItemId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "supportItemId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "supportItemId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "path": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "supportItemId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "supportItemId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentScalarWhereWithAggregatesInput": {
            "AND": [
                1533
            ],
            "OR": [
                1533
            ],
            "NOT": [
                1533
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "path": [
                615
            ],
            "supportId": [
                617
            ],
            "userId": [
                617
            ],
            "supportItemId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupportEvent": {
            "_count": [
                1535
            ],
            "_avg": [
                1536
            ],
            "_sum": [
                1537
            ],
            "_min": [
                1538
            ],
            "_max": [
                1539
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "description": [
                3
            ],
            "is_read": [
                3
            ],
            "private": [
                3
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "supportItemId": [
                5
            ],
            "statusId": [
                5
            ],
            "messageId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1535
            ],
            "_avg": [
                1536
            ],
            "_sum": [
                1537
            ],
            "_min": [
                1538
            ],
            "_max": [
                1539
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_read": [
                228
            ],
            "private": [
                228
            ],
            "supportItemId": [
                228
            ],
            "statusId": [
                228
            ],
            "messageId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1542
            ],
            "_avg": [
                1543
            ],
            "_max": [
                1544
            ],
            "_min": [
                1545
            ],
            "_sum": [
                1546
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_read": [
                227
            ],
            "private": [
                227
            ],
            "supportItemId": [
                227
            ],
            "statusId": [
                227
            ],
            "messageId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportItemId": [
                227
            ],
            "statusId": [
                227
            ],
            "messageId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_read": [
                227
            ],
            "private": [
                227
            ],
            "supportItemId": [
                227
            ],
            "statusId": [
                227
            ],
            "messageId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_read": [
                227
            ],
            "private": [
                227
            ],
            "supportItemId": [
                227
            ],
            "statusId": [
                227
            ],
            "messageId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportItemId": [
                227
            ],
            "statusId": [
                227
            ],
            "messageId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventScalarWhereWithAggregatesInput": {
            "AND": [
                1547
            ],
            "OR": [
                1547
            ],
            "NOT": [
                1547
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "description": [
                615
            ],
            "is_read": [
                627
            ],
            "private": [
                627
            ],
            "supportItemId": [
                617
            ],
            "statusId": [
                617
            ],
            "messageId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupportItem": {
            "_count": [
                1549
            ],
            "_avg": [
                1550
            ],
            "_sum": [
                1551
            ],
            "_min": [
                1552
            ],
            "_max": [
                1553
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "description": [
                3
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "productId": [
                5
            ],
            "supportId": [
                5
            ],
            "statusId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1549
            ],
            "_avg": [
                1550
            ],
            "_sum": [
                1551
            ],
            "_min": [
                1552
            ],
            "_max": [
                1553
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                228
            ],
            "productId": [
                228
            ],
            "supportId": [
                228
            ],
            "statusId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1556
            ],
            "_avg": [
                1557
            ],
            "_max": [
                1558
            ],
            "_min": [
                1559
            ],
            "_sum": [
                1560
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "productId": [
                227
            ],
            "supportId": [
                227
            ],
            "statusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "productId": [
                227
            ],
            "supportId": [
                227
            ],
            "statusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "productId": [
                227
            ],
            "supportId": [
                227
            ],
            "statusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "description": [
                227
            ],
            "productId": [
                227
            ],
            "supportId": [
                227
            ],
            "statusId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "productId": [
                227
            ],
            "supportId": [
                227
            ],
            "statusId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemScalarWhereWithAggregatesInput": {
            "AND": [
                1561
            ],
            "OR": [
                1561
            ],
            "NOT": [
                1561
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "description": [
                615
            ],
            "productId": [
                617
            ],
            "supportId": [
                617
            ],
            "statusId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupportItemStatus": {
            "_count": [
                1563
            ],
            "_avg": [
                1564
            ],
            "_sum": [
                1565
            ],
            "_min": [
                1566
            ],
            "_max": [
                1567
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "source": [
                3
            ],
            "platform": [
                3
            ],
            "description": [
                3
            ],
            "is_deleted": [
                3
            ],
            "status": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusWhereUniqueInput": {
            "id": [
                3
            ],
            "out_ref": [
                9
            ],
            "AND": [
                199
            ],
            "OR": [
                199
            ],
            "NOT": [
                199
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "status": [
                184
            ],
            "SupportEvent": [
                195
            ],
            "SupportItem": [
                178
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusScalarFieldEnum": {},
        "SupportItemStatusGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "_count": [
                1563
            ],
            "_avg": [
                1564
            ],
            "_sum": [
                1565
            ],
            "_min": [
                1566
            ],
            "_max": [
                1567
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "source": [
                227
            ],
            "platform": [
                228
            ],
            "description": [
                228
            ],
            "is_deleted": [
                228
            ],
            "status": [
                228
            ],
            "_count": [
                1572
            ],
            "_avg": [
                1573
            ],
            "_max": [
                1574
            ],
            "_min": [
                1575
            ],
            "_sum": [
                1576
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "source": [
                227
            ],
            "platform": [
                227
            ],
            "description": [
                227
            ],
            "is_deleted": [
                227
            ],
            "status": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusScalarWhereWithAggregatesInput": {
            "AND": [
                1577
            ],
            "OR": [
                1577
            ],
            "NOT": [
                1577
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "source": [
                621
            ],
            "platform": [
                623
            ],
            "description": [
                615
            ],
            "is_deleted": [
                627
            ],
            "status": [
                1514
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSupportMessage": {
            "_count": [
                1579
            ],
            "_avg": [
                1580
            ],
            "_sum": [
                1581
            ],
            "_min": [
                1582
            ],
            "_max": [
                1583
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "message": [
                3
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "supportId": [
                5
            ],
            "userId": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1579
            ],
            "_avg": [
                1580
            ],
            "_sum": [
                1581
            ],
            "_min": [
                1582
            ],
            "_max": [
                1583
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "message": [
                228
            ],
            "supportId": [
                228
            ],
            "userId": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1586
            ],
            "_avg": [
                1587
            ],
            "_max": [
                1588
            ],
            "_min": [
                1589
            ],
            "_sum": [
                1590
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "message": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "message": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "message": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "supportId": [
                227
            ],
            "userId": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageScalarWhereWithAggregatesInput": {
            "AND": [
                1591
            ],
            "OR": [
                1591
            ],
            "NOT": [
                1591
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "message": [
                615
            ],
            "supportId": [
                617
            ],
            "userId": [
                617
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "AggregateSyncronize": {
            "_count": [
                1593
            ],
            "_avg": [
                1594
            ],
            "_sum": [
                1595
            ],
            "_min": [
                1596
            ],
            "_max": [
                1599
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "name": [
                3
            ],
            "erp": [
                3
            ],
            "type": [
                3
            ],
            "started": [
                3
            ],
            "updated": [
                3
            ],
            "sync_by": [
                3
            ],
            "hierarchy": [
                3
            ],
            "state": [
                3
            ],
            "data": [
                3
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeAvgAggregate": {
            "id": [
                5
            ],
            "hierarchy": [
                5
            ],
            "total_records": [
                5
            ],
            "done_records": [
                5
            ],
            "error_records": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeSumAggregate": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncType": {},
        "CronExpression": {},
        "SyncronizeMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeWhereInput": {
            "AND": [
                1600
            ],
            "OR": [
                1600
            ],
            "NOT": [
                1600
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                61
            ],
            "erp": [
                20
            ],
            "type": [
                1601
            ],
            "started": [
                43
            ],
            "updated": [
                43
            ],
            "sync_by": [
                20
            ],
            "hierarchy": [
                23
            ],
            "state": [
                20
            ],
            "data": [
                78
            ],
            "total_records": [
                23
            ],
            "done_records": [
                23
            ],
            "error_records": [
                23
            ],
            "cron": [
                1603
            ],
            "__typename": [
                9
            ]
        },
        "EnumSyncTypeNullableFilter": {
            "equals": [
                1597
            ],
            "in": [
                1597
            ],
            "notIn": [
                1597
            ],
            "not": [
                1602
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSyncTypeNullableFilter": {
            "equals": [
                1597
            ],
            "in": [
                1597
            ],
            "notIn": [
                1597
            ],
            "not": [
                1602
            ],
            "__typename": [
                9
            ]
        },
        "EnumCronExpressionNullableFilter": {
            "equals": [
                1598
            ],
            "in": [
                1598
            ],
            "notIn": [
                1598
            ],
            "not": [
                1604
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumCronExpressionNullableFilter": {
            "equals": [
                1598
            ],
            "in": [
                1598
            ],
            "notIn": [
                1598
            ],
            "not": [
                1604
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "erp": [
                228
            ],
            "type": [
                228
            ],
            "started": [
                228
            ],
            "updated": [
                228
            ],
            "sync_by": [
                228
            ],
            "hierarchy": [
                228
            ],
            "state": [
                228
            ],
            "data": [
                228
            ],
            "total_records": [
                228
            ],
            "done_records": [
                228
            ],
            "error_records": [
                228
            ],
            "cron": [
                228
            ],
            "_relevance": [
                1606
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeOrderByRelevanceInput": {
            "fields": [
                1607
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeOrderByRelevanceFieldEnum": {},
        "SyncronizeWhereUniqueInput": {
            "id": [
                3
            ],
            "name": [
                9
            ],
            "AND": [
                1600
            ],
            "OR": [
                1600
            ],
            "NOT": [
                1600
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "erp": [
                20
            ],
            "type": [
                1601
            ],
            "started": [
                43
            ],
            "updated": [
                43
            ],
            "sync_by": [
                20
            ],
            "hierarchy": [
                23
            ],
            "state": [
                20
            ],
            "data": [
                78
            ],
            "total_records": [
                23
            ],
            "done_records": [
                23
            ],
            "error_records": [
                23
            ],
            "cron": [
                1603
            ],
            "__typename": [
                9
            ]
        },
        "Syncronize": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "data": [
                79
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeScalarFieldEnum": {},
        "SyncronizeGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "data": [
                79
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "_count": [
                1593
            ],
            "_avg": [
                1594
            ],
            "_sum": [
                1595
            ],
            "_min": [
                1596
            ],
            "_max": [
                1599
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "erp": [
                228
            ],
            "type": [
                228
            ],
            "started": [
                228
            ],
            "updated": [
                228
            ],
            "sync_by": [
                228
            ],
            "hierarchy": [
                228
            ],
            "state": [
                228
            ],
            "data": [
                228
            ],
            "total_records": [
                228
            ],
            "done_records": [
                228
            ],
            "error_records": [
                228
            ],
            "cron": [
                228
            ],
            "_count": [
                1613
            ],
            "_avg": [
                1614
            ],
            "_max": [
                1615
            ],
            "_min": [
                1616
            ],
            "_sum": [
                1617
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "erp": [
                227
            ],
            "type": [
                227
            ],
            "started": [
                227
            ],
            "updated": [
                227
            ],
            "sync_by": [
                227
            ],
            "hierarchy": [
                227
            ],
            "state": [
                227
            ],
            "data": [
                227
            ],
            "total_records": [
                227
            ],
            "done_records": [
                227
            ],
            "error_records": [
                227
            ],
            "cron": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "total_records": [
                227
            ],
            "done_records": [
                227
            ],
            "error_records": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "erp": [
                227
            ],
            "type": [
                227
            ],
            "started": [
                227
            ],
            "updated": [
                227
            ],
            "sync_by": [
                227
            ],
            "hierarchy": [
                227
            ],
            "state": [
                227
            ],
            "total_records": [
                227
            ],
            "done_records": [
                227
            ],
            "error_records": [
                227
            ],
            "cron": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "name": [
                227
            ],
            "erp": [
                227
            ],
            "type": [
                227
            ],
            "started": [
                227
            ],
            "updated": [
                227
            ],
            "sync_by": [
                227
            ],
            "hierarchy": [
                227
            ],
            "state": [
                227
            ],
            "total_records": [
                227
            ],
            "done_records": [
                227
            ],
            "error_records": [
                227
            ],
            "cron": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "hierarchy": [
                227
            ],
            "total_records": [
                227
            ],
            "done_records": [
                227
            ],
            "error_records": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeScalarWhereWithAggregatesInput": {
            "AND": [
                1618
            ],
            "OR": [
                1618
            ],
            "NOT": [
                1618
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "name": [
                865
            ],
            "erp": [
                615
            ],
            "type": [
                1619
            ],
            "started": [
                780
            ],
            "updated": [
                780
            ],
            "sync_by": [
                615
            ],
            "hierarchy": [
                617
            ],
            "state": [
                615
            ],
            "data": [
                689
            ],
            "total_records": [
                617
            ],
            "done_records": [
                617
            ],
            "error_records": [
                617
            ],
            "cron": [
                1621
            ],
            "__typename": [
                9
            ]
        },
        "EnumSyncTypeNullableWithAggregatesFilter": {
            "equals": [
                1597
            ],
            "in": [
                1597
            ],
            "notIn": [
                1597
            ],
            "not": [
                1620
            ],
            "_count": [
                24
            ],
            "_min": [
                1602
            ],
            "_max": [
                1602
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumSyncTypeNullableWithAggregatesFilter": {
            "equals": [
                1597
            ],
            "in": [
                1597
            ],
            "notIn": [
                1597
            ],
            "not": [
                1620
            ],
            "_count": [
                24
            ],
            "_min": [
                1602
            ],
            "_max": [
                1602
            ],
            "__typename": [
                9
            ]
        },
        "EnumCronExpressionNullableWithAggregatesFilter": {
            "equals": [
                1598
            ],
            "in": [
                1598
            ],
            "notIn": [
                1598
            ],
            "not": [
                1622
            ],
            "_count": [
                24
            ],
            "_min": [
                1604
            ],
            "_max": [
                1604
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumCronExpressionNullableWithAggregatesFilter": {
            "equals": [
                1598
            ],
            "in": [
                1598
            ],
            "notIn": [
                1598
            ],
            "not": [
                1622
            ],
            "_count": [
                24
            ],
            "_min": [
                1604
            ],
            "_max": [
                1604
            ],
            "__typename": [
                9
            ]
        },
        "AggregateTenant": {
            "_count": [
                1624
            ],
            "_avg": [
                1625
            ],
            "_sum": [
                1626
            ],
            "_min": [
                1627
            ],
            "_max": [
                1628
            ],
            "__typename": [
                9
            ]
        },
        "TenantCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "company_id": [
                3
            ],
            "company_name": [
                3
            ],
            "company_code": [
                3
            ],
            "config": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "TenantAvgAggregate": {
            "id": [
                5
            ],
            "company_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "TenantSumAggregate": {
            "id": [
                3
            ],
            "company_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "TenantMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "TenantMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "TenantWhereInput": {
            "AND": [
                1629
            ],
            "OR": [
                1629
            ],
            "NOT": [
                1629
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "company_id": [
                23
            ],
            "company_name": [
                20
            ],
            "company_code": [
                20
            ],
            "config": [
                78
            ],
            "__typename": [
                9
            ]
        },
        "TenantOrderByWithRelationAndSearchRelevanceInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "company_id": [
                228
            ],
            "company_name": [
                228
            ],
            "company_code": [
                228
            ],
            "config": [
                228
            ],
            "_relevance": [
                1631
            ],
            "__typename": [
                9
            ]
        },
        "TenantOrderByRelevanceInput": {
            "fields": [
                1632
            ],
            "sort": [
                227
            ],
            "search": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "TenantOrderByRelevanceFieldEnum": {},
        "TenantWhereUniqueInput": {
            "id": [
                3
            ],
            "AND": [
                1629
            ],
            "OR": [
                1629
            ],
            "NOT": [
                1629
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "company_id": [
                23
            ],
            "company_name": [
                20
            ],
            "company_code": [
                20
            ],
            "config": [
                78
            ],
            "__typename": [
                9
            ]
        },
        "Tenant": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "config": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "TenantScalarFieldEnum": {},
        "TenantGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "config": [
                79
            ],
            "_count": [
                1624
            ],
            "_avg": [
                1625
            ],
            "_sum": [
                1626
            ],
            "_min": [
                1627
            ],
            "_max": [
                1628
            ],
            "__typename": [
                9
            ]
        },
        "TenantOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "company_id": [
                228
            ],
            "company_name": [
                228
            ],
            "company_code": [
                228
            ],
            "config": [
                228
            ],
            "_count": [
                1638
            ],
            "_avg": [
                1639
            ],
            "_max": [
                1640
            ],
            "_min": [
                1641
            ],
            "_sum": [
                1642
            ],
            "__typename": [
                9
            ]
        },
        "TenantCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "company_id": [
                227
            ],
            "company_name": [
                227
            ],
            "company_code": [
                227
            ],
            "config": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "TenantAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "company_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "TenantMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "company_id": [
                227
            ],
            "company_name": [
                227
            ],
            "company_code": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "TenantMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "company_id": [
                227
            ],
            "company_name": [
                227
            ],
            "company_code": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "TenantSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "company_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "TenantScalarWhereWithAggregatesInput": {
            "AND": [
                1643
            ],
            "OR": [
                1643
            ],
            "NOT": [
                1643
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "company_id": [
                617
            ],
            "company_name": [
                615
            ],
            "company_code": [
                615
            ],
            "config": [
                689
            ],
            "__typename": [
                9
            ]
        },
        "AggregateUser": {
            "_count": [
                1645
            ],
            "_avg": [
                1646
            ],
            "_sum": [
                1647
            ],
            "_min": [
                1648
            ],
            "_max": [
                1649
            ],
            "__typename": [
                9
            ]
        },
        "UserCountAggregate": {
            "id": [
                3
            ],
            "active": [
                3
            ],
            "out_ref": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                3
            ],
            "updated_at": [
                3
            ],
            "is_updated": [
                3
            ],
            "name": [
                3
            ],
            "code": [
                3
            ],
            "slug": [
                3
            ],
            "source": [
                3
            ],
            "provider_id": [
                3
            ],
            "first_name": [
                3
            ],
            "last_name": [
                3
            ],
            "gender": [
                3
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                3
            ],
            "gsm": [
                3
            ],
            "password": [
                3
            ],
            "is_deleted": [
                3
            ],
            "_all": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "UserAvgAggregate": {
            "id": [
                5
            ],
            "created_by": [
                5
            ],
            "updated_by": [
                5
            ],
            "default_address_id": [
                5
            ],
            "role_id": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "UserSumAggregate": {
            "id": [
                3
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "UserMinAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserMaxAggregate": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserGroupBy": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "_count": [
                1645
            ],
            "_avg": [
                1646
            ],
            "_sum": [
                1647
            ],
            "_min": [
                1648
            ],
            "_max": [
                1649
            ],
            "__typename": [
                9
            ]
        },
        "UserOrderByWithAggregationInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                228
            ],
            "created_by": [
                228
            ],
            "updated_by": [
                228
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                228
            ],
            "code": [
                228
            ],
            "slug": [
                228
            ],
            "source": [
                227
            ],
            "provider_id": [
                228
            ],
            "first_name": [
                228
            ],
            "last_name": [
                228
            ],
            "gender": [
                228
            ],
            "default_address_id": [
                228
            ],
            "role_id": [
                228
            ],
            "email": [
                228
            ],
            "gsm": [
                228
            ],
            "password": [
                228
            ],
            "is_deleted": [
                228
            ],
            "_count": [
                1652
            ],
            "_avg": [
                1653
            ],
            "_max": [
                1654
            ],
            "_min": [
                1655
            ],
            "_sum": [
                1656
            ],
            "__typename": [
                9
            ]
        },
        "UserCountOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "provider_id": [
                227
            ],
            "first_name": [
                227
            ],
            "last_name": [
                227
            ],
            "gender": [
                227
            ],
            "default_address_id": [
                227
            ],
            "role_id": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "password": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserAvgOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "default_address_id": [
                227
            ],
            "role_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserMaxOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "provider_id": [
                227
            ],
            "first_name": [
                227
            ],
            "last_name": [
                227
            ],
            "gender": [
                227
            ],
            "default_address_id": [
                227
            ],
            "role_id": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "password": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserMinOrderByAggregateInput": {
            "id": [
                227
            ],
            "active": [
                227
            ],
            "out_ref": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "created_at": [
                227
            ],
            "updated_at": [
                227
            ],
            "is_updated": [
                227
            ],
            "name": [
                227
            ],
            "code": [
                227
            ],
            "slug": [
                227
            ],
            "source": [
                227
            ],
            "provider_id": [
                227
            ],
            "first_name": [
                227
            ],
            "last_name": [
                227
            ],
            "gender": [
                227
            ],
            "default_address_id": [
                227
            ],
            "role_id": [
                227
            ],
            "email": [
                227
            ],
            "gsm": [
                227
            ],
            "password": [
                227
            ],
            "is_deleted": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserSumOrderByAggregateInput": {
            "id": [
                227
            ],
            "created_by": [
                227
            ],
            "updated_by": [
                227
            ],
            "default_address_id": [
                227
            ],
            "role_id": [
                227
            ],
            "__typename": [
                9
            ]
        },
        "UserScalarWhereWithAggregatesInput": {
            "AND": [
                1657
            ],
            "OR": [
                1657
            ],
            "NOT": [
                1657
            ],
            "id": [
                610
            ],
            "active": [
                613
            ],
            "out_ref": [
                615
            ],
            "created_by": [
                617
            ],
            "updated_by": [
                617
            ],
            "created_at": [
                619
            ],
            "updated_at": [
                619
            ],
            "is_updated": [
                613
            ],
            "name": [
                615
            ],
            "code": [
                615
            ],
            "slug": [
                615
            ],
            "source": [
                621
            ],
            "provider_id": [
                615
            ],
            "first_name": [
                615
            ],
            "last_name": [
                615
            ],
            "gender": [
                1658
            ],
            "default_address_id": [
                617
            ],
            "role_id": [
                617
            ],
            "email": [
                615
            ],
            "gsm": [
                615
            ],
            "password": [
                615
            ],
            "is_deleted": [
                627
            ],
            "__typename": [
                9
            ]
        },
        "EnumGenderNullableWithAggregatesFilter": {
            "equals": [
                52
            ],
            "in": [
                52
            ],
            "notIn": [
                52
            ],
            "not": [
                1659
            ],
            "_count": [
                24
            ],
            "_min": [
                53
            ],
            "_max": [
                53
            ],
            "__typename": [
                9
            ]
        },
        "NestedEnumGenderNullableWithAggregatesFilter": {
            "equals": [
                52
            ],
            "in": [
                52
            ],
            "notIn": [
                52
            ],
            "not": [
                1659
            ],
            "_count": [
                24
            ],
            "_min": [
                53
            ],
            "_max": [
                53
            ],
            "__typename": [
                9
            ]
        },
        "CategoryStat": {
            "name": [
                9
            ],
            "count": [
                5
            ],
            "sum": [
                5
            ],
            "id": [
                5
            ],
            "url": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "Mutation": {
            "createManyAddress": [
                1662,
                {
                    "data": [
                        1663,
                        "[AddressCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneAddress": [
                254,
                {
                    "data": [
                        1664,
                        "AddressCreateInput!"
                    ]
                }
            ],
            "deleteManyAddress": [
                1662,
                {
                    "where": [
                        15
                    ]
                }
            ],
            "deleteOneAddress": [
                254,
                {
                    "where": [
                        253,
                        "AddressWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyAddress": [
                1662,
                {
                    "data": [
                        2270,
                        "AddressUpdateManyMutationInput!"
                    ],
                    "where": [
                        15
                    ]
                }
            ],
            "updateOneAddress": [
                254,
                {
                    "data": [
                        2279,
                        "AddressUpdateInput!"
                    ],
                    "where": [
                        253,
                        "AddressWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneAddress": [
                254,
                {
                    "where": [
                        253,
                        "AddressWhereUniqueInput!"
                    ],
                    "create": [
                        1664,
                        "AddressCreateInput!"
                    ],
                    "update": [
                        2279,
                        "AddressUpdateInput!"
                    ]
                }
            ],
            "createManyAttribute": [
                1662,
                {
                    "data": [
                        3086,
                        "[AttributeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneAttribute": [
                492,
                {
                    "data": [
                        3087,
                        "AttributeCreateInput!"
                    ]
                }
            ],
            "deleteManyAttribute": [
                1662,
                {
                    "where": [
                        169
                    ]
                }
            ],
            "deleteOneAttribute": [
                492,
                {
                    "where": [
                        635,
                        "AttributeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyAttribute": [
                1662,
                {
                    "data": [
                        3088,
                        "AttributeUpdateManyMutationInput!"
                    ],
                    "where": [
                        169
                    ]
                }
            ],
            "updateOneAttribute": [
                492,
                {
                    "data": [
                        3089,
                        "AttributeUpdateInput!"
                    ],
                    "where": [
                        635,
                        "AttributeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneAttribute": [
                492,
                {
                    "where": [
                        635,
                        "AttributeWhereUniqueInput!"
                    ],
                    "create": [
                        3087,
                        "AttributeCreateInput!"
                    ],
                    "update": [
                        3089,
                        "AttributeUpdateInput!"
                    ]
                }
            ],
            "createManyAttributeToProductType": [
                1662,
                {
                    "data": [
                        3090,
                        "[AttributeToProductTypeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneAttributeToProductType": [
                491,
                {
                    "data": [
                        3091,
                        "AttributeToProductTypeCreateInput!"
                    ]
                }
            ],
            "deleteManyAttributeToProductType": [
                1662,
                {
                    "where": [
                        167
                    ]
                }
            ],
            "deleteOneAttributeToProductType": [
                491,
                {
                    "where": [
                        498,
                        "AttributeToProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyAttributeToProductType": [
                1662,
                {
                    "data": [
                        2930,
                        "AttributeToProductTypeUpdateManyMutationInput!"
                    ],
                    "where": [
                        167
                    ]
                }
            ],
            "updateOneAttributeToProductType": [
                491,
                {
                    "data": [
                        3092,
                        "AttributeToProductTypeUpdateInput!"
                    ],
                    "where": [
                        498,
                        "AttributeToProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneAttributeToProductType": [
                491,
                {
                    "where": [
                        498,
                        "AttributeToProductTypeWhereUniqueInput!"
                    ],
                    "create": [
                        3091,
                        "AttributeToProductTypeCreateInput!"
                    ],
                    "update": [
                        3092,
                        "AttributeToProductTypeUpdateInput!"
                    ]
                }
            ],
            "createManyAttributeValue": [
                1662,
                {
                    "data": [
                        3093,
                        "[AttributeValueCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneAttributeValue": [
                501,
                {
                    "data": [
                        3094,
                        "AttributeValueCreateInput!"
                    ]
                }
            ],
            "deleteManyAttributeValue": [
                1662,
                {
                    "where": [
                        171
                    ]
                }
            ],
            "deleteOneAttributeValue": [
                501,
                {
                    "where": [
                        506,
                        "AttributeValueWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyAttributeValue": [
                1662,
                {
                    "data": [
                        2935,
                        "AttributeValueUpdateManyMutationInput!"
                    ],
                    "where": [
                        171
                    ]
                }
            ],
            "updateOneAttributeValue": [
                501,
                {
                    "data": [
                        3095,
                        "AttributeValueUpdateInput!"
                    ],
                    "where": [
                        506,
                        "AttributeValueWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneAttributeValue": [
                501,
                {
                    "where": [
                        506,
                        "AttributeValueWhereUniqueInput!"
                    ],
                    "create": [
                        3094,
                        "AttributeValueCreateInput!"
                    ],
                    "update": [
                        3095,
                        "AttributeValueUpdateInput!"
                    ]
                }
            ],
            "createManyBank": [
                1662,
                {
                    "data": [
                        3096,
                        "[BankCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneBank": [
                296,
                {
                    "data": [
                        3097,
                        "BankCreateInput!"
                    ]
                }
            ],
            "deleteManyBank": [
                1662,
                {
                    "where": [
                        84
                    ]
                }
            ],
            "deleteOneBank": [
                296,
                {
                    "where": [
                        679,
                        "BankWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyBank": [
                1662,
                {
                    "data": [
                        3098,
                        "BankUpdateManyMutationInput!"
                    ],
                    "where": [
                        84
                    ]
                }
            ],
            "updateOneBank": [
                296,
                {
                    "data": [
                        3099,
                        "BankUpdateInput!"
                    ],
                    "where": [
                        679,
                        "BankWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneBank": [
                296,
                {
                    "where": [
                        679,
                        "BankWhereUniqueInput!"
                    ],
                    "create": [
                        3097,
                        "BankCreateInput!"
                    ],
                    "update": [
                        3099,
                        "BankUpdateInput!"
                    ]
                }
            ],
            "createManyBarcode": [
                1662,
                {
                    "data": [
                        3100,
                        "[BarcodeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneBarcode": [
                379,
                {
                    "data": [
                        3101,
                        "BarcodeCreateInput!"
                    ]
                }
            ],
            "deleteManyBarcode": [
                1662,
                {
                    "where": [
                        127
                    ]
                }
            ],
            "deleteOneBarcode": [
                379,
                {
                    "where": [
                        409,
                        "BarcodeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyBarcode": [
                1662,
                {
                    "data": [
                        2424,
                        "BarcodeUpdateManyMutationInput!"
                    ],
                    "where": [
                        127
                    ]
                }
            ],
            "updateOneBarcode": [
                379,
                {
                    "data": [
                        3105,
                        "BarcodeUpdateInput!"
                    ],
                    "where": [
                        409,
                        "BarcodeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneBarcode": [
                379,
                {
                    "where": [
                        409,
                        "BarcodeWhereUniqueInput!"
                    ],
                    "create": [
                        3101,
                        "BarcodeCreateInput!"
                    ],
                    "update": [
                        3105,
                        "BarcodeUpdateInput!"
                    ]
                }
            ],
            "createManyBinNumber": [
                1662,
                {
                    "data": [
                        3110,
                        "[BinNumberCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneBinNumber": [
                298,
                {
                    "data": [
                        3111
                    ]
                }
            ],
            "deleteManyBinNumber": [
                1662,
                {
                    "where": [
                        86
                    ]
                }
            ],
            "deleteOneBinNumber": [
                298,
                {
                    "where": [
                        308,
                        "BinNumberWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyBinNumber": [
                1662,
                {
                    "data": [
                        2382,
                        "BinNumberUpdateManyMutationInput!"
                    ],
                    "where": [
                        86
                    ]
                }
            ],
            "updateOneBinNumber": [
                298,
                {
                    "data": [
                        3115,
                        "BinNumberUpdateInput!"
                    ],
                    "where": [
                        308,
                        "BinNumberWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneBinNumber": [
                298,
                {
                    "where": [
                        308,
                        "BinNumberWhereUniqueInput!"
                    ],
                    "create": [
                        3111,
                        "BinNumberCreateInput!"
                    ],
                    "update": [
                        3115,
                        "BinNumberUpdateInput!"
                    ]
                }
            ],
            "createManyBrand": [
                1662,
                {
                    "data": [
                        3120,
                        "[BrandCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneBrand": [
                439,
                {
                    "data": [
                        3121,
                        "BrandCreateInput!"
                    ]
                }
            ],
            "deleteManyBrand": [
                1662,
                {
                    "where": [
                        130
                    ]
                }
            ],
            "deleteOneBrand": [
                439,
                {
                    "where": [
                        467,
                        "BrandWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyBrand": [
                1662,
                {
                    "data": [
                        2743,
                        "BrandUpdateManyMutationInput!"
                    ],
                    "where": [
                        130
                    ]
                }
            ],
            "updateOneBrand": [
                439,
                {
                    "data": [
                        3122,
                        "BrandUpdateInput!"
                    ],
                    "where": [
                        467,
                        "BrandWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneBrand": [
                439,
                {
                    "where": [
                        467,
                        "BrandWhereUniqueInput!"
                    ],
                    "create": [
                        3121,
                        "BrandCreateInput!"
                    ],
                    "update": [
                        3122,
                        "BrandUpdateInput!"
                    ]
                }
            ],
            "createManyCampaign": [
                1662,
                {
                    "data": [
                        3123,
                        "[CampaignCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCampaign": [
                443,
                {
                    "data": [
                        3124,
                        "CampaignCreateInput!"
                    ]
                }
            ],
            "deleteManyCampaign": [
                1662,
                {
                    "where": [
                        137
                    ]
                }
            ],
            "deleteOneCampaign": [
                443,
                {
                    "where": [
                        557,
                        "CampaignWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCampaign": [
                1662,
                {
                    "data": [
                        2923,
                        "CampaignUpdateManyMutationInput!"
                    ],
                    "where": [
                        137
                    ]
                }
            ],
            "updateOneCampaign": [
                443,
                {
                    "data": [
                        3125,
                        "CampaignUpdateInput!"
                    ],
                    "where": [
                        557,
                        "CampaignWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCampaign": [
                443,
                {
                    "where": [
                        557,
                        "CampaignWhereUniqueInput!"
                    ],
                    "create": [
                        3124,
                        "CampaignCreateInput!"
                    ],
                    "update": [
                        3125,
                        "CampaignUpdateInput!"
                    ]
                }
            ],
            "createManyCargoAgency": [
                1662,
                {
                    "data": [
                        3126,
                        "[CargoAgencyCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCargoAgency": [
                475,
                {
                    "data": [
                        3127,
                        "CargoAgencyCreateInput!"
                    ]
                }
            ],
            "deleteManyCargoAgency": [
                1662,
                {
                    "where": [
                        157
                    ]
                }
            ],
            "deleteOneCargoAgency": [
                475,
                {
                    "where": [
                        753,
                        "CargoAgencyWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCargoAgency": [
                1662,
                {
                    "data": [
                        3133,
                        "CargoAgencyUpdateManyMutationInput!"
                    ],
                    "where": [
                        157
                    ]
                }
            ],
            "updateOneCargoAgency": [
                475,
                {
                    "data": [
                        3134,
                        "CargoAgencyUpdateInput!"
                    ],
                    "where": [
                        753,
                        "CargoAgencyWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCargoAgency": [
                475,
                {
                    "where": [
                        753,
                        "CargoAgencyWhereUniqueInput!"
                    ],
                    "create": [
                        3127,
                        "CargoAgencyCreateInput!"
                    ],
                    "update": [
                        3134,
                        "CargoAgencyUpdateInput!"
                    ]
                }
            ],
            "createManyCart": [
                1662,
                {
                    "data": [
                        3140,
                        "[CartCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCart": [
                259,
                {
                    "data": [
                        3141,
                        "CartCreateInput!"
                    ]
                }
            ],
            "deleteManyCart": [
                1662,
                {
                    "where": [
                        39
                    ]
                }
            ],
            "deleteOneCart": [
                259,
                {
                    "where": [
                        283,
                        "CartWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCart": [
                1662,
                {
                    "data": [
                        3041,
                        "CartUpdateManyMutationInput!"
                    ],
                    "where": [
                        39
                    ]
                }
            ],
            "updateOneCart": [
                259,
                {
                    "data": [
                        3142,
                        "CartUpdateInput!"
                    ],
                    "where": [
                        283,
                        "CartWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCart": [
                259,
                {
                    "where": [
                        283,
                        "CartWhereUniqueInput!"
                    ],
                    "create": [
                        3141,
                        "CartCreateInput!"
                    ],
                    "update": [
                        3142,
                        "CartUpdateInput!"
                    ]
                }
            ],
            "createManyCartItem": [
                1662,
                {
                    "data": [
                        3143,
                        "[CartItemCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCartItem": [
                375,
                {
                    "data": [
                        3144,
                        "CartItemCreateInput!"
                    ]
                }
            ],
            "deleteManyCartItem": [
                1662,
                {
                    "where": [
                        119
                    ]
                }
            ],
            "deleteOneCartItem": [
                375,
                {
                    "where": [
                        414,
                        "CartItemWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCartItem": [
                1662,
                {
                    "data": [
                        3025,
                        "CartItemUpdateManyMutationInput!"
                    ],
                    "where": [
                        119
                    ]
                }
            ],
            "updateOneCartItem": [
                375,
                {
                    "data": [
                        3145,
                        "CartItemUpdateInput!"
                    ],
                    "where": [
                        414,
                        "CartItemWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCartItem": [
                375,
                {
                    "where": [
                        414,
                        "CartItemWhereUniqueInput!"
                    ],
                    "create": [
                        3144,
                        "CartItemCreateInput!"
                    ],
                    "update": [
                        3145,
                        "CartItemUpdateInput!"
                    ]
                }
            ],
            "createManyCategory": [
                1662,
                {
                    "data": [
                        3146,
                        "[CategoryCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCategory": [
                458,
                {
                    "data": [
                        3147,
                        "CategoryCreateInput!"
                    ]
                }
            ],
            "deleteManyCategory": [
                1662,
                {
                    "where": [
                        140
                    ]
                }
            ],
            "deleteOneCategory": [
                458,
                {
                    "where": [
                        485,
                        "CategoryWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCategory": [
                1662,
                {
                    "data": [
                        2739,
                        "CategoryUpdateManyMutationInput!"
                    ],
                    "where": [
                        140
                    ]
                }
            ],
            "updateOneCategory": [
                458,
                {
                    "data": [
                        3148,
                        "CategoryUpdateInput!"
                    ],
                    "where": [
                        485,
                        "CategoryWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCategory": [
                458,
                {
                    "where": [
                        485,
                        "CategoryWhereUniqueInput!"
                    ],
                    "create": [
                        3147,
                        "CategoryCreateInput!"
                    ],
                    "update": [
                        3148,
                        "CategoryUpdateInput!"
                    ]
                }
            ],
            "createManyCity": [
                1662,
                {
                    "data": [
                        3149,
                        "[CityCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCity": [
                592,
                {
                    "data": [
                        3150,
                        "CityCreateInput!"
                    ]
                }
            ],
            "deleteManyCity": [
                1662,
                {
                    "where": [
                        222
                    ]
                }
            ],
            "deleteOneCity": [
                592,
                {
                    "where": [
                        820,
                        "CityWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCity": [
                1662,
                {
                    "data": [
                        3161,
                        "CityUpdateManyMutationInput!"
                    ],
                    "where": [
                        222
                    ]
                }
            ],
            "updateOneCity": [
                592,
                {
                    "data": [
                        3162,
                        "CityUpdateInput!"
                    ],
                    "where": [
                        820,
                        "CityWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCity": [
                592,
                {
                    "where": [
                        820,
                        "CityWhereUniqueInput!"
                    ],
                    "create": [
                        3150,
                        "CityCreateInput!"
                    ],
                    "update": [
                        3162,
                        "CityUpdateInput!"
                    ]
                }
            ],
            "createManyCollection": [
                1662,
                {
                    "data": [
                        3175,
                        "[CollectionCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCollection": [
                469,
                {
                    "data": [
                        3176,
                        "CollectionCreateInput!"
                    ]
                }
            ],
            "deleteManyCollection": [
                1662,
                {
                    "where": [
                        148
                    ]
                }
            ],
            "deleteOneCollection": [
                469,
                {
                    "where": [
                        559,
                        "CollectionWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCollection": [
                1662,
                {
                    "data": [
                        2915,
                        "CollectionUpdateManyMutationInput!"
                    ],
                    "where": [
                        148
                    ]
                }
            ],
            "updateOneCollection": [
                469,
                {
                    "data": [
                        3177,
                        "CollectionUpdateInput!"
                    ],
                    "where": [
                        559,
                        "CollectionWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCollection": [
                469,
                {
                    "where": [
                        559,
                        "CollectionWhereUniqueInput!"
                    ],
                    "create": [
                        3176,
                        "CollectionCreateInput!"
                    ],
                    "update": [
                        3177,
                        "CollectionUpdateInput!"
                    ]
                }
            ],
            "createManyComment": [
                1662,
                {
                    "data": [
                        3178,
                        "[CommentCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneComment": [
                855,
                {
                    "data": [
                        3179,
                        "CommentCreateInput!"
                    ]
                }
            ],
            "deleteManyComment": [
                1662,
                {
                    "where": [
                        850
                    ]
                }
            ],
            "deleteOneComment": [
                855,
                {
                    "where": [
                        854,
                        "CommentWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyComment": [
                1662,
                {
                    "data": [
                        3180,
                        "CommentUpdateManyMutationInput!"
                    ],
                    "where": [
                        850
                    ]
                }
            ],
            "updateOneComment": [
                855,
                {
                    "data": [
                        3181,
                        "CommentUpdateInput!"
                    ],
                    "where": [
                        854,
                        "CommentWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneComment": [
                855,
                {
                    "where": [
                        854,
                        "CommentWhereUniqueInput!"
                    ],
                    "create": [
                        3179,
                        "CommentCreateInput!"
                    ],
                    "update": [
                        3181,
                        "CommentUpdateInput!"
                    ]
                }
            ],
            "createManyCompany": [
                1662,
                {
                    "data": [
                        3182,
                        "[CompanyCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCompany": [
                256,
                {
                    "data": [
                        3183,
                        "CompanyCreateInput!"
                    ]
                }
            ],
            "deleteManyCompany": [
                1662,
                {
                    "where": [
                        36
                    ]
                }
            ],
            "deleteOneCompany": [
                256,
                {
                    "where": [
                        487,
                        "CompanyWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCompany": [
                1662,
                {
                    "data": [
                        2747,
                        "CompanyUpdateManyMutationInput!"
                    ],
                    "where": [
                        36
                    ]
                }
            ],
            "updateOneCompany": [
                256,
                {
                    "data": [
                        3184,
                        "CompanyUpdateInput!"
                    ],
                    "where": [
                        487,
                        "CompanyWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCompany": [
                256,
                {
                    "where": [
                        487,
                        "CompanyWhereUniqueInput!"
                    ],
                    "create": [
                        3183,
                        "CompanyCreateInput!"
                    ],
                    "update": [
                        3184,
                        "CompanyUpdateInput!"
                    ]
                }
            ],
            "createManyCompanyStatement": [
                1662,
                {
                    "data": [
                        3185,
                        "[CompanyStatementCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCompanyStatement": [
                584,
                {
                    "data": [
                        3186,
                        "CompanyStatementCreateInput!"
                    ]
                }
            ],
            "deleteManyCompanyStatement": [
                1662,
                {
                    "where": [
                        218
                    ]
                }
            ],
            "deleteOneCompanyStatement": [
                584,
                {
                    "where": [
                        588,
                        "CompanyStatementWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCompanyStatement": [
                1662,
                {
                    "data": [
                        2361,
                        "CompanyStatementUpdateManyMutationInput!"
                    ],
                    "where": [
                        218
                    ]
                }
            ],
            "updateOneCompanyStatement": [
                584,
                {
                    "data": [
                        3190,
                        "CompanyStatementUpdateInput!"
                    ],
                    "where": [
                        588,
                        "CompanyStatementWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCompanyStatement": [
                584,
                {
                    "where": [
                        588,
                        "CompanyStatementWhereUniqueInput!"
                    ],
                    "create": [
                        3186,
                        "CompanyStatementCreateInput!"
                    ],
                    "update": [
                        3190,
                        "CompanyStatementUpdateInput!"
                    ]
                }
            ],
            "createManyCompanyToPaymentMethod": [
                1662,
                {
                    "data": [
                        3195,
                        "[CompanyToPaymentMethodCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCompanyToPaymentMethod": [
                356,
                {
                    "data": [
                        3196,
                        "CompanyToPaymentMethodCreateInput!"
                    ]
                }
            ],
            "deleteManyCompanyToPaymentMethod": [
                1662,
                {
                    "where": [
                        106
                    ]
                }
            ],
            "deleteOneCompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        358,
                        "CompanyToPaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCompanyToPaymentMethod": [
                1662,
                {
                    "data": [
                        2844,
                        "CompanyToPaymentMethodUpdateManyMutationInput!"
                    ],
                    "where": [
                        106
                    ]
                }
            ],
            "updateOneCompanyToPaymentMethod": [
                356,
                {
                    "data": [
                        3197,
                        "CompanyToPaymentMethodUpdateInput!"
                    ],
                    "where": [
                        358,
                        "CompanyToPaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCompanyToPaymentMethod": [
                356,
                {
                    "where": [
                        358,
                        "CompanyToPaymentMethodWhereUniqueInput!"
                    ],
                    "create": [
                        3196,
                        "CompanyToPaymentMethodCreateInput!"
                    ],
                    "update": [
                        3197,
                        "CompanyToPaymentMethodUpdateInput!"
                    ]
                }
            ],
            "createManyConfiguration": [
                1662,
                {
                    "data": [
                        3198,
                        "[ConfigurationCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneConfiguration": [
                920,
                {
                    "data": [
                        3199,
                        "ConfigurationCreateInput!"
                    ]
                }
            ],
            "deleteManyConfiguration": [
                1662,
                {
                    "where": [
                        915
                    ]
                }
            ],
            "deleteOneConfiguration": [
                920,
                {
                    "where": [
                        919,
                        "ConfigurationWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyConfiguration": [
                1662,
                {
                    "data": [
                        3200,
                        "ConfigurationUpdateManyMutationInput!"
                    ],
                    "where": [
                        915
                    ]
                }
            ],
            "updateOneConfiguration": [
                920,
                {
                    "data": [
                        3201,
                        "ConfigurationUpdateInput!"
                    ],
                    "where": [
                        919,
                        "ConfigurationWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneConfiguration": [
                920,
                {
                    "where": [
                        919,
                        "ConfigurationWhereUniqueInput!"
                    ],
                    "create": [
                        3199,
                        "ConfigurationCreateInput!"
                    ],
                    "update": [
                        3201,
                        "ConfigurationUpdateInput!"
                    ]
                }
            ],
            "createManyConfirmCode": [
                1662,
                {
                    "data": [
                        3202,
                        "[ConfirmCodeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneConfirmCode": [
                285,
                {
                    "data": [
                        3203
                    ]
                }
            ],
            "deleteManyConfirmCode": [
                1662,
                {
                    "where": [
                        55
                    ]
                }
            ],
            "deleteOneConfirmCode": [
                285,
                {
                    "where": [
                        289,
                        "ConfirmCodeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyConfirmCode": [
                1662,
                {
                    "data": [
                        2342,
                        "ConfirmCodeUpdateManyMutationInput!"
                    ],
                    "where": [
                        55
                    ]
                }
            ],
            "updateOneConfirmCode": [
                285,
                {
                    "data": [
                        3207,
                        "ConfirmCodeUpdateInput!"
                    ],
                    "where": [
                        289,
                        "ConfirmCodeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneConfirmCode": [
                285,
                {
                    "where": [
                        289,
                        "ConfirmCodeWhereUniqueInput!"
                    ],
                    "create": [
                        3203,
                        "ConfirmCodeCreateInput!"
                    ],
                    "update": [
                        3207,
                        "ConfirmCodeUpdateInput!"
                    ]
                }
            ],
            "createManyCounty": [
                1662,
                {
                    "data": [
                        3212,
                        "[CountyCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneCounty": [
                590,
                {
                    "data": [
                        3213,
                        "CountyCreateInput!"
                    ]
                }
            ],
            "deleteManyCounty": [
                1662,
                {
                    "where": [
                        220
                    ]
                }
            ],
            "deleteOneCounty": [
                590,
                {
                    "where": [
                        594,
                        "CountyWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyCounty": [
                1662,
                {
                    "data": [
                        3174,
                        "CountyUpdateManyMutationInput!"
                    ],
                    "where": [
                        220
                    ]
                }
            ],
            "updateOneCounty": [
                590,
                {
                    "data": [
                        3214,
                        "CountyUpdateInput!"
                    ],
                    "where": [
                        594,
                        "CountyWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneCounty": [
                590,
                {
                    "where": [
                        594,
                        "CountyWhereUniqueInput!"
                    ],
                    "create": [
                        3213,
                        "CountyCreateInput!"
                    ],
                    "update": [
                        3214,
                        "CountyUpdateInput!"
                    ]
                }
            ],
            "createManyDBQuery": [
                1662,
                {
                    "data": [
                        3215,
                        "[DBQueryCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneDBQuery": [
                971,
                {
                    "data": [
                        3216
                    ]
                }
            ],
            "deleteManyDBQuery": [
                1662,
                {
                    "where": [
                        966
                    ]
                }
            ],
            "deleteOneDBQuery": [
                971,
                {
                    "where": [
                        970,
                        "DBQueryWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyDBQuery": [
                1662,
                {
                    "data": [
                        3217,
                        "DBQueryUpdateManyMutationInput!"
                    ],
                    "where": [
                        966
                    ]
                }
            ],
            "updateOneDBQuery": [
                971,
                {
                    "data": [
                        3218,
                        "DBQueryUpdateInput!"
                    ],
                    "where": [
                        970,
                        "DBQueryWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneDBQuery": [
                971,
                {
                    "where": [
                        970,
                        "DBQueryWhereUniqueInput!"
                    ],
                    "create": [
                        3216,
                        "DBQueryCreateInput!"
                    ],
                    "update": [
                        3218,
                        "DBQueryUpdateInput!"
                    ]
                }
            ],
            "createManyDevice": [
                1662,
                {
                    "data": [
                        3219,
                        "[DeviceCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneDevice": [
                992,
                {
                    "data": [
                        3220,
                        "DeviceCreateInput!"
                    ]
                }
            ],
            "deleteManyDevice": [
                1662,
                {
                    "where": [
                        987
                    ]
                }
            ],
            "deleteOneDevice": [
                992,
                {
                    "where": [
                        991,
                        "DeviceWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyDevice": [
                1662,
                {
                    "data": [
                        3221,
                        "DeviceUpdateManyMutationInput!"
                    ],
                    "where": [
                        987
                    ]
                }
            ],
            "updateOneDevice": [
                992,
                {
                    "data": [
                        3222,
                        "DeviceUpdateInput!"
                    ],
                    "where": [
                        991,
                        "DeviceWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneDevice": [
                992,
                {
                    "where": [
                        991,
                        "DeviceWhereUniqueInput!"
                    ],
                    "create": [
                        3220,
                        "DeviceCreateInput!"
                    ],
                    "update": [
                        3222,
                        "DeviceUpdateInput!"
                    ]
                }
            ],
            "createManyDistrict": [
                1662,
                {
                    "data": [
                        3223,
                        "[DistrictCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneDistrict": [
                596,
                {
                    "data": [
                        3224,
                        "DistrictCreateInput!"
                    ]
                }
            ],
            "deleteManyDistrict": [
                1662,
                {
                    "where": [
                        225
                    ]
                }
            ],
            "deleteOneDistrict": [
                596,
                {
                    "where": [
                        600,
                        "DistrictWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyDistrict": [
                1662,
                {
                    "data": [
                        2305,
                        "DistrictUpdateManyMutationInput!"
                    ],
                    "where": [
                        225
                    ]
                }
            ],
            "updateOneDistrict": [
                596,
                {
                    "data": [
                        3228,
                        "DistrictUpdateInput!"
                    ],
                    "where": [
                        600,
                        "DistrictWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneDistrict": [
                596,
                {
                    "where": [
                        600,
                        "DistrictWhereUniqueInput!"
                    ],
                    "create": [
                        3224,
                        "DistrictCreateInput!"
                    ],
                    "update": [
                        3228,
                        "DistrictUpdateInput!"
                    ]
                }
            ],
            "createManyError": [
                1662,
                {
                    "data": [
                        3233,
                        "[ErrorCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneError": [
                1027,
                {
                    "data": [
                        3234
                    ]
                }
            ],
            "deleteManyError": [
                1662,
                {
                    "where": [
                        1022
                    ]
                }
            ],
            "deleteOneError": [
                1027,
                {
                    "where": [
                        1026,
                        "ErrorWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyError": [
                1662,
                {
                    "data": [
                        3235,
                        "ErrorUpdateManyMutationInput!"
                    ],
                    "where": [
                        1022
                    ]
                }
            ],
            "updateOneError": [
                1027,
                {
                    "data": [
                        3236,
                        "ErrorUpdateInput!"
                    ],
                    "where": [
                        1026,
                        "ErrorWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneError": [
                1027,
                {
                    "where": [
                        1026,
                        "ErrorWhereUniqueInput!"
                    ],
                    "create": [
                        3234,
                        "ErrorCreateInput!"
                    ],
                    "update": [
                        3236,
                        "ErrorUpdateInput!"
                    ]
                }
            ],
            "createManyFileManager": [
                1662,
                {
                    "data": [
                        3237,
                        "[FileManagerCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneFileManager": [
                1048,
                {
                    "data": [
                        3238,
                        "FileManagerCreateInput!"
                    ]
                }
            ],
            "deleteManyFileManager": [
                1662,
                {
                    "where": [
                        1043
                    ]
                }
            ],
            "deleteOneFileManager": [
                1048,
                {
                    "where": [
                        1047,
                        "FileManagerWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyFileManager": [
                1662,
                {
                    "data": [
                        3239,
                        "FileManagerUpdateManyMutationInput!"
                    ],
                    "where": [
                        1043
                    ]
                }
            ],
            "updateOneFileManager": [
                1048,
                {
                    "data": [
                        3240,
                        "FileManagerUpdateInput!"
                    ],
                    "where": [
                        1047,
                        "FileManagerWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneFileManager": [
                1048,
                {
                    "where": [
                        1047,
                        "FileManagerWhereUniqueInput!"
                    ],
                    "create": [
                        3238,
                        "FileManagerCreateInput!"
                    ],
                    "update": [
                        3240,
                        "FileManagerUpdateInput!"
                    ]
                }
            ],
            "createManyImage": [
                1662,
                {
                    "data": [
                        3241,
                        "[ImageCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneImage": [
                441,
                {
                    "data": [
                        3242,
                        "ImageCreateInput!"
                    ]
                }
            ],
            "deleteManyImage": [
                1662,
                {
                    "where": [
                        132
                    ]
                }
            ],
            "deleteOneImage": [
                441,
                {
                    "where": [
                        454,
                        "ImageWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyImage": [
                1662,
                {
                    "data": [
                        2895,
                        "ImageUpdateManyMutationInput!"
                    ],
                    "where": [
                        132
                    ]
                }
            ],
            "updateOneImage": [
                441,
                {
                    "data": [
                        3243,
                        "ImageUpdateInput!"
                    ],
                    "where": [
                        454,
                        "ImageWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneImage": [
                441,
                {
                    "where": [
                        454,
                        "ImageWhereUniqueInput!"
                    ],
                    "create": [
                        3242,
                        "ImageCreateInput!"
                    ],
                    "update": [
                        3243,
                        "ImageUpdateInput!"
                    ]
                }
            ],
            "createManyInstallment": [
                1662,
                {
                    "data": [
                        3244,
                        "[InstallmentCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneInstallment": [
                329,
                {
                    "data": [
                        3245
                    ]
                }
            ],
            "deleteManyInstallment": [
                1662,
                {
                    "where": [
                        93
                    ]
                }
            ],
            "deleteOneInstallment": [
                329,
                {
                    "where": [
                        1080,
                        "InstallmentWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyInstallment": [
                1662,
                {
                    "data": [
                        3246,
                        "InstallmentUpdateManyMutationInput!"
                    ],
                    "where": [
                        93
                    ]
                }
            ],
            "updateOneInstallment": [
                329,
                {
                    "data": [
                        3247,
                        "InstallmentUpdateInput!"
                    ],
                    "where": [
                        1080,
                        "InstallmentWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneInstallment": [
                329,
                {
                    "where": [
                        1080,
                        "InstallmentWhereUniqueInput!"
                    ],
                    "create": [
                        3245,
                        "InstallmentCreateInput!"
                    ],
                    "update": [
                        3247,
                        "InstallmentUpdateInput!"
                    ]
                }
            ],
            "createManyInstallmentToCompany": [
                1662,
                {
                    "data": [
                        3248,
                        "[InstallmentToCompanyCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneInstallmentToCompany": [
                331,
                {
                    "data": [
                        3249,
                        "InstallmentToCompanyCreateInput!"
                    ]
                }
            ],
            "deleteManyInstallmentToCompany": [
                1662,
                {
                    "where": [
                        95
                    ]
                }
            ],
            "deleteOneInstallmentToCompany": [
                331,
                {
                    "where": [
                        338,
                        "InstallmentToCompanyWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyInstallmentToCompany": [
                1662,
                {
                    "data": [
                        2999,
                        "InstallmentToCompanyUpdateManyMutationInput!"
                    ],
                    "where": [
                        95
                    ]
                }
            ],
            "updateOneInstallmentToCompany": [
                331,
                {
                    "data": [
                        3250,
                        "InstallmentToCompanyUpdateInput!"
                    ],
                    "where": [
                        338,
                        "InstallmentToCompanyWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneInstallmentToCompany": [
                331,
                {
                    "where": [
                        338,
                        "InstallmentToCompanyWhereUniqueInput!"
                    ],
                    "create": [
                        3249,
                        "InstallmentToCompanyCreateInput!"
                    ],
                    "update": [
                        3250,
                        "InstallmentToCompanyUpdateInput!"
                    ]
                }
            ],
            "createManyInstallmentToPosAccount": [
                1662,
                {
                    "data": [
                        3251,
                        "[InstallmentToPosAccountCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneInstallmentToPosAccount": [
                328,
                {
                    "data": [
                        3252,
                        "InstallmentToPosAccountCreateInput!"
                    ]
                }
            ],
            "deleteManyInstallmentToPosAccount": [
                1662,
                {
                    "where": [
                        91
                    ]
                }
            ],
            "deleteOneInstallmentToPosAccount": [
                328,
                {
                    "where": [
                        344,
                        "InstallmentToPosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyInstallmentToPosAccount": [
                1662,
                {
                    "data": [
                        2994,
                        "InstallmentToPosAccountUpdateManyMutationInput!"
                    ],
                    "where": [
                        91
                    ]
                }
            ],
            "updateOneInstallmentToPosAccount": [
                328,
                {
                    "data": [
                        3253,
                        "InstallmentToPosAccountUpdateInput!"
                    ],
                    "where": [
                        344,
                        "InstallmentToPosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneInstallmentToPosAccount": [
                328,
                {
                    "where": [
                        344,
                        "InstallmentToPosAccountWhereUniqueInput!"
                    ],
                    "create": [
                        3252,
                        "InstallmentToPosAccountCreateInput!"
                    ],
                    "update": [
                        3253,
                        "InstallmentToPosAccountUpdateInput!"
                    ]
                }
            ],
            "createManyOrder": [
                1662,
                {
                    "data": [
                        3254,
                        "[OrderCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneOrder": [
                291,
                {
                    "data": [
                        3255,
                        "OrderCreateInput!"
                    ]
                }
            ],
            "deleteManyOrder": [
                1662,
                {
                    "where": [
                        60
                    ]
                }
            ],
            "deleteOneOrder": [
                291,
                {
                    "where": [
                        568,
                        "OrderWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyOrder": [
                1662,
                {
                    "data": [
                        2864,
                        "OrderUpdateManyMutationInput!"
                    ],
                    "where": [
                        60
                    ]
                }
            ],
            "updateOneOrder": [
                291,
                {
                    "data": [
                        3256,
                        "OrderUpdateInput!"
                    ],
                    "where": [
                        568,
                        "OrderWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneOrder": [
                291,
                {
                    "where": [
                        568,
                        "OrderWhereUniqueInput!"
                    ],
                    "create": [
                        3255,
                        "OrderCreateInput!"
                    ],
                    "update": [
                        3256,
                        "OrderUpdateInput!"
                    ]
                }
            ],
            "createManyOrderItem": [
                1662,
                {
                    "data": [
                        3257,
                        "[OrderItemCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneOrderItem": [
                373,
                {
                    "data": [
                        3258,
                        "OrderItemCreateInput!"
                    ]
                }
            ],
            "deleteManyOrderItem": [
                1662,
                {
                    "where": [
                        114
                    ]
                }
            ],
            "deleteOneOrderItem": [
                373,
                {
                    "where": [
                        437,
                        "OrderItemWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyOrderItem": [
                1662,
                {
                    "data": [
                        2791,
                        "OrderItemUpdateManyMutationInput!"
                    ],
                    "where": [
                        114
                    ]
                }
            ],
            "updateOneOrderItem": [
                373,
                {
                    "data": [
                        3259,
                        "OrderItemUpdateInput!"
                    ],
                    "where": [
                        437,
                        "OrderItemWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneOrderItem": [
                373,
                {
                    "where": [
                        437,
                        "OrderItemWhereUniqueInput!"
                    ],
                    "create": [
                        3258,
                        "OrderItemCreateInput!"
                    ],
                    "update": [
                        3259,
                        "OrderItemUpdateInput!"
                    ]
                }
            ],
            "createManyOrderItemStatus": [
                1662,
                {
                    "data": [
                        3260,
                        "[OrderItemStatusCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneOrderItemStatus": [
                562,
                {
                    "data": [
                        3261,
                        "OrderItemStatusCreateInput!"
                    ]
                }
            ],
            "deleteManyOrderItemStatus": [
                1662,
                {
                    "where": [
                        204
                    ]
                }
            ],
            "deleteOneOrderItemStatus": [
                562,
                {
                    "where": [
                        566,
                        "OrderItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyOrderItemStatus": [
                1662,
                {
                    "data": [
                        3267,
                        "OrderItemStatusUpdateManyMutationInput!"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "updateOneOrderItemStatus": [
                562,
                {
                    "data": [
                        3268,
                        "OrderItemStatusUpdateInput!"
                    ],
                    "where": [
                        566,
                        "OrderItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneOrderItemStatus": [
                562,
                {
                    "where": [
                        566,
                        "OrderItemStatusWhereUniqueInput!"
                    ],
                    "create": [
                        3261,
                        "OrderItemStatusCreateInput!"
                    ],
                    "update": [
                        3268,
                        "OrderItemStatusUpdateInput!"
                    ]
                }
            ],
            "createManyOrderStatus": [
                1662,
                {
                    "data": [
                        3274,
                        "[OrderStatusCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneOrderStatus": [
                564,
                {
                    "data": [
                        3275,
                        "OrderStatusCreateInput!"
                    ]
                }
            ],
            "deleteManyOrderStatus": [
                1662,
                {
                    "where": [
                        206
                    ]
                }
            ],
            "deleteOneOrderStatus": [
                564,
                {
                    "where": [
                        1172,
                        "OrderStatusWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyOrderStatus": [
                1662,
                {
                    "data": [
                        3281,
                        "OrderStatusUpdateManyMutationInput!"
                    ],
                    "where": [
                        206
                    ]
                }
            ],
            "updateOneOrderStatus": [
                564,
                {
                    "data": [
                        3282,
                        "OrderStatusUpdateInput!"
                    ],
                    "where": [
                        1172,
                        "OrderStatusWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneOrderStatus": [
                564,
                {
                    "where": [
                        1172,
                        "OrderStatusWhereUniqueInput!"
                    ],
                    "create": [
                        3275,
                        "OrderStatusCreateInput!"
                    ],
                    "update": [
                        3282,
                        "OrderStatusUpdateInput!"
                    ]
                }
            ],
            "createManyOrigin": [
                1662,
                {
                    "data": [
                        3289,
                        "[OriginCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneOrigin": [
                1193,
                {
                    "data": [
                        3290,
                        "OriginCreateInput!"
                    ]
                }
            ],
            "deleteManyOrigin": [
                1662,
                {
                    "where": [
                        1188
                    ]
                }
            ],
            "deleteOneOrigin": [
                1193,
                {
                    "where": [
                        1192,
                        "OriginWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyOrigin": [
                1662,
                {
                    "data": [
                        3291,
                        "OriginUpdateManyMutationInput!"
                    ],
                    "where": [
                        1188
                    ]
                }
            ],
            "updateOneOrigin": [
                1193,
                {
                    "data": [
                        3292,
                        "OriginUpdateInput!"
                    ],
                    "where": [
                        1192,
                        "OriginWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneOrigin": [
                1193,
                {
                    "where": [
                        1192,
                        "OriginWhereUniqueInput!"
                    ],
                    "create": [
                        3290,
                        "OriginCreateInput!"
                    ],
                    "update": [
                        3292,
                        "OriginUpdateInput!"
                    ]
                }
            ],
            "createManyPayment": [
                1662,
                {
                    "data": [
                        3293,
                        "[PaymentCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePayment": [
                294,
                {
                    "data": [
                        3294,
                        "PaymentCreateInput!"
                    ]
                }
            ],
            "deleteManyPayment": [
                1662,
                {
                    "where": [
                        74
                    ]
                }
            ],
            "deleteOnePayment": [
                294,
                {
                    "where": [
                        324,
                        "PaymentWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPayment": [
                1662,
                {
                    "data": [
                        2808,
                        "PaymentUpdateManyMutationInput!"
                    ],
                    "where": [
                        74
                    ]
                }
            ],
            "updateOnePayment": [
                294,
                {
                    "data": [
                        3295,
                        "PaymentUpdateInput!"
                    ],
                    "where": [
                        324,
                        "PaymentWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePayment": [
                294,
                {
                    "where": [
                        324,
                        "PaymentWhereUniqueInput!"
                    ],
                    "create": [
                        3294,
                        "PaymentCreateInput!"
                    ],
                    "update": [
                        3295,
                        "PaymentUpdateInput!"
                    ]
                }
            ],
            "createManyPaymentCheck": [
                1662,
                {
                    "data": [
                        3296,
                        "[PaymentCheckCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePaymentCheck": [
                366,
                {
                    "data": [
                        3297,
                        "PaymentCheckCreateInput!"
                    ]
                }
            ],
            "deleteManyPaymentCheck": [
                1662,
                {
                    "where": [
                        109
                    ]
                }
            ],
            "deleteOnePaymentCheck": [
                366,
                {
                    "where": [
                        371,
                        "PaymentCheckWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPaymentCheck": [
                1662,
                {
                    "data": [
                        2804,
                        "PaymentCheckUpdateManyMutationInput!"
                    ],
                    "where": [
                        109
                    ]
                }
            ],
            "updateOnePaymentCheck": [
                366,
                {
                    "data": [
                        3301,
                        "PaymentCheckUpdateInput!"
                    ],
                    "where": [
                        371,
                        "PaymentCheckWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePaymentCheck": [
                366,
                {
                    "where": [
                        371,
                        "PaymentCheckWhereUniqueInput!"
                    ],
                    "create": [
                        3297,
                        "PaymentCheckCreateInput!"
                    ],
                    "update": [
                        3301,
                        "PaymentCheckUpdateInput!"
                    ]
                }
            ],
            "createManyPaymentGroup": [
                1662,
                {
                    "data": [
                        3307,
                        "[PaymentGroupCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePaymentGroup": [
                293,
                {
                    "data": [
                        3308
                    ]
                }
            ],
            "deleteManyPaymentGroup": [
                1662,
                {
                    "where": [
                        71
                    ]
                }
            ],
            "deleteOnePaymentGroup": [
                293,
                {
                    "where": [
                        364,
                        "PaymentGroupWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPaymentGroup": [
                1662,
                {
                    "data": [
                        2796,
                        "PaymentGroupUpdateManyMutationInput!"
                    ],
                    "where": [
                        71
                    ]
                }
            ],
            "updateOnePaymentGroup": [
                293,
                {
                    "data": [
                        3309,
                        "PaymentGroupUpdateInput!"
                    ],
                    "where": [
                        364,
                        "PaymentGroupWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePaymentGroup": [
                293,
                {
                    "where": [
                        364,
                        "PaymentGroupWhereUniqueInput!"
                    ],
                    "create": [
                        3308,
                        "PaymentGroupCreateInput!"
                    ],
                    "update": [
                        3309,
                        "PaymentGroupUpdateInput!"
                    ]
                }
            ],
            "createManyPaymentMethod": [
                1662,
                {
                    "data": [
                        3310,
                        "[PaymentMethodCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePaymentMethod": [
                354,
                {
                    "data": [
                        3311,
                        "PaymentMethodCreateInput!"
                    ]
                }
            ],
            "deleteManyPaymentMethod": [
                1662,
                {
                    "where": [
                        104
                    ]
                }
            ],
            "deleteOnePaymentMethod": [
                354,
                {
                    "where": [
                        1257,
                        "PaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPaymentMethod": [
                1662,
                {
                    "data": [
                        3312,
                        "PaymentMethodUpdateManyMutationInput!"
                    ],
                    "where": [
                        104
                    ]
                }
            ],
            "updateOnePaymentMethod": [
                354,
                {
                    "data": [
                        3313,
                        "PaymentMethodUpdateInput!"
                    ],
                    "where": [
                        1257,
                        "PaymentMethodWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePaymentMethod": [
                354,
                {
                    "where": [
                        1257,
                        "PaymentMethodWhereUniqueInput!"
                    ],
                    "create": [
                        3311,
                        "PaymentMethodCreateInput!"
                    ],
                    "update": [
                        3313,
                        "PaymentMethodUpdateInput!"
                    ]
                }
            ],
            "createManyPaymentSystem": [
                1662,
                {
                    "data": [
                        3314,
                        "[PaymentSystemCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePaymentSystem": [
                346,
                {
                    "data": [
                        3315,
                        "PaymentSystemCreateInput!"
                    ]
                }
            ],
            "deleteManyPaymentSystem": [
                1662,
                {
                    "where": [
                        99
                    ]
                }
            ],
            "deleteOnePaymentSystem": [
                346,
                {
                    "where": [
                        1273,
                        "PaymentSystemWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPaymentSystem": [
                1662,
                {
                    "data": [
                        3321,
                        "PaymentSystemUpdateManyMutationInput!"
                    ],
                    "where": [
                        99
                    ]
                }
            ],
            "updateOnePaymentSystem": [
                346,
                {
                    "data": [
                        3322,
                        "PaymentSystemUpdateInput!"
                    ],
                    "where": [
                        1273,
                        "PaymentSystemWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePaymentSystem": [
                346,
                {
                    "where": [
                        1273,
                        "PaymentSystemWhereUniqueInput!"
                    ],
                    "create": [
                        3315,
                        "PaymentSystemCreateInput!"
                    ],
                    "update": [
                        3322,
                        "PaymentSystemUpdateInput!"
                    ]
                }
            ],
            "createManyPermission": [
                1662,
                {
                    "data": [
                        3328,
                        "[PermissionCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePermission": [
                574,
                {
                    "data": [
                        3329,
                        "PermissionCreateInput!"
                    ]
                }
            ],
            "deleteManyPermission": [
                1662,
                {
                    "where": [
                        215
                    ]
                }
            ],
            "deleteOnePermission": [
                574,
                {
                    "where": [
                        582,
                        "PermissionWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPermission": [
                1662,
                {
                    "data": [
                        2725,
                        "PermissionUpdateManyMutationInput!"
                    ],
                    "where": [
                        215
                    ]
                }
            ],
            "updateOnePermission": [
                574,
                {
                    "data": [
                        3338,
                        "PermissionUpdateInput!"
                    ],
                    "where": [
                        582,
                        "PermissionWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePermission": [
                574,
                {
                    "where": [
                        582,
                        "PermissionWhereUniqueInput!"
                    ],
                    "create": [
                        3329,
                        "PermissionCreateInput!"
                    ],
                    "update": [
                        3338,
                        "PermissionUpdateInput!"
                    ]
                }
            ],
            "createManyPlugin": [
                1662,
                {
                    "data": [
                        3351,
                        "[PluginCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePlugin": [
                1308,
                {
                    "data": [
                        3352,
                        "PluginCreateInput!"
                    ]
                }
            ],
            "deleteManyPlugin": [
                1662,
                {
                    "where": [
                        1303
                    ]
                }
            ],
            "deleteOnePlugin": [
                1308,
                {
                    "where": [
                        1307,
                        "PluginWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPlugin": [
                1662,
                {
                    "data": [
                        3353,
                        "PluginUpdateManyMutationInput!"
                    ],
                    "where": [
                        1303
                    ]
                }
            ],
            "updateOnePlugin": [
                1308,
                {
                    "data": [
                        3354,
                        "PluginUpdateInput!"
                    ],
                    "where": [
                        1307,
                        "PluginWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePlugin": [
                1308,
                {
                    "where": [
                        1307,
                        "PluginWhereUniqueInput!"
                    ],
                    "create": [
                        3352,
                        "PluginCreateInput!"
                    ],
                    "update": [
                        3354,
                        "PluginUpdateInput!"
                    ]
                }
            ],
            "createManyPosAccount": [
                1662,
                {
                    "data": [
                        3355,
                        "[PosAccountCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePosAccount": [
                326,
                {
                    "data": [
                        3356,
                        "PosAccountCreateInput!"
                    ]
                }
            ],
            "deleteManyPosAccount": [
                1662,
                {
                    "where": [
                        89
                    ]
                }
            ],
            "deleteOnePosAccount": [
                326,
                {
                    "where": [
                        348,
                        "PosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPosAccount": [
                1662,
                {
                    "data": [
                        3056,
                        "PosAccountUpdateManyMutationInput!"
                    ],
                    "where": [
                        89
                    ]
                }
            ],
            "updateOnePosAccount": [
                326,
                {
                    "data": [
                        3357,
                        "PosAccountUpdateInput!"
                    ],
                    "where": [
                        348,
                        "PosAccountWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePosAccount": [
                326,
                {
                    "where": [
                        348,
                        "PosAccountWhereUniqueInput!"
                    ],
                    "create": [
                        3356,
                        "PosAccountCreateInput!"
                    ],
                    "update": [
                        3357,
                        "PosAccountUpdateInput!"
                    ]
                }
            ],
            "createManyPosConfig": [
                1662,
                {
                    "data": [
                        3358,
                        "[PosConfigCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePosConfig": [
                350,
                {
                    "data": [
                        3359
                    ]
                }
            ],
            "deleteManyPosConfig": [
                1662,
                {
                    "where": [
                        101
                    ]
                }
            ],
            "deleteOnePosConfig": [
                350,
                {
                    "where": [
                        352,
                        "PosConfigWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPosConfig": [
                1662,
                {
                    "data": [
                        2819,
                        "PosConfigUpdateManyMutationInput!"
                    ],
                    "where": [
                        101
                    ]
                }
            ],
            "updateOnePosConfig": [
                350,
                {
                    "data": [
                        3360,
                        "PosConfigUpdateInput!"
                    ],
                    "where": [
                        352,
                        "PosConfigWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePosConfig": [
                350,
                {
                    "where": [
                        352,
                        "PosConfigWhereUniqueInput!"
                    ],
                    "create": [
                        3359,
                        "PosConfigCreateInput!"
                    ],
                    "update": [
                        3360,
                        "PosConfigUpdateInput!"
                    ]
                }
            ],
            "createManyPrice": [
                1662,
                {
                    "data": [
                        3361,
                        "[PriceCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePrice": [
                461,
                {
                    "data": [
                        3362,
                        "PriceCreateInput!"
                    ]
                }
            ],
            "deleteManyPrice": [
                1662,
                {
                    "where": [
                        145
                    ]
                }
            ],
            "deleteOnePrice": [
                461,
                {
                    "where": [
                        1352,
                        "PriceWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPrice": [
                1662,
                {
                    "data": [
                        3363,
                        "PriceUpdateManyMutationInput!"
                    ],
                    "where": [
                        145
                    ]
                }
            ],
            "updateOnePrice": [
                461,
                {
                    "data": [
                        3364,
                        "PriceUpdateInput!"
                    ],
                    "where": [
                        1352,
                        "PriceWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePrice": [
                461,
                {
                    "where": [
                        1352,
                        "PriceWhereUniqueInput!"
                    ],
                    "create": [
                        3362,
                        "PriceCreateInput!"
                    ],
                    "update": [
                        3364,
                        "PriceUpdateInput!"
                    ]
                }
            ],
            "createManyPriceToCategory": [
                1662,
                {
                    "data": [
                        3365,
                        "[PriceToCategoryCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOnePriceToCategory": [
                460,
                {
                    "data": [
                        3366,
                        "PriceToCategoryCreateInput!"
                    ]
                }
            ],
            "deleteManyPriceToCategory": [
                1662,
                {
                    "where": [
                        142
                    ]
                }
            ],
            "deleteOnePriceToCategory": [
                460,
                {
                    "where": [
                        464,
                        "PriceToCategoryWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyPriceToCategory": [
                1662,
                {
                    "data": [
                        2546,
                        "PriceToCategoryUpdateManyMutationInput!"
                    ],
                    "where": [
                        142
                    ]
                }
            ],
            "updateOnePriceToCategory": [
                460,
                {
                    "data": [
                        3367,
                        "PriceToCategoryUpdateInput!"
                    ],
                    "where": [
                        464,
                        "PriceToCategoryWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOnePriceToCategory": [
                460,
                {
                    "where": [
                        464,
                        "PriceToCategoryWhereUniqueInput!"
                    ],
                    "create": [
                        3366,
                        "PriceToCategoryCreateInput!"
                    ],
                    "update": [
                        3367,
                        "PriceToCategoryUpdateInput!"
                    ]
                }
            ],
            "createManyProduct": [
                1662,
                {
                    "data": [
                        3368,
                        "[ProductCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneProduct": [
                377,
                {
                    "data": [
                        3369,
                        "ProductCreateInput!"
                    ]
                }
            ],
            "deleteManyProduct": [
                1662,
                {
                    "where": [
                        122
                    ]
                }
            ],
            "deleteOneProduct": [
                377,
                {
                    "where": [
                        456,
                        "ProductWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyProduct": [
                1662,
                {
                    "data": [
                        2899,
                        "ProductUpdateManyMutationInput!"
                    ],
                    "where": [
                        122
                    ]
                }
            ],
            "updateOneProduct": [
                377,
                {
                    "data": [
                        3370,
                        "ProductUpdateInput!"
                    ],
                    "where": [
                        456,
                        "ProductWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneProduct": [
                377,
                {
                    "where": [
                        456,
                        "ProductWhereUniqueInput!"
                    ],
                    "create": [
                        3369,
                        "ProductCreateInput!"
                    ],
                    "update": [
                        3370,
                        "ProductUpdateInput!"
                    ]
                }
            ],
            "createManyProductLot": [
                1662,
                {
                    "data": [
                        3371,
                        "[ProductLotCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneProductLot": [
                508,
                {
                    "data": [
                        3372,
                        "ProductLotCreateInput!"
                    ]
                }
            ],
            "deleteManyProductLot": [
                1662,
                {
                    "where": [
                        175
                    ]
                }
            ],
            "deleteOneProductLot": [
                508,
                {
                    "where": [
                        512,
                        "ProductLotWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyProductLot": [
                1662,
                {
                    "data": [
                        2569,
                        "ProductLotUpdateManyMutationInput!"
                    ],
                    "where": [
                        175
                    ]
                }
            ],
            "updateOneProductLot": [
                508,
                {
                    "data": [
                        3376,
                        "ProductLotUpdateInput!"
                    ],
                    "where": [
                        512,
                        "ProductLotWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneProductLot": [
                508,
                {
                    "where": [
                        512,
                        "ProductLotWhereUniqueInput!"
                    ],
                    "create": [
                        3372,
                        "ProductLotCreateInput!"
                    ],
                    "update": [
                        3376,
                        "ProductLotUpdateInput!"
                    ]
                }
            ],
            "createManyProductStock": [
                1662,
                {
                    "data": [
                        3381,
                        "[ProductStockCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneProductStock": [
                514,
                {
                    "data": [
                        3382,
                        "ProductStockCreateInput!"
                    ]
                }
            ],
            "deleteManyProductStock": [
                1662,
                {
                    "where": [
                        177
                    ]
                }
            ],
            "deleteOneProductStock": [
                514,
                {
                    "where": [
                        518,
                        "ProductStockWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyProductStock": [
                1662,
                {
                    "data": [
                        2576,
                        "ProductStockUpdateManyMutationInput!"
                    ],
                    "where": [
                        177
                    ]
                }
            ],
            "updateOneProductStock": [
                514,
                {
                    "data": [
                        3386,
                        "ProductStockUpdateInput!"
                    ],
                    "where": [
                        518,
                        "ProductStockWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneProductStock": [
                514,
                {
                    "where": [
                        518,
                        "ProductStockWhereUniqueInput!"
                    ],
                    "create": [
                        3382,
                        "ProductStockCreateInput!"
                    ],
                    "update": [
                        3386,
                        "ProductStockUpdateInput!"
                    ]
                }
            ],
            "createManyProductType": [
                1662,
                {
                    "data": [
                        3391,
                        "[ProductTypeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneProductType": [
                489,
                {
                    "data": [
                        3392,
                        "ProductTypeCreateInput!"
                    ]
                }
            ],
            "deleteManyProductType": [
                1662,
                {
                    "where": [
                        165
                    ]
                }
            ],
            "deleteOneProductType": [
                489,
                {
                    "where": [
                        1426,
                        "ProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyProductType": [
                1662,
                {
                    "data": [
                        3393,
                        "ProductTypeUpdateManyMutationInput!"
                    ],
                    "where": [
                        165
                    ]
                }
            ],
            "updateOneProductType": [
                489,
                {
                    "data": [
                        3394,
                        "ProductTypeUpdateInput!"
                    ],
                    "where": [
                        1426,
                        "ProductTypeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneProductType": [
                489,
                {
                    "where": [
                        1426,
                        "ProductTypeWhereUniqueInput!"
                    ],
                    "create": [
                        3392,
                        "ProductTypeCreateInput!"
                    ],
                    "update": [
                        3394,
                        "ProductTypeUpdateInput!"
                    ]
                }
            ],
            "createManyRole": [
                1662,
                {
                    "data": [
                        3395,
                        "[RoleCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneRole": [
                570,
                {
                    "data": [
                        3396,
                        "RoleCreateInput!"
                    ]
                }
            ],
            "deleteManyRole": [
                1662,
                {
                    "where": [
                        209
                    ]
                }
            ],
            "deleteOneRole": [
                570,
                {
                    "where": [
                        576,
                        "RoleWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyRole": [
                1662,
                {
                    "data": [
                        3350,
                        "RoleUpdateManyMutationInput!"
                    ],
                    "where": [
                        209
                    ]
                }
            ],
            "updateOneRole": [
                570,
                {
                    "data": [
                        3397,
                        "RoleUpdateInput!"
                    ],
                    "where": [
                        576,
                        "RoleWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneRole": [
                570,
                {
                    "where": [
                        576,
                        "RoleWhereUniqueInput!"
                    ],
                    "create": [
                        3396,
                        "RoleCreateInput!"
                    ],
                    "update": [
                        3397,
                        "RoleUpdateInput!"
                    ]
                }
            ],
            "createManyShipment": [
                1662,
                {
                    "data": [
                        3398,
                        "[ShipmentCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneShipment": [
                473,
                {
                    "data": [
                        3399,
                        "ShipmentCreateInput!"
                    ]
                }
            ],
            "deleteManyShipment": [
                1662,
                {
                    "where": [
                        152
                    ]
                }
            ],
            "deleteOneShipment": [
                473,
                {
                    "where": [
                        477,
                        "ShipmentWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyShipment": [
                1662,
                {
                    "data": [
                        2881,
                        "ShipmentUpdateManyMutationInput!"
                    ],
                    "where": [
                        152
                    ]
                }
            ],
            "updateOneShipment": [
                473,
                {
                    "data": [
                        3400,
                        "ShipmentUpdateInput!"
                    ],
                    "where": [
                        477,
                        "ShipmentWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneShipment": [
                473,
                {
                    "where": [
                        477,
                        "ShipmentWhereUniqueInput!"
                    ],
                    "create": [
                        3399,
                        "ShipmentCreateInput!"
                    ],
                    "update": [
                        3400,
                        "ShipmentUpdateInput!"
                    ]
                }
            ],
            "createManyShipmentEvent": [
                1662,
                {
                    "data": [
                        3401,
                        "[ShipmentEventCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneShipmentEvent": [
                479,
                {
                    "data": [
                        3402,
                        "ShipmentEventCreateInput!"
                    ]
                }
            ],
            "deleteManyShipmentEvent": [
                1662,
                {
                    "where": [
                        159
                    ]
                }
            ],
            "deleteOneShipmentEvent": [
                479,
                {
                    "where": [
                        483,
                        "ShipmentEventWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyShipmentEvent": [
                1662,
                {
                    "data": [
                        2877,
                        "ShipmentEventUpdateManyMutationInput!"
                    ],
                    "where": [
                        159
                    ]
                }
            ],
            "updateOneShipmentEvent": [
                479,
                {
                    "data": [
                        3403,
                        "ShipmentEventUpdateInput!"
                    ],
                    "where": [
                        483,
                        "ShipmentEventWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneShipmentEvent": [
                479,
                {
                    "where": [
                        483,
                        "ShipmentEventWhereUniqueInput!"
                    ],
                    "create": [
                        3402,
                        "ShipmentEventCreateInput!"
                    ],
                    "update": [
                        3403,
                        "ShipmentEventUpdateInput!"
                    ]
                }
            ],
            "createManyShipmentStatus": [
                1662,
                {
                    "data": [
                        3404,
                        "[ShipmentStatusCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneShipmentStatus": [
                471,
                {
                    "data": [
                        3405,
                        "ShipmentStatusCreateInput!"
                    ]
                }
            ],
            "deleteManyShipmentStatus": [
                1662,
                {
                    "where": [
                        150
                    ]
                }
            ],
            "deleteOneShipmentStatus": [
                471,
                {
                    "where": [
                        1488,
                        "ShipmentStatusWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyShipmentStatus": [
                1662,
                {
                    "data": [
                        3406,
                        "ShipmentStatusUpdateManyMutationInput!"
                    ],
                    "where": [
                        150
                    ]
                }
            ],
            "updateOneShipmentStatus": [
                471,
                {
                    "data": [
                        3407,
                        "ShipmentStatusUpdateInput!"
                    ],
                    "where": [
                        1488,
                        "ShipmentStatusWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneShipmentStatus": [
                471,
                {
                    "where": [
                        1488,
                        "ShipmentStatusWhereUniqueInput!"
                    ],
                    "create": [
                        3405,
                        "ShipmentStatusCreateInput!"
                    ],
                    "update": [
                        3407,
                        "ShipmentStatusUpdateInput!"
                    ]
                }
            ],
            "createManySupport": [
                1662,
                {
                    "data": [
                        3408,
                        "[SupportCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupport": [
                523,
                {
                    "data": [
                        3409,
                        "SupportCreateInput!"
                    ]
                }
            ],
            "deleteManySupport": [
                1662,
                {
                    "where": [
                        183
                    ]
                }
            ],
            "deleteOneSupport": [
                523,
                {
                    "where": [
                        1504,
                        "SupportWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupport": [
                1662,
                {
                    "data": [
                        3410,
                        "SupportUpdateManyMutationInput!"
                    ],
                    "where": [
                        183
                    ]
                }
            ],
            "updateOneSupport": [
                523,
                {
                    "data": [
                        3411,
                        "SupportUpdateInput!"
                    ],
                    "where": [
                        1504,
                        "SupportWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupport": [
                523,
                {
                    "where": [
                        1504,
                        "SupportWhereUniqueInput!"
                    ],
                    "create": [
                        3409,
                        "SupportCreateInput!"
                    ],
                    "update": [
                        3411,
                        "SupportUpdateInput!"
                    ]
                }
            ],
            "createManySupportAttachment": [
                1662,
                {
                    "data": [
                        3412,
                        "[SupportAttachmentCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupportAttachment": [
                522,
                {
                    "data": [
                        3413,
                        "SupportAttachmentCreateInput!"
                    ]
                }
            ],
            "deleteManySupportAttachment": [
                1662,
                {
                    "where": [
                        181
                    ]
                }
            ],
            "deleteOneSupportAttachment": [
                522,
                {
                    "where": [
                        538,
                        "SupportAttachmentWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupportAttachment": [
                1662,
                {
                    "data": [
                        2689,
                        "SupportAttachmentUpdateManyMutationInput!"
                    ],
                    "where": [
                        181
                    ]
                }
            ],
            "updateOneSupportAttachment": [
                522,
                {
                    "data": [
                        3414,
                        "SupportAttachmentUpdateInput!"
                    ],
                    "where": [
                        538,
                        "SupportAttachmentWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupportAttachment": [
                522,
                {
                    "where": [
                        538,
                        "SupportAttachmentWhereUniqueInput!"
                    ],
                    "create": [
                        3413,
                        "SupportAttachmentCreateInput!"
                    ],
                    "update": [
                        3414,
                        "SupportAttachmentUpdateInput!"
                    ]
                }
            ],
            "createManySupportEvent": [
                1662,
                {
                    "data": [
                        3415,
                        "[SupportEventCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupportEvent": [
                544,
                {
                    "data": [
                        3416,
                        "SupportEventCreateInput!"
                    ]
                }
            ],
            "deleteManySupportEvent": [
                1662,
                {
                    "where": [
                        196
                    ]
                }
            ],
            "deleteOneSupportEvent": [
                544,
                {
                    "where": [
                        553,
                        "SupportEventWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupportEvent": [
                1662,
                {
                    "data": [
                        2771,
                        "SupportEventUpdateManyMutationInput!"
                    ],
                    "where": [
                        196
                    ]
                }
            ],
            "updateOneSupportEvent": [
                544,
                {
                    "data": [
                        3417,
                        "SupportEventUpdateInput!"
                    ],
                    "where": [
                        553,
                        "SupportEventWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupportEvent": [
                544,
                {
                    "where": [
                        553,
                        "SupportEventWhereUniqueInput!"
                    ],
                    "create": [
                        3416,
                        "SupportEventCreateInput!"
                    ],
                    "update": [
                        3417,
                        "SupportEventUpdateInput!"
                    ]
                }
            ],
            "createManySupportItem": [
                1662,
                {
                    "data": [
                        3418,
                        "[SupportItemCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupportItem": [
                520,
                {
                    "data": [
                        3419,
                        "SupportItemCreateInput!"
                    ]
                }
            ],
            "deleteManySupportItem": [
                1662,
                {
                    "where": [
                        179
                    ]
                }
            ],
            "deleteOneSupportItem": [
                520,
                {
                    "where": [
                        540,
                        "SupportItemWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupportItem": [
                1662,
                {
                    "data": [
                        2757,
                        "SupportItemUpdateManyMutationInput!"
                    ],
                    "where": [
                        179
                    ]
                }
            ],
            "updateOneSupportItem": [
                520,
                {
                    "data": [
                        3420,
                        "SupportItemUpdateInput!"
                    ],
                    "where": [
                        540,
                        "SupportItemWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupportItem": [
                520,
                {
                    "where": [
                        540,
                        "SupportItemWhereUniqueInput!"
                    ],
                    "create": [
                        3419,
                        "SupportItemCreateInput!"
                    ],
                    "update": [
                        3420,
                        "SupportItemUpdateInput!"
                    ]
                }
            ],
            "createManySupportItemStatus": [
                1662,
                {
                    "data": [
                        3421,
                        "[SupportItemStatusCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupportItemStatus": [
                545,
                {
                    "data": [
                        3422,
                        "SupportItemStatusCreateInput!"
                    ]
                }
            ],
            "deleteManySupportItemStatus": [
                1662,
                {
                    "where": [
                        199
                    ]
                }
            ],
            "deleteOneSupportItemStatus": [
                545,
                {
                    "where": [
                        1568,
                        "SupportItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupportItemStatus": [
                1662,
                {
                    "data": [
                        3423,
                        "SupportItemStatusUpdateManyMutationInput!"
                    ],
                    "where": [
                        199
                    ]
                }
            ],
            "updateOneSupportItemStatus": [
                545,
                {
                    "data": [
                        3424,
                        "SupportItemStatusUpdateInput!"
                    ],
                    "where": [
                        1568,
                        "SupportItemStatusWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupportItemStatus": [
                545,
                {
                    "where": [
                        1568,
                        "SupportItemStatusWhereUniqueInput!"
                    ],
                    "create": [
                        3422,
                        "SupportItemStatusCreateInput!"
                    ],
                    "update": [
                        3424,
                        "SupportItemStatusUpdateInput!"
                    ]
                }
            ],
            "createManySupportMessage": [
                1662,
                {
                    "data": [
                        3425,
                        "[SupportMessageCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSupportMessage": [
                542,
                {
                    "data": [
                        3426,
                        "SupportMessageCreateInput!"
                    ]
                }
            ],
            "deleteManySupportMessage": [
                1662,
                {
                    "where": [
                        194
                    ]
                }
            ],
            "deleteOneSupportMessage": [
                542,
                {
                    "where": [
                        555,
                        "SupportMessageWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySupportMessage": [
                1662,
                {
                    "data": [
                        2752,
                        "SupportMessageUpdateManyMutationInput!"
                    ],
                    "where": [
                        194
                    ]
                }
            ],
            "updateOneSupportMessage": [
                542,
                {
                    "data": [
                        3427,
                        "SupportMessageUpdateInput!"
                    ],
                    "where": [
                        555,
                        "SupportMessageWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSupportMessage": [
                542,
                {
                    "where": [
                        555,
                        "SupportMessageWhereUniqueInput!"
                    ],
                    "create": [
                        3426,
                        "SupportMessageCreateInput!"
                    ],
                    "update": [
                        3427,
                        "SupportMessageUpdateInput!"
                    ]
                }
            ],
            "createManySyncronize": [
                1662,
                {
                    "data": [
                        3428,
                        "[SyncronizeCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneSyncronize": [
                1609,
                {
                    "data": [
                        3429,
                        "SyncronizeCreateInput!"
                    ]
                }
            ],
            "deleteManySyncronize": [
                1662,
                {
                    "where": [
                        1600
                    ]
                }
            ],
            "deleteOneSyncronize": [
                1609,
                {
                    "where": [
                        1608,
                        "SyncronizeWhereUniqueInput!"
                    ]
                }
            ],
            "updateManySyncronize": [
                1662,
                {
                    "data": [
                        3430,
                        "SyncronizeUpdateManyMutationInput!"
                    ],
                    "where": [
                        1600
                    ]
                }
            ],
            "updateOneSyncronize": [
                1609,
                {
                    "data": [
                        3433,
                        "SyncronizeUpdateInput!"
                    ],
                    "where": [
                        1608,
                        "SyncronizeWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneSyncronize": [
                1609,
                {
                    "where": [
                        1608,
                        "SyncronizeWhereUniqueInput!"
                    ],
                    "create": [
                        3429,
                        "SyncronizeCreateInput!"
                    ],
                    "update": [
                        3433,
                        "SyncronizeUpdateInput!"
                    ]
                }
            ],
            "createManyTenant": [
                1662,
                {
                    "data": [
                        3434,
                        "[TenantCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneTenant": [
                1634,
                {
                    "data": [
                        3435,
                        "TenantCreateInput!"
                    ]
                }
            ],
            "deleteManyTenant": [
                1662,
                {
                    "where": [
                        1629
                    ]
                }
            ],
            "deleteOneTenant": [
                1634,
                {
                    "where": [
                        1633,
                        "TenantWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyTenant": [
                1662,
                {
                    "data": [
                        3436,
                        "TenantUpdateManyMutationInput!"
                    ],
                    "where": [
                        1629
                    ]
                }
            ],
            "updateOneTenant": [
                1634,
                {
                    "data": [
                        3437,
                        "TenantUpdateInput!"
                    ],
                    "where": [
                        1633,
                        "TenantWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneTenant": [
                1634,
                {
                    "where": [
                        1633,
                        "TenantWhereUniqueInput!"
                    ],
                    "create": [
                        3435,
                        "TenantCreateInput!"
                    ],
                    "update": [
                        3437,
                        "TenantUpdateInput!"
                    ]
                }
            ],
            "createManyUser": [
                1662,
                {
                    "data": [
                        3438,
                        "[UserCreateManyInput!]!"
                    ],
                    "skipDuplicates": [
                        8
                    ]
                }
            ],
            "createOneUser": [
                261,
                {
                    "data": [
                        3439,
                        "UserCreateInput!"
                    ]
                }
            ],
            "deleteManyUser": [
                1662,
                {
                    "where": [
                        50
                    ]
                }
            ],
            "deleteOneUser": [
                261,
                {
                    "where": [
                        572,
                        "UserWhereUniqueInput!"
                    ]
                }
            ],
            "updateManyUser": [
                1662,
                {
                    "data": [
                        2839,
                        "UserUpdateManyMutationInput!"
                    ],
                    "where": [
                        50
                    ]
                }
            ],
            "updateOneUser": [
                261,
                {
                    "data": [
                        3440,
                        "UserUpdateInput!"
                    ],
                    "where": [
                        572,
                        "UserWhereUniqueInput!"
                    ]
                }
            ],
            "upsertOneUser": [
                261,
                {
                    "where": [
                        572,
                        "UserWhereUniqueInput!"
                    ],
                    "create": [
                        3439,
                        "UserCreateInput!"
                    ],
                    "update": [
                        3440,
                        "UserUpdateInput!"
                    ]
                }
            ],
            "login": [
                3441,
                {
                    "password": [
                        9,
                        "String!"
                    ],
                    "email": [
                        9,
                        "String!"
                    ]
                }
            ],
            "selectCompany": [
                5,
                {
                    "companyId": [
                        5,
                        "Float!"
                    ]
                }
            ],
            "validateOtp": [
                9,
                {
                    "otp": [
                        9,
                        "String!"
                    ]
                }
            ],
            "addToCart": [
                3442,
                {
                    "data": [
                        3443,
                        "AddToCartArgs!"
                    ]
                }
            ],
            "updateCartItem": [
                375,
                {
                    "data": [
                        3145,
                        "CartItemUpdateInput!"
                    ],
                    "id": [
                        5,
                        "Float!"
                    ]
                }
            ],
            "updateCart": [
                3444,
                {
                    "data": [
                        3445,
                        "UpdateCartArgs!"
                    ]
                }
            ],
            "removeItemFromCart": [
                3446,
                {
                    "id": [
                        5,
                        "Float!"
                    ]
                }
            ],
            "addProductTest": [
                377
            ],
            "createAddress": [
                254,
                {
                    "address": [
                        3447,
                        "AddressInput!"
                    ]
                }
            ],
            "__typename": [
                9
            ]
        },
        "AffectedRowsOutput": {
            "count": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutAddressInput": {
            "create": [
                1666
            ],
            "connectOrCreate": [
                2269
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedManyWithoutCompanyInput": {
            "create": [
                1668
            ],
            "connectOrCreate": [
                2266
            ],
            "createMany": [
                2267
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "ShipmentAddress": [
                1690
            ],
            "User": [
                1696
            ],
            "CartItem": [
                1747
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedOneWithoutCartBillingInput": {
            "create": [
                1670
            ],
            "connectOrCreate": [
                2265
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutCartBillingInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateNestedOneWithoutAddressInput": {
            "create": [
                1672
            ],
            "connectOrCreate": [
                1681
            ],
            "connect": [
                594
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateWithoutAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "City": [
                1673
            ],
            "District": [
                1676
            ],
            "__typename": [
                9
            ]
        },
        "CityCreateNestedOneWithoutCountyInput": {
            "create": [
                1674
            ],
            "connectOrCreate": [
                1675
            ],
            "connect": [
                820
            ],
            "__typename": [
                9
            ]
        },
        "CityCreateWithoutCountyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "CityCreateOrConnectWithoutCountyInput": {
            "where": [
                820
            ],
            "create": [
                1674
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateNestedManyWithoutCountyInput": {
            "create": [
                1677
            ],
            "connectOrCreate": [
                1678
            ],
            "createMany": [
                1679
            ],
            "connect": [
                600
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateWithoutCountyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateOrConnectWithoutCountyInput": {
            "where": [
                600
            ],
            "create": [
                1677
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateManyCountyInputEnvelope": {
            "data": [
                1680
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateManyCountyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateOrConnectWithoutAddressInput": {
            "where": [
                594
            ],
            "create": [
                1672
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedManyWithoutShipmentAddressInput": {
            "create": [
                1683
            ],
            "connectOrCreate": [
                2262
            ],
            "createMany": [
                2263
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutShipmentAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "Company": [
                1684
            ],
            "User": [
                1696
            ],
            "CartItem": [
                1747
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutCartInput": {
            "create": [
                1685
            ],
            "connectOrCreate": [
                2261
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutCartInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedManyWithoutCompanyInput": {
            "create": [
                1687
            ],
            "connectOrCreate": [
                2258
            ],
            "createMany": [
                2259
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedManyWithoutBillingAddressInput": {
            "create": [
                1689
            ],
            "connectOrCreate": [
                2255
            ],
            "createMany": [
                2256
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutBillingAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "Company": [
                1684
            ],
            "ShipmentAddress": [
                1690
            ],
            "User": [
                1696
            ],
            "CartItem": [
                1747
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedOneWithoutCartShipmentInput": {
            "create": [
                1691
            ],
            "connectOrCreate": [
                2254
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutCartShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedManyWithoutBillingAddressInput": {
            "create": [
                1693
            ],
            "connectOrCreate": [
                2251
            ],
            "createMany": [
                2252
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutBillingAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedOneWithoutOrderInput": {
            "create": [
                1695
            ],
            "connectOrCreate": [
                2250
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutOrderInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "Company": [
                1684
            ],
            "ShipmentAddress": [
                1690
            ],
            "User": [
                1696
            ],
            "CartItem": [
                1747
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutCartInput": {
            "create": [
                1697
            ],
            "connectOrCreate": [
                2249
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutCartInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateNestedManyWithoutUserInput": {
            "create": [
                1699
            ],
            "connectOrCreate": [
                1700
            ],
            "createMany": [
                1701
            ],
            "connect": [
                289
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateWithoutUserInput": {
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateOrConnectWithoutUserInput": {
            "where": [
                289
            ],
            "create": [
                1699
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateManyUserInputEnvelope": {
            "data": [
                1702
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateManyUserInput": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedManyWithoutUserInput": {
            "create": [
                1704
            ],
            "connectOrCreate": [
                2246
            ],
            "createMany": [
                2247
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedOneWithoutOrderBillingInput": {
            "create": [
                1706
            ],
            "connectOrCreate": [
                2245
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutOrderBillingInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedManyWithoutShipmentAddressInput": {
            "create": [
                1708
            ],
            "connectOrCreate": [
                2242
            ],
            "createMany": [
                2243
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutShipmentAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutOrderInput": {
            "create": [
                1710
            ],
            "connectOrCreate": [
                2241
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutOrderInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateNestedManyWithoutCompanyInput": {
            "create": [
                1712
            ],
            "connectOrCreate": [
                1713
            ],
            "createMany": [
                1714
            ],
            "connect": [
                588
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateOrConnectWithoutCompanyInput": {
            "where": [
                588
            ],
            "create": [
                1712
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateManyCompanyInputEnvelope": {
            "data": [
                1715
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateManyCompanyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateNestedManyWithoutCompanyInput": {
            "create": [
                1717
            ],
            "connectOrCreate": [
                2238
            ],
            "createMany": [
                2239
            ],
            "connect": [
                358
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateWithoutCompanyInput": {
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "PaymentMethod": [
                1718
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateNestedOneWithoutCompanyToPaymentMethodInput": {
            "create": [
                1719
            ],
            "connectOrCreate": [
                2237
            ],
            "connect": [
                1257
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateWithoutCompanyToPaymentMethodInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "Payment": [
                1720
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutMethodInput": {
            "create": [
                1721
            ],
            "connectOrCreate": [
                2234
            ],
            "createMany": [
                2235
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutMethodInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateNestedOneWithoutPaymentInput": {
            "create": [
                1723
            ],
            "connectOrCreate": [
                2233
            ],
            "connect": [
                679
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "bin_numbers": [
                1724
            ],
            "PosAccount": [
                1729
            ],
            "PosConfig": [
                2008
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateNestedManyWithoutBankInput": {
            "create": [
                1725
            ],
            "connectOrCreate": [
                1726
            ],
            "createMany": [
                1727
            ],
            "connect": [
                308
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateWithoutBankInput": {
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateOrConnectWithoutBankInput": {
            "where": [
                308
            ],
            "create": [
                1725
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateManyBankInputEnvelope": {
            "data": [
                1728
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateManyBankInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateNestedManyWithoutBankInput": {
            "create": [
                1730
            ],
            "connectOrCreate": [
                2230
            ],
            "createMany": [
                2231
            ],
            "connect": [
                348
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateWithoutBankInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "InstallmentToPosAccount": [
                1731
            ],
            "Payment": [
                1820
            ],
            "PaymentSystem": [
                1918
            ],
            "PosConfig": [
                2017
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateNestedManyWithoutPosAccountInput": {
            "create": [
                1732
            ],
            "connectOrCreate": [
                2227
            ],
            "createMany": [
                2228
            ],
            "connect": [
                344
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateWithoutPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "Installment": [
                1733
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateNestedOneWithoutInstallmentToPosAccountInput": {
            "create": [
                1734
            ],
            "connectOrCreate": [
                2226
            ],
            "connect": [
                1080
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateWithoutInstallmentToPosAccountInput": {
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "InstallmentToCompany": [
                1735
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateNestedManyWithoutInstallmentInput": {
            "create": [
                1736
            ],
            "connectOrCreate": [
                2223
            ],
            "createMany": [
                2224
            ],
            "connect": [
                338
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateWithoutInstallmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "Company": [
                1737
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutInstallmentToCompanyInput": {
            "create": [
                1738
            ],
            "connectOrCreate": [
                2222
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutInstallmentToCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedManyWithoutCompanyInput": {
            "create": [
                1740
            ],
            "connectOrCreate": [
                2219
            ],
            "createMany": [
                2220
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedOneWithoutOrderShipmentInput": {
            "create": [
                1742
            ],
            "connectOrCreate": [
                2218
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutOrderShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedManyWithoutAddressInput": {
            "create": [
                1744
            ],
            "connectOrCreate": [
                2215
            ],
            "createMany": [
                2216
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutAddressInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedManyWithoutUserInput": {
            "create": [
                1746
            ],
            "connectOrCreate": [
                2212
            ],
            "createMany": [
                2213
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "Company": [
                1684
            ],
            "ShipmentAddress": [
                1690
            ],
            "CartItem": [
                1747
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateNestedManyWithoutCartInput": {
            "create": [
                1748
            ],
            "connectOrCreate": [
                2209
            ],
            "createMany": [
                2210
            ],
            "connect": [
                414
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateWithoutCartInput": {
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                1749
            ],
            "OrderItem": [
                1779
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutCartItemInput": {
            "create": [
                1750
            ],
            "connectOrCreate": [
                2208
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutCartItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreatefeaturesInput": {
            "set": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateNestedManyWithoutProductInput": {
            "create": [
                1753
            ],
            "connectOrCreate": [
                1754
            ],
            "createMany": [
                1755
            ],
            "connect": [
                409
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateOrConnectWithoutProductInput": {
            "where": [
                409
            ],
            "create": [
                1753
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateManyProductInputEnvelope": {
            "data": [
                1756
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateManyProductInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutProductInput": {
            "create": [
                1758
            ],
            "connectOrCreate": [
                2205
            ],
            "createMany": [
                2206
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "OrderItemStatus": [
                1769
            ],
            "Shipment": [
                1781
            ],
            "User": [
                1904
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateNestedOneWithoutOrderItemInput": {
            "create": [
                1760
            ],
            "connectOrCreate": [
                2204
            ],
            "connect": [
                414
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateWithoutOrderItemInput": {
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1761
            ],
            "Product": [
                1749
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateNestedOneWithoutCartItemInput": {
            "create": [
                1762
            ],
            "connectOrCreate": [
                2203
            ],
            "connect": [
                283
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateWithoutCartItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "Company": [
                1684
            ],
            "ShipmentAddress": [
                1690
            ],
            "User": [
                1696
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedOneWithoutCartInput": {
            "create": [
                1764
            ],
            "connectOrCreate": [
                2202
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutCartInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutOrderInput": {
            "create": [
                1766
            ],
            "connectOrCreate": [
                2201
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutOrderInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutUserInput": {
            "create": [
                1768
            ],
            "connectOrCreate": [
                2198
            ],
            "createMany": [
                2199
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "OrderItemStatus": [
                1769
            ],
            "Product": [
                1775
            ],
            "Shipment": [
                1781
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateNestedOneWithoutOrderItemInput": {
            "create": [
                1770
            ],
            "connectOrCreate": [
                1774
            ],
            "connect": [
                566
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateWithoutOrderItemInput": {
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "OrderStatus": [
                1771
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCreateNestedOneWithoutOrderItemStatusInput": {
            "create": [
                1772
            ],
            "connectOrCreate": [
                1773
            ],
            "connect": [
                1172
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCreateWithoutOrderItemStatusInput": {
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCreateOrConnectWithoutOrderItemStatusInput": {
            "where": [
                1172
            ],
            "create": [
                1772
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateOrConnectWithoutOrderItemInput": {
            "where": [
                566
            ],
            "create": [
                1770
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutOrderItemInput": {
            "create": [
                1776
            ],
            "connectOrCreate": [
                2197
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutOrderItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateNestedManyWithoutProductInput": {
            "create": [
                1778
            ],
            "connectOrCreate": [
                2194
            ],
            "createMany": [
                2195
            ],
            "connect": [
                414
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateWithoutProductInput": {
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1761
            ],
            "OrderItem": [
                1779
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutCartItemInput": {
            "create": [
                1780
            ],
            "connectOrCreate": [
                2191
            ],
            "createMany": [
                2192
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutCartItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "OrderItemStatus": [
                1769
            ],
            "Product": [
                1775
            ],
            "Shipment": [
                1781
            ],
            "User": [
                1904
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateNestedOneWithoutOrderItemInput": {
            "create": [
                1782
            ],
            "connectOrCreate": [
                2190
            ],
            "connect": [
                477
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateWithoutOrderItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "CargoAgency": [
                1783
            ],
            "ShipmentStatus": [
                1786
            ],
            "ShipmentEvent": [
                2055
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCreateNestedOneWithoutShipmentInput": {
            "create": [
                1784
            ],
            "connectOrCreate": [
                1785
            ],
            "connect": [
                753
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCreateWithoutShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "creadentials": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCreateOrConnectWithoutShipmentInput": {
            "where": [
                753
            ],
            "create": [
                1784
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateNestedOneWithoutShipmentInput": {
            "create": [
                1787
            ],
            "connectOrCreate": [
                2189
            ],
            "connect": [
                1488
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateWithoutShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "image": [
                1788
            ],
            "ShipmentEvent": [
                2066
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutShipmentStatusInput": {
            "create": [
                1789
            ],
            "connectOrCreate": [
                2186
            ],
            "createMany": [
                2187
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutShipmentStatusInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Campaign": [
                1798
            ],
            "Category": [
                1806
            ],
            "Collection": [
                1892
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateNestedOneWithoutImageInput": {
            "create": [
                1791
            ],
            "connectOrCreate": [
                2185
            ],
            "connect": [
                467
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateWithoutImageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Product": [
                1792
            ],
            "Categories": [
                1959
            ],
            "Company": [
                2139
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutBrandInput": {
            "create": [
                1793
            ],
            "connectOrCreate": [
                2182
            ],
            "createMany": [
                2183
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutBrandInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateNestedOneWithoutProductInput": {
            "create": [
                1795
            ],
            "connectOrCreate": [
                2181
            ],
            "connect": [
                485
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1796
            ],
            "PriceToCategory": [
                1808
            ],
            "Brand": [
                2137
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutCategoryInput": {
            "create": [
                1797
            ],
            "connectOrCreate": [
                2178
            ],
            "createMany": [
                2179
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutCategoryInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Campaign": [
                1798
            ],
            "Collection": [
                1892
            ],
            "shipmentStatus": [
                1898
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateNestedOneWithoutImageInput": {
            "create": [
                1799
            ],
            "connectOrCreate": [
                2177
            ],
            "connect": [
                557
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateWithoutImageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                1800
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutCampaignInput": {
            "create": [
                1801
            ],
            "connectOrCreate": [
                2176
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutCampaignInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateNestedOneWithoutProductInput": {
            "create": [
                1803
            ],
            "connectOrCreate": [
                2175
            ],
            "connect": [
                467
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1804
            ],
            "Categories": [
                1959
            ],
            "Company": [
                2139
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutBrandInput": {
            "create": [
                1805
            ],
            "connectOrCreate": [
                2172
            ],
            "createMany": [
                2173
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutBrandInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Campaign": [
                1798
            ],
            "Category": [
                1806
            ],
            "Collection": [
                1892
            ],
            "shipmentStatus": [
                1898
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateNestedOneWithoutImageInput": {
            "create": [
                1807
            ],
            "connectOrCreate": [
                2171
            ],
            "connect": [
                485
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateWithoutImageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "PriceToCategory": [
                1808
            ],
            "Product": [
                1828
            ],
            "Brand": [
                2137
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateNestedManyWithoutCategoryInput": {
            "create": [
                1809
            ],
            "connectOrCreate": [
                2168
            ],
            "createMany": [
                2169
            ],
            "connect": [
                464
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateWithoutCategoryInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Company": [
                1810
            ],
            "Price": [
                2144
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutPriceToCategoryInput": {
            "create": [
                1811
            ],
            "connectOrCreate": [
                2167
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutPriceToCategoryInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateNestedManyWithoutCompanyInput": {
            "create": [
                1813
            ],
            "connectOrCreate": [
                2164
            ],
            "createMany": [
                2165
            ],
            "connect": [
                338
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "Installment": [
                1814
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateNestedOneWithoutInstallmentToCompanyInput": {
            "create": [
                1815
            ],
            "connectOrCreate": [
                2163
            ],
            "connect": [
                1080
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateWithoutInstallmentToCompanyInput": {
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "InstallmentToPosAccount": [
                1816
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateNestedManyWithoutInstallmentInput": {
            "create": [
                1817
            ],
            "connectOrCreate": [
                2160
            ],
            "createMany": [
                2161
            ],
            "connect": [
                344
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateWithoutInstallmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "PosAccount": [
                1818
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateNestedOneWithoutInstallmentToPosAccountInput": {
            "create": [
                1819
            ],
            "connectOrCreate": [
                2159
            ],
            "connect": [
                348
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateWithoutInstallmentToPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "Payment": [
                1820
            ],
            "PaymentSystem": [
                1918
            ],
            "Bank": [
                1921
            ],
            "PosConfig": [
                2017
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutPosAccountInput": {
            "create": [
                1821
            ],
            "connectOrCreate": [
                2156
            ],
            "createMany": [
                2157
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutPaymentInput": {
            "create": [
                1823
            ],
            "connectOrCreate": [
                2155
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateNestedManyWithoutCompanyInput": {
            "create": [
                1825
            ],
            "connectOrCreate": [
                2152
            ],
            "createMany": [
                2153
            ],
            "connect": [
                464
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Category": [
                1826
            ],
            "Price": [
                2144
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateNestedOneWithoutPriceToCategoryInput": {
            "create": [
                1827
            ],
            "connectOrCreate": [
                2143
            ],
            "connect": [
                485
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateWithoutPriceToCategoryInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1796
            ],
            "Product": [
                1828
            ],
            "Brand": [
                2137
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutCategoryInput": {
            "create": [
                1829
            ],
            "connectOrCreate": [
                2134
            ],
            "createMany": [
                2135
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutCategoryInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateNestedOneWithoutProductInput": {
            "create": [
                1831
            ],
            "connectOrCreate": [
                1837
            ],
            "connect": [
                1352
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "PriceToCategory": [
                1832
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateNestedManyWithoutPriceInput": {
            "create": [
                1833
            ],
            "connectOrCreate": [
                1834
            ],
            "createMany": [
                1835
            ],
            "connect": [
                464
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateWithoutPriceInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Category": [
                1826
            ],
            "Company": [
                1810
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateOrConnectWithoutPriceInput": {
            "where": [
                464
            ],
            "create": [
                1833
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyPriceInputEnvelope": {
            "data": [
                1836
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyPriceInput": {
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateOrConnectWithoutProductInput": {
            "where": [
                1352
            ],
            "create": [
                1831
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateNestedOneWithoutProductInput": {
            "create": [
                1839
            ],
            "connectOrCreate": [
                2133
            ],
            "connect": [
                1426
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "AttributeToProductType": [
                1840
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateNestedManyWithoutProductTypeInput": {
            "create": [
                1841
            ],
            "connectOrCreate": [
                2130
            ],
            "createMany": [
                2131
            ],
            "connect": [
                498
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateWithoutProductTypeInput": {
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "Attribute": [
                1842
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateNestedOneWithoutAttributeToProductTypeInput": {
            "create": [
                1843
            ],
            "connectOrCreate": [
                2129
            ],
            "connect": [
                635
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateWithoutAttributeToProductTypeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "AttributeValue": [
                1844
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateNestedManyWithoutAttributeInput": {
            "create": [
                1845
            ],
            "connectOrCreate": [
                2126
            ],
            "createMany": [
                2127
            ],
            "connect": [
                506
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateWithoutAttributeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Product": [
                1846
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutAttributeValuesInput": {
            "create": [
                1847
            ],
            "connectOrCreate": [
                2125
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutAttributeValuesInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateNestedManyWithoutProductInput": {
            "create": [
                1849
            ],
            "connectOrCreate": [
                1850
            ],
            "createMany": [
                1851
            ],
            "connect": [
                512
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateOrConnectWithoutProductInput": {
            "where": [
                512
            ],
            "create": [
                1849
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateManyProductInputEnvelope": {
            "data": [
                1852
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateManyProductInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateNestedManyWithoutProductInput": {
            "create": [
                1854
            ],
            "connectOrCreate": [
                1855
            ],
            "createMany": [
                1856
            ],
            "connect": [
                518
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateOrConnectWithoutProductInput": {
            "where": [
                518
            ],
            "create": [
                1854
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateManyProductInputEnvelope": {
            "data": [
                1857
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateManyProductInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateNestedManyWithoutProductInput": {
            "create": [
                1859
            ],
            "connectOrCreate": [
                2122
            ],
            "createMany": [
                2123
            ],
            "connect": [
                540
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1860
            ],
            "SupportEvent": [
                1866
            ],
            "SupportItemStatus": [
                1975
            ],
            "Support": [
                1940
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateNestedManyWithoutSupportItemInput": {
            "create": [
                1861
            ],
            "connectOrCreate": [
                2119
            ],
            "createMany": [
                2120
            ],
            "connect": [
                538
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateWithoutSupportItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Support": [
                1862
            ],
            "User": [
                2104
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateNestedOneWithoutSupportAttachmentInput": {
            "create": [
                1863
            ],
            "connectOrCreate": [
                2118
            ],
            "connect": [
                1504
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateWithoutSupportAttachmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "SupportItem": [
                1864
            ],
            "SupportMessage": [
                1942
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateNestedManyWithoutSupportInput": {
            "create": [
                1865
            ],
            "connectOrCreate": [
                2115
            ],
            "createMany": [
                2116
            ],
            "connect": [
                540
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateWithoutSupportInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1860
            ],
            "SupportEvent": [
                1866
            ],
            "Product": [
                1876
            ],
            "SupportItemStatus": [
                1975
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateNestedManyWithoutItemInput": {
            "create": [
                1867
            ],
            "connectOrCreate": [
                2112
            ],
            "createMany": [
                2113
            ],
            "connect": [
                553
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateWithoutItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "is_deleted": [
                8
            ],
            "Message": [
                1868
            ],
            "Status": [
                1936
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateNestedOneWithoutSupportEventInput": {
            "create": [
                1869
            ],
            "connectOrCreate": [
                2111
            ],
            "connect": [
                555
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateWithoutSupportEventInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Support": [
                1870
            ],
            "User": [
                1944
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateNestedOneWithoutSupportMessageInput": {
            "create": [
                1871
            ],
            "connectOrCreate": [
                2110
            ],
            "connect": [
                1504
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateWithoutSupportMessageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1872
            ],
            "SupportItem": [
                1864
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateNestedManyWithoutSupportInput": {
            "create": [
                1873
            ],
            "connectOrCreate": [
                2107
            ],
            "createMany": [
                2108
            ],
            "connect": [
                538
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateWithoutSupportInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportItem": [
                1874
            ],
            "User": [
                2104
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateNestedOneWithoutSupportAttachmentInput": {
            "create": [
                1875
            ],
            "connectOrCreate": [
                2103
            ],
            "connect": [
                540
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateWithoutSupportAttachmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportEvent": [
                1866
            ],
            "Product": [
                1876
            ],
            "SupportItemStatus": [
                1975
            ],
            "Support": [
                1940
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutSupportItemInput": {
            "create": [
                1877
            ],
            "connectOrCreate": [
                2102
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutSupportItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateNestedManyWithoutProductInput": {
            "create": [
                1879
            ],
            "connectOrCreate": [
                2101
            ],
            "connect": [
                506
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Attribute": [
                1880
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateNestedOneWithoutAttributeValueInput": {
            "create": [
                1881
            ],
            "connectOrCreate": [
                2100
            ],
            "connect": [
                635
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateWithoutAttributeValueInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "AttributeToProductType": [
                1882
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateNestedManyWithoutAttributeInput": {
            "create": [
                1883
            ],
            "connectOrCreate": [
                2097
            ],
            "createMany": [
                2098
            ],
            "connect": [
                498
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateWithoutAttributeInput": {
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "ProductType": [
                1884
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateNestedOneWithoutAttributeToProductTypeInput": {
            "create": [
                1885
            ],
            "connectOrCreate": [
                2096
            ],
            "connect": [
                1426
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateWithoutAttributeToProductTypeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "Product": [
                1886
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutProductTypeInput": {
            "create": [
                1887
            ],
            "connectOrCreate": [
                2093
            ],
            "createMany": [
                2094
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutProductTypeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateNestedManyWithoutProductInput": {
            "create": [
                1889
            ],
            "connectOrCreate": [
                2092
            ],
            "connect": [
                557
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Image": [
                1890
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutCampaignInput": {
            "create": [
                1891
            ],
            "connectOrCreate": [
                2089
            ],
            "createMany": [
                2090
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutCampaignInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Category": [
                1806
            ],
            "Collection": [
                1892
            ],
            "shipmentStatus": [
                1898
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateNestedOneWithoutImageInput": {
            "create": [
                1893
            ],
            "connectOrCreate": [
                2077
            ],
            "connect": [
                559
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateWithoutImageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                1894
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutCollectionInput": {
            "create": [
                1895
            ],
            "connectOrCreate": [
                2076
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutCollectionInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutProductInput": {
            "create": [
                1897
            ],
            "connectOrCreate": [
                2075
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutProductInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Campaign": [
                1798
            ],
            "Category": [
                1806
            ],
            "Collection": [
                1892
            ],
            "shipmentStatus": [
                1898
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateNestedOneWithoutImageInput": {
            "create": [
                1899
            ],
            "connectOrCreate": [
                2074
            ],
            "connect": [
                1488
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateWithoutImageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Shipment": [
                1900
            ],
            "ShipmentEvent": [
                2066
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateNestedManyWithoutShipmentStatusInput": {
            "create": [
                1901
            ],
            "connectOrCreate": [
                2063
            ],
            "createMany": [
                2064
            ],
            "connect": [
                477
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateWithoutShipmentStatusInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                1902
            ],
            "CargoAgency": [
                1783
            ],
            "ShipmentEvent": [
                2055
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutShipmentInput": {
            "create": [
                1903
            ],
            "connectOrCreate": [
                2052
            ],
            "createMany": [
                2053
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "OrderItemStatus": [
                1769
            ],
            "Product": [
                1775
            ],
            "User": [
                1904
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutOrderItemInput": {
            "create": [
                1905
            ],
            "connectOrCreate": [
                2040
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutOrderItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutUserInput": {
            "create": [
                1907
            ],
            "connectOrCreate": [
                2037
            ],
            "createMany": [
                2038
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateNestedOneWithoutPaymentInput": {
            "create": [
                1909
            ],
            "connectOrCreate": [
                2036
            ],
            "connect": [
                1257
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "CompanyToPaymentMethod": [
                1910
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateNestedManyWithoutPaymentMethodInput": {
            "create": [
                1911
            ],
            "connectOrCreate": [
                2033
            ],
            "createMany": [
                2034
            ],
            "connect": [
                358
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateWithoutPaymentMethodInput": {
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "Company": [
                1912
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutCompanyToPaymentMethodInput": {
            "create": [
                1913
            ],
            "connectOrCreate": [
                2032
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutCompanyToPaymentMethodInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutCompanyInput": {
            "create": [
                1915
            ],
            "connectOrCreate": [
                2026
            ],
            "createMany": [
                2027
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateNestedOneWithoutPaymentInput": {
            "create": [
                1917
            ],
            "connectOrCreate": [
                2025
            ],
            "connect": [
                348
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "InstallmentToPosAccount": [
                1731
            ],
            "PaymentSystem": [
                1918
            ],
            "Bank": [
                1921
            ],
            "PosConfig": [
                2017
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCreateNestedOneWithoutPosAccountInput": {
            "create": [
                1919
            ],
            "connectOrCreate": [
                1920
            ],
            "connect": [
                1273
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCreateWithoutPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCreateOrConnectWithoutPosAccountInput": {
            "where": [
                1273
            ],
            "create": [
                1919
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateNestedOneWithoutPosAccountInput": {
            "create": [
                1922
            ],
            "connectOrCreate": [
                2016
            ],
            "connect": [
                679
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateWithoutPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "bin_numbers": [
                1724
            ],
            "Payment": [
                1923
            ],
            "PosConfig": [
                2008
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutCard_bankInput": {
            "create": [
                1924
            ],
            "connectOrCreate": [
                2005
            ],
            "createMany": [
                2006
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutCard_bankInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutPaymentInput": {
            "create": [
                1926
            ],
            "connectOrCreate": [
                1990
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateNestedManyWithoutUserInput": {
            "create": [
                1928
            ],
            "connectOrCreate": [
                1929
            ],
            "createMany": [
                1930
            ],
            "connect": [
                538
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Support": [
                1862
            ],
            "SupportItem": [
                1874
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateOrConnectWithoutUserInput": {
            "where": [
                538
            ],
            "create": [
                1928
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManyUserInputEnvelope": {
            "data": [
                1931
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateNestedManyWithoutUserInput": {
            "create": [
                1933
            ],
            "connectOrCreate": [
                1987
            ],
            "createMany": [
                1988
            ],
            "connect": [
                555
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportEvent": [
                1934
            ],
            "Support": [
                1870
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateNestedManyWithoutMessageInput": {
            "create": [
                1935
            ],
            "connectOrCreate": [
                1984
            ],
            "createMany": [
                1985
            ],
            "connect": [
                553
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateWithoutMessageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "is_deleted": [
                8
            ],
            "Status": [
                1936
            ],
            "Item": [
                1973
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateNestedOneWithoutSupportEventInput": {
            "create": [
                1937
            ],
            "connectOrCreate": [
                1972
            ],
            "connect": [
                1568
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateWithoutSupportEventInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "SupportItem": [
                1938
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateNestedManyWithoutSupportItemStatusInput": {
            "create": [
                1939
            ],
            "connectOrCreate": [
                1969
            ],
            "createMany": [
                1970
            ],
            "connect": [
                540
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateWithoutSupportItemStatusInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1860
            ],
            "SupportEvent": [
                1866
            ],
            "Product": [
                1876
            ],
            "Support": [
                1940
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateNestedOneWithoutSupportItemInput": {
            "create": [
                1941
            ],
            "connectOrCreate": [
                1968
            ],
            "connect": [
                1504
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateWithoutSupportItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1872
            ],
            "SupportMessage": [
                1942
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateNestedManyWithoutSupportInput": {
            "create": [
                1943
            ],
            "connectOrCreate": [
                1965
            ],
            "createMany": [
                1966
            ],
            "connect": [
                555
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateWithoutSupportInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportEvent": [
                1934
            ],
            "User": [
                1944
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutSupportMessageInput": {
            "create": [
                1945
            ],
            "connectOrCreate": [
                1964
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutSupportMessageInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedOneWithoutUserInput": {
            "create": [
                1947
            ],
            "connectOrCreate": [
                1948
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "County": [
                1671
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutUserInput": {
            "where": [
                253
            ],
            "create": [
                1947
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateNestedOneWithoutUserInput": {
            "create": [
                1950
            ],
            "connectOrCreate": [
                1954
            ],
            "connect": [
                576
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Permissions": [
                1951
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCreateNestedManyWithoutRoleInput": {
            "create": [
                1952
            ],
            "connectOrCreate": [
                1953
            ],
            "connect": [
                582
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCreateWithoutRoleInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCreateOrConnectWithoutRoleInput": {
            "where": [
                582
            ],
            "create": [
                1952
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateOrConnectWithoutUserInput": {
            "where": [
                576
            ],
            "create": [
                1950
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedManyWithoutUserInput": {
            "create": [
                1956
            ],
            "connectOrCreate": [
                1963
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutUserInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateNestedManyWithoutCompanyInput": {
            "create": [
                1958
            ],
            "connectOrCreate": [
                1962
            ],
            "connect": [
                467
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1804
            ],
            "Product": [
                1792
            ],
            "Categories": [
                1959
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateNestedManyWithoutBrandInput": {
            "create": [
                1960
            ],
            "connectOrCreate": [
                1961
            ],
            "connect": [
                485
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateWithoutBrandInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1796
            ],
            "PriceToCategory": [
                1808
            ],
            "Product": [
                1828
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateOrConnectWithoutBrandInput": {
            "where": [
                485
            ],
            "create": [
                1960
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateOrConnectWithoutCompanyInput": {
            "where": [
                467
            ],
            "create": [
                1958
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutUserInput": {
            "where": [
                487
            ],
            "create": [
                1956
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutSupportMessageInput": {
            "where": [
                572
            ],
            "create": [
                1945
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateOrConnectWithoutSupportInput": {
            "where": [
                555
            ],
            "create": [
                1943
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateManySupportInputEnvelope": {
            "data": [
                1967
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateManySupportInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateOrConnectWithoutSupportItemInput": {
            "where": [
                1504
            ],
            "create": [
                1941
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateOrConnectWithoutSupportItemStatusInput": {
            "where": [
                540
            ],
            "create": [
                1939
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManySupportItemStatusInputEnvelope": {
            "data": [
                1971
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManySupportItemStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateOrConnectWithoutSupportEventInput": {
            "where": [
                1568
            ],
            "create": [
                1937
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateNestedOneWithoutSupportEventInput": {
            "create": [
                1974
            ],
            "connectOrCreate": [
                1983
            ],
            "connect": [
                540
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateWithoutSupportEventInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1860
            ],
            "Product": [
                1876
            ],
            "SupportItemStatus": [
                1975
            ],
            "Support": [
                1940
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateNestedOneWithoutSupportItemInput": {
            "create": [
                1976
            ],
            "connectOrCreate": [
                1982
            ],
            "connect": [
                1568
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateWithoutSupportItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "SupportEvent": [
                1977
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateNestedManyWithoutStatusInput": {
            "create": [
                1978
            ],
            "connectOrCreate": [
                1979
            ],
            "createMany": [
                1980
            ],
            "connect": [
                553
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateWithoutStatusInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "is_deleted": [
                8
            ],
            "Message": [
                1868
            ],
            "Item": [
                1973
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateOrConnectWithoutStatusInput": {
            "where": [
                553
            ],
            "create": [
                1978
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyStatusInputEnvelope": {
            "data": [
                1981
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateOrConnectWithoutSupportItemInput": {
            "where": [
                1568
            ],
            "create": [
                1976
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateOrConnectWithoutSupportEventInput": {
            "where": [
                540
            ],
            "create": [
                1974
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateOrConnectWithoutMessageInput": {
            "where": [
                553
            ],
            "create": [
                1935
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyMessageInputEnvelope": {
            "data": [
                1986
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyMessageInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateOrConnectWithoutUserInput": {
            "where": [
                555
            ],
            "create": [
                1933
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateManyUserInputEnvelope": {
            "data": [
                1989
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutPaymentInput": {
            "where": [
                572
            ],
            "create": [
                1926
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateNestedManyWithoutPaymentInput": {
            "create": [
                1992
            ],
            "connectOrCreate": [
                1999
            ],
            "createMany": [
                2000
            ],
            "connect": [
                364
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateWithoutPaymentInput": {
            "paid_price": [
                46
            ],
            "description": [
                9
            ],
            "Order": [
                1993
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedOneWithoutPaymentGroupInput": {
            "create": [
                1994
            ],
            "connectOrCreate": [
                1998
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutPaymentGroupInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutOrderInput": {
            "create": [
                1996
            ],
            "connectOrCreate": [
                1997
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutOrderInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "OrderItemStatus": [
                1769
            ],
            "Product": [
                1775
            ],
            "Shipment": [
                1781
            ],
            "User": [
                1904
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutOrderInput": {
            "where": [
                437
            ],
            "create": [
                1996
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutPaymentGroupInput": {
            "where": [
                568
            ],
            "create": [
                1994
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateOrConnectWithoutPaymentInput": {
            "where": [
                364
            ],
            "create": [
                1992
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateManyPaymentInputEnvelope": {
            "data": [
                2001
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateManyPaymentInput": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCreateNestedManyWithoutPaymentInput": {
            "create": [
                2003
            ],
            "connectOrCreate": [
                2004
            ],
            "connect": [
                371
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCreateWithoutPaymentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCreateOrConnectWithoutPaymentInput": {
            "where": [
                371
            ],
            "create": [
                2003
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutCard_bankInput": {
            "where": [
                324
            ],
            "create": [
                1924
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyCard_bankInputEnvelope": {
            "data": [
                2007
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyCard_bankInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateNestedManyWithoutBankInput": {
            "create": [
                2009
            ],
            "connectOrCreate": [
                2013
            ],
            "createMany": [
                2014
            ],
            "connect": [
                352
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateWithoutBankInput": {
            "is_default": [
                8
            ],
            "PosAccount": [
                2010
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateNestedOneWithoutPosConfigInput": {
            "create": [
                2011
            ],
            "connectOrCreate": [
                2012
            ],
            "connect": [
                348
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateWithoutPosConfigInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "InstallmentToPosAccount": [
                1731
            ],
            "Payment": [
                1820
            ],
            "PaymentSystem": [
                1918
            ],
            "Bank": [
                1921
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateOrConnectWithoutPosConfigInput": {
            "where": [
                348
            ],
            "create": [
                2011
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateOrConnectWithoutBankInput": {
            "where": [
                352
            ],
            "create": [
                2009
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateManyBankInputEnvelope": {
            "data": [
                2015
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateManyBankInput": {
            "id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateOrConnectWithoutPosAccountInput": {
            "where": [
                679
            ],
            "create": [
                1922
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateNestedManyWithoutPosAccountInput": {
            "create": [
                2018
            ],
            "connectOrCreate": [
                2022
            ],
            "createMany": [
                2023
            ],
            "connect": [
                352
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateWithoutPosAccountInput": {
            "is_default": [
                8
            ],
            "Bank": [
                2019
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateNestedOneWithoutPosConfigInput": {
            "create": [
                2020
            ],
            "connectOrCreate": [
                2021
            ],
            "connect": [
                679
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateWithoutPosConfigInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "bin_numbers": [
                1724
            ],
            "Payment": [
                1923
            ],
            "PosAccount": [
                1729
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateOrConnectWithoutPosConfigInput": {
            "where": [
                679
            ],
            "create": [
                2020
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateOrConnectWithoutPosAccountInput": {
            "where": [
                352
            ],
            "create": [
                2018
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateManyPosAccountInputEnvelope": {
            "data": [
                2024
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateManyPosAccountInput": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateOrConnectWithoutPaymentInput": {
            "where": [
                348
            ],
            "create": [
                1917
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutCompanyInput": {
            "where": [
                324
            ],
            "create": [
                1915
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyCompanyInputEnvelope": {
            "data": [
                2028
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyCompanyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedManyWithoutCompanyInput": {
            "create": [
                2030
            ],
            "connectOrCreate": [
                2031
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutCompanyInput": {
            "where": [
                572
            ],
            "create": [
                2030
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutCompanyToPaymentMethodInput": {
            "where": [
                487
            ],
            "create": [
                1913
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateOrConnectWithoutPaymentMethodInput": {
            "where": [
                358
            ],
            "create": [
                1911
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateManyPaymentMethodInputEnvelope": {
            "data": [
                2035
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateManyPaymentMethodInput": {
            "company_id": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateOrConnectWithoutPaymentInput": {
            "where": [
                1257
            ],
            "create": [
                1909
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutUserInput": {
            "where": [
                324
            ],
            "create": [
                1907
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyUserInputEnvelope": {
            "data": [
                2039
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutOrderItemInput": {
            "where": [
                572
            ],
            "create": [
                1905
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateNestedManyWithoutOrderItemInput": {
            "create": [
                2042
            ],
            "connectOrCreate": [
                2051
            ],
            "connect": [
                568
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateWithoutOrderItemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateNestedManyWithoutOrderInput": {
            "create": [
                2044
            ],
            "connectOrCreate": [
                2048
            ],
            "createMany": [
                2049
            ],
            "connect": [
                364
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateWithoutOrderInput": {
            "paid_price": [
                46
            ],
            "description": [
                9
            ],
            "Payment": [
                2045
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedOneWithoutPaymentGroupInput": {
            "create": [
                2046
            ],
            "connectOrCreate": [
                2047
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutPaymentGroupInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutPaymentGroupInput": {
            "where": [
                324
            ],
            "create": [
                2046
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateOrConnectWithoutOrderInput": {
            "where": [
                364
            ],
            "create": [
                2044
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateManyOrderInputEnvelope": {
            "data": [
                2050
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateManyOrderInput": {
            "id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutOrderItemInput": {
            "where": [
                568
            ],
            "create": [
                2042
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutShipmentInput": {
            "where": [
                437
            ],
            "create": [
                1903
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyShipmentInputEnvelope": {
            "data": [
                2054
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyShipmentInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateNestedManyWithoutShipmentInput": {
            "create": [
                2056
            ],
            "connectOrCreate": [
                2060
            ],
            "createMany": [
                2061
            ],
            "connect": [
                483
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateWithoutShipmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "ShipmentStatus": [
                2057
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateNestedOneWithoutShipmentEventInput": {
            "create": [
                2058
            ],
            "connectOrCreate": [
                2059
            ],
            "connect": [
                1488
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateWithoutShipmentEventInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "image": [
                1788
            ],
            "Shipment": [
                1900
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateOrConnectWithoutShipmentEventInput": {
            "where": [
                1488
            ],
            "create": [
                2058
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateOrConnectWithoutShipmentInput": {
            "where": [
                483
            ],
            "create": [
                2056
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateManyShipmentInputEnvelope": {
            "data": [
                2062
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateManyShipmentInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateOrConnectWithoutShipmentStatusInput": {
            "where": [
                477
            ],
            "create": [
                1901
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateManyShipmentStatusInputEnvelope": {
            "data": [
                2065
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateManyShipmentStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateNestedManyWithoutShipmentStatusInput": {
            "create": [
                2067
            ],
            "connectOrCreate": [
                2071
            ],
            "createMany": [
                2072
            ],
            "connect": [
                483
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateWithoutShipmentStatusInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Shipment": [
                2068
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateNestedOneWithoutShipmentEventInput": {
            "create": [
                2069
            ],
            "connectOrCreate": [
                2070
            ],
            "connect": [
                477
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateWithoutShipmentEventInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                1902
            ],
            "CargoAgency": [
                1783
            ],
            "ShipmentStatus": [
                1786
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateOrConnectWithoutShipmentEventInput": {
            "where": [
                477
            ],
            "create": [
                2069
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateOrConnectWithoutShipmentStatusInput": {
            "where": [
                483
            ],
            "create": [
                2067
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateManyShipmentStatusInputEnvelope": {
            "data": [
                2073
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateManyShipmentStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateOrConnectWithoutImageInput": {
            "where": [
                1488
            ],
            "create": [
                1899
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutProductInput": {
            "where": [
                454
            ],
            "create": [
                1897
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutCollectionInput": {
            "where": [
                456
            ],
            "create": [
                1895
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateOrConnectWithoutImageInput": {
            "where": [
                559
            ],
            "create": [
                1893
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutImagesInput": {
            "create": [
                2079
            ],
            "connectOrCreate": [
                2088
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutImagesInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateNestedManyWithoutProductInput": {
            "create": [
                2081
            ],
            "connectOrCreate": [
                2087
            ],
            "connect": [
                559
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateWithoutProductInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Image": [
                2082
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateNestedManyWithoutCollectionInput": {
            "create": [
                2083
            ],
            "connectOrCreate": [
                2084
            ],
            "createMany": [
                2085
            ],
            "connect": [
                454
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateWithoutCollectionInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Campaign": [
                1798
            ],
            "Category": [
                1806
            ],
            "shipmentStatus": [
                1898
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutCollectionInput": {
            "where": [
                454
            ],
            "create": [
                2083
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCollectionInputEnvelope": {
            "data": [
                2086
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCollectionInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateOrConnectWithoutProductInput": {
            "where": [
                559
            ],
            "create": [
                2081
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutImagesInput": {
            "where": [
                456
            ],
            "create": [
                2079
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutCampaignInput": {
            "where": [
                454
            ],
            "create": [
                1891
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCampaignInputEnvelope": {
            "data": [
                2091
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCampaignInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateOrConnectWithoutProductInput": {
            "where": [
                557
            ],
            "create": [
                1889
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutProductTypeInput": {
            "where": [
                456
            ],
            "create": [
                1887
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyProductTypeInputEnvelope": {
            "data": [
                2095
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyProductTypeInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateOrConnectWithoutAttributeToProductTypeInput": {
            "where": [
                1426
            ],
            "create": [
                1885
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateOrConnectWithoutAttributeInput": {
            "where": [
                498
            ],
            "create": [
                1883
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateManyAttributeInputEnvelope": {
            "data": [
                2099
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateManyAttributeInput": {
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateOrConnectWithoutAttributeValueInput": {
            "where": [
                635
            ],
            "create": [
                1881
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateOrConnectWithoutProductInput": {
            "where": [
                506
            ],
            "create": [
                1879
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutSupportItemInput": {
            "where": [
                456
            ],
            "create": [
                1877
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateOrConnectWithoutSupportAttachmentInput": {
            "where": [
                540
            ],
            "create": [
                1875
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutSupportAttachmentInput": {
            "create": [
                2105
            ],
            "connectOrCreate": [
                2106
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutSupportAttachmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutSupportAttachmentInput": {
            "where": [
                572
            ],
            "create": [
                2105
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateOrConnectWithoutSupportInput": {
            "where": [
                538
            ],
            "create": [
                1873
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManySupportInputEnvelope": {
            "data": [
                2109
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManySupportInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateOrConnectWithoutSupportMessageInput": {
            "where": [
                1504
            ],
            "create": [
                1871
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateOrConnectWithoutSupportEventInput": {
            "where": [
                555
            ],
            "create": [
                1869
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateOrConnectWithoutItemInput": {
            "where": [
                553
            ],
            "create": [
                1867
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyItemInputEnvelope": {
            "data": [
                2114
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyItemInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateOrConnectWithoutSupportInput": {
            "where": [
                540
            ],
            "create": [
                1865
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManySupportInputEnvelope": {
            "data": [
                2117
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManySupportInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateOrConnectWithoutSupportAttachmentInput": {
            "where": [
                1504
            ],
            "create": [
                1863
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateOrConnectWithoutSupportItemInput": {
            "where": [
                538
            ],
            "create": [
                1861
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManySupportItemInputEnvelope": {
            "data": [
                2121
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManySupportItemInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateOrConnectWithoutProductInput": {
            "where": [
                540
            ],
            "create": [
                1859
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManyProductInputEnvelope": {
            "data": [
                2124
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManyProductInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutAttributeValuesInput": {
            "where": [
                456
            ],
            "create": [
                1847
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateOrConnectWithoutAttributeInput": {
            "where": [
                506
            ],
            "create": [
                1845
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateManyAttributeInputEnvelope": {
            "data": [
                2128
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateManyAttributeInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateOrConnectWithoutAttributeToProductTypeInput": {
            "where": [
                635
            ],
            "create": [
                1843
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateOrConnectWithoutProductTypeInput": {
            "where": [
                498
            ],
            "create": [
                1841
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateManyProductTypeInputEnvelope": {
            "data": [
                2132
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateManyProductTypeInput": {
            "attribute_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateOrConnectWithoutProductInput": {
            "where": [
                1426
            ],
            "create": [
                1839
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutCategoryInput": {
            "where": [
                456
            ],
            "create": [
                1829
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyCategoryInputEnvelope": {
            "data": [
                2136
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyCategoryInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateNestedManyWithoutCategoriesInput": {
            "create": [
                2138
            ],
            "connectOrCreate": [
                2142
            ],
            "connect": [
                467
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateWithoutCategoriesInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1804
            ],
            "Product": [
                1792
            ],
            "Company": [
                2139
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedManyWithoutBrandInput": {
            "create": [
                2140
            ],
            "connectOrCreate": [
                2141
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutBrandInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutBrandInput": {
            "where": [
                487
            ],
            "create": [
                2140
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateOrConnectWithoutCategoriesInput": {
            "where": [
                467
            ],
            "create": [
                2138
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateOrConnectWithoutPriceToCategoryInput": {
            "where": [
                485
            ],
            "create": [
                1827
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateNestedOneWithoutPriceToCategoryInput": {
            "create": [
                2145
            ],
            "connectOrCreate": [
                2151
            ],
            "connect": [
                1352
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateWithoutPriceToCategoryInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                2146
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedManyWithoutPriceInput": {
            "create": [
                2147
            ],
            "connectOrCreate": [
                2148
            ],
            "createMany": [
                2149
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutPriceInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutPriceInput": {
            "where": [
                456
            ],
            "create": [
                2147
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyPriceInputEnvelope": {
            "data": [
                2150
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyPriceInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateOrConnectWithoutPriceToCategoryInput": {
            "where": [
                1352
            ],
            "create": [
                2145
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateOrConnectWithoutCompanyInput": {
            "where": [
                464
            ],
            "create": [
                1825
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyCompanyInputEnvelope": {
            "data": [
                2154
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyCompanyInput": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutPaymentInput": {
            "where": [
                487
            ],
            "create": [
                1823
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutPosAccountInput": {
            "where": [
                324
            ],
            "create": [
                1821
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyPosAccountInputEnvelope": {
            "data": [
                2158
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyPosAccountInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateOrConnectWithoutInstallmentToPosAccountInput": {
            "where": [
                348
            ],
            "create": [
                1819
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateOrConnectWithoutInstallmentInput": {
            "where": [
                344
            ],
            "create": [
                1817
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateManyInstallmentInputEnvelope": {
            "data": [
                2162
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateManyInstallmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateOrConnectWithoutInstallmentToCompanyInput": {
            "where": [
                1080
            ],
            "create": [
                1815
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateOrConnectWithoutCompanyInput": {
            "where": [
                338
            ],
            "create": [
                1813
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateManyCompanyInputEnvelope": {
            "data": [
                2166
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateManyCompanyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutPriceToCategoryInput": {
            "where": [
                487
            ],
            "create": [
                1811
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateOrConnectWithoutCategoryInput": {
            "where": [
                464
            ],
            "create": [
                1809
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyCategoryInputEnvelope": {
            "data": [
                2170
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyCategoryInput": {
            "price_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateOrConnectWithoutImageInput": {
            "where": [
                485
            ],
            "create": [
                1807
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutBrandInput": {
            "where": [
                454
            ],
            "create": [
                1805
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyBrandInputEnvelope": {
            "data": [
                2174
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyBrandInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateOrConnectWithoutProductInput": {
            "where": [
                467
            ],
            "create": [
                1803
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutCampaignInput": {
            "where": [
                456
            ],
            "create": [
                1801
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateOrConnectWithoutImageInput": {
            "where": [
                557
            ],
            "create": [
                1799
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutCategoryInput": {
            "where": [
                454
            ],
            "create": [
                1797
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCategoryInputEnvelope": {
            "data": [
                2180
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyCategoryInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateOrConnectWithoutProductInput": {
            "where": [
                485
            ],
            "create": [
                1795
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutBrandInput": {
            "where": [
                456
            ],
            "create": [
                1793
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyBrandInputEnvelope": {
            "data": [
                2184
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyBrandInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateOrConnectWithoutImageInput": {
            "where": [
                467
            ],
            "create": [
                1791
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateOrConnectWithoutShipmentStatusInput": {
            "where": [
                454
            ],
            "create": [
                1789
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyShipmentStatusInputEnvelope": {
            "data": [
                2188
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyShipmentStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateOrConnectWithoutShipmentInput": {
            "where": [
                1488
            ],
            "create": [
                1787
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateOrConnectWithoutOrderItemInput": {
            "where": [
                477
            ],
            "create": [
                1782
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutCartItemInput": {
            "where": [
                437
            ],
            "create": [
                1780
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyCartItemInputEnvelope": {
            "data": [
                2193
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyCartItemInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateOrConnectWithoutProductInput": {
            "where": [
                414
            ],
            "create": [
                1778
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateManyProductInputEnvelope": {
            "data": [
                2196
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateManyProductInput": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutOrderItemInput": {
            "where": [
                456
            ],
            "create": [
                1776
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutUserInput": {
            "where": [
                437
            ],
            "create": [
                1768
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyUserInputEnvelope": {
            "data": [
                2200
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutOrderInput": {
            "where": [
                572
            ],
            "create": [
                1766
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutCartInput": {
            "where": [
                568
            ],
            "create": [
                1764
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutCartItemInput": {
            "where": [
                283
            ],
            "create": [
                1762
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateOrConnectWithoutOrderItemInput": {
            "where": [
                414
            ],
            "create": [
                1760
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutProductInput": {
            "where": [
                437
            ],
            "create": [
                1758
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyProductInputEnvelope": {
            "data": [
                2207
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyProductInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutCartItemInput": {
            "where": [
                456
            ],
            "create": [
                1750
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateOrConnectWithoutCartInput": {
            "where": [
                414
            ],
            "create": [
                1748
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateManyCartInputEnvelope": {
            "data": [
                2211
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateManyCartInput": {
            "id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutUserInput": {
            "where": [
                283
            ],
            "create": [
                1746
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyUserInputEnvelope": {
            "data": [
                2214
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutAddressInput": {
            "where": [
                572
            ],
            "create": [
                1744
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateManyAddressInputEnvelope": {
            "data": [
                2217
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateManyAddressInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutOrderShipmentInput": {
            "where": [
                253
            ],
            "create": [
                1742
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutCompanyInput": {
            "where": [
                568
            ],
            "create": [
                1740
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyCompanyInputEnvelope": {
            "data": [
                2221
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyCompanyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutInstallmentToCompanyInput": {
            "where": [
                487
            ],
            "create": [
                1738
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateOrConnectWithoutInstallmentInput": {
            "where": [
                338
            ],
            "create": [
                1736
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateManyInstallmentInputEnvelope": {
            "data": [
                2225
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateManyInstallmentInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateOrConnectWithoutInstallmentToPosAccountInput": {
            "where": [
                1080
            ],
            "create": [
                1734
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateOrConnectWithoutPosAccountInput": {
            "where": [
                344
            ],
            "create": [
                1732
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateManyPosAccountInputEnvelope": {
            "data": [
                2229
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateManyPosAccountInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateOrConnectWithoutBankInput": {
            "where": [
                348
            ],
            "create": [
                1730
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateManyBankInputEnvelope": {
            "data": [
                2232
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateManyBankInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateOrConnectWithoutPaymentInput": {
            "where": [
                679
            ],
            "create": [
                1723
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutMethodInput": {
            "where": [
                324
            ],
            "create": [
                1721
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyMethodInputEnvelope": {
            "data": [
                2236
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyMethodInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateOrConnectWithoutCompanyToPaymentMethodInput": {
            "where": [
                1257
            ],
            "create": [
                1719
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateOrConnectWithoutCompanyInput": {
            "where": [
                358
            ],
            "create": [
                1717
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateManyCompanyInputEnvelope": {
            "data": [
                2240
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateManyCompanyInput": {
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutOrderInput": {
            "where": [
                487
            ],
            "create": [
                1710
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutShipmentAddressInput": {
            "where": [
                568
            ],
            "create": [
                1708
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyShipmentAddressInputEnvelope": {
            "data": [
                2244
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyShipmentAddressInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutOrderBillingInput": {
            "where": [
                253
            ],
            "create": [
                1706
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutUserInput": {
            "where": [
                568
            ],
            "create": [
                1704
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyUserInputEnvelope": {
            "data": [
                2248
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyUserInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutCartInput": {
            "where": [
                572
            ],
            "create": [
                1697
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutOrderInput": {
            "where": [
                283
            ],
            "create": [
                1695
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateOrConnectWithoutBillingAddressInput": {
            "where": [
                568
            ],
            "create": [
                1693
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyBillingAddressInputEnvelope": {
            "data": [
                2253
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyBillingAddressInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutCartShipmentInput": {
            "where": [
                253
            ],
            "create": [
                1691
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutBillingAddressInput": {
            "where": [
                283
            ],
            "create": [
                1689
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyBillingAddressInputEnvelope": {
            "data": [
                2257
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyBillingAddressInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutCompanyInput": {
            "where": [
                253
            ],
            "create": [
                1687
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateManyCompanyInputEnvelope": {
            "data": [
                2260
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateManyCompanyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutCartInput": {
            "where": [
                487
            ],
            "create": [
                1685
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutShipmentAddressInput": {
            "where": [
                283
            ],
            "create": [
                1683
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyShipmentAddressInputEnvelope": {
            "data": [
                2264
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyShipmentAddressInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutCartBillingInput": {
            "where": [
                253
            ],
            "create": [
                1670
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateOrConnectWithoutCompanyInput": {
            "where": [
                283
            ],
            "create": [
                1668
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyCompanyInputEnvelope": {
            "data": [
                2268
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyCompanyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutAddressInput": {
            "where": [
                487
            ],
            "create": [
                1666
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "BoolFieldUpdateOperationsInput": {
            "set": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "NullableStringFieldUpdateOperationsInput": {
            "set": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "NullableIntFieldUpdateOperationsInput": {
            "set": [
                3
            ],
            "increment": [
                3
            ],
            "decrement": [
                3
            ],
            "multiply": [
                3
            ],
            "divide": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DateTimeFieldUpdateOperationsInput": {
            "set": [
                10
            ],
            "__typename": [
                9
            ]
        },
        "EnumSourceFieldUpdateOperationsInput": {
            "set": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumPlatformFieldUpdateOperationsInput": {
            "set": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumAddressTypeFieldUpdateOperationsInput": {
            "set": [
                13
            ],
            "__typename": [
                9
            ]
        },
        "NullableBoolFieldUpdateOperationsInput": {
            "set": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutAddressNestedInput": {
            "create": [
                1666
            ],
            "connectOrCreate": [
                2269
            ],
            "upsert": [
                2281
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                3085
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutAddressInput": {
            "update": [
                2282
            ],
            "create": [
                1666
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1668
            ],
            "connectOrCreate": [
                2266
            ],
            "upsert": [
                2284
            ],
            "createMany": [
                2267
            ],
            "set": [
                283
            ],
            "disconnect": [
                283
            ],
            "delete": [
                283
            ],
            "connect": [
                283
            ],
            "update": [
                3083
            ],
            "updateMany": [
                3084
            ],
            "deleteMany": [
                3040
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                283
            ],
            "update": [
                2285
            ],
            "create": [
                1668
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "ShipmentAddress": [
                2319
            ],
            "User": [
                2331
            ],
            "CartItem": [
                2410
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumCartStatusFieldUpdateOperationsInput": {
            "set": [
                41
            ],
            "__typename": [
                9
            ]
        },
        "NullableDateTimeFieldUpdateOperationsInput": {
            "set": [
                10
            ],
            "__typename": [
                9
            ]
        },
        "NullableDecimalFieldUpdateOperationsInput": {
            "set": [
                46
            ],
            "increment": [
                46
            ],
            "decrement": [
                46
            ],
            "multiply": [
                46
            ],
            "divide": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateOneWithoutCartBillingNestedInput": {
            "create": [
                1670
            ],
            "connectOrCreate": [
                2265
            ],
            "upsert": [
                2290
            ],
            "disconnect": [
                15
            ],
            "delete": [
                15
            ],
            "connect": [
                253
            ],
            "update": [
                3082
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithoutCartBillingInput": {
            "update": [
                2291
            ],
            "create": [
                1670
            ],
            "where": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutCartBillingInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateOneWithoutAddressNestedInput": {
            "create": [
                1672
            ],
            "connectOrCreate": [
                1681
            ],
            "upsert": [
                2293
            ],
            "disconnect": [
                220
            ],
            "delete": [
                220
            ],
            "connect": [
                594
            ],
            "update": [
                2306
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpsertWithoutAddressInput": {
            "update": [
                2294
            ],
            "create": [
                1672
            ],
            "where": [
                220
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateWithoutAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "City": [
                2295
            ],
            "District": [
                2299
            ],
            "__typename": [
                9
            ]
        },
        "CityUpdateOneWithoutCountyNestedInput": {
            "create": [
                1674
            ],
            "connectOrCreate": [
                1675
            ],
            "upsert": [
                2296
            ],
            "disconnect": [
                222
            ],
            "delete": [
                222
            ],
            "connect": [
                820
            ],
            "update": [
                2298
            ],
            "__typename": [
                9
            ]
        },
        "CityUpsertWithoutCountyInput": {
            "update": [
                2297
            ],
            "create": [
                1674
            ],
            "where": [
                222
            ],
            "__typename": [
                9
            ]
        },
        "CityUpdateWithoutCountyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "CityUpdateToOneWithWhereWithoutCountyInput": {
            "where": [
                222
            ],
            "data": [
                2297
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateManyWithoutCountyNestedInput": {
            "create": [
                1677
            ],
            "connectOrCreate": [
                1678
            ],
            "upsert": [
                2300
            ],
            "createMany": [
                1679
            ],
            "set": [
                600
            ],
            "disconnect": [
                600
            ],
            "delete": [
                600
            ],
            "connect": [
                600
            ],
            "update": [
                2302
            ],
            "updateMany": [
                2303
            ],
            "deleteMany": [
                2304
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpsertWithWhereUniqueWithoutCountyInput": {
            "where": [
                600
            ],
            "update": [
                2301
            ],
            "create": [
                1677
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateWithoutCountyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateWithWhereUniqueWithoutCountyInput": {
            "where": [
                600
            ],
            "data": [
                2301
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateManyWithWhereWithoutCountyInput": {
            "where": [
                2304
            ],
            "data": [
                2305
            ],
            "__typename": [
                9
            ]
        },
        "DistrictScalarWhereInput": {
            "AND": [
                2304
            ],
            "OR": [
                2304
            ],
            "NOT": [
                2304
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "countyId": [
                23
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateToOneWithWhereWithoutAddressInput": {
            "where": [
                220
            ],
            "data": [
                2294
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithoutShipmentAddressNestedInput": {
            "create": [
                1683
            ],
            "connectOrCreate": [
                2262
            ],
            "upsert": [
                2308
            ],
            "createMany": [
                2263
            ],
            "set": [
                283
            ],
            "disconnect": [
                283
            ],
            "delete": [
                283
            ],
            "connect": [
                283
            ],
            "update": [
                3080
            ],
            "updateMany": [
                3081
            ],
            "deleteMany": [
                3040
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithWhereUniqueWithoutShipmentAddressInput": {
            "where": [
                283
            ],
            "update": [
                2309
            ],
            "create": [
                1683
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutShipmentAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "Company": [
                2310
            ],
            "User": [
                2331
            ],
            "CartItem": [
                2410
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutCartNestedInput": {
            "create": [
                1685
            ],
            "connectOrCreate": [
                2261
            ],
            "upsert": [
                2311
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                3079
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutCartInput": {
            "update": [
                2312
            ],
            "create": [
                1685
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutCartInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1687
            ],
            "connectOrCreate": [
                2258
            ],
            "upsert": [
                2314
            ],
            "createMany": [
                2259
            ],
            "set": [
                253
            ],
            "disconnect": [
                253
            ],
            "delete": [
                253
            ],
            "connect": [
                253
            ],
            "update": [
                3076
            ],
            "updateMany": [
                3077
            ],
            "deleteMany": [
                3078
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                253
            ],
            "update": [
                2315
            ],
            "create": [
                1687
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithoutBillingAddressNestedInput": {
            "create": [
                1689
            ],
            "connectOrCreate": [
                2255
            ],
            "upsert": [
                2317
            ],
            "createMany": [
                2256
            ],
            "set": [
                283
            ],
            "disconnect": [
                283
            ],
            "delete": [
                283
            ],
            "connect": [
                283
            ],
            "update": [
                3074
            ],
            "updateMany": [
                3075
            ],
            "deleteMany": [
                3040
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithWhereUniqueWithoutBillingAddressInput": {
            "where": [
                283
            ],
            "update": [
                2318
            ],
            "create": [
                1689
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutBillingAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "Company": [
                2310
            ],
            "ShipmentAddress": [
                2319
            ],
            "User": [
                2331
            ],
            "CartItem": [
                2410
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateOneWithoutCartShipmentNestedInput": {
            "create": [
                1691
            ],
            "connectOrCreate": [
                2254
            ],
            "upsert": [
                2320
            ],
            "disconnect": [
                15
            ],
            "delete": [
                15
            ],
            "connect": [
                253
            ],
            "update": [
                3073
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithoutCartShipmentInput": {
            "update": [
                2321
            ],
            "create": [
                1691
            ],
            "where": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutCartShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithoutBillingAddressNestedInput": {
            "create": [
                1693
            ],
            "connectOrCreate": [
                2251
            ],
            "upsert": [
                2323
            ],
            "createMany": [
                2252
            ],
            "set": [
                568
            ],
            "disconnect": [
                568
            ],
            "delete": [
                568
            ],
            "connect": [
                568
            ],
            "update": [
                3071
            ],
            "updateMany": [
                3072
            ],
            "deleteMany": [
                2863
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithWhereUniqueWithoutBillingAddressInput": {
            "where": [
                568
            ],
            "update": [
                2324
            ],
            "create": [
                1693
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutBillingAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "StringFieldUpdateOperationsInput": {
            "set": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumOrderPaymentStatusFieldUpdateOperationsInput": {
            "set": [
                64
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumIsSendStatusFieldUpdateOperationsInput": {
            "set": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateOneWithoutOrderNestedInput": {
            "create": [
                1695
            ],
            "connectOrCreate": [
                2250
            ],
            "upsert": [
                2329
            ],
            "disconnect": [
                39
            ],
            "delete": [
                39
            ],
            "connect": [
                283
            ],
            "update": [
                3070
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithoutOrderInput": {
            "update": [
                2330
            ],
            "create": [
                1695
            ],
            "where": [
                39
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutOrderInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "Company": [
                2310
            ],
            "ShipmentAddress": [
                2319
            ],
            "User": [
                2331
            ],
            "CartItem": [
                2410
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutCartNestedInput": {
            "create": [
                1697
            ],
            "connectOrCreate": [
                2249
            ],
            "upsert": [
                2332
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                3069
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutCartInput": {
            "update": [
                2333
            ],
            "create": [
                1697
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutCartInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumGenderFieldUpdateOperationsInput": {
            "set": [
                52
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateManyWithoutUserNestedInput": {
            "create": [
                1699
            ],
            "connectOrCreate": [
                1700
            ],
            "upsert": [
                2336
            ],
            "createMany": [
                1701
            ],
            "set": [
                289
            ],
            "disconnect": [
                289
            ],
            "delete": [
                289
            ],
            "connect": [
                289
            ],
            "update": [
                2339
            ],
            "updateMany": [
                2340
            ],
            "deleteMany": [
                2341
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                289
            ],
            "update": [
                2337
            ],
            "create": [
                1699
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateWithoutUserInput": {
            "code": [
                2272
            ],
            "expire_at": [
                2287
            ],
            "confirmed_at": [
                2287
            ],
            "type": [
                2338
            ],
            "gsm_no": [
                2272
            ],
            "email": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumConfirmCodeTypeFieldUpdateOperationsInput": {
            "set": [
                57
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                289
            ],
            "data": [
                2337
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2341
            ],
            "data": [
                2342
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeScalarWhereInput": {
            "AND": [
                2341
            ],
            "OR": [
                2341
            ],
            "NOT": [
                2341
            ],
            "id": [
                16
            ],
            "code": [
                20
            ],
            "expire_at": [
                43
            ],
            "confirmed_at": [
                43
            ],
            "user_id": [
                23
            ],
            "type": [
                56
            ],
            "gsm_no": [
                20
            ],
            "email": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateManyMutationInput": {
            "code": [
                2272
            ],
            "expire_at": [
                2287
            ],
            "confirmed_at": [
                2287
            ],
            "type": [
                2338
            ],
            "gsm_no": [
                2272
            ],
            "email": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithoutUserNestedInput": {
            "create": [
                1704
            ],
            "connectOrCreate": [
                2246
            ],
            "upsert": [
                2344
            ],
            "createMany": [
                2247
            ],
            "set": [
                568
            ],
            "disconnect": [
                568
            ],
            "delete": [
                568
            ],
            "connect": [
                568
            ],
            "update": [
                3067
            ],
            "updateMany": [
                3068
            ],
            "deleteMany": [
                2863
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                568
            ],
            "update": [
                2345
            ],
            "create": [
                1704
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateOneWithoutOrderBillingNestedInput": {
            "create": [
                1706
            ],
            "connectOrCreate": [
                2245
            ],
            "upsert": [
                2347
            ],
            "disconnect": [
                15
            ],
            "delete": [
                15
            ],
            "connect": [
                253
            ],
            "update": [
                3066
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithoutOrderBillingInput": {
            "update": [
                2348
            ],
            "create": [
                1706
            ],
            "where": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutOrderBillingInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithoutShipmentAddressNestedInput": {
            "create": [
                1708
            ],
            "connectOrCreate": [
                2242
            ],
            "upsert": [
                2350
            ],
            "createMany": [
                2243
            ],
            "set": [
                568
            ],
            "disconnect": [
                568
            ],
            "delete": [
                568
            ],
            "connect": [
                568
            ],
            "update": [
                3064
            ],
            "updateMany": [
                3065
            ],
            "deleteMany": [
                2863
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithWhereUniqueWithoutShipmentAddressInput": {
            "where": [
                568
            ],
            "update": [
                2351
            ],
            "create": [
                1708
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutShipmentAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutOrderNestedInput": {
            "create": [
                1710
            ],
            "connectOrCreate": [
                2241
            ],
            "upsert": [
                2353
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                3063
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutOrderInput": {
            "update": [
                2354
            ],
            "create": [
                1710
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutOrderInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1712
            ],
            "connectOrCreate": [
                1713
            ],
            "upsert": [
                2356
            ],
            "createMany": [
                1714
            ],
            "set": [
                588
            ],
            "disconnect": [
                588
            ],
            "delete": [
                588
            ],
            "connect": [
                588
            ],
            "update": [
                2358
            ],
            "updateMany": [
                2359
            ],
            "deleteMany": [
                2360
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                588
            ],
            "update": [
                2357
            ],
            "create": [
                1712
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "document_type": [
                2272
            ],
            "document_number": [
                2272
            ],
            "description": [
                2272
            ],
            "debit": [
                2288
            ],
            "credit": [
                2288
            ],
            "balance": [
                2288
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "document_date": [
                2287
            ],
            "data_number": [
                2273
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                588
            ],
            "data": [
                2357
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2360
            ],
            "data": [
                2361
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementScalarWhereInput": {
            "AND": [
                2360
            ],
            "OR": [
                2360
            ],
            "NOT": [
                2360
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "document_type": [
                20
            ],
            "document_number": [
                20
            ],
            "description": [
                20
            ],
            "debit": [
                45
            ],
            "credit": [
                45
            ],
            "balance": [
                45
            ],
            "code": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "company_id": [
                23
            ],
            "document_date": [
                43
            ],
            "data_number": [
                23
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "document_type": [
                2272
            ],
            "document_number": [
                2272
            ],
            "description": [
                2272
            ],
            "debit": [
                2288
            ],
            "credit": [
                2288
            ],
            "balance": [
                2288
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "document_date": [
                2287
            ],
            "data_number": [
                2273
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1717
            ],
            "connectOrCreate": [
                2238
            ],
            "upsert": [
                2363
            ],
            "createMany": [
                2239
            ],
            "set": [
                358
            ],
            "disconnect": [
                358
            ],
            "delete": [
                358
            ],
            "connect": [
                358
            ],
            "update": [
                3061
            ],
            "updateMany": [
                3062
            ],
            "deleteMany": [
                2843
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                358
            ],
            "update": [
                2364
            ],
            "create": [
                1717
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateWithoutCompanyInput": {
            "minimum_amount": [
                2288
            ],
            "maximum_amount": [
                2288
            ],
            "end_date": [
                2287
            ],
            "start_date": [
                2287
            ],
            "total_amount": [
                2288
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "PaymentMethod": [
                2365
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateOneRequiredWithoutCompanyToPaymentMethodNestedInput": {
            "create": [
                1719
            ],
            "connectOrCreate": [
                2237
            ],
            "upsert": [
                2366
            ],
            "connect": [
                1257
            ],
            "update": [
                3060
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpsertWithoutCompanyToPaymentMethodInput": {
            "update": [
                2367
            ],
            "create": [
                1719
            ],
            "where": [
                104
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateWithoutCompanyToPaymentMethodInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "minimum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "title": [
                2272
            ],
            "is_default": [
                2278
            ],
            "Payment": [
                2368
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutMethodNestedInput": {
            "create": [
                1721
            ],
            "connectOrCreate": [
                2234
            ],
            "upsert": [
                2369
            ],
            "createMany": [
                2235
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                3058
            ],
            "updateMany": [
                3059
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutMethodInput": {
            "where": [
                324
            ],
            "update": [
                2370
            ],
            "create": [
                1721
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutMethodInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumPaymentStatusFieldUpdateOperationsInput": {
            "set": [
                76
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumGatewayTypeFieldUpdateOperationsInput": {
            "set": [
                81
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateOneWithoutPaymentNestedInput": {
            "create": [
                1723
            ],
            "connectOrCreate": [
                2233
            ],
            "upsert": [
                2374
            ],
            "disconnect": [
                84
            ],
            "delete": [
                84
            ],
            "connect": [
                679
            ],
            "update": [
                3057
            ],
            "__typename": [
                9
            ]
        },
        "BankUpsertWithoutPaymentInput": {
            "update": [
                2375
            ],
            "create": [
                1723
            ],
            "where": [
                84
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "bin_numbers": [
                2376
            ],
            "PosAccount": [
                2383
            ],
            "PosConfig": [
                2809
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateManyWithoutBankNestedInput": {
            "create": [
                1725
            ],
            "connectOrCreate": [
                1726
            ],
            "upsert": [
                2377
            ],
            "createMany": [
                1727
            ],
            "set": [
                308
            ],
            "disconnect": [
                308
            ],
            "delete": [
                308
            ],
            "connect": [
                308
            ],
            "update": [
                2379
            ],
            "updateMany": [
                2380
            ],
            "deleteMany": [
                2381
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpsertWithWhereUniqueWithoutBankInput": {
            "where": [
                308
            ],
            "update": [
                2378
            ],
            "create": [
                1725
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateWithoutBankInput": {
            "active": [
                2271
            ],
            "is_bussiness_card": [
                2278
            ],
            "network": [
                2272
            ],
            "family": [
                2272
            ],
            "binnumber": [
                2272
            ],
            "cardtype": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateWithWhereUniqueWithoutBankInput": {
            "where": [
                308
            ],
            "data": [
                2378
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateManyWithWhereWithoutBankInput": {
            "where": [
                2381
            ],
            "data": [
                2382
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberScalarWhereInput": {
            "AND": [
                2381
            ],
            "OR": [
                2381
            ],
            "NOT": [
                2381
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "is_bussiness_card": [
                33
            ],
            "network": [
                20
            ],
            "family": [
                20
            ],
            "bank_id": [
                23
            ],
            "binnumber": [
                20
            ],
            "cardtype": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "is_bussiness_card": [
                2278
            ],
            "network": [
                2272
            ],
            "family": [
                2272
            ],
            "binnumber": [
                2272
            ],
            "cardtype": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateManyWithoutBankNestedInput": {
            "create": [
                1730
            ],
            "connectOrCreate": [
                2230
            ],
            "upsert": [
                2384
            ],
            "createMany": [
                2231
            ],
            "set": [
                348
            ],
            "disconnect": [
                348
            ],
            "delete": [
                348
            ],
            "connect": [
                348
            ],
            "update": [
                3053
            ],
            "updateMany": [
                3054
            ],
            "deleteMany": [
                3055
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpsertWithWhereUniqueWithoutBankInput": {
            "where": [
                348
            ],
            "update": [
                2385
            ],
            "create": [
                1730
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithoutBankInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "InstallmentToPosAccount": [
                2386
            ],
            "Payment": [
                2522
            ],
            "PaymentSystem": [
                2670
            ],
            "PosConfig": [
                2821
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateManyWithoutPosAccountNestedInput": {
            "create": [
                1732
            ],
            "connectOrCreate": [
                2227
            ],
            "upsert": [
                2387
            ],
            "createMany": [
                2228
            ],
            "set": [
                344
            ],
            "disconnect": [
                344
            ],
            "delete": [
                344
            ],
            "connect": [
                344
            ],
            "update": [
                3051
            ],
            "updateMany": [
                3052
            ],
            "deleteMany": [
                2993
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpsertWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                344
            ],
            "update": [
                2388
            ],
            "create": [
                1732
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateWithoutPosAccountInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "Installment": [
                2389
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateOneRequiredWithoutInstallmentToPosAccountNestedInput": {
            "create": [
                1734
            ],
            "connectOrCreate": [
                2226
            ],
            "upsert": [
                2390
            ],
            "connect": [
                1080
            ],
            "update": [
                3050
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpsertWithoutInstallmentToPosAccountInput": {
            "update": [
                2391
            ],
            "create": [
                1734
            ],
            "where": [
                93
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateWithoutInstallmentToPosAccountInput": {
            "code": [
                2272
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "count": [
                2273
            ],
            "InstallmentToCompany": [
                2392
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateManyWithoutInstallmentNestedInput": {
            "create": [
                1736
            ],
            "connectOrCreate": [
                2223
            ],
            "upsert": [
                2393
            ],
            "createMany": [
                2224
            ],
            "set": [
                338
            ],
            "disconnect": [
                338
            ],
            "delete": [
                338
            ],
            "connect": [
                338
            ],
            "update": [
                3048
            ],
            "updateMany": [
                3049
            ],
            "deleteMany": [
                2998
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpsertWithWhereUniqueWithoutInstallmentInput": {
            "where": [
                338
            ],
            "update": [
                2394
            ],
            "create": [
                1736
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateWithoutInstallmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "Company": [
                2395
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneRequiredWithoutInstallmentToCompanyNestedInput": {
            "create": [
                1738
            ],
            "connectOrCreate": [
                2222
            ],
            "upsert": [
                2396
            ],
            "connect": [
                487
            ],
            "update": [
                3047
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutInstallmentToCompanyInput": {
            "update": [
                2397
            ],
            "create": [
                1738
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutInstallmentToCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1740
            ],
            "connectOrCreate": [
                2219
            ],
            "upsert": [
                2399
            ],
            "createMany": [
                2220
            ],
            "set": [
                568
            ],
            "disconnect": [
                568
            ],
            "delete": [
                568
            ],
            "connect": [
                568
            ],
            "update": [
                3045
            ],
            "updateMany": [
                3046
            ],
            "deleteMany": [
                2863
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                568
            ],
            "update": [
                2400
            ],
            "create": [
                1740
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateOneWithoutOrderShipmentNestedInput": {
            "create": [
                1742
            ],
            "connectOrCreate": [
                2218
            ],
            "upsert": [
                2402
            ],
            "disconnect": [
                15
            ],
            "delete": [
                15
            ],
            "connect": [
                253
            ],
            "update": [
                3044
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithoutOrderShipmentInput": {
            "update": [
                2403
            ],
            "create": [
                1742
            ],
            "where": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutOrderShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithoutAddressNestedInput": {
            "create": [
                1744
            ],
            "connectOrCreate": [
                2215
            ],
            "upsert": [
                2405
            ],
            "createMany": [
                2216
            ],
            "set": [
                572
            ],
            "disconnect": [
                572
            ],
            "delete": [
                572
            ],
            "connect": [
                572
            ],
            "update": [
                3042
            ],
            "updateMany": [
                3043
            ],
            "deleteMany": [
                2838
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithWhereUniqueWithoutAddressInput": {
            "where": [
                572
            ],
            "update": [
                2406
            ],
            "create": [
                1744
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutAddressInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithoutUserNestedInput": {
            "create": [
                1746
            ],
            "connectOrCreate": [
                2212
            ],
            "upsert": [
                2408
            ],
            "createMany": [
                2213
            ],
            "set": [
                283
            ],
            "disconnect": [
                283
            ],
            "delete": [
                283
            ],
            "connect": [
                283
            ],
            "update": [
                3038
            ],
            "updateMany": [
                3039
            ],
            "deleteMany": [
                3040
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                283
            ],
            "update": [
                2409
            ],
            "create": [
                1746
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "Company": [
                2310
            ],
            "ShipmentAddress": [
                2319
            ],
            "CartItem": [
                2410
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateManyWithoutCartNestedInput": {
            "create": [
                1748
            ],
            "connectOrCreate": [
                2209
            ],
            "upsert": [
                2411
            ],
            "createMany": [
                2210
            ],
            "set": [
                414
            ],
            "disconnect": [
                414
            ],
            "delete": [
                414
            ],
            "connect": [
                414
            ],
            "update": [
                3036
            ],
            "updateMany": [
                3037
            ],
            "deleteMany": [
                3024
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpsertWithWhereUniqueWithoutCartInput": {
            "where": [
                414
            ],
            "update": [
                2412
            ],
            "create": [
                1748
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateWithoutCartInput": {
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "vat": [
                2288
            ],
            "unit_code": [
                2272
            ],
            "unit": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "features": [
                79
            ],
            "discount_rate": [
                2288
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                2413
            ],
            "OrderItem": [
                2459
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutCartItemNestedInput": {
            "create": [
                1750
            ],
            "connectOrCreate": [
                2208
            ],
            "upsert": [
                2414
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                3035
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutCartItemInput": {
            "update": [
                2415
            ],
            "create": [
                1750
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutCartItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "NullableFloatFieldUpdateOperationsInput": {
            "set": [
                5
            ],
            "increment": [
                5
            ],
            "decrement": [
                5
            ],
            "multiply": [
                5
            ],
            "divide": [
                5
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdatefeaturesInput": {
            "set": [
                79
            ],
            "push": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateManyWithoutProductNestedInput": {
            "create": [
                1753
            ],
            "connectOrCreate": [
                1754
            ],
            "upsert": [
                2419
            ],
            "createMany": [
                1755
            ],
            "set": [
                409
            ],
            "disconnect": [
                409
            ],
            "delete": [
                409
            ],
            "connect": [
                409
            ],
            "update": [
                2421
            ],
            "updateMany": [
                2422
            ],
            "deleteMany": [
                2423
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                409
            ],
            "update": [
                2420
            ],
            "create": [
                1753
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "module": [
                2272
            ],
            "module_out_ref": [
                2273
            ],
            "barcode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                409
            ],
            "data": [
                2420
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2423
            ],
            "data": [
                2424
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeScalarWhereInput": {
            "AND": [
                2423
            ],
            "OR": [
                2423
            ],
            "NOT": [
                2423
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "module": [
                20
            ],
            "module_out_ref": [
                23
            ],
            "product_id": [
                23
            ],
            "barcode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "module": [
                2272
            ],
            "module_out_ref": [
                2273
            ],
            "barcode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutProductNestedInput": {
            "create": [
                1758
            ],
            "connectOrCreate": [
                2205
            ],
            "upsert": [
                2426
            ],
            "createMany": [
                2206
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                3033
            ],
            "updateMany": [
                3034
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                437
            ],
            "update": [
                2427
            ],
            "create": [
                1758
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "OrderItemStatus": [
                2444
            ],
            "Shipment": [
                2462
            ],
            "User": [
                2649
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumShipmentMethodFieldUpdateOperationsInput": {
            "set": [
                116
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateOneWithoutOrderItemNestedInput": {
            "create": [
                1760
            ],
            "connectOrCreate": [
                2204
            ],
            "upsert": [
                2430
            ],
            "disconnect": [
                119
            ],
            "delete": [
                119
            ],
            "connect": [
                414
            ],
            "update": [
                3032
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpsertWithoutOrderItemInput": {
            "update": [
                2431
            ],
            "create": [
                1760
            ],
            "where": [
                119
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateWithoutOrderItemInput": {
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "vat": [
                2288
            ],
            "unit_code": [
                2272
            ],
            "unit": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "features": [
                79
            ],
            "discount_rate": [
                2288
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2432
            ],
            "Product": [
                2413
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateOneWithoutCartItemNestedInput": {
            "create": [
                1762
            ],
            "connectOrCreate": [
                2203
            ],
            "upsert": [
                2433
            ],
            "disconnect": [
                39
            ],
            "delete": [
                39
            ],
            "connect": [
                283
            ],
            "update": [
                3031
            ],
            "__typename": [
                9
            ]
        },
        "CartUpsertWithoutCartItemInput": {
            "update": [
                2434
            ],
            "create": [
                1762
            ],
            "where": [
                39
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithoutCartItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "Company": [
                2310
            ],
            "ShipmentAddress": [
                2319
            ],
            "User": [
                2331
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateOneWithoutCartNestedInput": {
            "create": [
                1764
            ],
            "connectOrCreate": [
                2202
            ],
            "upsert": [
                2436
            ],
            "disconnect": [
                60
            ],
            "delete": [
                60
            ],
            "connect": [
                568
            ],
            "update": [
                3030
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithoutCartInput": {
            "update": [
                2437
            ],
            "create": [
                1764
            ],
            "where": [
                60
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutCartInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutOrderNestedInput": {
            "create": [
                1766
            ],
            "connectOrCreate": [
                2201
            ],
            "upsert": [
                2439
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                3029
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutOrderInput": {
            "update": [
                2440
            ],
            "create": [
                1766
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutOrderInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutUserNestedInput": {
            "create": [
                1768
            ],
            "connectOrCreate": [
                2198
            ],
            "upsert": [
                2442
            ],
            "createMany": [
                2199
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                3027
            ],
            "updateMany": [
                3028
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                437
            ],
            "update": [
                2443
            ],
            "create": [
                1768
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "OrderItemStatus": [
                2444
            ],
            "Product": [
                2453
            ],
            "Shipment": [
                2462
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateOneWithoutOrderItemNestedInput": {
            "create": [
                1770
            ],
            "connectOrCreate": [
                1774
            ],
            "upsert": [
                2445
            ],
            "disconnect": [
                204
            ],
            "delete": [
                204
            ],
            "connect": [
                566
            ],
            "update": [
                2452
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpsertWithoutOrderItemInput": {
            "update": [
                2446
            ],
            "create": [
                1770
            ],
            "where": [
                204
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateWithoutOrderItemInput": {
            "hierarchy": [
                2447
            ],
            "color": [
                2272
            ],
            "active": [
                2271
            ],
            "code": [
                2272
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "OrderStatus": [
                2448
            ],
            "__typename": [
                9
            ]
        },
        "IntFieldUpdateOperationsInput": {
            "set": [
                3
            ],
            "increment": [
                3
            ],
            "decrement": [
                3
            ],
            "multiply": [
                3
            ],
            "divide": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpdateOneWithoutOrderItemStatusNestedInput": {
            "create": [
                1772
            ],
            "connectOrCreate": [
                1773
            ],
            "upsert": [
                2449
            ],
            "disconnect": [
                206
            ],
            "delete": [
                206
            ],
            "connect": [
                1172
            ],
            "update": [
                2451
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpsertWithoutOrderItemStatusInput": {
            "update": [
                2450
            ],
            "create": [
                1772
            ],
            "where": [
                206
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpdateWithoutOrderItemStatusInput": {
            "active": [
                2271
            ],
            "arrangement": [
                2447
            ],
            "created_at": [
                2274
            ],
            "name": [
                2272
            ],
            "slug": [
                2272
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpdateToOneWithWhereWithoutOrderItemStatusInput": {
            "where": [
                206
            ],
            "data": [
                2450
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateToOneWithWhereWithoutOrderItemInput": {
            "where": [
                204
            ],
            "data": [
                2446
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutOrderItemNestedInput": {
            "create": [
                1776
            ],
            "connectOrCreate": [
                2197
            ],
            "upsert": [
                2454
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                3026
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutOrderItemInput": {
            "update": [
                2455
            ],
            "create": [
                1776
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutOrderItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateManyWithoutProductNestedInput": {
            "create": [
                1778
            ],
            "connectOrCreate": [
                2194
            ],
            "upsert": [
                2457
            ],
            "createMany": [
                2195
            ],
            "set": [
                414
            ],
            "disconnect": [
                414
            ],
            "delete": [
                414
            ],
            "connect": [
                414
            ],
            "update": [
                3022
            ],
            "updateMany": [
                3023
            ],
            "deleteMany": [
                3024
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                414
            ],
            "update": [
                2458
            ],
            "create": [
                1778
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateWithoutProductInput": {
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "vat": [
                2288
            ],
            "unit_code": [
                2272
            ],
            "unit": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "features": [
                79
            ],
            "discount_rate": [
                2288
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2432
            ],
            "OrderItem": [
                2459
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutCartItemNestedInput": {
            "create": [
                1780
            ],
            "connectOrCreate": [
                2191
            ],
            "upsert": [
                2460
            ],
            "createMany": [
                2192
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                3020
            ],
            "updateMany": [
                3021
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutCartItemInput": {
            "where": [
                437
            ],
            "update": [
                2461
            ],
            "create": [
                1780
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutCartItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "OrderItemStatus": [
                2444
            ],
            "Product": [
                2453
            ],
            "Shipment": [
                2462
            ],
            "User": [
                2649
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateOneWithoutOrderItemNestedInput": {
            "create": [
                1782
            ],
            "connectOrCreate": [
                2190
            ],
            "upsert": [
                2463
            ],
            "disconnect": [
                152
            ],
            "delete": [
                152
            ],
            "connect": [
                477
            ],
            "update": [
                3019
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpsertWithoutOrderItemInput": {
            "update": [
                2464
            ],
            "create": [
                1782
            ],
            "where": [
                152
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithoutOrderItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "CargoAgency": [
                2466
            ],
            "ShipmentStatus": [
                2470
            ],
            "ShipmentEvent": [
                2867
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumShipmentCostStatusFieldUpdateOperationsInput": {
            "set": [
                154
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpdateOneWithoutShipmentNestedInput": {
            "create": [
                1784
            ],
            "connectOrCreate": [
                1785
            ],
            "upsert": [
                2467
            ],
            "disconnect": [
                157
            ],
            "delete": [
                157
            ],
            "connect": [
                753
            ],
            "update": [
                2469
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpsertWithoutShipmentInput": {
            "update": [
                2468
            ],
            "create": [
                1784
            ],
            "where": [
                157
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpdateWithoutShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "phone": [
                2272
            ],
            "email": [
                2272
            ],
            "address": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "creadentials": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpdateToOneWithWhereWithoutShipmentInput": {
            "where": [
                157
            ],
            "data": [
                2468
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateOneWithoutShipmentNestedInput": {
            "create": [
                1787
            ],
            "connectOrCreate": [
                2189
            ],
            "upsert": [
                2471
            ],
            "disconnect": [
                150
            ],
            "delete": [
                150
            ],
            "connect": [
                1488
            ],
            "update": [
                3018
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpsertWithoutShipmentInput": {
            "update": [
                2472
            ],
            "create": [
                1787
            ],
            "where": [
                150
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateWithoutShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "description": [
                2272
            ],
            "color": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "image": [
                2473
            ],
            "ShipmentEvent": [
                2882
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutShipmentStatusNestedInput": {
            "create": [
                1789
            ],
            "connectOrCreate": [
                2186
            ],
            "upsert": [
                2474
            ],
            "createMany": [
                2187
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                3016
            ],
            "updateMany": [
                3017
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                454
            ],
            "update": [
                2475
            ],
            "create": [
                1789
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutShipmentStatusInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Campaign": [
                2489
            ],
            "Category": [
                2501
            ],
            "Collection": [
                2631
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumFolderTypeFieldUpdateOperationsInput": {
            "set": [
                134
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateOneWithoutImageNestedInput": {
            "create": [
                1791
            ],
            "connectOrCreate": [
                2185
            ],
            "upsert": [
                2478
            ],
            "disconnect": [
                130
            ],
            "delete": [
                130
            ],
            "connect": [
                467
            ],
            "update": [
                3015
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpsertWithoutImageInput": {
            "update": [
                2479
            ],
            "create": [
                1791
            ],
            "where": [
                130
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithoutImageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Product": [
                2480
            ],
            "Categories": [
                2733
            ],
            "Company": [
                2968
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutBrandNestedInput": {
            "create": [
                1793
            ],
            "connectOrCreate": [
                2182
            ],
            "upsert": [
                2481
            ],
            "createMany": [
                2183
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                3013
            ],
            "updateMany": [
                3014
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutBrandInput": {
            "where": [
                456
            ],
            "update": [
                2482
            ],
            "create": [
                1793
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutBrandInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateOneWithoutProductNestedInput": {
            "create": [
                1795
            ],
            "connectOrCreate": [
                2181
            ],
            "upsert": [
                2484
            ],
            "disconnect": [
                140
            ],
            "delete": [
                140
            ],
            "connect": [
                485
            ],
            "update": [
                3012
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpsertWithoutProductInput": {
            "update": [
                2485
            ],
            "create": [
                1795
            ],
            "where": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2486
            ],
            "PriceToCategory": [
                2504
            ],
            "Brand": [
                2965
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutCategoryNestedInput": {
            "create": [
                1797
            ],
            "connectOrCreate": [
                2178
            ],
            "upsert": [
                2487
            ],
            "createMany": [
                2179
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                3010
            ],
            "updateMany": [
                3011
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutCategoryInput": {
            "where": [
                454
            ],
            "update": [
                2488
            ],
            "create": [
                1797
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutCategoryInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Campaign": [
                2489
            ],
            "Collection": [
                2631
            ],
            "shipmentStatus": [
                2640
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateOneWithoutImageNestedInput": {
            "create": [
                1799
            ],
            "connectOrCreate": [
                2177
            ],
            "upsert": [
                2490
            ],
            "disconnect": [
                137
            ],
            "delete": [
                137
            ],
            "connect": [
                557
            ],
            "update": [
                3009
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpsertWithoutImageInput": {
            "update": [
                2491
            ],
            "create": [
                1799
            ],
            "where": [
                137
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateWithoutImageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                2492
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutCampaignNestedInput": {
            "create": [
                1801
            ],
            "connectOrCreate": [
                2176
            ],
            "upsert": [
                2493
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                3007
            ],
            "updateMany": [
                3008
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutCampaignInput": {
            "where": [
                456
            ],
            "update": [
                2494
            ],
            "create": [
                1801
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutCampaignInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateOneWithoutProductNestedInput": {
            "create": [
                1803
            ],
            "connectOrCreate": [
                2175
            ],
            "upsert": [
                2496
            ],
            "disconnect": [
                130
            ],
            "delete": [
                130
            ],
            "connect": [
                467
            ],
            "update": [
                3006
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpsertWithoutProductInput": {
            "update": [
                2497
            ],
            "create": [
                1803
            ],
            "where": [
                130
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2498
            ],
            "Categories": [
                2733
            ],
            "Company": [
                2968
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutBrandNestedInput": {
            "create": [
                1805
            ],
            "connectOrCreate": [
                2172
            ],
            "upsert": [
                2499
            ],
            "createMany": [
                2173
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                3004
            ],
            "updateMany": [
                3005
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutBrandInput": {
            "where": [
                454
            ],
            "update": [
                2500
            ],
            "create": [
                1805
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutBrandInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Campaign": [
                2489
            ],
            "Category": [
                2501
            ],
            "Collection": [
                2631
            ],
            "shipmentStatus": [
                2640
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateOneWithoutImageNestedInput": {
            "create": [
                1807
            ],
            "connectOrCreate": [
                2171
            ],
            "upsert": [
                2502
            ],
            "disconnect": [
                140
            ],
            "delete": [
                140
            ],
            "connect": [
                485
            ],
            "update": [
                3003
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpsertWithoutImageInput": {
            "update": [
                2503
            ],
            "create": [
                1807
            ],
            "where": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateWithoutImageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "PriceToCategory": [
                2504
            ],
            "Product": [
                2534
            ],
            "Brand": [
                2965
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithoutCategoryNestedInput": {
            "create": [
                1809
            ],
            "connectOrCreate": [
                2168
            ],
            "upsert": [
                2505
            ],
            "createMany": [
                2169
            ],
            "set": [
                464
            ],
            "disconnect": [
                464
            ],
            "delete": [
                464
            ],
            "connect": [
                464
            ],
            "update": [
                3001
            ],
            "updateMany": [
                3002
            ],
            "deleteMany": [
                2545
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpsertWithWhereUniqueWithoutCategoryInput": {
            "where": [
                464
            ],
            "update": [
                2506
            ],
            "create": [
                1809
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithoutCategoryInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Company": [
                2507
            ],
            "Price": [
                2976
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutPriceToCategoryNestedInput": {
            "create": [
                1811
            ],
            "connectOrCreate": [
                2167
            ],
            "upsert": [
                2508
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                3000
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutPriceToCategoryInput": {
            "update": [
                2509
            ],
            "create": [
                1811
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutPriceToCategoryInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1813
            ],
            "connectOrCreate": [
                2164
            ],
            "upsert": [
                2511
            ],
            "createMany": [
                2165
            ],
            "set": [
                338
            ],
            "disconnect": [
                338
            ],
            "delete": [
                338
            ],
            "connect": [
                338
            ],
            "update": [
                2996
            ],
            "updateMany": [
                2997
            ],
            "deleteMany": [
                2998
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                338
            ],
            "update": [
                2512
            ],
            "create": [
                1813
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "Installment": [
                2513
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateOneRequiredWithoutInstallmentToCompanyNestedInput": {
            "create": [
                1815
            ],
            "connectOrCreate": [
                2163
            ],
            "upsert": [
                2514
            ],
            "connect": [
                1080
            ],
            "update": [
                2995
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpsertWithoutInstallmentToCompanyInput": {
            "update": [
                2515
            ],
            "create": [
                1815
            ],
            "where": [
                93
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateWithoutInstallmentToCompanyInput": {
            "code": [
                2272
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "count": [
                2273
            ],
            "InstallmentToPosAccount": [
                2516
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateManyWithoutInstallmentNestedInput": {
            "create": [
                1817
            ],
            "connectOrCreate": [
                2160
            ],
            "upsert": [
                2517
            ],
            "createMany": [
                2161
            ],
            "set": [
                344
            ],
            "disconnect": [
                344
            ],
            "delete": [
                344
            ],
            "connect": [
                344
            ],
            "update": [
                2991
            ],
            "updateMany": [
                2992
            ],
            "deleteMany": [
                2993
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpsertWithWhereUniqueWithoutInstallmentInput": {
            "where": [
                344
            ],
            "update": [
                2518
            ],
            "create": [
                1817
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateWithoutInstallmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "PosAccount": [
                2519
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateOneRequiredWithoutInstallmentToPosAccountNestedInput": {
            "create": [
                1819
            ],
            "connectOrCreate": [
                2159
            ],
            "upsert": [
                2520
            ],
            "connect": [
                348
            ],
            "update": [
                2990
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpsertWithoutInstallmentToPosAccountInput": {
            "update": [
                2521
            ],
            "create": [
                1819
            ],
            "where": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithoutInstallmentToPosAccountInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "Payment": [
                2522
            ],
            "PaymentSystem": [
                2670
            ],
            "Bank": [
                2674
            ],
            "PosConfig": [
                2821
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutPosAccountNestedInput": {
            "create": [
                1821
            ],
            "connectOrCreate": [
                2156
            ],
            "upsert": [
                2523
            ],
            "createMany": [
                2157
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                2988
            ],
            "updateMany": [
                2989
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                324
            ],
            "update": [
                2524
            ],
            "create": [
                1821
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutPosAccountInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutPaymentNestedInput": {
            "create": [
                1823
            ],
            "connectOrCreate": [
                2155
            ],
            "upsert": [
                2526
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                2987
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutPaymentInput": {
            "update": [
                2527
            ],
            "create": [
                1823
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1825
            ],
            "connectOrCreate": [
                2152
            ],
            "upsert": [
                2529
            ],
            "createMany": [
                2153
            ],
            "set": [
                464
            ],
            "disconnect": [
                464
            ],
            "delete": [
                464
            ],
            "connect": [
                464
            ],
            "update": [
                2985
            ],
            "updateMany": [
                2986
            ],
            "deleteMany": [
                2545
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                464
            ],
            "update": [
                2530
            ],
            "create": [
                1825
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Category": [
                2531
            ],
            "Price": [
                2976
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateOneRequiredWithoutPriceToCategoryNestedInput": {
            "create": [
                1827
            ],
            "connectOrCreate": [
                2143
            ],
            "upsert": [
                2532
            ],
            "connect": [
                485
            ],
            "update": [
                2975
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpsertWithoutPriceToCategoryInput": {
            "update": [
                2533
            ],
            "create": [
                1827
            ],
            "where": [
                140
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateWithoutPriceToCategoryInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2486
            ],
            "Product": [
                2534
            ],
            "Brand": [
                2965
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutCategoryNestedInput": {
            "create": [
                1829
            ],
            "connectOrCreate": [
                2134
            ],
            "upsert": [
                2535
            ],
            "createMany": [
                2135
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2963
            ],
            "updateMany": [
                2964
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutCategoryInput": {
            "where": [
                456
            ],
            "update": [
                2536
            ],
            "create": [
                1829
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutCategoryInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateOneWithoutProductNestedInput": {
            "create": [
                1831
            ],
            "connectOrCreate": [
                1837
            ],
            "upsert": [
                2538
            ],
            "disconnect": [
                145
            ],
            "delete": [
                145
            ],
            "connect": [
                1352
            ],
            "update": [
                2547
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpsertWithoutProductInput": {
            "update": [
                2539
            ],
            "create": [
                1831
            ],
            "where": [
                145
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "price": [
                2288
            ],
            "price_discounted": [
                2288
            ],
            "retail": [
                2288
            ],
            "project_name": [
                2272
            ],
            "project_code": [
                2272
            ],
            "discount_rate": [
                2288
            ],
            "price_type": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "PriceToCategory": [
                2540
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithoutPriceNestedInput": {
            "create": [
                1833
            ],
            "connectOrCreate": [
                1834
            ],
            "upsert": [
                2541
            ],
            "createMany": [
                1835
            ],
            "set": [
                464
            ],
            "disconnect": [
                464
            ],
            "delete": [
                464
            ],
            "connect": [
                464
            ],
            "update": [
                2543
            ],
            "updateMany": [
                2544
            ],
            "deleteMany": [
                2545
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpsertWithWhereUniqueWithoutPriceInput": {
            "where": [
                464
            ],
            "update": [
                2542
            ],
            "create": [
                1833
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithoutPriceInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Category": [
                2531
            ],
            "Company": [
                2507
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithWhereUniqueWithoutPriceInput": {
            "where": [
                464
            ],
            "data": [
                2542
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithWhereWithoutPriceInput": {
            "where": [
                2545
            ],
            "data": [
                2546
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryScalarWhereInput": {
            "AND": [
                2545
            ],
            "OR": [
                2545
            ],
            "NOT": [
                2545
            ],
            "price_id": [
                16
            ],
            "category_id": [
                16
            ],
            "companyId": [
                23
            ],
            "active": [
                18
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateToOneWithWhereWithoutProductInput": {
            "where": [
                145
            ],
            "data": [
                2539
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateOneWithoutProductNestedInput": {
            "create": [
                1839
            ],
            "connectOrCreate": [
                2133
            ],
            "upsert": [
                2549
            ],
            "disconnect": [
                165
            ],
            "delete": [
                165
            ],
            "connect": [
                1426
            ],
            "update": [
                2962
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpsertWithoutProductInput": {
            "update": [
                2550
            ],
            "create": [
                1839
            ],
            "where": [
                165
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "AttributeToProductType": [
                2551
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateManyWithoutProductTypeNestedInput": {
            "create": [
                1841
            ],
            "connectOrCreate": [
                2130
            ],
            "upsert": [
                2552
            ],
            "createMany": [
                2131
            ],
            "set": [
                498
            ],
            "disconnect": [
                498
            ],
            "delete": [
                498
            ],
            "connect": [
                498
            ],
            "update": [
                2960
            ],
            "updateMany": [
                2961
            ],
            "deleteMany": [
                2929
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpsertWithWhereUniqueWithoutProductTypeInput": {
            "where": [
                498
            ],
            "update": [
                2553
            ],
            "create": [
                1841
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateWithoutProductTypeInput": {
            "is_variant": [
                2278
            ],
            "group_hierarchy": [
                2273
            ],
            "is_group": [
                2278
            ],
            "variant_hierarchy": [
                2273
            ],
            "Attribute": [
                2554
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateOneRequiredWithoutAttributeToProductTypeNestedInput": {
            "create": [
                1843
            ],
            "connectOrCreate": [
                2129
            ],
            "upsert": [
                2555
            ],
            "connect": [
                635
            ],
            "update": [
                2959
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpsertWithoutAttributeToProductTypeInput": {
            "update": [
                2556
            ],
            "create": [
                1843
            ],
            "where": [
                169
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateWithoutAttributeToProductTypeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "AttributeValue": [
                2557
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateManyWithoutAttributeNestedInput": {
            "create": [
                1845
            ],
            "connectOrCreate": [
                2126
            ],
            "upsert": [
                2558
            ],
            "createMany": [
                2127
            ],
            "set": [
                506
            ],
            "disconnect": [
                506
            ],
            "delete": [
                506
            ],
            "connect": [
                506
            ],
            "update": [
                2957
            ],
            "updateMany": [
                2958
            ],
            "deleteMany": [
                2934
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpsertWithWhereUniqueWithoutAttributeInput": {
            "where": [
                506
            ],
            "update": [
                2559
            ],
            "create": [
                1845
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateWithoutAttributeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Product": [
                2560
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutAttributeValuesNestedInput": {
            "create": [
                1847
            ],
            "connectOrCreate": [
                2125
            ],
            "upsert": [
                2561
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2955
            ],
            "updateMany": [
                2956
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutAttributeValuesInput": {
            "where": [
                456
            ],
            "update": [
                2562
            ],
            "create": [
                1847
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutAttributeValuesInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateManyWithoutProductNestedInput": {
            "create": [
                1849
            ],
            "connectOrCreate": [
                1850
            ],
            "upsert": [
                2564
            ],
            "createMany": [
                1851
            ],
            "set": [
                512
            ],
            "disconnect": [
                512
            ],
            "delete": [
                512
            ],
            "connect": [
                512
            ],
            "update": [
                2566
            ],
            "updateMany": [
                2567
            ],
            "deleteMany": [
                2568
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                512
            ],
            "update": [
                2565
            ],
            "create": [
                1849
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                512
            ],
            "data": [
                2565
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2568
            ],
            "data": [
                2569
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotScalarWhereInput": {
            "AND": [
                2568
            ],
            "OR": [
                2568
            ],
            "NOT": [
                2568
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateManyWithoutProductNestedInput": {
            "create": [
                1854
            ],
            "connectOrCreate": [
                1855
            ],
            "upsert": [
                2571
            ],
            "createMany": [
                1856
            ],
            "set": [
                518
            ],
            "disconnect": [
                518
            ],
            "delete": [
                518
            ],
            "connect": [
                518
            ],
            "update": [
                2573
            ],
            "updateMany": [
                2574
            ],
            "deleteMany": [
                2575
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                518
            ],
            "update": [
                2572
            ],
            "create": [
                1854
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                518
            ],
            "data": [
                2572
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2575
            ],
            "data": [
                2576
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockScalarWhereInput": {
            "AND": [
                2575
            ],
            "OR": [
                2575
            ],
            "NOT": [
                2575
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "product_id": [
                23
            ],
            "quantity": [
                45
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithoutProductNestedInput": {
            "create": [
                1859
            ],
            "connectOrCreate": [
                2122
            ],
            "upsert": [
                2578
            ],
            "createMany": [
                2123
            ],
            "set": [
                540
            ],
            "disconnect": [
                540
            ],
            "delete": [
                540
            ],
            "connect": [
                540
            ],
            "update": [
                2953
            ],
            "updateMany": [
                2954
            ],
            "deleteMany": [
                2756
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                540
            ],
            "update": [
                2579
            ],
            "create": [
                1859
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2580
            ],
            "SupportEvent": [
                2592
            ],
            "SupportItemStatus": [
                2762
            ],
            "Support": [
                2702
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithoutSupportItemNestedInput": {
            "create": [
                1861
            ],
            "connectOrCreate": [
                2119
            ],
            "upsert": [
                2581
            ],
            "createMany": [
                2120
            ],
            "set": [
                538
            ],
            "disconnect": [
                538
            ],
            "delete": [
                538
            ],
            "connect": [
                538
            ],
            "update": [
                2951
            ],
            "updateMany": [
                2952
            ],
            "deleteMany": [
                2688
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpsertWithWhereUniqueWithoutSupportItemInput": {
            "where": [
                538
            ],
            "update": [
                2582
            ],
            "create": [
                1861
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithoutSupportItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Support": [
                2583
            ],
            "User": [
                2938
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateOneWithoutSupportAttachmentNestedInput": {
            "create": [
                1863
            ],
            "connectOrCreate": [
                2118
            ],
            "upsert": [
                2584
            ],
            "disconnect": [
                183
            ],
            "delete": [
                183
            ],
            "connect": [
                1504
            ],
            "update": [
                2950
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpsertWithoutSupportAttachmentInput": {
            "update": [
                2585
            ],
            "create": [
                1863
            ],
            "where": [
                183
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateWithoutSupportAttachmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "status": [
                2586
            ],
            "type": [
                2587
            ],
            "priority": [
                2588
            ],
            "companyId": [
                2273
            ],
            "assignedToId": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "SupportItem": [
                2589
            ],
            "SupportMessage": [
                2705
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumSupportStatusFieldUpdateOperationsInput": {
            "set": [
                185
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumSupportTypeFieldUpdateOperationsInput": {
            "set": [
                188
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumPriorityFieldUpdateOperationsInput": {
            "set": [
                191
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithoutSupportNestedInput": {
            "create": [
                1865
            ],
            "connectOrCreate": [
                2115
            ],
            "upsert": [
                2590
            ],
            "createMany": [
                2116
            ],
            "set": [
                540
            ],
            "disconnect": [
                540
            ],
            "delete": [
                540
            ],
            "connect": [
                540
            ],
            "update": [
                2948
            ],
            "updateMany": [
                2949
            ],
            "deleteMany": [
                2756
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpsertWithWhereUniqueWithoutSupportInput": {
            "where": [
                540
            ],
            "update": [
                2591
            ],
            "create": [
                1865
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithoutSupportInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2580
            ],
            "SupportEvent": [
                2592
            ],
            "Product": [
                2607
            ],
            "SupportItemStatus": [
                2762
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithoutItemNestedInput": {
            "create": [
                1867
            ],
            "connectOrCreate": [
                2112
            ],
            "upsert": [
                2593
            ],
            "createMany": [
                2113
            ],
            "set": [
                553
            ],
            "disconnect": [
                553
            ],
            "delete": [
                553
            ],
            "connect": [
                553
            ],
            "update": [
                2946
            ],
            "updateMany": [
                2947
            ],
            "deleteMany": [
                2770
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpsertWithWhereUniqueWithoutItemInput": {
            "where": [
                553
            ],
            "update": [
                2594
            ],
            "create": [
                1867
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithoutItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_read": [
                2278
            ],
            "private": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "Message": [
                2595
            ],
            "Status": [
                2696
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateOneWithoutSupportEventNestedInput": {
            "create": [
                1869
            ],
            "connectOrCreate": [
                2111
            ],
            "upsert": [
                2596
            ],
            "disconnect": [
                194
            ],
            "delete": [
                194
            ],
            "connect": [
                555
            ],
            "update": [
                2945
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpsertWithoutSupportEventInput": {
            "update": [
                2597
            ],
            "create": [
                1869
            ],
            "where": [
                194
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateWithoutSupportEventInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "message": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Support": [
                2598
            ],
            "User": [
                2708
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateOneWithoutSupportMessageNestedInput": {
            "create": [
                1871
            ],
            "connectOrCreate": [
                2110
            ],
            "upsert": [
                2599
            ],
            "disconnect": [
                183
            ],
            "delete": [
                183
            ],
            "connect": [
                1504
            ],
            "update": [
                2944
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpsertWithoutSupportMessageInput": {
            "update": [
                2600
            ],
            "create": [
                1871
            ],
            "where": [
                183
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateWithoutSupportMessageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "status": [
                2586
            ],
            "type": [
                2587
            ],
            "priority": [
                2588
            ],
            "companyId": [
                2273
            ],
            "assignedToId": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2601
            ],
            "SupportItem": [
                2589
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithoutSupportNestedInput": {
            "create": [
                1873
            ],
            "connectOrCreate": [
                2107
            ],
            "upsert": [
                2602
            ],
            "createMany": [
                2108
            ],
            "set": [
                538
            ],
            "disconnect": [
                538
            ],
            "delete": [
                538
            ],
            "connect": [
                538
            ],
            "update": [
                2942
            ],
            "updateMany": [
                2943
            ],
            "deleteMany": [
                2688
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpsertWithWhereUniqueWithoutSupportInput": {
            "where": [
                538
            ],
            "update": [
                2603
            ],
            "create": [
                1873
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithoutSupportInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportItem": [
                2604
            ],
            "User": [
                2938
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateOneWithoutSupportAttachmentNestedInput": {
            "create": [
                1875
            ],
            "connectOrCreate": [
                2103
            ],
            "upsert": [
                2605
            ],
            "disconnect": [
                179
            ],
            "delete": [
                179
            ],
            "connect": [
                540
            ],
            "update": [
                2937
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpsertWithoutSupportAttachmentInput": {
            "update": [
                2606
            ],
            "create": [
                1875
            ],
            "where": [
                179
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithoutSupportAttachmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportEvent": [
                2592
            ],
            "Product": [
                2607
            ],
            "SupportItemStatus": [
                2762
            ],
            "Support": [
                2702
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutSupportItemNestedInput": {
            "create": [
                1877
            ],
            "connectOrCreate": [
                2102
            ],
            "upsert": [
                2608
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                2936
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutSupportItemInput": {
            "update": [
                2609
            ],
            "create": [
                1877
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutSupportItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateManyWithoutProductNestedInput": {
            "create": [
                1879
            ],
            "connectOrCreate": [
                2101
            ],
            "upsert": [
                2611
            ],
            "set": [
                506
            ],
            "disconnect": [
                506
            ],
            "delete": [
                506
            ],
            "connect": [
                506
            ],
            "update": [
                2932
            ],
            "updateMany": [
                2933
            ],
            "deleteMany": [
                2934
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                506
            ],
            "update": [
                2612
            ],
            "create": [
                1879
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Attribute": [
                2613
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateOneWithoutAttributeValueNestedInput": {
            "create": [
                1881
            ],
            "connectOrCreate": [
                2100
            ],
            "upsert": [
                2614
            ],
            "disconnect": [
                169
            ],
            "delete": [
                169
            ],
            "connect": [
                635
            ],
            "update": [
                2931
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpsertWithoutAttributeValueInput": {
            "update": [
                2615
            ],
            "create": [
                1881
            ],
            "where": [
                169
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateWithoutAttributeValueInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "AttributeToProductType": [
                2616
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateManyWithoutAttributeNestedInput": {
            "create": [
                1883
            ],
            "connectOrCreate": [
                2097
            ],
            "upsert": [
                2617
            ],
            "createMany": [
                2098
            ],
            "set": [
                498
            ],
            "disconnect": [
                498
            ],
            "delete": [
                498
            ],
            "connect": [
                498
            ],
            "update": [
                2927
            ],
            "updateMany": [
                2928
            ],
            "deleteMany": [
                2929
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpsertWithWhereUniqueWithoutAttributeInput": {
            "where": [
                498
            ],
            "update": [
                2618
            ],
            "create": [
                1883
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateWithoutAttributeInput": {
            "is_variant": [
                2278
            ],
            "group_hierarchy": [
                2273
            ],
            "is_group": [
                2278
            ],
            "variant_hierarchy": [
                2273
            ],
            "ProductType": [
                2619
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateOneRequiredWithoutAttributeToProductTypeNestedInput": {
            "create": [
                1885
            ],
            "connectOrCreate": [
                2096
            ],
            "upsert": [
                2620
            ],
            "connect": [
                1426
            ],
            "update": [
                2926
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpsertWithoutAttributeToProductTypeInput": {
            "update": [
                2621
            ],
            "create": [
                1885
            ],
            "where": [
                165
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateWithoutAttributeToProductTypeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "Product": [
                2622
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutProductTypeNestedInput": {
            "create": [
                1887
            ],
            "connectOrCreate": [
                2093
            ],
            "upsert": [
                2623
            ],
            "createMany": [
                2094
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2924
            ],
            "updateMany": [
                2925
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutProductTypeInput": {
            "where": [
                456
            ],
            "update": [
                2624
            ],
            "create": [
                1887
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutProductTypeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateManyWithoutProductNestedInput": {
            "create": [
                1889
            ],
            "connectOrCreate": [
                2092
            ],
            "upsert": [
                2626
            ],
            "set": [
                557
            ],
            "disconnect": [
                557
            ],
            "delete": [
                557
            ],
            "connect": [
                557
            ],
            "update": [
                2920
            ],
            "updateMany": [
                2921
            ],
            "deleteMany": [
                2922
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                557
            ],
            "update": [
                2627
            ],
            "create": [
                1889
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Image": [
                2628
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutCampaignNestedInput": {
            "create": [
                1891
            ],
            "connectOrCreate": [
                2089
            ],
            "upsert": [
                2629
            ],
            "createMany": [
                2090
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                2918
            ],
            "updateMany": [
                2919
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutCampaignInput": {
            "where": [
                454
            ],
            "update": [
                2630
            ],
            "create": [
                1891
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutCampaignInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Category": [
                2501
            ],
            "Collection": [
                2631
            ],
            "shipmentStatus": [
                2640
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateOneWithoutImageNestedInput": {
            "create": [
                1893
            ],
            "connectOrCreate": [
                2077
            ],
            "upsert": [
                2632
            ],
            "disconnect": [
                148
            ],
            "delete": [
                148
            ],
            "connect": [
                559
            ],
            "update": [
                2900
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpsertWithoutImageInput": {
            "update": [
                2633
            ],
            "create": [
                1893
            ],
            "where": [
                148
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateWithoutImageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                2634
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutCollectionNestedInput": {
            "create": [
                1895
            ],
            "connectOrCreate": [
                2076
            ],
            "upsert": [
                2635
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2896
            ],
            "updateMany": [
                2897
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutCollectionInput": {
            "where": [
                456
            ],
            "update": [
                2636
            ],
            "create": [
                1895
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutCollectionInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutProductNestedInput": {
            "create": [
                1897
            ],
            "connectOrCreate": [
                2075
            ],
            "upsert": [
                2638
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                2892
            ],
            "updateMany": [
                2893
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                454
            ],
            "update": [
                2639
            ],
            "create": [
                1897
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Campaign": [
                2489
            ],
            "Category": [
                2501
            ],
            "Collection": [
                2631
            ],
            "shipmentStatus": [
                2640
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateOneWithoutImageNestedInput": {
            "create": [
                1899
            ],
            "connectOrCreate": [
                2074
            ],
            "upsert": [
                2641
            ],
            "disconnect": [
                150
            ],
            "delete": [
                150
            ],
            "connect": [
                1488
            ],
            "update": [
                2891
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpsertWithoutImageInput": {
            "update": [
                2642
            ],
            "create": [
                1899
            ],
            "where": [
                150
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateWithoutImageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "description": [
                2272
            ],
            "color": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Shipment": [
                2643
            ],
            "ShipmentEvent": [
                2882
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateManyWithoutShipmentStatusNestedInput": {
            "create": [
                1901
            ],
            "connectOrCreate": [
                2063
            ],
            "upsert": [
                2644
            ],
            "createMany": [
                2064
            ],
            "set": [
                477
            ],
            "disconnect": [
                477
            ],
            "delete": [
                477
            ],
            "connect": [
                477
            ],
            "update": [
                2878
            ],
            "updateMany": [
                2879
            ],
            "deleteMany": [
                2880
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpsertWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                477
            ],
            "update": [
                2645
            ],
            "create": [
                1901
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithoutShipmentStatusInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                2646
            ],
            "CargoAgency": [
                2466
            ],
            "ShipmentEvent": [
                2867
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutShipmentNestedInput": {
            "create": [
                1903
            ],
            "connectOrCreate": [
                2052
            ],
            "upsert": [
                2647
            ],
            "createMany": [
                2053
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                2865
            ],
            "updateMany": [
                2866
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutShipmentInput": {
            "where": [
                437
            ],
            "update": [
                2648
            ],
            "create": [
                1903
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "OrderItemStatus": [
                2444
            ],
            "Product": [
                2453
            ],
            "User": [
                2649
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutOrderItemNestedInput": {
            "create": [
                1905
            ],
            "connectOrCreate": [
                2040
            ],
            "upsert": [
                2650
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                2848
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutOrderItemInput": {
            "update": [
                2651
            ],
            "create": [
                1905
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutOrderItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutUserNestedInput": {
            "create": [
                1907
            ],
            "connectOrCreate": [
                2037
            ],
            "upsert": [
                2653
            ],
            "createMany": [
                2038
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                2846
            ],
            "updateMany": [
                2847
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                324
            ],
            "update": [
                2654
            ],
            "create": [
                1907
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateOneWithoutPaymentNestedInput": {
            "create": [
                1909
            ],
            "connectOrCreate": [
                2036
            ],
            "upsert": [
                2656
            ],
            "disconnect": [
                104
            ],
            "delete": [
                104
            ],
            "connect": [
                1257
            ],
            "update": [
                2845
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpsertWithoutPaymentInput": {
            "update": [
                2657
            ],
            "create": [
                1909
            ],
            "where": [
                104
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "minimum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "title": [
                2272
            ],
            "is_default": [
                2278
            ],
            "CompanyToPaymentMethod": [
                2658
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateManyWithoutPaymentMethodNestedInput": {
            "create": [
                1911
            ],
            "connectOrCreate": [
                2033
            ],
            "upsert": [
                2659
            ],
            "createMany": [
                2034
            ],
            "set": [
                358
            ],
            "disconnect": [
                358
            ],
            "delete": [
                358
            ],
            "connect": [
                358
            ],
            "update": [
                2841
            ],
            "updateMany": [
                2842
            ],
            "deleteMany": [
                2843
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpsertWithWhereUniqueWithoutPaymentMethodInput": {
            "where": [
                358
            ],
            "update": [
                2660
            ],
            "create": [
                1911
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateWithoutPaymentMethodInput": {
            "minimum_amount": [
                2288
            ],
            "maximum_amount": [
                2288
            ],
            "end_date": [
                2287
            ],
            "start_date": [
                2287
            ],
            "total_amount": [
                2288
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "Company": [
                2661
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneRequiredWithoutCompanyToPaymentMethodNestedInput": {
            "create": [
                1913
            ],
            "connectOrCreate": [
                2032
            ],
            "upsert": [
                2662
            ],
            "connect": [
                487
            ],
            "update": [
                2840
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutCompanyToPaymentMethodInput": {
            "update": [
                2663
            ],
            "create": [
                1913
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutCompanyToPaymentMethodInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1915
            ],
            "connectOrCreate": [
                2026
            ],
            "upsert": [
                2665
            ],
            "createMany": [
                2027
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                2831
            ],
            "updateMany": [
                2832
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                324
            ],
            "update": [
                2666
            ],
            "create": [
                1915
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateOneWithoutPaymentNestedInput": {
            "create": [
                1917
            ],
            "connectOrCreate": [
                2025
            ],
            "upsert": [
                2668
            ],
            "disconnect": [
                89
            ],
            "delete": [
                89
            ],
            "connect": [
                348
            ],
            "update": [
                2830
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpsertWithoutPaymentInput": {
            "update": [
                2669
            ],
            "create": [
                1917
            ],
            "where": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "InstallmentToPosAccount": [
                2386
            ],
            "PaymentSystem": [
                2670
            ],
            "Bank": [
                2674
            ],
            "PosConfig": [
                2821
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpdateOneWithoutPosAccountNestedInput": {
            "create": [
                1919
            ],
            "connectOrCreate": [
                1920
            ],
            "upsert": [
                2671
            ],
            "disconnect": [
                99
            ],
            "delete": [
                99
            ],
            "connect": [
                1273
            ],
            "update": [
                2673
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpsertWithoutPosAccountInput": {
            "update": [
                2672
            ],
            "create": [
                1919
            ],
            "where": [
                99
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpdateWithoutPosAccountInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpdateToOneWithWhereWithoutPosAccountInput": {
            "where": [
                99
            ],
            "data": [
                2672
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateOneWithoutPosAccountNestedInput": {
            "create": [
                1922
            ],
            "connectOrCreate": [
                2016
            ],
            "upsert": [
                2675
            ],
            "disconnect": [
                84
            ],
            "delete": [
                84
            ],
            "connect": [
                679
            ],
            "update": [
                2820
            ],
            "__typename": [
                9
            ]
        },
        "BankUpsertWithoutPosAccountInput": {
            "update": [
                2676
            ],
            "create": [
                1922
            ],
            "where": [
                84
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateWithoutPosAccountInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "bin_numbers": [
                2376
            ],
            "Payment": [
                2677
            ],
            "PosConfig": [
                2809
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutCard_bankNestedInput": {
            "create": [
                1924
            ],
            "connectOrCreate": [
                2005
            ],
            "upsert": [
                2678
            ],
            "createMany": [
                2006
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                2805
            ],
            "updateMany": [
                2806
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutCard_bankInput": {
            "where": [
                324
            ],
            "update": [
                2679
            ],
            "create": [
                1924
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutCard_bankInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutPaymentNestedInput": {
            "create": [
                1926
            ],
            "connectOrCreate": [
                1990
            ],
            "upsert": [
                2681
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                2778
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutPaymentInput": {
            "update": [
                2682
            ],
            "create": [
                1926
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithoutUserNestedInput": {
            "create": [
                1928
            ],
            "connectOrCreate": [
                1929
            ],
            "upsert": [
                2684
            ],
            "createMany": [
                1930
            ],
            "set": [
                538
            ],
            "disconnect": [
                538
            ],
            "delete": [
                538
            ],
            "connect": [
                538
            ],
            "update": [
                2686
            ],
            "updateMany": [
                2687
            ],
            "deleteMany": [
                2688
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                538
            ],
            "update": [
                2685
            ],
            "create": [
                1928
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Support": [
                2583
            ],
            "SupportItem": [
                2604
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                538
            ],
            "data": [
                2685
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2688
            ],
            "data": [
                2689
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentScalarWhereInput": {
            "AND": [
                2688
            ],
            "OR": [
                2688
            ],
            "NOT": [
                2688
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "path": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "supportItemId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateManyWithoutUserNestedInput": {
            "create": [
                1933
            ],
            "connectOrCreate": [
                1987
            ],
            "upsert": [
                2691
            ],
            "createMany": [
                1988
            ],
            "set": [
                555
            ],
            "disconnect": [
                555
            ],
            "delete": [
                555
            ],
            "connect": [
                555
            ],
            "update": [
                2776
            ],
            "updateMany": [
                2777
            ],
            "deleteMany": [
                2751
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                555
            ],
            "update": [
                2692
            ],
            "create": [
                1933
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "message": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportEvent": [
                2693
            ],
            "Support": [
                2598
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithoutMessageNestedInput": {
            "create": [
                1935
            ],
            "connectOrCreate": [
                1984
            ],
            "upsert": [
                2694
            ],
            "createMany": [
                1985
            ],
            "set": [
                553
            ],
            "disconnect": [
                553
            ],
            "delete": [
                553
            ],
            "connect": [
                553
            ],
            "update": [
                2774
            ],
            "updateMany": [
                2775
            ],
            "deleteMany": [
                2770
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpsertWithWhereUniqueWithoutMessageInput": {
            "where": [
                553
            ],
            "update": [
                2695
            ],
            "create": [
                1935
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithoutMessageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_read": [
                2278
            ],
            "private": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "Status": [
                2696
            ],
            "Item": [
                2759
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateOneWithoutSupportEventNestedInput": {
            "create": [
                1937
            ],
            "connectOrCreate": [
                1972
            ],
            "upsert": [
                2697
            ],
            "disconnect": [
                199
            ],
            "delete": [
                199
            ],
            "connect": [
                1568
            ],
            "update": [
                2758
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpsertWithoutSupportEventInput": {
            "update": [
                2698
            ],
            "create": [
                1937
            ],
            "where": [
                199
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateWithoutSupportEventInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2586
            ],
            "SupportItem": [
                2699
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithoutSupportItemStatusNestedInput": {
            "create": [
                1939
            ],
            "connectOrCreate": [
                1969
            ],
            "upsert": [
                2700
            ],
            "createMany": [
                1970
            ],
            "set": [
                540
            ],
            "disconnect": [
                540
            ],
            "delete": [
                540
            ],
            "connect": [
                540
            ],
            "update": [
                2754
            ],
            "updateMany": [
                2755
            ],
            "deleteMany": [
                2756
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpsertWithWhereUniqueWithoutSupportItemStatusInput": {
            "where": [
                540
            ],
            "update": [
                2701
            ],
            "create": [
                1939
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithoutSupportItemStatusInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2580
            ],
            "SupportEvent": [
                2592
            ],
            "Product": [
                2607
            ],
            "Support": [
                2702
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateOneWithoutSupportItemNestedInput": {
            "create": [
                1941
            ],
            "connectOrCreate": [
                1968
            ],
            "upsert": [
                2703
            ],
            "disconnect": [
                183
            ],
            "delete": [
                183
            ],
            "connect": [
                1504
            ],
            "update": [
                2753
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpsertWithoutSupportItemInput": {
            "update": [
                2704
            ],
            "create": [
                1941
            ],
            "where": [
                183
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateWithoutSupportItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "status": [
                2586
            ],
            "type": [
                2587
            ],
            "priority": [
                2588
            ],
            "companyId": [
                2273
            ],
            "assignedToId": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2601
            ],
            "SupportMessage": [
                2705
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateManyWithoutSupportNestedInput": {
            "create": [
                1943
            ],
            "connectOrCreate": [
                1965
            ],
            "upsert": [
                2706
            ],
            "createMany": [
                1966
            ],
            "set": [
                555
            ],
            "disconnect": [
                555
            ],
            "delete": [
                555
            ],
            "connect": [
                555
            ],
            "update": [
                2749
            ],
            "updateMany": [
                2750
            ],
            "deleteMany": [
                2751
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpsertWithWhereUniqueWithoutSupportInput": {
            "where": [
                555
            ],
            "update": [
                2707
            ],
            "create": [
                1943
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateWithoutSupportInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "message": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportEvent": [
                2693
            ],
            "User": [
                2708
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutSupportMessageNestedInput": {
            "create": [
                1945
            ],
            "connectOrCreate": [
                1964
            ],
            "upsert": [
                2709
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                2748
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutSupportMessageInput": {
            "update": [
                2710
            ],
            "create": [
                1945
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutSupportMessageInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateOneWithoutUserNestedInput": {
            "create": [
                1947
            ],
            "connectOrCreate": [
                1948
            ],
            "upsert": [
                2712
            ],
            "disconnect": [
                15
            ],
            "delete": [
                15
            ],
            "connect": [
                253
            ],
            "update": [
                2714
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithoutUserInput": {
            "update": [
                2713
            ],
            "create": [
                1947
            ],
            "where": [
                15
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "County": [
                2292
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateToOneWithWhereWithoutUserInput": {
            "where": [
                15
            ],
            "data": [
                2713
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateOneWithoutUserNestedInput": {
            "create": [
                1950
            ],
            "connectOrCreate": [
                1954
            ],
            "upsert": [
                2716
            ],
            "disconnect": [
                209
            ],
            "delete": [
                209
            ],
            "connect": [
                576
            ],
            "update": [
                2726
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpsertWithoutUserInput": {
            "update": [
                2717
            ],
            "create": [
                1950
            ],
            "where": [
                209
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "type": [
                2718
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Permissions": [
                2719
            ],
            "__typename": [
                9
            ]
        },
        "EnumRolesFieldUpdateOperationsInput": {
            "set": [
                211
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateManyWithoutRoleNestedInput": {
            "create": [
                1952
            ],
            "connectOrCreate": [
                1953
            ],
            "upsert": [
                2720
            ],
            "set": [
                582
            ],
            "disconnect": [
                582
            ],
            "delete": [
                582
            ],
            "connect": [
                582
            ],
            "update": [
                2722
            ],
            "updateMany": [
                2723
            ],
            "deleteMany": [
                2724
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpsertWithWhereUniqueWithoutRoleInput": {
            "where": [
                582
            ],
            "update": [
                2721
            ],
            "create": [
                1952
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateWithoutRoleInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateWithWhereUniqueWithoutRoleInput": {
            "where": [
                582
            ],
            "data": [
                2721
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateManyWithWhereWithoutRoleInput": {
            "where": [
                2724
            ],
            "data": [
                2725
            ],
            "__typename": [
                9
            ]
        },
        "PermissionScalarWhereInput": {
            "AND": [
                2724
            ],
            "OR": [
                2724
            ],
            "NOT": [
                2724
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "is_deleted": [
                33
            ],
            "source": [
                27
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateToOneWithWhereWithoutUserInput": {
            "where": [
                209
            ],
            "data": [
                2717
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateManyWithoutUserNestedInput": {
            "create": [
                1956
            ],
            "connectOrCreate": [
                1963
            ],
            "upsert": [
                2728
            ],
            "set": [
                487
            ],
            "disconnect": [
                487
            ],
            "delete": [
                487
            ],
            "connect": [
                487
            ],
            "update": [
                2744
            ],
            "updateMany": [
                2745
            ],
            "deleteMany": [
                2746
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithWhereUniqueWithoutUserInput": {
            "where": [
                487
            ],
            "update": [
                2729
            ],
            "create": [
                1956
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutUserInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateManyWithoutCompanyNestedInput": {
            "create": [
                1958
            ],
            "connectOrCreate": [
                1962
            ],
            "upsert": [
                2731
            ],
            "set": [
                467
            ],
            "disconnect": [
                467
            ],
            "delete": [
                467
            ],
            "connect": [
                467
            ],
            "update": [
                2740
            ],
            "updateMany": [
                2741
            ],
            "deleteMany": [
                2742
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                467
            ],
            "update": [
                2732
            ],
            "create": [
                1958
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2498
            ],
            "Product": [
                2480
            ],
            "Categories": [
                2733
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateManyWithoutBrandNestedInput": {
            "create": [
                1960
            ],
            "connectOrCreate": [
                1961
            ],
            "upsert": [
                2734
            ],
            "set": [
                485
            ],
            "disconnect": [
                485
            ],
            "delete": [
                485
            ],
            "connect": [
                485
            ],
            "update": [
                2736
            ],
            "updateMany": [
                2737
            ],
            "deleteMany": [
                2738
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpsertWithWhereUniqueWithoutBrandInput": {
            "where": [
                485
            ],
            "update": [
                2735
            ],
            "create": [
                1960
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateWithoutBrandInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2486
            ],
            "PriceToCategory": [
                2504
            ],
            "Product": [
                2534
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateWithWhereUniqueWithoutBrandInput": {
            "where": [
                485
            ],
            "data": [
                2735
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateManyWithWhereWithoutBrandInput": {
            "where": [
                2738
            ],
            "data": [
                2739
            ],
            "__typename": [
                9
            ]
        },
        "CategoryScalarWhereInput": {
            "AND": [
                2738
            ],
            "OR": [
                2738
            ],
            "NOT": [
                2738
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "parent_id": [
                23
            ],
            "is_parent": [
                33
            ],
            "level": [
                23
            ],
            "description": [
                20
            ],
            "background_img": [
                20
            ],
            "is_corporate": [
                33
            ],
            "link_url": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                467
            ],
            "data": [
                2732
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2742
            ],
            "data": [
                2743
            ],
            "__typename": [
                9
            ]
        },
        "BrandScalarWhereInput": {
            "AND": [
                2742
            ],
            "OR": [
                2742
            ],
            "NOT": [
                2742
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "image": [
                20
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                487
            ],
            "data": [
                2729
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2746
            ],
            "data": [
                2747
            ],
            "__typename": [
                9
            ]
        },
        "CompanyScalarWhereInput": {
            "AND": [
                2746
            ],
            "OR": [
                2746
            ],
            "NOT": [
                2746
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "email": [
                20
            ],
            "title": [
                20
            ],
            "tax_office": [
                20
            ],
            "tax_number": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "gsm": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutSupportMessageInput": {
            "where": [
                50
            ],
            "data": [
                2710
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateWithWhereUniqueWithoutSupportInput": {
            "where": [
                555
            ],
            "data": [
                2707
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateManyWithWhereWithoutSupportInput": {
            "where": [
                2751
            ],
            "data": [
                2752
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageScalarWhereInput": {
            "AND": [
                2751
            ],
            "OR": [
                2751
            ],
            "NOT": [
                2751
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "message": [
                20
            ],
            "supportId": [
                23
            ],
            "userId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "message": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateToOneWithWhereWithoutSupportItemInput": {
            "where": [
                183
            ],
            "data": [
                2704
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithWhereUniqueWithoutSupportItemStatusInput": {
            "where": [
                540
            ],
            "data": [
                2701
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithWhereWithoutSupportItemStatusInput": {
            "where": [
                2756
            ],
            "data": [
                2757
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemScalarWhereInput": {
            "AND": [
                2756
            ],
            "OR": [
                2756
            ],
            "NOT": [
                2756
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "productId": [
                23
            ],
            "supportId": [
                23
            ],
            "statusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateToOneWithWhereWithoutSupportEventInput": {
            "where": [
                199
            ],
            "data": [
                2698
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateOneWithoutSupportEventNestedInput": {
            "create": [
                1974
            ],
            "connectOrCreate": [
                1983
            ],
            "upsert": [
                2760
            ],
            "disconnect": [
                179
            ],
            "delete": [
                179
            ],
            "connect": [
                540
            ],
            "update": [
                2773
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpsertWithoutSupportEventInput": {
            "update": [
                2761
            ],
            "create": [
                1974
            ],
            "where": [
                179
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithoutSupportEventInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2580
            ],
            "Product": [
                2607
            ],
            "SupportItemStatus": [
                2762
            ],
            "Support": [
                2702
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateOneWithoutSupportItemNestedInput": {
            "create": [
                1976
            ],
            "connectOrCreate": [
                1982
            ],
            "upsert": [
                2763
            ],
            "disconnect": [
                199
            ],
            "delete": [
                199
            ],
            "connect": [
                1568
            ],
            "update": [
                2772
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpsertWithoutSupportItemInput": {
            "update": [
                2764
            ],
            "create": [
                1976
            ],
            "where": [
                199
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateWithoutSupportItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2586
            ],
            "SupportEvent": [
                2765
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithoutStatusNestedInput": {
            "create": [
                1978
            ],
            "connectOrCreate": [
                1979
            ],
            "upsert": [
                2766
            ],
            "createMany": [
                1980
            ],
            "set": [
                553
            ],
            "disconnect": [
                553
            ],
            "delete": [
                553
            ],
            "connect": [
                553
            ],
            "update": [
                2768
            ],
            "updateMany": [
                2769
            ],
            "deleteMany": [
                2770
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpsertWithWhereUniqueWithoutStatusInput": {
            "where": [
                553
            ],
            "update": [
                2767
            ],
            "create": [
                1978
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithoutStatusInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_read": [
                2278
            ],
            "private": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "Message": [
                2595
            ],
            "Item": [
                2759
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithWhereUniqueWithoutStatusInput": {
            "where": [
                553
            ],
            "data": [
                2767
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithWhereWithoutStatusInput": {
            "where": [
                2770
            ],
            "data": [
                2771
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventScalarWhereInput": {
            "AND": [
                2770
            ],
            "OR": [
                2770
            ],
            "NOT": [
                2770
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "description": [
                20
            ],
            "is_read": [
                33
            ],
            "private": [
                33
            ],
            "supportItemId": [
                23
            ],
            "statusId": [
                23
            ],
            "messageId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_read": [
                2278
            ],
            "private": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateToOneWithWhereWithoutSupportItemInput": {
            "where": [
                199
            ],
            "data": [
                2764
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateToOneWithWhereWithoutSupportEventInput": {
            "where": [
                179
            ],
            "data": [
                2761
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithWhereUniqueWithoutMessageInput": {
            "where": [
                553
            ],
            "data": [
                2695
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithWhereWithoutMessageInput": {
            "where": [
                2770
            ],
            "data": [
                2771
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                555
            ],
            "data": [
                2692
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2751
            ],
            "data": [
                2752
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutPaymentInput": {
            "where": [
                50
            ],
            "data": [
                2682
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateManyWithoutPaymentNestedInput": {
            "create": [
                1992
            ],
            "connectOrCreate": [
                1999
            ],
            "upsert": [
                2780
            ],
            "createMany": [
                2000
            ],
            "set": [
                364
            ],
            "disconnect": [
                364
            ],
            "delete": [
                364
            ],
            "connect": [
                364
            ],
            "update": [
                2793
            ],
            "updateMany": [
                2794
            ],
            "deleteMany": [
                2795
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpsertWithWhereUniqueWithoutPaymentInput": {
            "where": [
                364
            ],
            "update": [
                2781
            ],
            "create": [
                1992
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateWithoutPaymentInput": {
            "paid_price": [
                2288
            ],
            "description": [
                2272
            ],
            "Order": [
                2782
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateOneWithoutPaymentGroupNestedInput": {
            "create": [
                1994
            ],
            "connectOrCreate": [
                1998
            ],
            "upsert": [
                2783
            ],
            "disconnect": [
                60
            ],
            "delete": [
                60
            ],
            "connect": [
                568
            ],
            "update": [
                2792
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithoutPaymentGroupInput": {
            "update": [
                2784
            ],
            "create": [
                1994
            ],
            "where": [
                60
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutPaymentGroupInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutOrderNestedInput": {
            "create": [
                1996
            ],
            "connectOrCreate": [
                1997
            ],
            "upsert": [
                2786
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                2788
            ],
            "updateMany": [
                2789
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutOrderInput": {
            "where": [
                437
            ],
            "update": [
                2787
            ],
            "create": [
                1996
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutOrderInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "OrderItemStatus": [
                2444
            ],
            "Product": [
                2453
            ],
            "Shipment": [
                2462
            ],
            "User": [
                2649
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutOrderInput": {
            "where": [
                437
            ],
            "data": [
                2787
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutOrderInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemScalarWhereInput": {
            "AND": [
                2790
            ],
            "OR": [
                2790
            ],
            "NOT": [
                2790
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "status_name": [
                20
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "quantity2": [
                45
            ],
            "discount_rate": [
                45
            ],
            "vat": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "unit": [
                45
            ],
            "unit_shipment_cost": [
                45
            ],
            "sent_unit": [
                45
            ],
            "waiting_unit": [
                45
            ],
            "features": [
                78
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "userId": [
                23
            ],
            "productId": [
                23
            ],
            "orderItemStatusId": [
                23
            ],
            "shipment_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "shipment_method": [
                115
            ],
            "cart_id": [
                23
            ],
            "orderId": [
                23
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateToOneWithWhereWithoutPaymentGroupInput": {
            "where": [
                60
            ],
            "data": [
                2784
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateWithWhereUniqueWithoutPaymentInput": {
            "where": [
                364
            ],
            "data": [
                2781
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateManyWithWhereWithoutPaymentInput": {
            "where": [
                2795
            ],
            "data": [
                2796
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupScalarWhereInput": {
            "AND": [
                2795
            ],
            "OR": [
                2795
            ],
            "NOT": [
                2795
            ],
            "id": [
                16
            ],
            "order_id": [
                23
            ],
            "paid_price": [
                45
            ],
            "payment_id": [
                23
            ],
            "description": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateManyMutationInput": {
            "paid_price": [
                2288
            ],
            "description": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateManyWithoutPaymentNestedInput": {
            "create": [
                2003
            ],
            "connectOrCreate": [
                2004
            ],
            "upsert": [
                2798
            ],
            "set": [
                371
            ],
            "disconnect": [
                371
            ],
            "delete": [
                371
            ],
            "connect": [
                371
            ],
            "update": [
                2801
            ],
            "updateMany": [
                2802
            ],
            "deleteMany": [
                2803
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpsertWithWhereUniqueWithoutPaymentInput": {
            "where": [
                371
            ],
            "update": [
                2799
            ],
            "create": [
                2003
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateWithoutPaymentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "amount": [
                2288
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                2273
            ],
            "reference_number": [
                2272
            ],
            "check_status": [
                2800
            ],
            "payment_status": [
                2371
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumPaymentCheckStatusFieldUpdateOperationsInput": {
            "set": [
                111
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateWithWhereUniqueWithoutPaymentInput": {
            "where": [
                371
            ],
            "data": [
                2799
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateManyWithWhereWithoutPaymentInput": {
            "where": [
                2803
            ],
            "data": [
                2804
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckScalarWhereInput": {
            "AND": [
                2803
            ],
            "OR": [
                2803
            ],
            "NOT": [
                2803
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "description": [
                20
            ],
            "amount": [
                45
            ],
            "bank_response": [
                78
            ],
            "payment_id": [
                23
            ],
            "reference_number": [
                20
            ],
            "check_status": [
                110
            ],
            "payment_status": [
                75
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "amount": [
                2288
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                2273
            ],
            "reference_number": [
                2272
            ],
            "check_status": [
                2800
            ],
            "payment_status": [
                2371
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutCard_bankInput": {
            "where": [
                324
            ],
            "data": [
                2679
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutCard_bankInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "PaymentScalarWhereInput": {
            "AND": [
                2807
            ],
            "OR": [
                2807
            ],
            "NOT": [
                2807
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "order_key": [
                20
            ],
            "price": [
                45
            ],
            "installment": [
                23
            ],
            "fraud_status": [
                23
            ],
            "payment_id": [
                20
            ],
            "payment_status": [
                75
            ],
            "payment_desc": [
                20
            ],
            "description": [
                20
            ],
            "card_owner": [
                20
            ],
            "card_number": [
                20
            ],
            "card_type": [
                20
            ],
            "card_association": [
                20
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                20
            ],
            "user_id": [
                23
            ],
            "method_id": [
                23
            ],
            "pos_account_id": [
                23
            ],
            "company_id": [
                23
            ],
            "auth_code": [
                20
            ],
            "bankId": [
                23
            ],
            "bank_message": [
                20
            ],
            "is_sent_erp": [
                33
            ],
            "bank_response": [
                78
            ],
            "ip": [
                20
            ],
            "req_absolute_path": [
                20
            ],
            "gateway_type": [
                80
            ],
            "temp_field": [
                78
            ],
            "is_sent_status": [
                66
            ],
            "payment_key": [
                20
            ],
            "payment_event_id": [
                23
            ],
            "outer_payment_key": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateManyWithoutBankNestedInput": {
            "create": [
                2009
            ],
            "connectOrCreate": [
                2013
            ],
            "upsert": [
                2810
            ],
            "createMany": [
                2014
            ],
            "set": [
                352
            ],
            "disconnect": [
                352
            ],
            "delete": [
                352
            ],
            "connect": [
                352
            ],
            "update": [
                2816
            ],
            "updateMany": [
                2817
            ],
            "deleteMany": [
                2818
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpsertWithWhereUniqueWithoutBankInput": {
            "where": [
                352
            ],
            "update": [
                2811
            ],
            "create": [
                2009
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateWithoutBankInput": {
            "is_default": [
                2278
            ],
            "PosAccount": [
                2812
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateOneWithoutPosConfigNestedInput": {
            "create": [
                2011
            ],
            "connectOrCreate": [
                2012
            ],
            "upsert": [
                2813
            ],
            "disconnect": [
                89
            ],
            "delete": [
                89
            ],
            "connect": [
                348
            ],
            "update": [
                2815
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpsertWithoutPosConfigInput": {
            "update": [
                2814
            ],
            "create": [
                2011
            ],
            "where": [
                89
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithoutPosConfigInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "InstallmentToPosAccount": [
                2386
            ],
            "Payment": [
                2522
            ],
            "PaymentSystem": [
                2670
            ],
            "Bank": [
                2674
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateToOneWithWhereWithoutPosConfigInput": {
            "where": [
                89
            ],
            "data": [
                2814
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateWithWhereUniqueWithoutBankInput": {
            "where": [
                352
            ],
            "data": [
                2811
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateManyWithWhereWithoutBankInput": {
            "where": [
                2818
            ],
            "data": [
                2819
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigScalarWhereInput": {
            "AND": [
                2818
            ],
            "OR": [
                2818
            ],
            "NOT": [
                2818
            ],
            "id": [
                16
            ],
            "bank_id": [
                23
            ],
            "pos_id": [
                23
            ],
            "is_default": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateManyMutationInput": {
            "is_default": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateToOneWithWhereWithoutPosAccountInput": {
            "where": [
                84
            ],
            "data": [
                2676
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateManyWithoutPosAccountNestedInput": {
            "create": [
                2018
            ],
            "connectOrCreate": [
                2022
            ],
            "upsert": [
                2822
            ],
            "createMany": [
                2023
            ],
            "set": [
                352
            ],
            "disconnect": [
                352
            ],
            "delete": [
                352
            ],
            "connect": [
                352
            ],
            "update": [
                2828
            ],
            "updateMany": [
                2829
            ],
            "deleteMany": [
                2818
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpsertWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                352
            ],
            "update": [
                2823
            ],
            "create": [
                2018
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateWithoutPosAccountInput": {
            "is_default": [
                2278
            ],
            "Bank": [
                2824
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateOneWithoutPosConfigNestedInput": {
            "create": [
                2020
            ],
            "connectOrCreate": [
                2021
            ],
            "upsert": [
                2825
            ],
            "disconnect": [
                84
            ],
            "delete": [
                84
            ],
            "connect": [
                679
            ],
            "update": [
                2827
            ],
            "__typename": [
                9
            ]
        },
        "BankUpsertWithoutPosConfigInput": {
            "update": [
                2826
            ],
            "create": [
                2020
            ],
            "where": [
                84
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateWithoutPosConfigInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "bin_numbers": [
                2376
            ],
            "Payment": [
                2677
            ],
            "PosAccount": [
                2383
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateToOneWithWhereWithoutPosConfigInput": {
            "where": [
                84
            ],
            "data": [
                2826
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                352
            ],
            "data": [
                2823
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateManyWithWhereWithoutPosAccountInput": {
            "where": [
                2818
            ],
            "data": [
                2819
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateToOneWithWhereWithoutPaymentInput": {
            "where": [
                89
            ],
            "data": [
                2669
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                324
            ],
            "data": [
                2666
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithoutCompanyNestedInput": {
            "create": [
                2030
            ],
            "connectOrCreate": [
                2031
            ],
            "upsert": [
                2834
            ],
            "set": [
                572
            ],
            "disconnect": [
                572
            ],
            "delete": [
                572
            ],
            "connect": [
                572
            ],
            "update": [
                2836
            ],
            "updateMany": [
                2837
            ],
            "deleteMany": [
                2838
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithWhereUniqueWithoutCompanyInput": {
            "where": [
                572
            ],
            "update": [
                2835
            ],
            "create": [
                2030
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutCompanyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                572
            ],
            "data": [
                2835
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2838
            ],
            "data": [
                2839
            ],
            "__typename": [
                9
            ]
        },
        "UserScalarWhereInput": {
            "AND": [
                2838
            ],
            "OR": [
                2838
            ],
            "NOT": [
                2838
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "provider_id": [
                20
            ],
            "first_name": [
                20
            ],
            "last_name": [
                20
            ],
            "gender": [
                51
            ],
            "default_address_id": [
                23
            ],
            "role_id": [
                23
            ],
            "email": [
                20
            ],
            "gsm": [
                20
            ],
            "password": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutCompanyToPaymentMethodInput": {
            "where": [
                36
            ],
            "data": [
                2663
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateWithWhereUniqueWithoutPaymentMethodInput": {
            "where": [
                358
            ],
            "data": [
                2660
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateManyWithWhereWithoutPaymentMethodInput": {
            "where": [
                2843
            ],
            "data": [
                2844
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodScalarWhereInput": {
            "AND": [
                2843
            ],
            "OR": [
                2843
            ],
            "NOT": [
                2843
            ],
            "company_id": [
                16
            ],
            "payment_methodId": [
                16
            ],
            "minimum_amount": [
                45
            ],
            "maximum_amount": [
                45
            ],
            "end_date": [
                43
            ],
            "start_date": [
                43
            ],
            "total_amount": [
                45
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateManyMutationInput": {
            "minimum_amount": [
                2288
            ],
            "maximum_amount": [
                2288
            ],
            "end_date": [
                2287
            ],
            "start_date": [
                2287
            ],
            "total_amount": [
                2288
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateToOneWithWhereWithoutPaymentInput": {
            "where": [
                104
            ],
            "data": [
                2657
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                324
            ],
            "data": [
                2654
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutOrderItemInput": {
            "where": [
                50
            ],
            "data": [
                2651
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithoutOrderItemNestedInput": {
            "create": [
                2042
            ],
            "connectOrCreate": [
                2051
            ],
            "upsert": [
                2850
            ],
            "set": [
                568
            ],
            "disconnect": [
                568
            ],
            "delete": [
                568
            ],
            "connect": [
                568
            ],
            "update": [
                2861
            ],
            "updateMany": [
                2862
            ],
            "deleteMany": [
                2863
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpsertWithWhereUniqueWithoutOrderItemInput": {
            "where": [
                568
            ],
            "update": [
                2851
            ],
            "create": [
                2042
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithoutOrderItemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateManyWithoutOrderNestedInput": {
            "create": [
                2044
            ],
            "connectOrCreate": [
                2048
            ],
            "upsert": [
                2853
            ],
            "createMany": [
                2049
            ],
            "set": [
                364
            ],
            "disconnect": [
                364
            ],
            "delete": [
                364
            ],
            "connect": [
                364
            ],
            "update": [
                2859
            ],
            "updateMany": [
                2860
            ],
            "deleteMany": [
                2795
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpsertWithWhereUniqueWithoutOrderInput": {
            "where": [
                364
            ],
            "update": [
                2854
            ],
            "create": [
                2044
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateWithoutOrderInput": {
            "paid_price": [
                2288
            ],
            "description": [
                2272
            ],
            "Payment": [
                2855
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateOneWithoutPaymentGroupNestedInput": {
            "create": [
                2046
            ],
            "connectOrCreate": [
                2047
            ],
            "upsert": [
                2856
            ],
            "disconnect": [
                74
            ],
            "delete": [
                74
            ],
            "connect": [
                324
            ],
            "update": [
                2858
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithoutPaymentGroupInput": {
            "update": [
                2857
            ],
            "create": [
                2046
            ],
            "where": [
                74
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutPaymentGroupInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateToOneWithWhereWithoutPaymentGroupInput": {
            "where": [
                74
            ],
            "data": [
                2857
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateWithWhereUniqueWithoutOrderInput": {
            "where": [
                364
            ],
            "data": [
                2854
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateManyWithWhereWithoutOrderInput": {
            "where": [
                2795
            ],
            "data": [
                2796
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithWhereUniqueWithoutOrderItemInput": {
            "where": [
                568
            ],
            "data": [
                2851
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithWhereWithoutOrderItemInput": {
            "where": [
                2863
            ],
            "data": [
                2864
            ],
            "__typename": [
                9
            ]
        },
        "OrderScalarWhereInput": {
            "AND": [
                2863
            ],
            "OR": [
                2863
            ],
            "NOT": [
                2863
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "ordered_at": [
                43
            ],
            "order_key": [
                61
            ],
            "outer_order_key": [
                20
            ],
            "company_id": [
                23
            ],
            "user_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "billing_address_id": [
                23
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "total_discount": [
                45
            ],
            "total_quantity": [
                23
            ],
            "order_note": [
                20
            ],
            "is_approved": [
                33
            ],
            "approved_by": [
                23
            ],
            "approved_at": [
                43
            ],
            "is_sent_erp": [
                33
            ],
            "sent_at": [
                43
            ],
            "sent_by": [
                23
            ],
            "company_name": [
                20
            ],
            "is_deleted": [
                33
            ],
            "status": [
                20
            ],
            "payment_status": [
                63
            ],
            "cartId": [
                23
            ],
            "warehouse": [
                20
            ],
            "is_sent_status": [
                66
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutShipmentInput": {
            "where": [
                437
            ],
            "data": [
                2648
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutShipmentInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateManyWithoutShipmentNestedInput": {
            "create": [
                2056
            ],
            "connectOrCreate": [
                2060
            ],
            "upsert": [
                2868
            ],
            "createMany": [
                2061
            ],
            "set": [
                483
            ],
            "disconnect": [
                483
            ],
            "delete": [
                483
            ],
            "connect": [
                483
            ],
            "update": [
                2874
            ],
            "updateMany": [
                2875
            ],
            "deleteMany": [
                2876
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpsertWithWhereUniqueWithoutShipmentInput": {
            "where": [
                483
            ],
            "update": [
                2869
            ],
            "create": [
                2056
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateWithoutShipmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "eventDateTime": [
                2287
            ],
            "deliveryDateTime": [
                2287
            ],
            "deliveryTo": [
                2272
            ],
            "description": [
                2272
            ],
            "pieceTotal": [
                2273
            ],
            "location": [
                2272
            ],
            "locationPhone": [
                2272
            ],
            "private": [
                2278
            ],
            "isRead": [
                2278
            ],
            "locationAddress": [
                2272
            ],
            "trackingCode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "ShipmentStatus": [
                2870
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateOneWithoutShipmentEventNestedInput": {
            "create": [
                2058
            ],
            "connectOrCreate": [
                2059
            ],
            "upsert": [
                2871
            ],
            "disconnect": [
                150
            ],
            "delete": [
                150
            ],
            "connect": [
                1488
            ],
            "update": [
                2873
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpsertWithoutShipmentEventInput": {
            "update": [
                2872
            ],
            "create": [
                2058
            ],
            "where": [
                150
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateWithoutShipmentEventInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "description": [
                2272
            ],
            "color": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "image": [
                2473
            ],
            "Shipment": [
                2643
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateToOneWithWhereWithoutShipmentEventInput": {
            "where": [
                150
            ],
            "data": [
                2872
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateWithWhereUniqueWithoutShipmentInput": {
            "where": [
                483
            ],
            "data": [
                2869
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateManyWithWhereWithoutShipmentInput": {
            "where": [
                2876
            ],
            "data": [
                2877
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventScalarWhereInput": {
            "AND": [
                2876
            ],
            "OR": [
                2876
            ],
            "NOT": [
                2876
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "eventDateTime": [
                43
            ],
            "deliveryDateTime": [
                43
            ],
            "deliveryTo": [
                20
            ],
            "description": [
                20
            ],
            "pieceTotal": [
                23
            ],
            "location": [
                20
            ],
            "locationPhone": [
                20
            ],
            "private": [
                33
            ],
            "shipmentId": [
                23
            ],
            "statusId": [
                23
            ],
            "isRead": [
                33
            ],
            "locationAddress": [
                20
            ],
            "trackingCode": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "eventDateTime": [
                2287
            ],
            "deliveryDateTime": [
                2287
            ],
            "deliveryTo": [
                2272
            ],
            "description": [
                2272
            ],
            "pieceTotal": [
                2273
            ],
            "location": [
                2272
            ],
            "locationPhone": [
                2272
            ],
            "private": [
                2278
            ],
            "isRead": [
                2278
            ],
            "locationAddress": [
                2272
            ],
            "trackingCode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                477
            ],
            "data": [
                2645
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateManyWithWhereWithoutShipmentStatusInput": {
            "where": [
                2880
            ],
            "data": [
                2881
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentScalarWhereInput": {
            "AND": [
                2880
            ],
            "OR": [
                2880
            ],
            "NOT": [
                2880
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "cargo_agency_id": [
                23
            ],
            "tracking_code": [
                20
            ],
            "tracking_url": [
                20
            ],
            "date_of_shipment": [
                43
            ],
            "date_of_delivery": [
                43
            ],
            "other_info": [
                20
            ],
            "cargo_order": [
                23
            ],
            "shipment_status_id": [
                23
            ],
            "shipment_cost": [
                45
            ],
            "shipment_cost_status": [
                153
            ],
            "shipment_method": [
                115
            ],
            "quantity": [
                45
            ],
            "referance_code": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateManyWithoutShipmentStatusNestedInput": {
            "create": [
                2067
            ],
            "connectOrCreate": [
                2071
            ],
            "upsert": [
                2883
            ],
            "createMany": [
                2072
            ],
            "set": [
                483
            ],
            "disconnect": [
                483
            ],
            "delete": [
                483
            ],
            "connect": [
                483
            ],
            "update": [
                2889
            ],
            "updateMany": [
                2890
            ],
            "deleteMany": [
                2876
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpsertWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                483
            ],
            "update": [
                2884
            ],
            "create": [
                2067
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateWithoutShipmentStatusInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "eventDateTime": [
                2287
            ],
            "deliveryDateTime": [
                2287
            ],
            "deliveryTo": [
                2272
            ],
            "description": [
                2272
            ],
            "pieceTotal": [
                2273
            ],
            "location": [
                2272
            ],
            "locationPhone": [
                2272
            ],
            "private": [
                2278
            ],
            "isRead": [
                2278
            ],
            "locationAddress": [
                2272
            ],
            "trackingCode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Shipment": [
                2885
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateOneWithoutShipmentEventNestedInput": {
            "create": [
                2069
            ],
            "connectOrCreate": [
                2070
            ],
            "upsert": [
                2886
            ],
            "disconnect": [
                152
            ],
            "delete": [
                152
            ],
            "connect": [
                477
            ],
            "update": [
                2888
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpsertWithoutShipmentEventInput": {
            "update": [
                2887
            ],
            "create": [
                2069
            ],
            "where": [
                152
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithoutShipmentEventInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                2646
            ],
            "CargoAgency": [
                2466
            ],
            "ShipmentStatus": [
                2470
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateToOneWithWhereWithoutShipmentEventInput": {
            "where": [
                152
            ],
            "data": [
                2887
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                483
            ],
            "data": [
                2884
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateManyWithWhereWithoutShipmentStatusInput": {
            "where": [
                2876
            ],
            "data": [
                2877
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateToOneWithWhereWithoutImageInput": {
            "where": [
                150
            ],
            "data": [
                2642
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                454
            ],
            "data": [
                2639
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "ImageScalarWhereInput": {
            "AND": [
                2894
            ],
            "OR": [
                2894
            ],
            "NOT": [
                2894
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "out_ref": [
                20
            ],
            "url": [
                20
            ],
            "store_at": [
                20
            ],
            "alt": [
                20
            ],
            "title": [
                20
            ],
            "categoryId": [
                23
            ],
            "brandId": [
                23
            ],
            "campaignId": [
                23
            ],
            "collectionId": [
                23
            ],
            "folder_type": [
                133
            ],
            "shipmentStatusId": [
                23
            ],
            "index": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutCollectionInput": {
            "where": [
                456
            ],
            "data": [
                2636
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutCollectionInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "ProductScalarWhereInput": {
            "AND": [
                2898
            ],
            "OR": [
                2898
            ],
            "NOT": [
                2898
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "group": [
                20
            ],
            "brand_id": [
                23
            ],
            "category_id": [
                23
            ],
            "negative_sale": [
                33
            ],
            "on_sale": [
                33
            ],
            "rating": [
                123
            ],
            "vat": [
                45
            ],
            "sold_count": [
                23
            ],
            "sold_quantity": [
                23
            ],
            "review_count": [
                23
            ],
            "description": [
                20
            ],
            "favorite_count": [
                33
            ],
            "in_stock": [
                33
            ],
            "unit": [
                45
            ],
            "unit_ref": [
                20
            ],
            "unit_code": [
                20
            ],
            "is_shipping_required": [
                33
            ],
            "tag": [
                20
            ],
            "cargo_fee": [
                45
            ],
            "features": [
                125
            ],
            "platform": [
                29
            ],
            "type_id": [
                23
            ],
            "unit_name": [
                20
            ],
            "title": [
                20
            ],
            "has_features": [
                33
            ],
            "priceId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "out_name": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateToOneWithWhereWithoutImageInput": {
            "where": [
                148
            ],
            "data": [
                2633
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutImagesNestedInput": {
            "create": [
                2079
            ],
            "connectOrCreate": [
                2088
            ],
            "upsert": [
                2902
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2916
            ],
            "updateMany": [
                2917
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutImagesInput": {
            "where": [
                456
            ],
            "update": [
                2903
            ],
            "create": [
                2079
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutImagesInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateManyWithoutProductNestedInput": {
            "create": [
                2081
            ],
            "connectOrCreate": [
                2087
            ],
            "upsert": [
                2905
            ],
            "set": [
                559
            ],
            "disconnect": [
                559
            ],
            "delete": [
                559
            ],
            "connect": [
                559
            ],
            "update": [
                2912
            ],
            "updateMany": [
                2913
            ],
            "deleteMany": [
                2914
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpsertWithWhereUniqueWithoutProductInput": {
            "where": [
                559
            ],
            "update": [
                2906
            ],
            "create": [
                2081
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateWithoutProductInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Image": [
                2907
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithoutCollectionNestedInput": {
            "create": [
                2083
            ],
            "connectOrCreate": [
                2084
            ],
            "upsert": [
                2908
            ],
            "createMany": [
                2085
            ],
            "set": [
                454
            ],
            "disconnect": [
                454
            ],
            "delete": [
                454
            ],
            "connect": [
                454
            ],
            "update": [
                2910
            ],
            "updateMany": [
                2911
            ],
            "deleteMany": [
                2894
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpsertWithWhereUniqueWithoutCollectionInput": {
            "where": [
                454
            ],
            "update": [
                2909
            ],
            "create": [
                2083
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithoutCollectionInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Campaign": [
                2489
            ],
            "Category": [
                2501
            ],
            "shipmentStatus": [
                2640
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutCollectionInput": {
            "where": [
                454
            ],
            "data": [
                2909
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutCollectionInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                559
            ],
            "data": [
                2906
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2914
            ],
            "data": [
                2915
            ],
            "__typename": [
                9
            ]
        },
        "CollectionScalarWhereInput": {
            "AND": [
                2914
            ],
            "OR": [
                2914
            ],
            "NOT": [
                2914
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutImagesInput": {
            "where": [
                456
            ],
            "data": [
                2903
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutImagesInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutCampaignInput": {
            "where": [
                454
            ],
            "data": [
                2630
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutCampaignInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                557
            ],
            "data": [
                2627
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2922
            ],
            "data": [
                2923
            ],
            "__typename": [
                9
            ]
        },
        "CampaignScalarWhereInput": {
            "AND": [
                2922
            ],
            "OR": [
                2922
            ],
            "NOT": [
                2922
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "start_date": [
                25
            ],
            "end_date": [
                25
            ],
            "url": [
                20
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutProductTypeInput": {
            "where": [
                456
            ],
            "data": [
                2624
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutProductTypeInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateToOneWithWhereWithoutAttributeToProductTypeInput": {
            "where": [
                165
            ],
            "data": [
                2621
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateWithWhereUniqueWithoutAttributeInput": {
            "where": [
                498
            ],
            "data": [
                2618
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateManyWithWhereWithoutAttributeInput": {
            "where": [
                2929
            ],
            "data": [
                2930
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeScalarWhereInput": {
            "AND": [
                2929
            ],
            "OR": [
                2929
            ],
            "NOT": [
                2929
            ],
            "attribute_id": [
                16
            ],
            "product_type_id": [
                16
            ],
            "is_variant": [
                33
            ],
            "group_hierarchy": [
                23
            ],
            "is_group": [
                33
            ],
            "variant_hierarchy": [
                23
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateManyMutationInput": {
            "is_variant": [
                2278
            ],
            "group_hierarchy": [
                2273
            ],
            "is_group": [
                2278
            ],
            "variant_hierarchy": [
                2273
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateToOneWithWhereWithoutAttributeValueInput": {
            "where": [
                169
            ],
            "data": [
                2615
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                506
            ],
            "data": [
                2612
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2934
            ],
            "data": [
                2935
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueScalarWhereInput": {
            "AND": [
                2934
            ],
            "OR": [
                2934
            ],
            "NOT": [
                2934
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "attributeId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "platform": [
                29
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutSupportItemInput": {
            "where": [
                122
            ],
            "data": [
                2609
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateToOneWithWhereWithoutSupportAttachmentInput": {
            "where": [
                179
            ],
            "data": [
                2606
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutSupportAttachmentNestedInput": {
            "create": [
                2105
            ],
            "connectOrCreate": [
                2106
            ],
            "upsert": [
                2939
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                2941
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutSupportAttachmentInput": {
            "update": [
                2940
            ],
            "create": [
                2105
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutSupportAttachmentInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutSupportAttachmentInput": {
            "where": [
                50
            ],
            "data": [
                2940
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithWhereUniqueWithoutSupportInput": {
            "where": [
                538
            ],
            "data": [
                2603
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithWhereWithoutSupportInput": {
            "where": [
                2688
            ],
            "data": [
                2689
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateToOneWithWhereWithoutSupportMessageInput": {
            "where": [
                183
            ],
            "data": [
                2600
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateToOneWithWhereWithoutSupportEventInput": {
            "where": [
                194
            ],
            "data": [
                2597
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateWithWhereUniqueWithoutItemInput": {
            "where": [
                553
            ],
            "data": [
                2594
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateManyWithWhereWithoutItemInput": {
            "where": [
                2770
            ],
            "data": [
                2771
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithWhereUniqueWithoutSupportInput": {
            "where": [
                540
            ],
            "data": [
                2591
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithWhereWithoutSupportInput": {
            "where": [
                2756
            ],
            "data": [
                2757
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateToOneWithWhereWithoutSupportAttachmentInput": {
            "where": [
                183
            ],
            "data": [
                2585
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateWithWhereUniqueWithoutSupportItemInput": {
            "where": [
                538
            ],
            "data": [
                2582
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateManyWithWhereWithoutSupportItemInput": {
            "where": [
                2688
            ],
            "data": [
                2689
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                540
            ],
            "data": [
                2579
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2756
            ],
            "data": [
                2757
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutAttributeValuesInput": {
            "where": [
                456
            ],
            "data": [
                2562
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutAttributeValuesInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateWithWhereUniqueWithoutAttributeInput": {
            "where": [
                506
            ],
            "data": [
                2559
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateManyWithWhereWithoutAttributeInput": {
            "where": [
                2934
            ],
            "data": [
                2935
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateToOneWithWhereWithoutAttributeToProductTypeInput": {
            "where": [
                169
            ],
            "data": [
                2556
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateWithWhereUniqueWithoutProductTypeInput": {
            "where": [
                498
            ],
            "data": [
                2553
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateManyWithWhereWithoutProductTypeInput": {
            "where": [
                2929
            ],
            "data": [
                2930
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateToOneWithWhereWithoutProductInput": {
            "where": [
                165
            ],
            "data": [
                2550
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutCategoryInput": {
            "where": [
                456
            ],
            "data": [
                2536
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutCategoryInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateManyWithoutCategoriesNestedInput": {
            "create": [
                2138
            ],
            "connectOrCreate": [
                2142
            ],
            "upsert": [
                2966
            ],
            "set": [
                467
            ],
            "disconnect": [
                467
            ],
            "delete": [
                467
            ],
            "connect": [
                467
            ],
            "update": [
                2973
            ],
            "updateMany": [
                2974
            ],
            "deleteMany": [
                2742
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpsertWithWhereUniqueWithoutCategoriesInput": {
            "where": [
                467
            ],
            "update": [
                2967
            ],
            "create": [
                2138
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithoutCategoriesInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2498
            ],
            "Product": [
                2480
            ],
            "Company": [
                2968
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateManyWithoutBrandNestedInput": {
            "create": [
                2140
            ],
            "connectOrCreate": [
                2141
            ],
            "upsert": [
                2969
            ],
            "set": [
                487
            ],
            "disconnect": [
                487
            ],
            "delete": [
                487
            ],
            "connect": [
                487
            ],
            "update": [
                2971
            ],
            "updateMany": [
                2972
            ],
            "deleteMany": [
                2746
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithWhereUniqueWithoutBrandInput": {
            "where": [
                487
            ],
            "update": [
                2970
            ],
            "create": [
                2140
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutBrandInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithWhereUniqueWithoutBrandInput": {
            "where": [
                487
            ],
            "data": [
                2970
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateManyWithWhereWithoutBrandInput": {
            "where": [
                2746
            ],
            "data": [
                2747
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateWithWhereUniqueWithoutCategoriesInput": {
            "where": [
                467
            ],
            "data": [
                2967
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateManyWithWhereWithoutCategoriesInput": {
            "where": [
                2742
            ],
            "data": [
                2743
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateToOneWithWhereWithoutPriceToCategoryInput": {
            "where": [
                140
            ],
            "data": [
                2533
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateOneRequiredWithoutPriceToCategoryNestedInput": {
            "create": [
                2145
            ],
            "connectOrCreate": [
                2151
            ],
            "upsert": [
                2977
            ],
            "connect": [
                1352
            ],
            "update": [
                2984
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpsertWithoutPriceToCategoryInput": {
            "update": [
                2978
            ],
            "create": [
                2145
            ],
            "where": [
                145
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateWithoutPriceToCategoryInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "price": [
                2288
            ],
            "price_discounted": [
                2288
            ],
            "retail": [
                2288
            ],
            "project_name": [
                2272
            ],
            "project_code": [
                2272
            ],
            "discount_rate": [
                2288
            ],
            "price_type": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                2979
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithoutPriceNestedInput": {
            "create": [
                2147
            ],
            "connectOrCreate": [
                2148
            ],
            "upsert": [
                2980
            ],
            "createMany": [
                2149
            ],
            "set": [
                456
            ],
            "disconnect": [
                456
            ],
            "delete": [
                456
            ],
            "connect": [
                456
            ],
            "update": [
                2982
            ],
            "updateMany": [
                2983
            ],
            "deleteMany": [
                2898
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithWhereUniqueWithoutPriceInput": {
            "where": [
                456
            ],
            "update": [
                2981
            ],
            "create": [
                2147
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutPriceInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutPriceInput": {
            "where": [
                456
            ],
            "data": [
                2981
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutPriceInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateToOneWithWhereWithoutPriceToCategoryInput": {
            "where": [
                145
            ],
            "data": [
                2978
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                464
            ],
            "data": [
                2530
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2545
            ],
            "data": [
                2546
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutPaymentInput": {
            "where": [
                36
            ],
            "data": [
                2527
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                324
            ],
            "data": [
                2524
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutPosAccountInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateToOneWithWhereWithoutInstallmentToPosAccountInput": {
            "where": [
                89
            ],
            "data": [
                2521
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateWithWhereUniqueWithoutInstallmentInput": {
            "where": [
                344
            ],
            "data": [
                2518
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateManyWithWhereWithoutInstallmentInput": {
            "where": [
                2993
            ],
            "data": [
                2994
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountScalarWhereInput": {
            "AND": [
                2993
            ],
            "OR": [
                2993
            ],
            "NOT": [
                2993
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "pos_account_id": [
                16
            ],
            "id": [
                16
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateToOneWithWhereWithoutInstallmentToCompanyInput": {
            "where": [
                93
            ],
            "data": [
                2515
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                338
            ],
            "data": [
                2512
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2998
            ],
            "data": [
                2999
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyScalarWhereInput": {
            "AND": [
                2998
            ],
            "OR": [
                2998
            ],
            "NOT": [
                2998
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "status": [
                33
            ],
            "blockageDay": [
                23
            ],
            "bankForiegnCardCommisionRate": [
                45
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                45
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                45
            ],
            "bankOnUsCreditCardCommisionRate": [
                45
            ],
            "bankOnUsDebitCardCommisionRate": [
                45
            ],
            "cardBrandName": [
                20
            ],
            "mininum": [
                45
            ],
            "maximum": [
                45
            ],
            "additiveInstallment": [
                23
            ],
            "merchantCommissionRate": [
                45
            ],
            "installment_id": [
                16
            ],
            "company_id": [
                16
            ],
            "id": [
                16
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutPriceToCategoryInput": {
            "where": [
                36
            ],
            "data": [
                2509
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateWithWhereUniqueWithoutCategoryInput": {
            "where": [
                464
            ],
            "data": [
                2506
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateManyWithWhereWithoutCategoryInput": {
            "where": [
                2545
            ],
            "data": [
                2546
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateToOneWithWhereWithoutImageInput": {
            "where": [
                140
            ],
            "data": [
                2503
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutBrandInput": {
            "where": [
                454
            ],
            "data": [
                2500
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutBrandInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateToOneWithWhereWithoutProductInput": {
            "where": [
                130
            ],
            "data": [
                2497
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutCampaignInput": {
            "where": [
                456
            ],
            "data": [
                2494
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutCampaignInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateToOneWithWhereWithoutImageInput": {
            "where": [
                137
            ],
            "data": [
                2491
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutCategoryInput": {
            "where": [
                454
            ],
            "data": [
                2488
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutCategoryInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateToOneWithWhereWithoutProductInput": {
            "where": [
                140
            ],
            "data": [
                2485
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithWhereUniqueWithoutBrandInput": {
            "where": [
                456
            ],
            "data": [
                2482
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateManyWithWhereWithoutBrandInput": {
            "where": [
                2898
            ],
            "data": [
                2899
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateToOneWithWhereWithoutImageInput": {
            "where": [
                130
            ],
            "data": [
                2479
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateWithWhereUniqueWithoutShipmentStatusInput": {
            "where": [
                454
            ],
            "data": [
                2475
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateManyWithWhereWithoutShipmentStatusInput": {
            "where": [
                2894
            ],
            "data": [
                2895
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateToOneWithWhereWithoutShipmentInput": {
            "where": [
                150
            ],
            "data": [
                2472
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateToOneWithWhereWithoutOrderItemInput": {
            "where": [
                152
            ],
            "data": [
                2464
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutCartItemInput": {
            "where": [
                437
            ],
            "data": [
                2461
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutCartItemInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                414
            ],
            "data": [
                2458
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateManyWithWhereWithoutProductInput": {
            "where": [
                3024
            ],
            "data": [
                3025
            ],
            "__typename": [
                9
            ]
        },
        "CartItemScalarWhereInput": {
            "AND": [
                3024
            ],
            "OR": [
                3024
            ],
            "NOT": [
                3024
            ],
            "id": [
                16
            ],
            "cart_id": [
                23
            ],
            "product_id": [
                23
            ],
            "price": [
                45
            ],
            "quantity": [
                45
            ],
            "vat": [
                45
            ],
            "unit_code": [
                20
            ],
            "unit": [
                45
            ],
            "quantity2": [
                45
            ],
            "total_price": [
                45
            ],
            "discount_price": [
                45
            ],
            "vat_price": [
                45
            ],
            "total_net_price": [
                45
            ],
            "features": [
                120
            ],
            "discount_rate": [
                45
            ],
            "notes1": [
                20
            ],
            "notes2": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateManyMutationInput": {
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "vat": [
                2288
            ],
            "unit_code": [
                2272
            ],
            "unit": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "features": [
                79
            ],
            "discount_rate": [
                2288
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutOrderItemInput": {
            "where": [
                122
            ],
            "data": [
                2455
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                437
            ],
            "data": [
                2443
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutOrderInput": {
            "where": [
                50
            ],
            "data": [
                2440
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateToOneWithWhereWithoutCartInput": {
            "where": [
                60
            ],
            "data": [
                2437
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateToOneWithWhereWithoutCartItemInput": {
            "where": [
                39
            ],
            "data": [
                2434
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateToOneWithWhereWithoutOrderItemInput": {
            "where": [
                119
            ],
            "data": [
                2431
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutProductInput": {
            "where": [
                437
            ],
            "data": [
                2427
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutProductInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutCartItemInput": {
            "where": [
                122
            ],
            "data": [
                2415
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateWithWhereUniqueWithoutCartInput": {
            "where": [
                414
            ],
            "data": [
                2412
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateManyWithWhereWithoutCartInput": {
            "where": [
                3024
            ],
            "data": [
                3025
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                283
            ],
            "data": [
                2409
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithWhereWithoutUserInput": {
            "where": [
                3040
            ],
            "data": [
                3041
            ],
            "__typename": [
                9
            ]
        },
        "CartScalarWhereInput": {
            "AND": [
                3040
            ],
            "OR": [
                3040
            ],
            "NOT": [
                3040
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "userId": [
                23
            ],
            "status": [
                40
            ],
            "billing_address_id": [
                23
            ],
            "shipment_address_id": [
                23
            ],
            "approved_by": [
                23
            ],
            "aprroved_at": [
                43
            ],
            "note": [
                20
            ],
            "order_key": [
                20
            ],
            "total_discount": [
                45
            ],
            "total_price": [
                45
            ],
            "total_price_net": [
                45
            ],
            "total_shipment_cost": [
                45
            ],
            "total_vat_cost": [
                45
            ],
            "company_id": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithWhereUniqueWithoutAddressInput": {
            "where": [
                572
            ],
            "data": [
                2406
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithWhereWithoutAddressInput": {
            "where": [
                2838
            ],
            "data": [
                2839
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateToOneWithWhereWithoutOrderShipmentInput": {
            "where": [
                15
            ],
            "data": [
                2403
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                568
            ],
            "data": [
                2400
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2863
            ],
            "data": [
                2864
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutInstallmentToCompanyInput": {
            "where": [
                36
            ],
            "data": [
                2397
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateWithWhereUniqueWithoutInstallmentInput": {
            "where": [
                338
            ],
            "data": [
                2394
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateManyWithWhereWithoutInstallmentInput": {
            "where": [
                2998
            ],
            "data": [
                2999
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateToOneWithWhereWithoutInstallmentToPosAccountInput": {
            "where": [
                93
            ],
            "data": [
                2391
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateWithWhereUniqueWithoutPosAccountInput": {
            "where": [
                344
            ],
            "data": [
                2388
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateManyWithWhereWithoutPosAccountInput": {
            "where": [
                2993
            ],
            "data": [
                2994
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithWhereUniqueWithoutBankInput": {
            "where": [
                348
            ],
            "data": [
                2385
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateManyWithWhereWithoutBankInput": {
            "where": [
                3055
            ],
            "data": [
                3056
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountScalarWhereInput": {
            "AND": [
                3055
            ],
            "OR": [
                3055
            ],
            "NOT": [
                3055
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "platform": [
                29
            ],
            "bank_id": [
                23
            ],
            "is_default": [
                33
            ],
            "image": [
                20
            ],
            "pos_props": [
                78
            ],
            "credentials": [
                78
            ],
            "agencies": [
                78
            ],
            "Payment_system_id": [
                23
            ],
            "maximum": [
                45
            ],
            "minimum": [
                45
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateToOneWithWhereWithoutPaymentInput": {
            "where": [
                84
            ],
            "data": [
                2375
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutMethodInput": {
            "where": [
                324
            ],
            "data": [
                2370
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutMethodInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateToOneWithWhereWithoutCompanyToPaymentMethodInput": {
            "where": [
                104
            ],
            "data": [
                2367
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                358
            ],
            "data": [
                2364
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                2843
            ],
            "data": [
                2844
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutOrderInput": {
            "where": [
                36
            ],
            "data": [
                2354
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithWhereUniqueWithoutShipmentAddressInput": {
            "where": [
                568
            ],
            "data": [
                2351
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithWhereWithoutShipmentAddressInput": {
            "where": [
                2863
            ],
            "data": [
                2864
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateToOneWithWhereWithoutOrderBillingInput": {
            "where": [
                15
            ],
            "data": [
                2348
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithWhereUniqueWithoutUserInput": {
            "where": [
                568
            ],
            "data": [
                2345
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithWhereWithoutUserInput": {
            "where": [
                2863
            ],
            "data": [
                2864
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutCartInput": {
            "where": [
                50
            ],
            "data": [
                2333
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateToOneWithWhereWithoutOrderInput": {
            "where": [
                39
            ],
            "data": [
                2330
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateWithWhereUniqueWithoutBillingAddressInput": {
            "where": [
                568
            ],
            "data": [
                2324
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateManyWithWhereWithoutBillingAddressInput": {
            "where": [
                2863
            ],
            "data": [
                2864
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateToOneWithWhereWithoutCartShipmentInput": {
            "where": [
                15
            ],
            "data": [
                2321
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithWhereUniqueWithoutBillingAddressInput": {
            "where": [
                283
            ],
            "data": [
                2318
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithWhereWithoutBillingAddressInput": {
            "where": [
                3040
            ],
            "data": [
                3041
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                253
            ],
            "data": [
                2315
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                3078
            ],
            "data": [
                2270
            ],
            "__typename": [
                9
            ]
        },
        "AddressScalarWhereInput": {
            "AND": [
                3078
            ],
            "OR": [
                3078
            ],
            "NOT": [
                3078
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "companyId": [
                23
            ],
            "countyId": [
                23
            ],
            "platform": [
                29
            ],
            "type": [
                31
            ],
            "address": [
                20
            ],
            "title": [
                20
            ],
            "email": [
                20
            ],
            "gsm": [
                20
            ],
            "phone1": [
                20
            ],
            "phone2": [
                20
            ],
            "tax_number": [
                20
            ],
            "tax_office": [
                20
            ],
            "website": [
                20
            ],
            "is_deleted": [
                33
            ],
            "subCompany_code": [
                20
            ],
            "subCompany_name": [
                20
            ],
            "subCompany_out_ref": [
                20
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutCartInput": {
            "where": [
                36
            ],
            "data": [
                2312
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithWhereUniqueWithoutShipmentAddressInput": {
            "where": [
                283
            ],
            "data": [
                2309
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithWhereWithoutShipmentAddressInput": {
            "where": [
                3040
            ],
            "data": [
                3041
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateToOneWithWhereWithoutCartBillingInput": {
            "where": [
                15
            ],
            "data": [
                2291
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateWithWhereUniqueWithoutCompanyInput": {
            "where": [
                283
            ],
            "data": [
                2285
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateManyWithWhereWithoutCompanyInput": {
            "where": [
                3040
            ],
            "data": [
                3041
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutAddressInput": {
            "where": [
                36
            ],
            "data": [
                2282
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AttributeCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "AttributeToProductType": [
                1882
            ],
            "AttributeValue": [
                1844
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "AttributeUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "AttributeToProductType": [
                2616
            ],
            "AttributeValue": [
                2557
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateManyInput": {
            "attribute_id": [
                3
            ],
            "product_type_id": [
                3
            ],
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeCreateInput": {
            "is_variant": [
                8
            ],
            "group_hierarchy": [
                3
            ],
            "is_group": [
                8
            ],
            "variant_hierarchy": [
                3
            ],
            "Attribute": [
                1842
            ],
            "ProductType": [
                1884
            ],
            "__typename": [
                9
            ]
        },
        "AttributeToProductTypeUpdateInput": {
            "is_variant": [
                2278
            ],
            "group_hierarchy": [
                2273
            ],
            "is_group": [
                2278
            ],
            "variant_hierarchy": [
                2273
            ],
            "Attribute": [
                2554
            ],
            "ProductType": [
                2619
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "attributeId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Attribute": [
                1880
            ],
            "Product": [
                1846
            ],
            "__typename": [
                9
            ]
        },
        "AttributeValueUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Attribute": [
                2613
            ],
            "Product": [
                2560
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "bin_numbers": [
                1724
            ],
            "Payment": [
                1923
            ],
            "PosAccount": [
                1729
            ],
            "PosConfig": [
                2008
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "bin_numbers": [
                2376
            ],
            "Payment": [
                2677
            ],
            "PosAccount": [
                2383
            ],
            "PosConfig": [
                2809
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "product_id": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "module": [
                9
            ],
            "module_out_ref": [
                3
            ],
            "barcode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                3102
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutBarcodeInput": {
            "create": [
                3103
            ],
            "connectOrCreate": [
                3104
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutBarcodeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutBarcodeInput": {
            "where": [
                456
            ],
            "create": [
                3103
            ],
            "__typename": [
                9
            ]
        },
        "BarcodeUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "module": [
                2272
            ],
            "module_out_ref": [
                2273
            ],
            "barcode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                3106
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutBarcodeNestedInput": {
            "create": [
                3103
            ],
            "connectOrCreate": [
                3104
            ],
            "upsert": [
                3107
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                3109
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutBarcodeInput": {
            "update": [
                3108
            ],
            "create": [
                3103
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutBarcodeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutBarcodeInput": {
            "where": [
                122
            ],
            "data": [
                3108
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "bank_id": [
                3
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberCreateInput": {
            "active": [
                8
            ],
            "is_bussiness_card": [
                8
            ],
            "network": [
                9
            ],
            "family": [
                9
            ],
            "binnumber": [
                9
            ],
            "cardtype": [
                9
            ],
            "Bank": [
                3112
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateNestedOneWithoutBin_numbersInput": {
            "create": [
                3113
            ],
            "connectOrCreate": [
                3114
            ],
            "connect": [
                679
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateWithoutBin_numbersInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "iban": [
                9
            ],
            "office": [
                9
            ],
            "account_number": [
                9
            ],
            "eft_code": [
                9
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                8
            ],
            "pos_category": [
                9
            ],
            "selected_pos_id": [
                3
            ],
            "is_payment_system": [
                8
            ],
            "is_deleted": [
                8
            ],
            "Payment": [
                1923
            ],
            "PosAccount": [
                1729
            ],
            "PosConfig": [
                2008
            ],
            "__typename": [
                9
            ]
        },
        "BankCreateOrConnectWithoutBin_numbersInput": {
            "where": [
                679
            ],
            "create": [
                3113
            ],
            "__typename": [
                9
            ]
        },
        "BinNumberUpdateInput": {
            "active": [
                2271
            ],
            "is_bussiness_card": [
                2278
            ],
            "network": [
                2272
            ],
            "family": [
                2272
            ],
            "binnumber": [
                2272
            ],
            "cardtype": [
                2272
            ],
            "Bank": [
                3116
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateOneWithoutBin_numbersNestedInput": {
            "create": [
                3113
            ],
            "connectOrCreate": [
                3114
            ],
            "upsert": [
                3117
            ],
            "disconnect": [
                84
            ],
            "delete": [
                84
            ],
            "connect": [
                679
            ],
            "update": [
                3119
            ],
            "__typename": [
                9
            ]
        },
        "BankUpsertWithoutBin_numbersInput": {
            "update": [
                3118
            ],
            "create": [
                3113
            ],
            "where": [
                84
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateWithoutBin_numbersInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "iban": [
                2272
            ],
            "office": [
                2272
            ],
            "account_number": [
                2272
            ],
            "eft_code": [
                2272
            ],
            "pos_defaults": [
                79
            ],
            "family": [
                79
            ],
            "is_pos": [
                2278
            ],
            "pos_category": [
                2272
            ],
            "selected_pos_id": [
                2273
            ],
            "is_payment_system": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "Payment": [
                2677
            ],
            "PosAccount": [
                2383
            ],
            "PosConfig": [
                2809
            ],
            "__typename": [
                9
            ]
        },
        "BankUpdateToOneWithWhereWithoutBin_numbersInput": {
            "where": [
                84
            ],
            "data": [
                3118
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "BrandCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "image": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1804
            ],
            "Product": [
                1792
            ],
            "Categories": [
                1959
            ],
            "Company": [
                2139
            ],
            "__typename": [
                9
            ]
        },
        "BrandUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "image": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2498
            ],
            "Product": [
                2480
            ],
            "Categories": [
                2733
            ],
            "Company": [
                2968
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CampaignCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Image": [
                1890
            ],
            "Product": [
                1800
            ],
            "__typename": [
                9
            ]
        },
        "CampaignUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Image": [
                2628
            ],
            "Product": [
                2492
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "creadentials": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "phone": [
                9
            ],
            "email": [
                9
            ],
            "address": [
                9
            ],
            "is_deleted": [
                8
            ],
            "creadentials": [
                79
            ],
            "Shipment": [
                3128
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateNestedManyWithoutCargoAgencyInput": {
            "create": [
                3129
            ],
            "connectOrCreate": [
                3130
            ],
            "createMany": [
                3131
            ],
            "connect": [
                477
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateWithoutCargoAgencyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                1902
            ],
            "ShipmentStatus": [
                1786
            ],
            "ShipmentEvent": [
                2055
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateOrConnectWithoutCargoAgencyInput": {
            "where": [
                477
            ],
            "create": [
                3129
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateManyCargoAgencyInputEnvelope": {
            "data": [
                3132
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateManyCargoAgencyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "phone": [
                2272
            ],
            "email": [
                2272
            ],
            "address": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "creadentials": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "CargoAgencyUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "phone": [
                2272
            ],
            "email": [
                2272
            ],
            "address": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "creadentials": [
                79
            ],
            "Shipment": [
                3135
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateManyWithoutCargoAgencyNestedInput": {
            "create": [
                3129
            ],
            "connectOrCreate": [
                3130
            ],
            "upsert": [
                3136
            ],
            "createMany": [
                3131
            ],
            "set": [
                477
            ],
            "disconnect": [
                477
            ],
            "delete": [
                477
            ],
            "connect": [
                477
            ],
            "update": [
                3138
            ],
            "updateMany": [
                3139
            ],
            "deleteMany": [
                2880
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpsertWithWhereUniqueWithoutCargoAgencyInput": {
            "where": [
                477
            ],
            "update": [
                3137
            ],
            "create": [
                3129
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithoutCargoAgencyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                2646
            ],
            "ShipmentStatus": [
                2470
            ],
            "ShipmentEvent": [
                2867
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateWithWhereUniqueWithoutCargoAgencyInput": {
            "where": [
                477
            ],
            "data": [
                3137
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateManyWithWhereWithoutCargoAgencyInput": {
            "where": [
                2880
            ],
            "data": [
                2881
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "userId": [
                3
            ],
            "status": [
                41
            ],
            "billing_address_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "company_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status": [
                41
            ],
            "approved_by": [
                3
            ],
            "aprroved_at": [
                10
            ],
            "note": [
                9
            ],
            "order_key": [
                9
            ],
            "total_discount": [
                46
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "is_deleted": [
                8
            ],
            "BillingAddress": [
                1669
            ],
            "Company": [
                1684
            ],
            "ShipmentAddress": [
                1690
            ],
            "User": [
                1696
            ],
            "CartItem": [
                1747
            ],
            "Order": [
                1763
            ],
            "__typename": [
                9
            ]
        },
        "CartUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status": [
                2286
            ],
            "approved_by": [
                2273
            ],
            "aprroved_at": [
                2287
            ],
            "note": [
                2272
            ],
            "order_key": [
                2272
            ],
            "total_discount": [
                2288
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "BillingAddress": [
                2289
            ],
            "Company": [
                2310
            ],
            "ShipmentAddress": [
                2319
            ],
            "User": [
                2331
            ],
            "CartItem": [
                2410
            ],
            "Order": [
                2435
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateManyInput": {
            "id": [
                3
            ],
            "cart_id": [
                3
            ],
            "product_id": [
                3
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CartItemCreateInput": {
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "vat": [
                46
            ],
            "unit_code": [
                9
            ],
            "unit": [
                46
            ],
            "quantity2": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "features": [
                79
            ],
            "discount_rate": [
                46
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1761
            ],
            "Product": [
                1749
            ],
            "OrderItem": [
                1779
            ],
            "__typename": [
                9
            ]
        },
        "CartItemUpdateInput": {
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "vat": [
                2288
            ],
            "unit_code": [
                2272
            ],
            "unit": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "features": [
                79
            ],
            "discount_rate": [
                2288
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2432
            ],
            "Product": [
                2413
            ],
            "OrderItem": [
                2459
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "CategoryCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "parent_id": [
                3
            ],
            "is_parent": [
                8
            ],
            "level": [
                3
            ],
            "description": [
                9
            ],
            "background_img": [
                9
            ],
            "is_corporate": [
                8
            ],
            "link_url": [
                9
            ],
            "is_deleted": [
                8
            ],
            "platform": [
                12
            ],
            "Image": [
                1796
            ],
            "PriceToCategory": [
                1808
            ],
            "Product": [
                1828
            ],
            "Brand": [
                2137
            ],
            "__typename": [
                9
            ]
        },
        "CategoryUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "parent_id": [
                2273
            ],
            "is_parent": [
                2278
            ],
            "level": [
                2273
            ],
            "description": [
                2272
            ],
            "background_img": [
                2272
            ],
            "is_corporate": [
                2278
            ],
            "link_url": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "platform": [
                2276
            ],
            "Image": [
                2486
            ],
            "PriceToCategory": [
                2504
            ],
            "Product": [
                2534
            ],
            "Brand": [
                2965
            ],
            "__typename": [
                9
            ]
        },
        "CityCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "CityCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "County": [
                3151
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateNestedManyWithoutCityInput": {
            "create": [
                3152
            ],
            "connectOrCreate": [
                3158
            ],
            "createMany": [
                3159
            ],
            "connect": [
                594
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateWithoutCityInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                3153
            ],
            "District": [
                1676
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateNestedManyWithoutCountyInput": {
            "create": [
                3154
            ],
            "connectOrCreate": [
                3155
            ],
            "createMany": [
                3156
            ],
            "connect": [
                253
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateWithoutCountyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "Company": [
                1665
            ],
            "CartBilling": [
                1688
            ],
            "CartShipment": [
                1682
            ],
            "OrderBilling": [
                1692
            ],
            "OrderShipment": [
                1707
            ],
            "User": [
                1743
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateOrConnectWithoutCountyInput": {
            "where": [
                253
            ],
            "create": [
                3154
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateManyCountyInputEnvelope": {
            "data": [
                3157
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "AddressCreateManyCountyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "companyId": [
                3
            ],
            "platform": [
                12
            ],
            "type": [
                13
            ],
            "address": [
                9
            ],
            "title": [
                9
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "tax_number": [
                9
            ],
            "tax_office": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "subCompany_code": [
                9
            ],
            "subCompany_name": [
                9
            ],
            "subCompany_out_ref": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateOrConnectWithoutCityInput": {
            "where": [
                594
            ],
            "create": [
                3152
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateManyCityInputEnvelope": {
            "data": [
                3160
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateManyCityInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CityUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "CityUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "County": [
                3163
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateManyWithoutCityNestedInput": {
            "create": [
                3152
            ],
            "connectOrCreate": [
                3158
            ],
            "upsert": [
                3164
            ],
            "createMany": [
                3159
            ],
            "set": [
                594
            ],
            "disconnect": [
                594
            ],
            "delete": [
                594
            ],
            "connect": [
                594
            ],
            "update": [
                3171
            ],
            "updateMany": [
                3172
            ],
            "deleteMany": [
                3173
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpsertWithWhereUniqueWithoutCityInput": {
            "where": [
                594
            ],
            "update": [
                3165
            ],
            "create": [
                3152
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateWithoutCityInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                3166
            ],
            "District": [
                2299
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateManyWithoutCountyNestedInput": {
            "create": [
                3154
            ],
            "connectOrCreate": [
                3155
            ],
            "upsert": [
                3167
            ],
            "createMany": [
                3156
            ],
            "set": [
                253
            ],
            "disconnect": [
                253
            ],
            "delete": [
                253
            ],
            "connect": [
                253
            ],
            "update": [
                3169
            ],
            "updateMany": [
                3170
            ],
            "deleteMany": [
                3078
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpsertWithWhereUniqueWithoutCountyInput": {
            "where": [
                253
            ],
            "update": [
                3168
            ],
            "create": [
                3154
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithoutCountyInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "type": [
                2277
            ],
            "address": [
                2272
            ],
            "title": [
                2272
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "subCompany_code": [
                2272
            ],
            "subCompany_name": [
                2272
            ],
            "subCompany_out_ref": [
                2272
            ],
            "Company": [
                2280
            ],
            "CartBilling": [
                2316
            ],
            "CartShipment": [
                2307
            ],
            "OrderBilling": [
                2322
            ],
            "OrderShipment": [
                2349
            ],
            "User": [
                2404
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateWithWhereUniqueWithoutCountyInput": {
            "where": [
                253
            ],
            "data": [
                3168
            ],
            "__typename": [
                9
            ]
        },
        "AddressUpdateManyWithWhereWithoutCountyInput": {
            "where": [
                3078
            ],
            "data": [
                2270
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateWithWhereUniqueWithoutCityInput": {
            "where": [
                594
            ],
            "data": [
                3165
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateManyWithWhereWithoutCityInput": {
            "where": [
                3173
            ],
            "data": [
                3174
            ],
            "__typename": [
                9
            ]
        },
        "CountyScalarWhereInput": {
            "AND": [
                3173
            ],
            "OR": [
                3173
            ],
            "NOT": [
                3173
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "cityId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CollectionCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "url": [
                9
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Image": [
                2082
            ],
            "Product": [
                1894
            ],
            "__typename": [
                9
            ]
        },
        "CollectionUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "start_date": [
                2274
            ],
            "end_date": [
                2274
            ],
            "url": [
                2272
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Image": [
                2907
            ],
            "Product": [
                2634
            ],
            "__typename": [
                9
            ]
        },
        "CommentCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CommentCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "userId": [
                3
            ],
            "comment_on": [
                9
            ],
            "comment_to": [
                9
            ],
            "comment": [
                9
            ],
            "rating": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CommentUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "userId": [
                2447
            ],
            "comment_on": [
                2325
            ],
            "comment_to": [
                2325
            ],
            "comment": [
                2272
            ],
            "rating": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CommentUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "userId": [
                2447
            ],
            "comment_on": [
                2325
            ],
            "comment_to": [
                2325
            ],
            "comment": [
                2272
            ],
            "rating": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyStatement": [
                1711
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyStatement": [
                2355
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "company_id": [
                3
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "document_type": [
                9
            ],
            "document_number": [
                9
            ],
            "description": [
                9
            ],
            "debit": [
                46
            ],
            "credit": [
                46
            ],
            "balance": [
                46
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "document_date": [
                10
            ],
            "data_number": [
                3
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Company": [
                3187
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateNestedOneWithoutCompanyStatementInput": {
            "create": [
                3188
            ],
            "connectOrCreate": [
                3189
            ],
            "connect": [
                487
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateWithoutCompanyStatementInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "email": [
                9
            ],
            "title": [
                9
            ],
            "tax_office": [
                9
            ],
            "tax_number": [
                9
            ],
            "phone1": [
                9
            ],
            "phone2": [
                9
            ],
            "gsm": [
                9
            ],
            "website": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                1686
            ],
            "Cart": [
                1667
            ],
            "CompanyToPaymentMethod": [
                1716
            ],
            "InstallmentToCompany": [
                1812
            ],
            "Order": [
                1739
            ],
            "Payment": [
                1914
            ],
            "PriceToCategory": [
                1824
            ],
            "Brand": [
                1957
            ],
            "User": [
                2029
            ],
            "__typename": [
                9
            ]
        },
        "CompanyCreateOrConnectWithoutCompanyStatementInput": {
            "where": [
                487
            ],
            "create": [
                3188
            ],
            "__typename": [
                9
            ]
        },
        "CompanyStatementUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "document_type": [
                2272
            ],
            "document_number": [
                2272
            ],
            "description": [
                2272
            ],
            "debit": [
                2288
            ],
            "credit": [
                2288
            ],
            "balance": [
                2288
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "document_date": [
                2287
            ],
            "data_number": [
                2273
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Company": [
                3191
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateOneWithoutCompanyStatementNestedInput": {
            "create": [
                3188
            ],
            "connectOrCreate": [
                3189
            ],
            "upsert": [
                3192
            ],
            "disconnect": [
                36
            ],
            "delete": [
                36
            ],
            "connect": [
                487
            ],
            "update": [
                3194
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpsertWithoutCompanyStatementInput": {
            "update": [
                3193
            ],
            "create": [
                3188
            ],
            "where": [
                36
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateWithoutCompanyStatementInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "email": [
                2272
            ],
            "title": [
                2272
            ],
            "tax_office": [
                2272
            ],
            "tax_number": [
                2272
            ],
            "phone1": [
                2272
            ],
            "phone2": [
                2272
            ],
            "gsm": [
                2272
            ],
            "website": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                2313
            ],
            "Cart": [
                2283
            ],
            "CompanyToPaymentMethod": [
                2362
            ],
            "InstallmentToCompany": [
                2510
            ],
            "Order": [
                2398
            ],
            "Payment": [
                2664
            ],
            "PriceToCategory": [
                2528
            ],
            "Brand": [
                2730
            ],
            "User": [
                2833
            ],
            "__typename": [
                9
            ]
        },
        "CompanyUpdateToOneWithWhereWithoutCompanyStatementInput": {
            "where": [
                36
            ],
            "data": [
                3193
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateManyInput": {
            "company_id": [
                3
            ],
            "payment_methodId": [
                3
            ],
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodCreateInput": {
            "minimum_amount": [
                46
            ],
            "maximum_amount": [
                46
            ],
            "end_date": [
                10
            ],
            "start_date": [
                10
            ],
            "total_amount": [
                46
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "Company": [
                1912
            ],
            "PaymentMethod": [
                1718
            ],
            "__typename": [
                9
            ]
        },
        "CompanyToPaymentMethodUpdateInput": {
            "minimum_amount": [
                2288
            ],
            "maximum_amount": [
                2288
            ],
            "end_date": [
                2287
            ],
            "start_date": [
                2287
            ],
            "total_amount": [
                2288
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "Company": [
                2661
            ],
            "PaymentMethod": [
                2365
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "module": [
                9
            ],
            "category": [
                9
            ],
            "key": [
                9
            ],
            "value": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "module": [
                2272
            ],
            "category": [
                2272
            ],
            "key": [
                2272
            ],
            "value": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "ConfigurationUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "module": [
                2272
            ],
            "category": [
                2272
            ],
            "key": [
                2272
            ],
            "value": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateManyInput": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "user_id": [
                3
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeCreateInput": {
            "code": [
                9
            ],
            "expire_at": [
                10
            ],
            "confirmed_at": [
                10
            ],
            "type": [
                57
            ],
            "gsm_no": [
                9
            ],
            "email": [
                9
            ],
            "User": [
                3204
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedOneWithoutConfirmCodeInput": {
            "create": [
                3205
            ],
            "connectOrCreate": [
                3206
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutConfirmCodeInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutConfirmCodeInput": {
            "where": [
                572
            ],
            "create": [
                3205
            ],
            "__typename": [
                9
            ]
        },
        "ConfirmCodeUpdateInput": {
            "code": [
                2272
            ],
            "expire_at": [
                2287
            ],
            "confirmed_at": [
                2287
            ],
            "type": [
                2338
            ],
            "gsm_no": [
                2272
            ],
            "email": [
                2272
            ],
            "User": [
                3208
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateOneWithoutConfirmCodeNestedInput": {
            "create": [
                3205
            ],
            "connectOrCreate": [
                3206
            ],
            "upsert": [
                3209
            ],
            "disconnect": [
                50
            ],
            "delete": [
                50
            ],
            "connect": [
                572
            ],
            "update": [
                3211
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithoutConfirmCodeInput": {
            "update": [
                3210
            ],
            "create": [
                3205
            ],
            "where": [
                50
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutConfirmCodeInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateToOneWithWhereWithoutConfirmCodeInput": {
            "where": [
                50
            ],
            "data": [
                3210
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "cityId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                3153
            ],
            "City": [
                1673
            ],
            "District": [
                1676
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                3166
            ],
            "City": [
                2295
            ],
            "District": [
                2299
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryCreateManyInput": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryCreateInput": {
            "method": [
                9
            ],
            "erp_name": [
                9
            ],
            "erp_sync_type": [
                9
            ],
            "name": [
                9
            ],
            "query": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryUpdateManyMutationInput": {
            "method": [
                2272
            ],
            "erp_name": [
                2272
            ],
            "erp_sync_type": [
                2272
            ],
            "name": [
                2272
            ],
            "query": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "DBQueryUpdateInput": {
            "method": [
                2272
            ],
            "erp_name": [
                2272
            ],
            "erp_sync_type": [
                2272
            ],
            "name": [
                2272
            ],
            "query": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "DeviceCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DeviceCreateInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "user_id": [
                3
            ],
            "app_version": [
                9
            ],
            "device_id": [
                9
            ],
            "device_name": [
                9
            ],
            "notification_id": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "DeviceUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "user_id": [
                2273
            ],
            "app_version": [
                2272
            ],
            "device_id": [
                2272
            ],
            "device_name": [
                2272
            ],
            "notification_id": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "DeviceUpdateInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "user_id": [
                2273
            ],
            "app_version": [
                2272
            ],
            "device_id": [
                2272
            ],
            "device_name": [
                2272
            ],
            "notification_id": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "countyId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "DistrictCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "County": [
                3225
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateNestedOneWithoutDistrictInput": {
            "create": [
                3226
            ],
            "connectOrCreate": [
                3227
            ],
            "connect": [
                594
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateWithoutDistrictInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "is_deleted": [
                8
            ],
            "Address": [
                3153
            ],
            "City": [
                1673
            ],
            "__typename": [
                9
            ]
        },
        "CountyCreateOrConnectWithoutDistrictInput": {
            "where": [
                594
            ],
            "create": [
                3226
            ],
            "__typename": [
                9
            ]
        },
        "DistrictUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "County": [
                3229
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateOneWithoutDistrictNestedInput": {
            "create": [
                3226
            ],
            "connectOrCreate": [
                3227
            ],
            "upsert": [
                3230
            ],
            "disconnect": [
                220
            ],
            "delete": [
                220
            ],
            "connect": [
                594
            ],
            "update": [
                3232
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpsertWithoutDistrictInput": {
            "update": [
                3231
            ],
            "create": [
                3226
            ],
            "where": [
                220
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateWithoutDistrictInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "is_deleted": [
                2278
            ],
            "Address": [
                3166
            ],
            "City": [
                2295
            ],
            "__typename": [
                9
            ]
        },
        "CountyUpdateToOneWithWhereWithoutDistrictInput": {
            "where": [
                220
            ],
            "data": [
                3231
            ],
            "__typename": [
                9
            ]
        },
        "ErrorCreateManyInput": {
            "id": [
                3
            ],
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorCreateInput": {
            "method": [
                9
            ],
            "name": [
                9
            ],
            "message": [
                9
            ],
            "stack": [
                9
            ],
            "created_at": [
                10
            ],
            "reference_id": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ErrorUpdateManyMutationInput": {
            "method": [
                2272
            ],
            "name": [
                2272
            ],
            "message": [
                2272
            ],
            "stack": [
                2272
            ],
            "created_at": [
                2274
            ],
            "reference_id": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "ErrorUpdateInput": {
            "method": [
                2272
            ],
            "name": [
                2272
            ],
            "message": [
                2272
            ],
            "stack": [
                2272
            ],
            "created_at": [
                2274
            ],
            "reference_id": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerCreateInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "FileManagerUpdateInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "categoryId": [
                3
            ],
            "brandId": [
                3
            ],
            "campaignId": [
                3
            ],
            "collectionId": [
                3
            ],
            "folder_type": [
                134
            ],
            "shipmentStatusId": [
                3
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ImageCreateInput": {
            "active": [
                8
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "out_ref": [
                9
            ],
            "url": [
                9
            ],
            "store_at": [
                9
            ],
            "alt": [
                9
            ],
            "title": [
                9
            ],
            "folder_type": [
                134
            ],
            "index": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Brand": [
                1790
            ],
            "Campaign": [
                1798
            ],
            "Category": [
                1806
            ],
            "Collection": [
                1892
            ],
            "shipmentStatus": [
                1898
            ],
            "Product": [
                2078
            ],
            "__typename": [
                9
            ]
        },
        "ImageUpdateInput": {
            "active": [
                2271
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "url": [
                2272
            ],
            "store_at": [
                2272
            ],
            "alt": [
                2272
            ],
            "title": [
                2272
            ],
            "folder_type": [
                2476
            ],
            "index": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Brand": [
                2477
            ],
            "Campaign": [
                2489
            ],
            "Category": [
                2501
            ],
            "Collection": [
                2631
            ],
            "shipmentStatus": [
                2640
            ],
            "Product": [
                2901
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateManyInput": {
            "id": [
                3
            ],
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentCreateInput": {
            "code": [
                9
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "count": [
                3
            ],
            "InstallmentToCompany": [
                1735
            ],
            "InstallmentToPosAccount": [
                1816
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateManyMutationInput": {
            "code": [
                2272
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "count": [
                2273
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentUpdateInput": {
            "code": [
                2272
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "count": [
                2273
            ],
            "InstallmentToCompany": [
                2392
            ],
            "InstallmentToPosAccount": [
                2516
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateManyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "company_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "Company": [
                1737
            ],
            "Installment": [
                1814
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToCompanyUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "Company": [
                2395
            ],
            "Installment": [
                2513
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateManyInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "installment_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "id": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "status": [
                8
            ],
            "blockageDay": [
                3
            ],
            "bankForiegnCardCommisionRate": [
                46
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                46
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                46
            ],
            "bankOnUsCreditCardCommisionRate": [
                46
            ],
            "bankOnUsDebitCardCommisionRate": [
                46
            ],
            "cardBrandName": [
                9
            ],
            "mininum": [
                46
            ],
            "maximum": [
                46
            ],
            "additiveInstallment": [
                3
            ],
            "merchantCommissionRate": [
                46
            ],
            "Installment": [
                1733
            ],
            "PosAccount": [
                1818
            ],
            "__typename": [
                9
            ]
        },
        "InstallmentToPosAccountUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "status": [
                2278
            ],
            "blockageDay": [
                2273
            ],
            "bankForiegnCardCommisionRate": [
                2288
            ],
            "bankNotOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankNotOnUsDebitCardCommisionRate": [
                2288
            ],
            "bankOnUsCreditCardCommisionRate": [
                2288
            ],
            "bankOnUsDebitCardCommisionRate": [
                2288
            ],
            "cardBrandName": [
                2272
            ],
            "mininum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "additiveInstallment": [
                2273
            ],
            "merchantCommissionRate": [
                2288
            ],
            "Installment": [
                2389
            ],
            "PosAccount": [
                2519
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "company_id": [
                3
            ],
            "user_id": [
                3
            ],
            "shipment_address_id": [
                3
            ],
            "billing_address_id": [
                3
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "cartId": [
                3
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "__typename": [
                9
            ]
        },
        "OrderCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "ordered_at": [
                10
            ],
            "order_key": [
                9
            ],
            "outer_order_key": [
                9
            ],
            "total_price": [
                46
            ],
            "total_price_net": [
                46
            ],
            "total_shipment_cost": [
                46
            ],
            "total_vat_cost": [
                46
            ],
            "total_discount": [
                46
            ],
            "total_quantity": [
                3
            ],
            "order_note": [
                9
            ],
            "is_approved": [
                8
            ],
            "approved_by": [
                3
            ],
            "approved_at": [
                10
            ],
            "is_sent_erp": [
                8
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                3
            ],
            "company_name": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                9
            ],
            "payment_status": [
                64
            ],
            "warehouse": [
                9
            ],
            "is_sent_status": [
                67
            ],
            "BillingAddress": [
                1705
            ],
            "Cart": [
                1694
            ],
            "Company": [
                1709
            ],
            "ShipmentAddress": [
                1741
            ],
            "User": [
                1765
            ],
            "PaymentGroup": [
                2043
            ],
            "OrderItem": [
                1995
            ],
            "__typename": [
                9
            ]
        },
        "OrderUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "ordered_at": [
                2287
            ],
            "order_key": [
                2325
            ],
            "outer_order_key": [
                2272
            ],
            "total_price": [
                2288
            ],
            "total_price_net": [
                2288
            ],
            "total_shipment_cost": [
                2288
            ],
            "total_vat_cost": [
                2288
            ],
            "total_discount": [
                2288
            ],
            "total_quantity": [
                2273
            ],
            "order_note": [
                2272
            ],
            "is_approved": [
                2278
            ],
            "approved_by": [
                2273
            ],
            "approved_at": [
                2287
            ],
            "is_sent_erp": [
                2278
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2273
            ],
            "company_name": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2272
            ],
            "payment_status": [
                2326
            ],
            "warehouse": [
                2272
            ],
            "is_sent_status": [
                2327
            ],
            "BillingAddress": [
                2346
            ],
            "Cart": [
                2328
            ],
            "Company": [
                2352
            ],
            "ShipmentAddress": [
                2401
            ],
            "User": [
                2438
            ],
            "PaymentGroup": [
                2852
            ],
            "OrderItem": [
                2785
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "orderItemStatusId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "OrderItemStatus": [
                1769
            ],
            "Product": [
                1775
            ],
            "Shipment": [
                1781
            ],
            "User": [
                1904
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "OrderItemStatus": [
                2444
            ],
            "Product": [
                2453
            ],
            "Shipment": [
                2462
            ],
            "User": [
                2649
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateManyInput": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "orderStatusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateInput": {
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                3262
            ],
            "OrderStatus": [
                1771
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateNestedManyWithoutOrderItemStatusInput": {
            "create": [
                3263
            ],
            "connectOrCreate": [
                3264
            ],
            "createMany": [
                3265
            ],
            "connect": [
                437
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateWithoutOrderItemStatusInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "orderId": [
                3
            ],
            "CartItem": [
                1759
            ],
            "Product": [
                1775
            ],
            "Shipment": [
                1781
            ],
            "User": [
                1904
            ],
            "Order": [
                2041
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateOrConnectWithoutOrderItemStatusInput": {
            "where": [
                437
            ],
            "create": [
                3263
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyOrderItemStatusInputEnvelope": {
            "data": [
                3266
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemCreateManyOrderItemStatusInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "status_name": [
                9
            ],
            "price": [
                46
            ],
            "quantity": [
                46
            ],
            "quantity2": [
                46
            ],
            "discount_rate": [
                46
            ],
            "vat": [
                46
            ],
            "total_price": [
                46
            ],
            "discount_price": [
                46
            ],
            "vat_price": [
                46
            ],
            "total_net_price": [
                46
            ],
            "unit": [
                46
            ],
            "unit_shipment_cost": [
                46
            ],
            "sent_unit": [
                46
            ],
            "waiting_unit": [
                46
            ],
            "features": [
                79
            ],
            "notes1": [
                9
            ],
            "notes2": [
                9
            ],
            "userId": [
                3
            ],
            "productId": [
                3
            ],
            "shipment_id": [
                3
            ],
            "is_deleted": [
                8
            ],
            "shipment_method": [
                116
            ],
            "cart_id": [
                3
            ],
            "orderId": [
                3
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateManyMutationInput": {
            "hierarchy": [
                2447
            ],
            "color": [
                2272
            ],
            "active": [
                2271
            ],
            "code": [
                2272
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateInput": {
            "hierarchy": [
                2447
            ],
            "color": [
                2272
            ],
            "active": [
                2271
            ],
            "code": [
                2272
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                3269
            ],
            "OrderStatus": [
                2448
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithoutOrderItemStatusNestedInput": {
            "create": [
                3263
            ],
            "connectOrCreate": [
                3264
            ],
            "upsert": [
                3270
            ],
            "createMany": [
                3265
            ],
            "set": [
                437
            ],
            "disconnect": [
                437
            ],
            "delete": [
                437
            ],
            "connect": [
                437
            ],
            "update": [
                3272
            ],
            "updateMany": [
                3273
            ],
            "deleteMany": [
                2790
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpsertWithWhereUniqueWithoutOrderItemStatusInput": {
            "where": [
                437
            ],
            "update": [
                3271
            ],
            "create": [
                3263
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithoutOrderItemStatusInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "status_name": [
                2272
            ],
            "price": [
                2288
            ],
            "quantity": [
                2288
            ],
            "quantity2": [
                2288
            ],
            "discount_rate": [
                2288
            ],
            "vat": [
                2288
            ],
            "total_price": [
                2288
            ],
            "discount_price": [
                2288
            ],
            "vat_price": [
                2288
            ],
            "total_net_price": [
                2288
            ],
            "unit": [
                2288
            ],
            "unit_shipment_cost": [
                2288
            ],
            "sent_unit": [
                2288
            ],
            "waiting_unit": [
                2288
            ],
            "features": [
                79
            ],
            "notes1": [
                2272
            ],
            "notes2": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "shipment_method": [
                2428
            ],
            "orderId": [
                2273
            ],
            "CartItem": [
                2429
            ],
            "Product": [
                2453
            ],
            "Shipment": [
                2462
            ],
            "User": [
                2649
            ],
            "Order": [
                2849
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateWithWhereUniqueWithoutOrderItemStatusInput": {
            "where": [
                437
            ],
            "data": [
                3271
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemUpdateManyWithWhereWithoutOrderItemStatusInput": {
            "where": [
                2790
            ],
            "data": [
                2791
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusCreateInput": {
            "active": [
                8
            ],
            "arrangement": [
                3
            ],
            "created_at": [
                10
            ],
            "name": [
                9
            ],
            "slug": [
                9
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "OrderItemStatus": [
                3276
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateNestedManyWithoutOrderStatusInput": {
            "create": [
                3277
            ],
            "connectOrCreate": [
                3278
            ],
            "createMany": [
                3279
            ],
            "connect": [
                566
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateWithoutOrderStatusInput": {
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                3262
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateOrConnectWithoutOrderStatusInput": {
            "where": [
                566
            ],
            "create": [
                3277
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateManyOrderStatusInputEnvelope": {
            "data": [
                3280
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusCreateManyOrderStatusInput": {
            "id": [
                3
            ],
            "hierarchy": [
                3
            ],
            "color": [
                9
            ],
            "active": [
                8
            ],
            "code": [
                9
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "name": [
                9
            ],
            "out_ref": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "arrangement": [
                2447
            ],
            "created_at": [
                2274
            ],
            "name": [
                2272
            ],
            "slug": [
                2272
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "OrderStatusUpdateInput": {
            "active": [
                2271
            ],
            "arrangement": [
                2447
            ],
            "created_at": [
                2274
            ],
            "name": [
                2272
            ],
            "slug": [
                2272
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "OrderItemStatus": [
                3283
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateManyWithoutOrderStatusNestedInput": {
            "create": [
                3277
            ],
            "connectOrCreate": [
                3278
            ],
            "upsert": [
                3284
            ],
            "createMany": [
                3279
            ],
            "set": [
                566
            ],
            "disconnect": [
                566
            ],
            "delete": [
                566
            ],
            "connect": [
                566
            ],
            "update": [
                3286
            ],
            "updateMany": [
                3287
            ],
            "deleteMany": [
                3288
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpsertWithWhereUniqueWithoutOrderStatusInput": {
            "where": [
                566
            ],
            "update": [
                3285
            ],
            "create": [
                3277
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateWithoutOrderStatusInput": {
            "hierarchy": [
                2447
            ],
            "color": [
                2272
            ],
            "active": [
                2271
            ],
            "code": [
                2272
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "name": [
                2272
            ],
            "out_ref": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                3269
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateWithWhereUniqueWithoutOrderStatusInput": {
            "where": [
                566
            ],
            "data": [
                3285
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusUpdateManyWithWhereWithoutOrderStatusInput": {
            "where": [
                3288
            ],
            "data": [
                3267
            ],
            "__typename": [
                9
            ]
        },
        "OrderItemStatusScalarWhereInput": {
            "AND": [
                3288
            ],
            "OR": [
                3288
            ],
            "NOT": [
                3288
            ],
            "id": [
                16
            ],
            "hierarchy": [
                16
            ],
            "color": [
                20
            ],
            "active": [
                18
            ],
            "code": [
                20
            ],
            "created_at": [
                25
            ],
            "created_by": [
                23
            ],
            "name": [
                20
            ],
            "out_ref": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "updated_at": [
                25
            ],
            "updated_by": [
                23
            ],
            "orderStatusId": [
                23
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "OriginCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "domain": [
                9
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "OriginUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "domain": [
                2272
            ],
            "description": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "OriginUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "domain": [
                2272
            ],
            "description": [
                2272
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "user_id": [
                3
            ],
            "method_id": [
                3
            ],
            "pos_account_id": [
                3
            ],
            "company_id": [
                3
            ],
            "auth_code": [
                9
            ],
            "bankId": [
                3
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "PaymentCheck": [
                2002
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "PaymentCheck": [
                2797
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "amount": [
                46
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                3
            ],
            "reference_number": [
                9
            ],
            "check_status": [
                111
            ],
            "payment_status": [
                76
            ],
            "Payment": [
                3298
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateNestedManyWithoutPaymentCheckInput": {
            "create": [
                3299
            ],
            "connectOrCreate": [
                3300
            ],
            "connect": [
                324
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateWithoutPaymentCheckInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "order_key": [
                9
            ],
            "price": [
                46
            ],
            "installment": [
                3
            ],
            "fraud_status": [
                3
            ],
            "payment_id": [
                9
            ],
            "payment_status": [
                76
            ],
            "payment_desc": [
                9
            ],
            "description": [
                9
            ],
            "card_owner": [
                9
            ],
            "card_number": [
                9
            ],
            "card_type": [
                9
            ],
            "card_association": [
                9
            ],
            "sent_at": [
                10
            ],
            "sent_by": [
                9
            ],
            "auth_code": [
                9
            ],
            "bank_message": [
                9
            ],
            "is_sent_erp": [
                8
            ],
            "bank_response": [
                79
            ],
            "ip": [
                9
            ],
            "req_absolute_path": [
                9
            ],
            "gateway_type": [
                81
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                67
            ],
            "payment_key": [
                9
            ],
            "payment_event_id": [
                3
            ],
            "outer_payment_key": [
                9
            ],
            "card_bank": [
                1722
            ],
            "Company": [
                1822
            ],
            "Method": [
                1908
            ],
            "PosAccount": [
                1916
            ],
            "User": [
                1925
            ],
            "PaymentGroup": [
                1991
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCreateOrConnectWithoutPaymentCheckInput": {
            "where": [
                324
            ],
            "create": [
                3299
            ],
            "__typename": [
                9
            ]
        },
        "PaymentCheckUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "amount": [
                2288
            ],
            "bank_response": [
                79
            ],
            "payment_id": [
                2273
            ],
            "reference_number": [
                2272
            ],
            "check_status": [
                2800
            ],
            "payment_status": [
                2371
            ],
            "Payment": [
                3302
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithoutPaymentCheckNestedInput": {
            "create": [
                3299
            ],
            "connectOrCreate": [
                3300
            ],
            "upsert": [
                3303
            ],
            "set": [
                324
            ],
            "disconnect": [
                324
            ],
            "delete": [
                324
            ],
            "connect": [
                324
            ],
            "update": [
                3305
            ],
            "updateMany": [
                3306
            ],
            "deleteMany": [
                2807
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpsertWithWhereUniqueWithoutPaymentCheckInput": {
            "where": [
                324
            ],
            "update": [
                3304
            ],
            "create": [
                3299
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithoutPaymentCheckInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "order_key": [
                2272
            ],
            "price": [
                2288
            ],
            "installment": [
                2273
            ],
            "fraud_status": [
                2273
            ],
            "payment_id": [
                2272
            ],
            "payment_status": [
                2371
            ],
            "payment_desc": [
                2272
            ],
            "description": [
                2272
            ],
            "card_owner": [
                2272
            ],
            "card_number": [
                2272
            ],
            "card_type": [
                2272
            ],
            "card_association": [
                2272
            ],
            "sent_at": [
                2287
            ],
            "sent_by": [
                2272
            ],
            "auth_code": [
                2272
            ],
            "bank_message": [
                2272
            ],
            "is_sent_erp": [
                2278
            ],
            "bank_response": [
                79
            ],
            "ip": [
                2272
            ],
            "req_absolute_path": [
                2272
            ],
            "gateway_type": [
                2372
            ],
            "temp_field": [
                79
            ],
            "is_sent_status": [
                2327
            ],
            "payment_key": [
                2272
            ],
            "payment_event_id": [
                2273
            ],
            "outer_payment_key": [
                2272
            ],
            "card_bank": [
                2373
            ],
            "Company": [
                2525
            ],
            "Method": [
                2655
            ],
            "PosAccount": [
                2667
            ],
            "User": [
                2680
            ],
            "PaymentGroup": [
                2779
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateWithWhereUniqueWithoutPaymentCheckInput": {
            "where": [
                324
            ],
            "data": [
                3304
            ],
            "__typename": [
                9
            ]
        },
        "PaymentUpdateManyWithWhereWithoutPaymentCheckInput": {
            "where": [
                2807
            ],
            "data": [
                2808
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateManyInput": {
            "id": [
                3
            ],
            "order_id": [
                3
            ],
            "paid_price": [
                46
            ],
            "payment_id": [
                3
            ],
            "description": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupCreateInput": {
            "paid_price": [
                46
            ],
            "description": [
                9
            ],
            "Order": [
                1993
            ],
            "Payment": [
                2045
            ],
            "__typename": [
                9
            ]
        },
        "PaymentGroupUpdateInput": {
            "paid_price": [
                2288
            ],
            "description": [
                2272
            ],
            "Order": [
                2782
            ],
            "Payment": [
                2855
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "minimum": [
                46
            ],
            "maximum": [
                46
            ],
            "title": [
                9
            ],
            "is_default": [
                8
            ],
            "CompanyToPaymentMethod": [
                1910
            ],
            "Payment": [
                1720
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "minimum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "title": [
                2272
            ],
            "is_default": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "PaymentMethodUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "minimum": [
                2288
            ],
            "maximum": [
                2288
            ],
            "title": [
                2272
            ],
            "is_default": [
                2278
            ],
            "CompanyToPaymentMethod": [
                2658
            ],
            "Payment": [
                2368
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "PosAccount": [
                3316
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateNestedManyWithoutPaymentSystemInput": {
            "create": [
                3317
            ],
            "connectOrCreate": [
                3318
            ],
            "createMany": [
                3319
            ],
            "connect": [
                348
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateWithoutPaymentSystemInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "InstallmentToPosAccount": [
                1731
            ],
            "Payment": [
                1820
            ],
            "Bank": [
                1921
            ],
            "PosConfig": [
                2017
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateOrConnectWithoutPaymentSystemInput": {
            "where": [
                348
            ],
            "create": [
                3317
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateManyPaymentSystemInputEnvelope": {
            "data": [
                3320
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateManyPaymentSystemInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "__typename": [
                9
            ]
        },
        "PaymentSystemUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "PosAccount": [
                3323
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateManyWithoutPaymentSystemNestedInput": {
            "create": [
                3317
            ],
            "connectOrCreate": [
                3318
            ],
            "upsert": [
                3324
            ],
            "createMany": [
                3319
            ],
            "set": [
                348
            ],
            "disconnect": [
                348
            ],
            "delete": [
                348
            ],
            "connect": [
                348
            ],
            "update": [
                3326
            ],
            "updateMany": [
                3327
            ],
            "deleteMany": [
                3055
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpsertWithWhereUniqueWithoutPaymentSystemInput": {
            "where": [
                348
            ],
            "update": [
                3325
            ],
            "create": [
                3317
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithoutPaymentSystemInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "InstallmentToPosAccount": [
                2386
            ],
            "Payment": [
                2522
            ],
            "Bank": [
                2674
            ],
            "PosConfig": [
                2821
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateWithWhereUniqueWithoutPaymentSystemInput": {
            "where": [
                348
            ],
            "data": [
                3325
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateManyWithWhereWithoutPaymentSystemInput": {
            "where": [
                3055
            ],
            "data": [
                3056
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "PermissionCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "Role": [
                3330
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateNestedManyWithoutPermissionsInput": {
            "create": [
                3331
            ],
            "connectOrCreate": [
                3337
            ],
            "connect": [
                576
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateWithoutPermissionsInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "User": [
                3332
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateNestedManyWithoutRoleInput": {
            "create": [
                3333
            ],
            "connectOrCreate": [
                3334
            ],
            "createMany": [
                3335
            ],
            "connect": [
                572
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateWithoutRoleInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateOrConnectWithoutRoleInput": {
            "where": [
                572
            ],
            "create": [
                3333
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateManyRoleInputEnvelope": {
            "data": [
                3336
            ],
            "skipDuplicates": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateManyRoleInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateOrConnectWithoutPermissionsInput": {
            "where": [
                576
            ],
            "create": [
                3331
            ],
            "__typename": [
                9
            ]
        },
        "PermissionUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "Role": [
                3339
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateManyWithoutPermissionsNestedInput": {
            "create": [
                3331
            ],
            "connectOrCreate": [
                3337
            ],
            "upsert": [
                3340
            ],
            "set": [
                576
            ],
            "disconnect": [
                576
            ],
            "delete": [
                576
            ],
            "connect": [
                576
            ],
            "update": [
                3347
            ],
            "updateMany": [
                3348
            ],
            "deleteMany": [
                3349
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpsertWithWhereUniqueWithoutPermissionsInput": {
            "where": [
                576
            ],
            "update": [
                3341
            ],
            "create": [
                3331
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateWithoutPermissionsInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "type": [
                2718
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "User": [
                3342
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithoutRoleNestedInput": {
            "create": [
                3333
            ],
            "connectOrCreate": [
                3334
            ],
            "upsert": [
                3343
            ],
            "createMany": [
                3335
            ],
            "set": [
                572
            ],
            "disconnect": [
                572
            ],
            "delete": [
                572
            ],
            "connect": [
                572
            ],
            "update": [
                3345
            ],
            "updateMany": [
                3346
            ],
            "deleteMany": [
                2838
            ],
            "__typename": [
                9
            ]
        },
        "UserUpsertWithWhereUniqueWithoutRoleInput": {
            "where": [
                572
            ],
            "update": [
                3344
            ],
            "create": [
                3333
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithoutRoleInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateWithWhereUniqueWithoutRoleInput": {
            "where": [
                572
            ],
            "data": [
                3344
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateManyWithWhereWithoutRoleInput": {
            "where": [
                2838
            ],
            "data": [
                2839
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateWithWhereUniqueWithoutPermissionsInput": {
            "where": [
                576
            ],
            "data": [
                3341
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateManyWithWhereWithoutPermissionsInput": {
            "where": [
                3349
            ],
            "data": [
                3350
            ],
            "__typename": [
                9
            ]
        },
        "RoleScalarWhereInput": {
            "AND": [
                3349
            ],
            "OR": [
                3349
            ],
            "NOT": [
                3349
            ],
            "id": [
                16
            ],
            "active": [
                18
            ],
            "out_ref": [
                20
            ],
            "created_by": [
                23
            ],
            "updated_by": [
                23
            ],
            "created_at": [
                25
            ],
            "updated_at": [
                25
            ],
            "is_updated": [
                18
            ],
            "name": [
                20
            ],
            "code": [
                20
            ],
            "slug": [
                20
            ],
            "source": [
                27
            ],
            "type": [
                210
            ],
            "description": [
                20
            ],
            "is_deleted": [
                33
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "type": [
                2718
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "PluginCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "props": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "PluginCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "start_date": [
                10
            ],
            "end_date": [
                10
            ],
            "image": [
                9
            ],
            "repo": [
                9
            ],
            "firm": [
                9
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                3
            ],
            "description": [
                9
            ],
            "category": [
                9
            ],
            "module": [
                9
            ],
            "props": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "PluginUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "start_date": [
                2287
            ],
            "end_date": [
                2287
            ],
            "image": [
                2272
            ],
            "repo": [
                2272
            ],
            "firm": [
                2272
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                2273
            ],
            "description": [
                2272
            ],
            "category": [
                2272
            ],
            "module": [
                2272
            ],
            "props": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "PluginUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "start_date": [
                2287
            ],
            "end_date": [
                2287
            ],
            "image": [
                2272
            ],
            "repo": [
                2272
            ],
            "firm": [
                2272
            ],
            "credentials": [
                79
            ],
            "hierarchy": [
                2273
            ],
            "description": [
                2272
            ],
            "category": [
                2272
            ],
            "module": [
                2272
            ],
            "props": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "bank_id": [
                3
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "Payment_system_id": [
                3
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "is_default": [
                8
            ],
            "image": [
                9
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                46
            ],
            "minimum": [
                46
            ],
            "InstallmentToPosAccount": [
                1731
            ],
            "Payment": [
                1820
            ],
            "PaymentSystem": [
                1918
            ],
            "Bank": [
                1921
            ],
            "PosConfig": [
                2017
            ],
            "__typename": [
                9
            ]
        },
        "PosAccountUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "is_default": [
                2278
            ],
            "image": [
                2272
            ],
            "pos_props": [
                79
            ],
            "credentials": [
                79
            ],
            "agencies": [
                79
            ],
            "maximum": [
                2288
            ],
            "minimum": [
                2288
            ],
            "InstallmentToPosAccount": [
                2386
            ],
            "Payment": [
                2522
            ],
            "PaymentSystem": [
                2670
            ],
            "Bank": [
                2674
            ],
            "PosConfig": [
                2821
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateManyInput": {
            "id": [
                3
            ],
            "bank_id": [
                3
            ],
            "pos_id": [
                3
            ],
            "is_default": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigCreateInput": {
            "is_default": [
                8
            ],
            "Bank": [
                2019
            ],
            "PosAccount": [
                2010
            ],
            "__typename": [
                9
            ]
        },
        "PosConfigUpdateInput": {
            "is_default": [
                2278
            ],
            "Bank": [
                2824
            ],
            "PosAccount": [
                2812
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "price": [
                46
            ],
            "price_discounted": [
                46
            ],
            "retail": [
                46
            ],
            "project_name": [
                9
            ],
            "project_code": [
                9
            ],
            "discount_rate": [
                46
            ],
            "price_type": [
                9
            ],
            "is_deleted": [
                8
            ],
            "PriceToCategory": [
                1832
            ],
            "Product": [
                2146
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "price": [
                2288
            ],
            "price_discounted": [
                2288
            ],
            "retail": [
                2288
            ],
            "project_name": [
                2272
            ],
            "project_code": [
                2272
            ],
            "discount_rate": [
                2288
            ],
            "price_type": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "PriceUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "price": [
                2288
            ],
            "price_discounted": [
                2288
            ],
            "retail": [
                2288
            ],
            "project_name": [
                2272
            ],
            "project_code": [
                2272
            ],
            "discount_rate": [
                2288
            ],
            "price_type": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "PriceToCategory": [
                2540
            ],
            "Product": [
                2979
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateManyInput": {
            "price_id": [
                3
            ],
            "category_id": [
                3
            ],
            "companyId": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryCreateInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "created_by": [
                3
            ],
            "updated_at": [
                10
            ],
            "updated_by": [
                3
            ],
            "is_deleted": [
                8
            ],
            "Category": [
                1826
            ],
            "Company": [
                1810
            ],
            "Price": [
                2144
            ],
            "__typename": [
                9
            ]
        },
        "PriceToCategoryUpdateInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "created_by": [
                2273
            ],
            "updated_at": [
                2274
            ],
            "updated_by": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "Category": [
                2531
            ],
            "Company": [
                2507
            ],
            "Price": [
                2976
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "brand_id": [
                3
            ],
            "category_id": [
                3
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "type_id": [
                3
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "priceId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                3373
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutProductLotInput": {
            "create": [
                3374
            ],
            "connectOrCreate": [
                3375
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutProductLotInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductStock": [
                1853
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutProductLotInput": {
            "where": [
                456
            ],
            "create": [
                3374
            ],
            "__typename": [
                9
            ]
        },
        "ProductLotUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                3377
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutProductLotNestedInput": {
            "create": [
                3374
            ],
            "connectOrCreate": [
                3375
            ],
            "upsert": [
                3378
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                3380
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutProductLotInput": {
            "update": [
                3379
            ],
            "create": [
                3374
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutProductLotInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductStock": [
                2570
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutProductLotInput": {
            "where": [
                122
            ],
            "data": [
                3379
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "product_id": [
                3
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "quantity": [
                46
            ],
            "is_deleted": [
                8
            ],
            "Product": [
                3383
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateNestedOneWithoutProductStockInput": {
            "create": [
                3384
            ],
            "connectOrCreate": [
                3385
            ],
            "connect": [
                456
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateWithoutProductStockInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "group": [
                9
            ],
            "negative_sale": [
                8
            ],
            "on_sale": [
                8
            ],
            "rating": [
                5
            ],
            "vat": [
                46
            ],
            "sold_count": [
                3
            ],
            "sold_quantity": [
                3
            ],
            "review_count": [
                3
            ],
            "description": [
                9
            ],
            "favorite_count": [
                8
            ],
            "in_stock": [
                8
            ],
            "unit": [
                46
            ],
            "unit_ref": [
                9
            ],
            "unit_code": [
                9
            ],
            "is_shipping_required": [
                8
            ],
            "tag": [
                9
            ],
            "cargo_fee": [
                46
            ],
            "features": [
                1751
            ],
            "platform": [
                12
            ],
            "unit_name": [
                9
            ],
            "title": [
                9
            ],
            "has_features": [
                8
            ],
            "is_deleted": [
                8
            ],
            "out_name": [
                9
            ],
            "Barcode": [
                1752
            ],
            "CartItem": [
                1777
            ],
            "OrderItem": [
                1757
            ],
            "Brand": [
                1802
            ],
            "Category": [
                1794
            ],
            "Price": [
                1830
            ],
            "ProductType": [
                1838
            ],
            "ProductLot": [
                1848
            ],
            "SupportItem": [
                1858
            ],
            "AttributeValues": [
                1878
            ],
            "Campaign": [
                1888
            ],
            "Collection": [
                2080
            ],
            "images": [
                1896
            ],
            "__typename": [
                9
            ]
        },
        "ProductCreateOrConnectWithoutProductStockInput": {
            "where": [
                456
            ],
            "create": [
                3384
            ],
            "__typename": [
                9
            ]
        },
        "ProductStockUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "quantity": [
                2288
            ],
            "is_deleted": [
                2278
            ],
            "Product": [
                3387
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateOneWithoutProductStockNestedInput": {
            "create": [
                3384
            ],
            "connectOrCreate": [
                3385
            ],
            "upsert": [
                3388
            ],
            "disconnect": [
                122
            ],
            "delete": [
                122
            ],
            "connect": [
                456
            ],
            "update": [
                3390
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpsertWithoutProductStockInput": {
            "update": [
                3389
            ],
            "create": [
                3384
            ],
            "where": [
                122
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateWithoutProductStockInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "group": [
                2272
            ],
            "negative_sale": [
                2278
            ],
            "on_sale": [
                2278
            ],
            "rating": [
                2416
            ],
            "vat": [
                2288
            ],
            "sold_count": [
                2273
            ],
            "sold_quantity": [
                2273
            ],
            "review_count": [
                2273
            ],
            "description": [
                2272
            ],
            "favorite_count": [
                2278
            ],
            "in_stock": [
                2278
            ],
            "unit": [
                2288
            ],
            "unit_ref": [
                2272
            ],
            "unit_code": [
                2272
            ],
            "is_shipping_required": [
                2278
            ],
            "tag": [
                2272
            ],
            "cargo_fee": [
                2288
            ],
            "features": [
                2417
            ],
            "platform": [
                2276
            ],
            "unit_name": [
                2272
            ],
            "title": [
                2272
            ],
            "has_features": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "out_name": [
                2272
            ],
            "Barcode": [
                2418
            ],
            "CartItem": [
                2456
            ],
            "OrderItem": [
                2425
            ],
            "Brand": [
                2495
            ],
            "Category": [
                2483
            ],
            "Price": [
                2537
            ],
            "ProductType": [
                2548
            ],
            "ProductLot": [
                2563
            ],
            "SupportItem": [
                2577
            ],
            "AttributeValues": [
                2610
            ],
            "Campaign": [
                2625
            ],
            "Collection": [
                2904
            ],
            "images": [
                2637
            ],
            "__typename": [
                9
            ]
        },
        "ProductUpdateToOneWithWhereWithoutProductStockInput": {
            "where": [
                122
            ],
            "data": [
                3389
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "is_deleted": [
                8
            ],
            "source": [
                11
            ],
            "AttributeToProductType": [
                1840
            ],
            "Product": [
                1886
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "__typename": [
                9
            ]
        },
        "ProductTypeUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "source": [
                2275
            ],
            "AttributeToProductType": [
                2551
            ],
            "Product": [
                2622
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "RoleCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "type": [
                211
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "User": [
                3332
            ],
            "Permissions": [
                1951
            ],
            "__typename": [
                9
            ]
        },
        "RoleUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "type": [
                2718
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "User": [
                3342
            ],
            "Permissions": [
                2719
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "cargo_agency_id": [
                3
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_status_id": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "tracking_code": [
                9
            ],
            "tracking_url": [
                9
            ],
            "date_of_shipment": [
                10
            ],
            "date_of_delivery": [
                10
            ],
            "other_info": [
                9
            ],
            "cargo_order": [
                3
            ],
            "shipment_cost": [
                46
            ],
            "shipment_cost_status": [
                154
            ],
            "shipment_method": [
                116
            ],
            "quantity": [
                46
            ],
            "referance_code": [
                9
            ],
            "is_deleted": [
                8
            ],
            "OrderItem": [
                1902
            ],
            "CargoAgency": [
                1783
            ],
            "ShipmentStatus": [
                1786
            ],
            "ShipmentEvent": [
                2055
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "tracking_code": [
                2272
            ],
            "tracking_url": [
                2272
            ],
            "date_of_shipment": [
                2287
            ],
            "date_of_delivery": [
                2287
            ],
            "other_info": [
                2272
            ],
            "cargo_order": [
                2273
            ],
            "shipment_cost": [
                2288
            ],
            "shipment_cost_status": [
                2465
            ],
            "shipment_method": [
                2428
            ],
            "quantity": [
                2288
            ],
            "referance_code": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "OrderItem": [
                2646
            ],
            "CargoAgency": [
                2466
            ],
            "ShipmentStatus": [
                2470
            ],
            "ShipmentEvent": [
                2867
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "shipmentId": [
                3
            ],
            "statusId": [
                3
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "eventDateTime": [
                10
            ],
            "deliveryDateTime": [
                10
            ],
            "deliveryTo": [
                9
            ],
            "description": [
                9
            ],
            "pieceTotal": [
                3
            ],
            "location": [
                9
            ],
            "locationPhone": [
                9
            ],
            "private": [
                8
            ],
            "isRead": [
                8
            ],
            "locationAddress": [
                9
            ],
            "trackingCode": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Shipment": [
                2068
            ],
            "ShipmentStatus": [
                2057
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentEventUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "eventDateTime": [
                2287
            ],
            "deliveryDateTime": [
                2287
            ],
            "deliveryTo": [
                2272
            ],
            "description": [
                2272
            ],
            "pieceTotal": [
                2273
            ],
            "location": [
                2272
            ],
            "locationPhone": [
                2272
            ],
            "private": [
                2278
            ],
            "isRead": [
                2278
            ],
            "locationAddress": [
                2272
            ],
            "trackingCode": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Shipment": [
                2885
            ],
            "ShipmentStatus": [
                2870
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "description": [
                9
            ],
            "color": [
                9
            ],
            "is_deleted": [
                8
            ],
            "image": [
                1788
            ],
            "Shipment": [
                1900
            ],
            "ShipmentEvent": [
                2066
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "description": [
                2272
            ],
            "color": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "ShipmentStatusUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "description": [
                2272
            ],
            "color": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "image": [
                2473
            ],
            "Shipment": [
                2643
            ],
            "ShipmentEvent": [
                2882
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "status": [
                185
            ],
            "type": [
                188
            ],
            "priority": [
                191
            ],
            "companyId": [
                3
            ],
            "assignedToId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1872
            ],
            "SupportItem": [
                1864
            ],
            "SupportMessage": [
                1942
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "status": [
                2586
            ],
            "type": [
                2587
            ],
            "priority": [
                2588
            ],
            "companyId": [
                2273
            ],
            "assignedToId": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "__typename": [
                9
            ]
        },
        "SupportUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "status": [
                2586
            ],
            "type": [
                2587
            ],
            "priority": [
                2588
            ],
            "companyId": [
                2273
            ],
            "assignedToId": [
                2273
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2601
            ],
            "SupportItem": [
                2589
            ],
            "SupportMessage": [
                2705
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "supportItemId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "path": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Support": [
                1862
            ],
            "SupportItem": [
                1874
            ],
            "User": [
                2104
            ],
            "__typename": [
                9
            ]
        },
        "SupportAttachmentUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "path": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Support": [
                2583
            ],
            "SupportItem": [
                2604
            ],
            "User": [
                2938
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "supportItemId": [
                3
            ],
            "statusId": [
                3
            ],
            "messageId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_read": [
                8
            ],
            "private": [
                8
            ],
            "is_deleted": [
                8
            ],
            "Message": [
                1868
            ],
            "Status": [
                1936
            ],
            "Item": [
                1973
            ],
            "__typename": [
                9
            ]
        },
        "SupportEventUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_read": [
                2278
            ],
            "private": [
                2278
            ],
            "is_deleted": [
                2278
            ],
            "Message": [
                2595
            ],
            "Status": [
                2696
            ],
            "Item": [
                2759
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "productId": [
                3
            ],
            "supportId": [
                3
            ],
            "statusId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportAttachment": [
                1860
            ],
            "SupportEvent": [
                1866
            ],
            "Product": [
                1876
            ],
            "SupportItemStatus": [
                1975
            ],
            "Support": [
                1940
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportAttachment": [
                2580
            ],
            "SupportEvent": [
                2592
            ],
            "Product": [
                2607
            ],
            "SupportItemStatus": [
                2762
            ],
            "Support": [
                2702
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "source": [
                11
            ],
            "platform": [
                12
            ],
            "description": [
                9
            ],
            "is_deleted": [
                8
            ],
            "status": [
                185
            ],
            "SupportEvent": [
                1977
            ],
            "SupportItem": [
                1938
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2586
            ],
            "__typename": [
                9
            ]
        },
        "SupportItemStatusUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "source": [
                2275
            ],
            "platform": [
                2276
            ],
            "description": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "status": [
                2586
            ],
            "SupportEvent": [
                2765
            ],
            "SupportItem": [
                2699
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "supportId": [
                3
            ],
            "userId": [
                3
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "message": [
                9
            ],
            "is_deleted": [
                8
            ],
            "SupportEvent": [
                1934
            ],
            "Support": [
                1870
            ],
            "User": [
                1944
            ],
            "__typename": [
                9
            ]
        },
        "SupportMessageUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "message": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "SupportEvent": [
                2693
            ],
            "Support": [
                2598
            ],
            "User": [
                2708
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "data": [
                79
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeCreateInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "name": [
                9
            ],
            "erp": [
                9
            ],
            "type": [
                1597
            ],
            "started": [
                10
            ],
            "updated": [
                10
            ],
            "sync_by": [
                9
            ],
            "hierarchy": [
                3
            ],
            "state": [
                9
            ],
            "data": [
                79
            ],
            "total_records": [
                3
            ],
            "done_records": [
                3
            ],
            "error_records": [
                3
            ],
            "cron": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2325
            ],
            "erp": [
                2272
            ],
            "type": [
                3431
            ],
            "started": [
                2287
            ],
            "updated": [
                2287
            ],
            "sync_by": [
                2272
            ],
            "hierarchy": [
                2273
            ],
            "state": [
                2272
            ],
            "data": [
                79
            ],
            "total_records": [
                2273
            ],
            "done_records": [
                2273
            ],
            "error_records": [
                2273
            ],
            "cron": [
                3432
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumSyncTypeFieldUpdateOperationsInput": {
            "set": [
                1597
            ],
            "__typename": [
                9
            ]
        },
        "NullableEnumCronExpressionFieldUpdateOperationsInput": {
            "set": [
                1598
            ],
            "__typename": [
                9
            ]
        },
        "SyncronizeUpdateInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "name": [
                2325
            ],
            "erp": [
                2272
            ],
            "type": [
                3431
            ],
            "started": [
                2287
            ],
            "updated": [
                2287
            ],
            "sync_by": [
                2272
            ],
            "hierarchy": [
                2273
            ],
            "state": [
                2272
            ],
            "data": [
                79
            ],
            "total_records": [
                2273
            ],
            "done_records": [
                2273
            ],
            "error_records": [
                2273
            ],
            "cron": [
                3432
            ],
            "__typename": [
                9
            ]
        },
        "TenantCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "config": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "TenantCreateInput": {
            "active": [
                8
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "company_id": [
                3
            ],
            "company_name": [
                9
            ],
            "company_code": [
                9
            ],
            "config": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "TenantUpdateManyMutationInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "company_id": [
                2273
            ],
            "company_name": [
                2272
            ],
            "company_code": [
                2272
            ],
            "config": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "TenantUpdateInput": {
            "active": [
                2271
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "company_id": [
                2273
            ],
            "company_name": [
                2272
            ],
            "company_code": [
                2272
            ],
            "config": [
                79
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateManyInput": {
            "id": [
                3
            ],
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "default_address_id": [
                3
            ],
            "role_id": [
                3
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "__typename": [
                9
            ]
        },
        "UserCreateInput": {
            "active": [
                8
            ],
            "out_ref": [
                9
            ],
            "created_by": [
                3
            ],
            "updated_by": [
                3
            ],
            "created_at": [
                10
            ],
            "updated_at": [
                10
            ],
            "is_updated": [
                8
            ],
            "name": [
                9
            ],
            "code": [
                9
            ],
            "slug": [
                9
            ],
            "source": [
                11
            ],
            "provider_id": [
                9
            ],
            "first_name": [
                9
            ],
            "last_name": [
                9
            ],
            "gender": [
                52
            ],
            "email": [
                9
            ],
            "gsm": [
                9
            ],
            "password": [
                9
            ],
            "is_deleted": [
                8
            ],
            "Cart": [
                1745
            ],
            "ConfirmCode": [
                1698
            ],
            "Order": [
                1703
            ],
            "OrderItem": [
                1767
            ],
            "Payment": [
                1906
            ],
            "SupportAttachment": [
                1927
            ],
            "SupportMessage": [
                1932
            ],
            "Address": [
                1946
            ],
            "Role": [
                1949
            ],
            "Company": [
                1955
            ],
            "__typename": [
                9
            ]
        },
        "UserUpdateInput": {
            "active": [
                2271
            ],
            "out_ref": [
                2272
            ],
            "created_by": [
                2273
            ],
            "updated_by": [
                2273
            ],
            "created_at": [
                2274
            ],
            "updated_at": [
                2274
            ],
            "is_updated": [
                2271
            ],
            "name": [
                2272
            ],
            "code": [
                2272
            ],
            "slug": [
                2272
            ],
            "source": [
                2275
            ],
            "provider_id": [
                2272
            ],
            "first_name": [
                2272
            ],
            "last_name": [
                2272
            ],
            "gender": [
                2334
            ],
            "email": [
                2272
            ],
            "gsm": [
                2272
            ],
            "password": [
                2272
            ],
            "is_deleted": [
                2278
            ],
            "Cart": [
                2407
            ],
            "ConfirmCode": [
                2335
            ],
            "Order": [
                2343
            ],
            "OrderItem": [
                2441
            ],
            "Payment": [
                2652
            ],
            "SupportAttachment": [
                2683
            ],
            "SupportMessage": [
                2690
            ],
            "Address": [
                2711
            ],
            "Role": [
                2715
            ],
            "Company": [
                2727
            ],
            "__typename": [
                9
            ]
        },
        "LoginResponse": {
            "access_token": [
                9
            ],
            "user": [
                261
            ],
            "__typename": [
                9
            ]
        },
        "AddToCartResponse": {
            "message": [
                9
            ],
            "status": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AddToCartArgs": {
            "id": [
                5
            ],
            "quantity": [
                5
            ],
            "type": [
                9
            ],
            "source": [
                11
            ],
            "features": [
                79
            ],
            "note1": [
                9
            ],
            "note2": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "UpdateCart": {
            "message": [
                9
            ],
            "status": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "UpdateCartArgs": {
            "id": [
                5
            ],
            "billingAddressId": [
                5
            ],
            "shippingAddressId": [
                5
            ],
            "note": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "RemoveToCartResponse": {
            "message": [
                9
            ],
            "status": [
                9
            ],
            "__typename": [
                9
            ]
        },
        "AddressInput": {
            "address": [
                9
            ],
            "addressType": [
                9
            ],
            "city": [
                9
            ],
            "email": [
                9
            ],
            "name": [
                9
            ],
            "phoneNumber": [
                9
            ],
            "primary": [
                8
            ],
            "state": [
                9
            ],
            "title": [
                9
            ],
            "__typename": [
                9
            ]
        }
    }
}