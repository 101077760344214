/* eslint-disable @typescript-eslint/no-shadow */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import { CartItem, generateMutationOp, generateQueryOp } from '../../../graphql/generated';
import { apolloClient } from '../../../graphql/provider';

export const getCartThunk = createAsyncThunk('checkout/getCart', async () => {
  const { query, variables } = generateQueryOp({
    getCart: {
      CartItems: {
        __scalar: true,
        Product: {
          __scalar: true,
          images: {
            __args: {
             take: 1,
            },
            url: true,
          }
        },
      },
      cart: {
        __scalar: true,
      },
    },
  });

  const { data } = await apolloClient.query({ query: gql(query), variables });
  return data?.getCart;
});

export const addToCartThunk = createAsyncThunk(
  'checkout/addToCart',
  async (item: any, { dispatch }) => {
    const { query, variables } = generateMutationOp({
      addToCart: {
        __args: {
          data: {
            source: 'web',
            type: 'add',
            ...item,
          },
        },
        message: true,
        status: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
    await dispatch(getCartThunk());
    return data.addToCart;
  }
);

export const removeFromCartThunk = createAsyncThunk(
  'checkout/removeFromCart',
  async ({ itemId }: { itemId: number }, { dispatch }) => {
    const { query, variables } = generateMutationOp({
      removeItemFromCart: {
        __args: {
          id: Number(itemId),
        },
        message: true,
        status: true,
      },
    });

    const { data } = await apolloClient.mutate({
      mutation: gql(query),
      variables,
    });
    await dispatch(getCartThunk());
    return data.removeItemFromCart;
  }
);
export const increaseQuantityThunk = createAsyncThunk(
  'checkout/increaseQuantity',
  async ({ item }: { item: CartItem }, { dispatch }) => {
    const { query, variables } = generateMutationOp({
      updateCartItem: {
        __args: {
          id: Number(item?.id),
          data: {
            quantity: { increment: '1' },
          },
        },
        id: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
    await dispatch(getCartThunk());
    return { ...data.addToCart, id: item?.id };
  }
);

export const decreaseQuantityThunk = createAsyncThunk(
  'checkout/decreaseQuantity',
  async ({ item }: { item: CartItem }, { dispatch }) => {
    const { query, variables } = generateMutationOp({
      updateCartItem: {
        __args: {
          id: Number(item?.id),
          data: {
            quantity: { decrement: '1' },
          },
        },
        id: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });

    await dispatch(getCartThunk());
    return {
      ...data.addToCart,
      id: item?.id,
    };
  }
);

export const updateCartItemFeaturesThunk = createAsyncThunk(
  'checkout/updateCartItemFeatures',
  async ({ item, features }: { item: CartItem; features: Record<string, any> }, { dispatch }) => {
    const { query, variables } = generateMutationOp({
      updateCartItem: {
        __args: {
          id: Number(item?.id),
          data: {
            features,
          },
        },
        id: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });

    await dispatch(getCartThunk());
    return data.updateCartItemFeatures;
  }
);

export const updateCartThunk = createAsyncThunk(
  'checkout/updateCart',
  async ({
    id,
    billingAddressId,
    shippingAddressId,
    note,
  }: {
    id: number;
    billingAddressId: number;
    shippingAddressId: number;
    note: string;
  }) => {
    const { query, variables } = generateMutationOp({
      updateCart: {
        __args: {
          data: {
            id: Number(id),
            billingAddressId: Number(billingAddressId),
            shippingAddressId: Number(shippingAddressId),
            note: String(note),
          },
        },
        message: true,
        status: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
    return data.updateCart;
  }
);

export const createShipmentAddressThunk = createAsyncThunk(
  'checkout/createShipmentAddress',
  async ({ shipment }: { shipment: any }) => {
    const { query, variables } = generateMutationOp({
      createAddress: {
        __args: {
          address: {
            ...shipment,
            addressType: 'shipment',
          },
        },
        __scalar: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
    return data;
  }
);

export const setDefaultAddressThunk = createAsyncThunk(
  'checkout/setDefaultAddress',
  async ({ address, user }: { address: any; user: any }) => {
    if (address.type !== 'company') {
      throw new Error('Address type must be company');
    }
    const { query, variables } = generateMutationOp({
      updateOneUser: {
        __args: {
          data: {
            Address: {
              connect: {
                id: address.id,
              },
            },
          },
          where: {
            id: user.id,
          },
        },
        id: true,
      },
    });

    const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
    return data.setDefaultAddress;
  }
);

const fetchOrderData = async (addressId: any) => {
  const { query, variables } = generateQueryOp({
    findFirstOrder: {
      __args: {
        where: {
          shipment_address_id: { equals: addressId },
        },
      },
      id: true,
    },
  });

  const { data: orderData } = await apolloClient.query({ query: gql(query), variables });
  return orderData?.findFirstOrder || null;
};

const fetchUserData = async (userId: any, addressId: any) => {
  const { query, variables } = generateQueryOp({
    findFirstUser: {
      __args: {
        where: {
          id: { equals: userId },
          default_address_id: { equals: addressId },
        },
      },
      id: true,
      default_address_id: true,
    },
  });

  const { data: userData } = await apolloClient.query({ query: gql(query), variables });
  return userData?.findFirstUser || null;
};

const disconnectAddressFromUser = async (userId: any, addressId: any) => {
  const { query, variables } = generateMutationOp({
    updateOneUser: {
      __args: {
        data: {
          Address: {
            disconnect: {
              id: { equals: addressId },
            },
          },
        },
        where: { id: userId },
      },
      id: true,
    },
  });

  const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
  return data?.updateOneUser || null;
};

const deleteAddress = async (addressId: any) => {
  const { query, variables } = generateMutationOp({
    deleteOneAddress: {
      __args: {
        where: { id: addressId },
      },
      id: true,
    },
  });

  const { data } = await apolloClient.mutate({ mutation: gql(query), variables });
  return data?.deleteOneAddress || null;
};

export const getOrderThunk = createAsyncThunk(
  'checkout/getOrderId',
  async ({ addressId, userId }: any) => {
    const orderData = await fetchOrderData(addressId);
    if (!orderData) {
      const userData = await fetchUserData(userId, addressId);
      if (userData?.default_address_id) {
        await disconnectAddressFromUser(userId, addressId);
      }
      const deletedAddress = await deleteAddress(addressId);
      return deletedAddress;
    }
    return orderData;
  }
);
