import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
//
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadButton from './download-button';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

// ----------------------------------------------------------------------

type FileIconProps = {
  file: File | string | any;
  tooltip?: boolean;
  imageView?: boolean;
  onDownload?: VoidFunction;
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
};

export default function FileThumbnail({
  file,
  tooltip,
  imageView,
  onDownload,
  sx,
  imgSx,
}: FileIconProps) {
  const { name = '', path = '', preview = '' } = fileData(file);

  const format = fileFormat(path || preview);

  const renderContent =
    format === 'image' && imageView ? (
      <>
        <PhotoProvider>
          <PhotoView src={preview}>
            <Box
              component="img"
              src={preview}
              sx={{
                width: 1,
                height: 1,
                flexShrink: 0,
                objectFit: 'cover',
                ...imgSx,
                cursor: 'zoom-in',
              }}
            />
          </PhotoView>
        </PhotoProvider>
      </>
    ) : typeof file !== 'string' && file.url ? (
      <>
        <PhotoProvider>
          <PhotoView src={file.url}>
            <Box
              component="img"
              src={file.url}
              sx={{
                width: 1,
                height: 1,
                flexShrink: 0,
                ...sx,
                cursor: 'zoom-in',
              }}
            />
          </PhotoView>
        </PhotoProvider>
      </>
    ) : (
      <>
        <PhotoProvider>
          <PhotoView src={fileThumb(format)}>
            <Box
              component="img"
              src={fileThumb(format)}
              sx={{
                width: 0.3,
                height: 0.3,
                flexShrink: 0,
                ...sx,
                cursor: 'zoom-in',
              }}
            />
          </PhotoView>
        </PhotoProvider>
      </>
    );

  if (tooltip) {
    return (
      <Tooltip title={name}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
}
