import axios, { AxiosError, AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { paths } from '../routes/paths';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    if (error.response?.status === 401 && window.location.pathname != paths.auth.jwt.login) {
      localStorage.clear();
      window.location.href = paths.auth.jwt.login;
    }
    return Promise.reject(
      (error.response && error.response.data) ||
        'Hatalı işlem yaptınız, lütfen destek ile iletişime geçiniz.'
    );
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/auth/me',
    login: '/auth/login',
    register: '/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  product: {
    list: '/product/search_products',
    details: `/product/sku/detail/`,
    detail: '/product/sku/list',
    search: '/search',
  },
  order: {
    list: '/order/list',
    detail: '/order',
    search: '/order/list',
  },
};
