import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  addToCartThunk,
  createShipmentAddressThunk,
  getCartThunk,
  updateCartItemFeaturesThunk,
  getOrderThunk,
} from './checkout.fetch';

type CheckoutState = {
  activeStep: number;
  items: any[];
  subTotal: number;
  total: number;
  vat: number;
  order_key: string;
  shipping: any;
  billing: any;
  totalItems: number;
  cartId: number | null;
  completed: boolean;
  order: any;
  note: any;
};
const initialState: CheckoutState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  vat: 0,
  order_key: '',
  shipping: null,
  billing: null,
  totalItems: 0,
  cartId: null,
  completed: false,
  order: null,
  note: '',
};

const chekoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    onBackStep: (state) => {
      state.activeStep -= 1;
    },
    onNextStep: (state) => {
      state.activeStep += 1;
    },
    onGotoStep: (state, action) => {
      state.activeStep = action.payload.step;
    },
    onApplyBilling: (state, action) => {
      state.billing = action.payload.billing;
    },
    onApplyShipping: (state, action) => {
      state.shipping = action.payload.shipping;
    },
    onReset: (state) => {
      Object.assign(state, initialState);
    },
    clearCart: (state) => initialState,
    setNote: (state, action) => {
      state.note = action.payload.note;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCartThunk.fulfilled, (state, action) => {
      state.items = action.payload.CartItems || [];
      state.totalItems = (action.payload.CartItems || []).reduce(
        (acc: number, item: any) => acc + Number(item.quantity),
        0
      );
      state.subTotal = action.payload.cart?.total_price;
      state.vat = action.payload.cart?.total_vat_cost;
      state.total = action.payload.cart?.total_price_net;
      state.cartId = action.payload.cart?.id;
    });
    builder.addCase(getCartThunk.rejected, (state, action) => initialState);
    builder.addCase(addToCartThunk.fulfilled, (state, action) => {
      enqueueSnackbar('Ürün sepete eklendi', { variant: 'success'});
    });
    builder.addCase(addToCartThunk.rejected, (state, action) => {
      enqueueSnackbar(action.error.message, { variant: 'error' });
    });
    builder.addCase(updateCartItemFeaturesThunk.fulfilled, (state, action) => {
      enqueueSnackbar('Ürün güncellendi', { variant: 'success' });
    });
    builder.addCase(createShipmentAddressThunk.fulfilled, (state, action) => {
      state.shipping = action.payload.createAddress;
    });
    builder.addCase(getOrderThunk.fulfilled, (state, action) => {
      const { __typename, id } = action.payload;

      if (__typename === 'Order' && id) {
        enqueueSnackbar('Dikkat: Bu adresin sipariş bağlantısı bulunmaktadır.', { variant: 'error' });
      } else if (__typename === 'Address' && id) {
        enqueueSnackbar('Adres silindi', { variant: 'success' });
      }
    });
    builder.addCase(getOrderThunk.rejected, (state, action) => {
      enqueueSnackbar(`Adres silinemedi: ${action.error.message}`, { variant: 'error' });
    });
  },
});

export const { actions } = chekoutSlice;
export default chekoutSlice.reducer;
