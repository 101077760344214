import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { showOnlyForRoles } from 'src/utils/permissions';
// auth
import { useAuthContext } from 'src/auth/hooks';
import path from 'path';

const icon = (name: string) => (
  <Iconify icon={name} />
);

const ICONS = {
  home: icon('solar:home-smile-angle-bold'),
  dashboard: icon('solar:widget-6-bold'),
  user: icon('solar:user-id-bold'),
  order: icon('solar:sale-bold'),
  product: icon('solar:chair-bold'),
  import: icon('solar:import-bold'),
  company: icon('solar:buildings-2-bold'),
  payment: icon('solar:card-bold'),
  chart: icon('solar:chart-2-bold'),
  document: icon('solar:document-bold'),
  statement: icon('solar:document-bold'),
  categories: icon("solar:copy-bold"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();

  const data = useMemo(() => {
    const navData = [
      {
       
        items: [
          {
            title: 'Özet',
            icon: ICONS.chart,
            path: paths.account.root,
          },
          {
            title: 'Satış Yönetimi',
            path: paths.account.order.list,
            icon: ICONS.order,
            children:
          [
            {
              title: 'Siparişler',
              path: paths.account.order.list,
            },
            {
              title: 'Bekleyen Siparişler',
              path: paths.account.order.pending,
            },
            {
              title: 'Bugün Sevk Edilen Siparişler',
             path: paths.account.order.todayshipped,
            },
          ],
        },
          
          {
            title: 'Ödemeler',
            icon: ICONS.payment,
            path: paths.account.payment.list,
          },
        ],
      },
    ];

    if (user && showOnlyForRoles(<></>, 'price:read')) {
      navData[0].items.push({
        title: 'Firma Raporları',
        icon: ICONS.statement,
        path: paths.account.statement.list,
        children: [
          {
            title: 'Hesap Ekstresi',
            path: paths.account.statement.list,
          },
        ],
      });
    }
    return navData;
  }, [user]);

  return data;
}
