import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import RoleBasedGuard from '../../auth/guard/role-based-guard';
import UserUpload from 'src/sections/user/view/user-upload-view';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(
  () => import('src/pages/dashboard/product_operations/product/details')
);
const ProductListPage = lazy(() => import('src/pages/dashboard/product_operations/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product_operations/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product_operations/product/edit'));
// BRAND
const BrandListPage = lazy(() => import('src/pages/dashboard/product_operations/brand/list'));
// CATEGORY
const CategoryListPage = lazy(() => import('src/pages/dashboard/product_operations/category/list'));
// SLIDER
const SliderListPage = lazy(() => import('src/pages/dashboard/product_operations/slider/list'));
const SliderCreatePage = lazy(() => import('src/pages/dashboard/product_operations/slider/new'));
const SliderEditPage = lazy(() => import('src/pages/dashboard/product_operations/slider/edit'));
// COLLECTION
const CollectionListPage = lazy(
  () => import('src/pages/dashboard/product_operations/collection/list')
);
const CollectionCreatePage = lazy(
  () => import('src/pages/dashboard/product_operations/collection/new')
);
const CollectionEditPage = lazy(
  () => import('src/pages/dashboard/product_operations/collection/edit')
);
/// BUFIPAY
const SettingsListPage = lazy(() => import('src/pages/dashboard/bufi-pay/settings/list'));
const BankListPage = lazy(() => import('src/pages/dashboard/bufi-pay/bank/list'));
const PosListPage = lazy(() => import('src/pages/dashboard/bufi-pay/pos/list'));
const PosDetailsPage = lazy(() => import('src/pages/dashboard/bufi-pay/pos/details'));
const PosCreatePage = lazy(() => import('src/pages/dashboard/bufi-pay/pos/new'));
const PosEditPage = lazy(() => import('src/pages/dashboard/bufi-pay/pos/edit'));
const PosEditInstallmentPage = lazy(
  () => import('src/pages/dashboard/bufi-pay/pos/edit-installment')
);
const PaymentListPage = lazy(() => import('src/pages/dashboard/bufi-pay/payment/list'));
const PaymentDetailsPage = lazy(() => import('src/pages/dashboard/bufi-pay/payment/details'));
const PaymentCreatePage = lazy(() => import('src/pages/dashboard/bufi-pay/payment/new'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
const OrderEditPage = lazy(() => import('src/pages/dashboard/order/edit'));
// COMPANY
const AccountListPage = lazy(() => import('src/pages/dashboard/company_transactions/account/list'));
const AccountDetailsPage = lazy(
  () => import('src/pages/dashboard/company_transactions/account/details')
);
const AddressListPage = lazy(() => import('src/pages/dashboard/company_transactions/address/list'));

// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerListPage = lazy(() => import('src/pages/dashboard/file-manager/list'));
const FileManagerDetailsPage = lazy(() => import('src/pages/dashboard/file-manager/details'));
const FileManagerSubFolderDetailsPage = lazy(
  () => import('src/pages/dashboard/file-manager/sub-folder-details')
);

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const Import = lazy(() => import('src/pages/import'));
//COMING-SOON PAGE
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/panel',
    element: (
      <AuthGuard>
        <RoleBasedGuard roles={['admin']} hasContent={true}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RoleBasedGuard>
      </AuthGuard>
    ),
    children: [
      { element: <OverviewBankingPage />, index: true },
      // { element: <UserProfilePage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'import', element: <Import /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'summary', element: <UserListPage /> },
          { path: 'role', element: <UserListPage /> },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: 'upload', element: <UserUpload />},
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'company',
        children: [
          { path: 'summary', element: <AccountListPage /> },
          { path: 'list', element: <AccountListPage /> },
          { path: 'account', element: <AccountListPage /> },
          { path: ':id', element: <AccountDetailsPage /> },
          { path: 'address', element: <AddressListPage /> },
        ],
      },
      {
        path: 'bufipay',
        children: [
          { path: 'summary', element: <InvoiceListPage /> },
          { path: 'cancel', element: <ComingSoonPage /> },
          { path: 'refund', element: <ComingSoonPage /> },
          { path: 'link', element: <ComingSoonPage /> },
          { path: 'report', element: <ComingSoonPage /> },
          { path: 'settings', element: <SettingsListPage /> },
          { path: 'bank', element: <BankListPage /> },
          { path: 'pos', element: <PosListPage /> },
          { path: 'api', element: <ComingSoonPage /> },
          // { path: 'list', element: <PosListPage /> },
          { path: ':id', element: <PosDetailsPage /> },
          { path: 'new', element: <PosCreatePage /> },
          { path: ':id/edit', element: <PosEditPage /> },
          { path: ':id/installment', element: <PosEditInstallmentPage /> },
          {
            path: 'payment',
            children: [
              { element: <PaymentListPage />, index: true },
              { path: ':id', element: <PaymentDetailsPage /> },
              { path: 'new', element: <PaymentCreatePage /> },
            ],
          },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'summary', element: <InvoiceListPage /> },
          { path: 'brand', element: <BrandListPage /> },
          { path: 'category', element: <CategoryListPage /> },
          {
            path: 'slider',
            children: [
              { element: <SliderListPage />, index: true },
              { path: ':id/edit', element: <SliderEditPage /> },
              { path: 'new', element: <SliderCreatePage /> },
            ],
          },
          {
            path: 'collection',
            children: [
              { element: <CollectionListPage />, index: true },
              { path: ':id/edit', element: <CollectionEditPage /> },
              { path: 'new', element: <CollectionCreatePage /> },
            ],
          },
          { path: 'type', element: <InvoiceListPage /> },
          { path: 'feature', element: <InvoiceListPage /> },
          { path: 'price', element: <InvoiceListPage /> },
          { path: 'stock', element: <InvoiceListPage /> },
          { path: 'campaign', element: <InvoiceListPage /> },
          { path: 'collection', element: <InvoiceListPage /> },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'summary', element: <OrderListPage /> },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
          { path: ':id/edit', element: <OrderEditPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      {
        path: 'file-manager',
        children: [
          { element: <FileManagerListPage />, index: true },
          { path: 'list', element: <FileManagerListPage /> },
          { path: ':id', element: <FileManagerDetailsPage /> },
          {
            path: ':id/sub-folder/:folder/:subfolder',
            element: <FileManagerSubFolderDetailsPage />,
          },
        ],
      },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
      { path: 'coming-soon', element: <ComingSoonPage /> },
    ],
  },
];
