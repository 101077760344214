import { createSlice } from '@reduxjs/toolkit';

type OptionsState = any;
const initialState: OptionsState = {
  open: false,
};
const optionsSlice = createSlice({
  name: 'options',
  initialState: initialState,
  reducers: {
    setOptions: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetOptions: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export default optionsSlice.reducer;
export const actions = optionsSlice.actions;
