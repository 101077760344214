
// @mui
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import Stack from '@mui/material/Stack';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
// components
//
import { HEADER, NAV } from './nav/config-layout';
//
import { AccountDrawer } from '../_common';

import { useCheckout } from '../../store/slices/checkout/checkout.hooks';
import { useSettingsContext } from '../../components/settings';
import SvgColor from 'src/components/svg-color/svg-color';

// ----------------------------------------------------------------------
type Props = {
  onOpenNav?: VoidFunction;
};
export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const { totalItems } = useCheckout();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const renderContent = (
    <>
      

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}


      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 2, sm: 2 }}
      >
        {/*<NotificationsPopover />*/}

        {/*<ContactsPopover />*/}
        <IconButton
            component={Link}
            to="/cart"
            sx={{
              color: 'text.primary',
              '&:hover': { bgcolor: 'transparent' },
              mr: 1.5,
            }}
          >
            <Badge badgeContent={totalItems} color="primary">
              <Iconify icon="ion:cart-outline" width={24} />
            </Badge>
          </IconButton>

        <AccountDrawer/>
      </Stack>
    </>
  );

  return (
    <AppBar
    sx={{
      height: HEADER.H_MOBILE,
      zIndex: theme.zIndex.appBar + 1,
      ...bgBlur({
        color: theme.palette.background.default,
      }),
      transition: theme.transitions.create(['height'], {
        duration: theme.transitions.duration.shorter,
      }),
      ...(lgUp && {
        width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
        height: HEADER.H_DESKTOP,
        ...(offsetTop && {
          height: HEADER.H_DESKTOP_OFFSET,
        }),
        ...(isNavHorizontal && {
          width: 1,
          bgcolor: 'background.default',
          height: HEADER.H_DESKTOP_OFFSET,
          borderBottom: `dashed 1px ${theme.palette.divider}`,
        }),
        ...(isNavMini && {
          width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          height: HEADER.H_MOBILE,
        }),
      }),
    }}
  >
     <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
