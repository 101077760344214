/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthContext } from '../auth/hooks';

const ROLES = {
  companyuser: 'companyuser',
  companyadmin: 'companyadmin',
  admin: 'admin',
  seller: 'seller',
  panel: 'panel',
};

const PERMISSIONS = {
  'price:read': [ROLES.companyadmin, ROLES.admin, ROLES.seller, ROLES.panel],
  'order:exportAll': [ROLES.admin, ROLES.panel],
};

type PermissionType = keyof typeof PERMISSIONS;
export const showOnlyForRoles = (children: any, permission: PermissionType) => {
  const { user } = useAuthContext();
  if(!user && !user?.Role) return null;
  if (PERMISSIONS[permission].includes(user.Role.slug)) {
    return children;
  }
  return null;
};
export const showOnlyForProfile = (children: any, permission: PermissionType) => {
  const { user } = useAuthContext();
  if(!user && !user?.Role) return null;
  if (PERMISSIONS[permission].includes(user.Role.slug)) {
    return children;
  }
  return null;
}
