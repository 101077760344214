// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
  H_VERTICAL: 0,
};

export const NAV = {
  W_VERTICAL: 320,
  W_MINI: 88,
};
