import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
// components
import {LoadingScreen} from 'src/components/loading-screen';
import ProfileLayout from '../../layouts/profile';
import TodayShippedPage from 'src/pages/account/order/todayShipped';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/account/banking'));
// PRODUCT
// BRAND
// CATEGORY
// SLIDER
// COLLECTION
/// BUFIPAY
const PaymentListPage = lazy(() => import('src/pages/account/payment/list'));
const PaymentDetailsPage = lazy(() => import('src/pages/account/payment/details'));
const PaymentCreatePage = lazy(() => import('src/pages/account/payment/new'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/account/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/account/order/details'));
const OrderEditPage = lazy(() => import('src/pages/account/order/edit'));
const OrderPendingPage = lazy(() => import('src/pages/account/order/pendingOrder'));
// STATEMENT
const StatementListPage = lazy(() => import('src/pages/account/statement/list'));

// COMPANY

// INVOICE
// USER
// BLOG
// JOB
// TOUR
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP

// ----------------------------------------------------------------------

export const profileRoutes = [
    {
        path: '/profile',
        element: (
            <AuthGuard>
                <ProfileLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </ProfileLayout>
            </AuthGuard>
        ),

        children: [
            {element: <IndexPage/>, index: true},
            {path: 'file-manager', element: <FileManagerPage/>},
            {
                path: 'payment',
                children: [
                    {element: <PaymentListPage/>, index: true},
                    {path: ':id', element: <PaymentDetailsPage/>},
                    {path: 'new', element: <PaymentCreatePage/>},
                ],
            },
            {
                path: 'order',
                children: [
                    { element: <OrderListPage />, index: true },
                    { path: 'list', element: <OrderListPage /> },
                    { path: ':id', element: <OrderDetailsPage /> },
                    { path: ':id/edit', element: <OrderEditPage /> },
                    {path: 'pending', element: <OrderPendingPage/>},
                    {path: 'todayshipped', element: <TodayShippedPage/>},
                ],
            },
            {
                path: 'statement',
                children: [
                    { element: <StatementListPage />, index: true },
                    { path: 'list', element: <StatementListPage /> },
                ],
            },
        ],
    },

];
