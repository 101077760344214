import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
// routes
import {useRouter} from 'src/routes/hooks';
// components
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
// types
import {paths} from '../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
    query: string;
    results: any[];
    onSearch: (inputValue: string) => void;
    loading?: boolean;
};

export default function Search({query, results, onSearch, loading}: Props) {
    const router = useRouter();
    const handleClick = (slug: string) => {
        router.push(paths.product.details(slug));
    };
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (query && results.length > 0) {
                router.push(paths.product.query(query));
            } else {
                router.push('/product/list');
            }
        }

    };
    const handleInputChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        if (!newValue) {
            router.push('/product/list');
        }
        onSearch(newValue);
    };

    return (
        <Autocomplete
            sx={{width: {xs: 1, sm: 400}}}
            loading={loading}
            autoHighlight
            popupIcon={null}
            options={results}
            filterOptions={(x) => x}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.name}
            noOptionsText={<SearchNotFound query={query} sx={{bgcolor: 'unset'}}/>}
            onChange={(event, value) => {
                if (value && value.slug) {
                    handleClick(value.slug);
                }
            }}
            freeSolo
            slotProps={{
                popper: {
                    placement: 'bottom-start',
                    sx: {
                        minWidth: 320,
                    },
                },
                paper: {
                    sx: {
                        [` .${autocompleteClasses.option}`]: {
                            pl: 0.75,
                        },
                    },
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Ara..."
                    onKeyUp={handleKeyUp}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }}
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ml: 1, color: 'text.disabled'}}/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {loading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{mr: -3}}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, product, {inputValue}) => {
                const matches = match(product.title, inputValue);
                const parts = parse(product.title, matches);

                return (
                    <Box component="li" {...props} onClick={() => handleClick(product.slug)} key={product.id}>
                        <Avatar
                            key={product.id}
                            alt={product.name}
                            src={product.images?.[0]?.url || '/assets/images/product/placeholder.webp'}
                            variant="rounded"
                            sx={{
                                width: 48,
                                height: 48,
                                flexShrink: 0,
                                mr: 1.5,
                                borderRadius: 1,
                            }}
                        />
                        <div key={inputValue}>
                            {parts.map((part, index) => (
                                <Typography
                                    key={index}
                                    component="span"
                                    color={part.highlight ? 'primary' : 'textPrimary'}
                                    sx={{
                                        typography: 'body2',
                                        fontWeight: part.highlight ? 'fontWeightSemiBold' : 'fontWeightRegular',
                                    }}
                                >
                                    {part.text}
                                </Typography>
                            ))}
                        </div>
                    </Box>
                );
            }}
        />

    );
}
