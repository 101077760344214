import { combineReducers } from 'redux';
import checkoutSlice from './checkout/checkout.slice';
import { pokemonApi } from '../api/test.api';
import optionsSlice from './options/options.slice';

export const reducers = combineReducers({
  checkout: checkoutSlice,
  options: optionsSlice,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
});
