import React, { createContext, useContext, useState, useEffect, useRef, ReactNode } from 'react';
import Iconify from '../components/iconify';

interface LogEntry {
  id: number;
  message: any;
}

interface ConsoleContextType {
  logs: LogEntry[];
  addLog: (message: any) => void;
  clearLogs: () => void;
}

const ConsoleContext = createContext<ConsoleContextType | undefined>(undefined);

export const useConsole = () => {
  const context = useContext(ConsoleContext);
  if (!context) {
    throw new Error('useConsole must be used within a ConsoleProvider');
  }
  return context;
};

interface ConsoleProviderProps {
  children: ReactNode;
}

export const ConsoleProvider: React.FC<ConsoleProviderProps> = ({ children }) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [developmentMode, setDevelopmentMode] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const consoleOutputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const originalConsoleLog = console.log;

    console.log = function (message: any) {
      originalConsoleLog.apply(console, arguments as any);
      addLog(message);
    };

    setDevelopmentMode(process.env.REACT_APP_DEVELOPMENT_MODE == 'true');

    return () => {
      console.log = originalConsoleLog;
    };
  }, []);

  const addLog = (message: any) => {
    setLogs((prevLogs) => {
      const newLogs = [...prevLogs, { id: Date.now(), message }];
      return newLogs.length > 5 ? newLogs.slice(newLogs.length - 5) : newLogs;
    });

    if (consoleOutputRef.current) {
      consoleOutputRef.current.style.display = 'block';
    }
  };

  const clearLogs = () => {
    setLogs([]);
  };

  return (
    <ConsoleContext.Provider value={{ logs, addLog, clearLogs }}>
      {children}

      {developmentMode && (
        <>
          <button
            style={{
              position: 'fixed',
              bottom: '15px',
              right: '15px',
              zIndex: 1000,
              padding: '10px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
            onClick={() => setIsVisible(!isVisible)}
          >
            <Iconify
              width={24}
              height={24}
              icon={isVisible ? 'mdi:console-line' : 'mdi:console-line'}
            />
          </button>
          {isVisible && (
            <div id="console-output" ref={consoleOutputRef}>
              {logs.map((log) => (
                <LogEntryComponent key={log.id} log={log} />
              ))}
            </div>
          )}
        </>
      )}
    </ConsoleContext.Provider>
  );
};

interface LogEntryComponentProps {
  log: LogEntry;
}

const LogEntryComponent: React.FC<LogEntryComponentProps> = ({ log }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  return (
    <div className="log-entry">
      {typeof log.message === 'object' ? (
        <>
          <div className="toggle-button" onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
            {isDetailsOpen ? '▼ Object' : '▶ Object'}
          </div>
          {isDetailsOpen && (
            <pre className="log-details open">{JSON.stringify(log.message, null, 2)}</pre>
          )}
        </>
      ) : (
        log.message
      )}
    </div>
  );
};
