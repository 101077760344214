import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthModernLayout from 'src/layouts/auth/modern';
// components
import { SplashScreen } from 'src/components/loading-screen';
import ClassicNewPasswordPage from 'src/pages/auth-demo/classic/new-password';
import ClassicForgotPasswordPage from 'src/pages/auth-demo/classic/forgot-password';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthModernLayout>
          <JwtLoginPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthModernLayout>
          <JwtRegisterPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <AuthModernLayout>
          <JwtForgotPasswordPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'verify',
      element: (
        <AuthModernLayout>
          <JwtVerifyPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'old-forgot-password',
      element: (
        <AuthModernLayout>
          <ClassicForgotPasswordPage />
        </AuthModernLayout>
      ),
    },
    {
      path: 'old-new-password',
      element: (
        <AuthModernLayout>
          <ClassicNewPasswordPage />
        </AuthModernLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
