import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';
// types
import { gql, useQuery } from '@apollo/client';
import { generateQueryOp } from '../graphql/generated';

const cache = new Map();
// ----------------------------------------------------------------------
const initialState = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'desc',
  keyword: '',
  filter: {
    active: true,
  },
};

type Props = {
  take?: number;
  skip?: number;
  args?: any;
  params?: any;
};
export function useGetProducts({ take = 10, skip = 0, args, params }: Props) {
  const { variables, query } = generateQueryOp({
    products: {
      __args: {
        where: {
          active: {
            equals: true,
          },
        },
        take,
        skip,
        ...args,
      },
      images: {
        url: true,
      },
      ...params,
      __scalar: true,
    },
  });
  const { data, loading, error } = useQuery(gql(query), { variables });

  const memoizedValue = useMemo(
    () => ({
      products: data?.products || [],
      productsLoading: loading,
      productsError: error,
      productsEmpty: !loading && !data?.products.length,
    }),
    [data, error, loading]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function getProductSkus(params?: any, filter?: any) {
  // without swr
  const URL = endpoints.product.detail;

  const { data } = await axiosInstance.post(
    URL,
    {
      ...initialState,
      ...params,
    },
    {}
  );

  return data?.result || [];
}
export function useGetProductSkus(params?: any, filter?: any) {
  const URL = endpoints.product.detail;

  const { data, isLoading, error, isValidating } = useSWR<any>(URL, (url) =>
    axiosInstance.post(
      url,
      {
        ...initialState,
        ...params,
      },
      {}
    )
  );
  const memoizedValue = useMemo(
    () => ({
      productSkus: data?.data?.result || [],
      productSkusLoading: isLoading,
      productSkusError: error,
      productSkusValidating: isValidating,
      productSkusEmpty: !isLoading && !data?.data?.result.length,
    }),
    [data?.data?.result, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetProduct(slug: string) {
  return {
    product: null,
    loading: false,
  };
}

// ----------------------------------------------------------------------

export function useSearchProducts(search: string) {
  const URL = search
    ? [endpoints.product.search, { params: { query: search, index: 'products' } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: false,
  });

  // const { data, isLoading, error, isValidating } = useSWR(
  //   URL,
  //   ([url]) =>
  //     axiosInstance.post(
  //       url,
  //       {
  //         ...initialState,
  //         keyword: query,
  //       },
  //       {}
  //     ),
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.hits || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.hits.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
