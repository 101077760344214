// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useEffect, useState } from 'react';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Footer() {

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [config, setConfig] = useState<any>(null);

  useEffect(() => {
    const storedTenant = window?.localStorage.getItem('TENANT');
    if(storedTenant) {
      setConfig(JSON.parse(storedTenant));
    }
  }, []);

  const company_email = config?.company_email?.name;
  const company_phone = config?.company_phone;

  const LINKS = [
    {
      headline: 'Şirket',
      children: [
        { name: 'Hakkımızda',href: '#' },
        { name: 'İletişim', href: '#' },
        { name: 'FAQs', href: '#' },
      ],
    },
    {
      headline: 'Legal',
      children: [
        { name: 'Hizmet Şartları', href: '#' },
        { name: 'Gizlilik Politikası', href: '#' },
      ],
    },
    {
      headline: 'İletişim',
      children: [
        {
          name: config?.config?.company_email || 'info@buficore.com',
          href: `mailto:${config?.config?.company_email || 'info@buficore.com'}`,
        },
        {
          name: config?.config?.company_phone|| 'Loading...',
          href: `tel:${config?.config?.company_phone || 'Loading...'}`,
        },
      ],
    },
  ];

  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
  //     <Container>
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by
  //         <Link href="https://minimals.cc/"> minimals.cc </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              {config?.config?.company_title || 'Loading...'}
              <br />
              {config?.config?.company_address || 'Loading...'}
              <br />
              {config?.config?.company_phone || 'Loading...'}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          {/* Turkish */}© {currentYear} Tüm hakları saklıdır |{' '}
          {config?.config?.company_short_name || 'Loading...'}
        </Typography>
      </Container>
    </Box>
  );

  // return isHome ? simpleFooter : mainFooter;
  return mainFooter;
}
