import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { Alert, Card, ListItemText, TableCell, TableRow, Typography } from '@mui/material';
import { Container, TablePagination } from '@mui/material';
import { useState, useEffect } from 'react';
import Scrollbar from 'src/components/scrollbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  TableHeadCustom,
  TableSkeleton,
  TableEmptyRows,
  emptyRows,
  TableNoData,
  useTable,
} from 'src/components/table';
import { PageLayout } from 'src/layouts/profile/pageLayout';
import { gql, useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';

const TABLE_HEAD = [
  { id: 'date', label: 'Kayıt Bilgisi', visible: true },
  { id: 'number', label: 'Açıklama / Sevk Adres', visible: true },
  { id: 'description', label: 'Ürün', visible: true },
  { id: 'balance', label: 'Ölçü', visible: true },
  { id: 'credit', label: 'Miktar', visible: true },
];

const GET_FN_ORDER_TODAY_SHIPPED = gql`
  query LotsQuery($id: String!) {
    getFnOrderTodayShipped(id: $id)
  }
`;

type Props = {
  company: any;
  user: any;
};

export default function TodayShippedView({ company }: Props) {
  const table = useTable();
  const [statementsEmpty, setStatementsEmpty] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [visibleColumns] = useState(TABLE_HEAD);
  const [parsedOrders, setParsedOrders] = useState([]);
  const [error, setError] = useState<string | null>(null);

  const denseHeight = table.dense ? 56 : 76;
  const canReset = !isEqual(null, company);
  const notFound = statementsEmpty || (canReset && statementsEmpty);

  const { data, loading } = useQuery(GET_FN_ORDER_TODAY_SHIPPED, {
    variables: { id: company?.code },
    skip: !company?.code,
    onError: (error) => {
      setError('Veriler çekilirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
      console.error('Query error:', error);
    }
  });

  useEffect(() => {
    if (data?.getFnOrderTodayShipped) {
      try {
        const parsed = JSON.parse(data.getFnOrderTodayShipped || '[]');
        const sortedParsed = parsed.sort((a: any, b: any) => 
          new Date(b.ordered_at).getTime() - new Date(a.ordered_at).getTime()
        );
        setParsedOrders(sortedParsed);
        setStatementsEmpty(!parsed.length);
      } catch (error) {
        console.error('JSON parse error:', error);
        setParsedOrders([]);
        setStatementsEmpty(true);
      }
    }
  }, [data]);
  const totalCount = parsedOrders.length;
  
  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log('parsedOrders', parsedOrders);

  const renderTableRow = (row: any, index: number) => (
    <TableRow hover tabIndex={-1} key={index} sx={{ height: denseHeight }}>
      <TableCell>
        <ListItemText
          primary={format(new Date(row?.ordered_at), 'dd MMM yyyy', { locale: tr })}
          secondary={row?.order_key}
        />
      </TableCell>
      <TableCell>
        <ListItemText primary={row?.invoice_number} secondary={row?.shipment_address} />
      </TableCell>
      <TableCell>
        <ListItemText primary={row?.product_name} secondary={row?.product_code} />
      </TableCell>
      <TableCell>
        <ListItemText
          primary={
            <>
              {row?.quantity}{' '}
              <Typography variant="caption" component="span">
                {row?.unit}
              </Typography>
            </>
          }
          secondary={row?.size}
        />
      </TableCell>
      <TableCell>{row?.features}</TableCell>
    </TableRow>
  );

  return (
    <PageLayout header="Bugün Sevk Edilen Siparişler">
      <Container maxWidth="xl">
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data?.getFnOrderTodayShipped.length}
                  visible={visibleColumns}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {loading ? (
                    [...Array(table.rowsPerPage)].map((_, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {parsedOrders?.map((row: any, index: number) => renderTableRow(row, index))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, data?.getFnOrderTodayShipped._count)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </PageLayout>
  );
}
