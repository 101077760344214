// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// hooks
import Link from '@mui/material/Link';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, Typography } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from '../../components/logo';
import { IMAGES } from 'src/theme/image';
import { useEffect, useState } from 'react';
import privacyPolicyData from './privacyPolicy.json';
import { AnimateAvatar } from 'src/components/animate';

// components

// ----------------------------------------------------------------------

type Props = {
  image?: string;
  children: React.ReactNode;
};

export default function AuthModernLayout({ children, image }: Props) {
  const upMd = useResponsive('up', 'md');
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [config, setConfig] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const storedTenant = window?.localStorage.getItem('TENANT');
    if(storedTenant) {
      setConfig(JSON.parse(storedTenant));
    }
  }, []);

  const dynamicFields = {
    company_title: config?.config.company_title,
    address: config?.config.company_address,
    email: config?.config?.company_email,
    phone: config?.config.company_phone,
    website: config?.config.company_website,
  };

  useEffect(() => {
    // JSON dosyasındaki metni dinamik verilerle doldur
    const filledPolicy = privacyPolicyData.privacyPolicyTemplate.replace(/{{(.*?)}}/g, (_, key: keyof typeof dynamicFields) => dynamicFields[key] || '');
    setPrivacyPolicy(filledPolicy);
  }, [dynamicFields]);
  
  const handleAbout = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
    setDrawerOpen(false);
  };
  const handleContact = () => {
    setDrawerOpen(true);
  };


  const renderAbout = (
    // Drawer component
    <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Gizlilik Politikası</DialogTitle>
        <DialogContent>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {privacyPolicy}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
  );

  const renderAvatar = (
    <AnimateAvatar
      width={96}
      slotProps={{
        avatar: { src: IMAGES.LOGOICON, alt: `${config?.config.company_title}` },
        overlay: {
          border: 2,
          spacing: 3,
        },
      }}
    >
      {config?.config.company_title.charAt(0).toUpperCase()}
    </AnimateAvatar>
  );
  const renderContact = (
    <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
      <Stack spacing={1} sx={{ width: 340, padding: 10 ,alignItems:"center"}}>
        {renderAvatar}
        <Typography variant="h5" sx={{ whiteSpace: 'pre-line' }}>
          {config?.config?.company_title || ''}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line', textAlign:"center" }}>
          {config?.config?.company_address || ''}
        </Typography>
        </Stack>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {config?.config?.company_email || ''}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {config?.config?.company_phone || ''}
        </Typography>
      </Stack>
    </Drawer>
  );

  const renderContent = (
    <Stack
      sx={{
        marginTop: { xs: 8, md: 0 },
        marginBottom: { xs: 8, md: 0 },
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
        <Logo
          sx={{
            mt: { xs: 2, md: 4 },
            mb: { xs: 2, md: 4 },
          }}
        />
      </Stack>

      <Card
        sx={{
          paddingTop: { xs: 5, md: 2 },
          py: { xs: 5, md: 4 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        {children}
      </Card>

      <Box
        alignItems="center"
        sx={{
          paddingTop: { xs: 5, md: 5 },
          py: { xs: 2, md: 2 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        <Grid>
          <Link
            onClick={handleAbout}
            variant="body2"
            color="inherit"
            sx={{ alignSelf: 'flex-end', cursor: 'pointer' }} // Added cursor pointer for better UX
          >
            Gizlilik Politikası
          </Link>

          {/* <span style={{ margin: '0 5px' }}>|</span> */}

          {/* <Link href="" variant="body2" color="inherit" sx={{ alignSelf: 'flex-end' }}>
            Hizmet Şartları
          </Link> */}

          <span style={{ margin: '0 5px' }}>|</span>

          <Link onClick={handleContact} variant="body2" color="inherit" sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}>
            İletişim
          </Link>
        </Grid>

        <Stack direction="row" alignItems="center" spacing={4} marginTop={2}>
          <Box
            component="img"
            alt="bufilogo"
            src={image || '/assets/footer/bufiFooterLogo.png'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/iosV2.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(40% - 32px)',
              height: 'calc(40% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="playstore"
            src={image || '/assets/footer/googleV2.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(40% - 32px)',
              height: 'calc(40% - 32px)',
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          marginTop={2}
        >
          <Box
            component="img"
            alt="ssl"
            src={image || '/assets/footer/ssl-1.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="3dsecure"
            src={image || '/assets/footer/3dsecure.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(30% - 32px)',
              height: 'calc(30% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/Visa.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(25% - 32px)',
              height: 'calc(25% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="appstore"
            src={image || '/assets/footer/MasterCard.svg'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(20% - 32px)',
              height: 'calc(20% - 32px)',
            }}
          />
          <Box
            component="img"
            alt="troy"
            src={image || '/assets/footer/logo-dark-2x.png'}
            sx={{
              top: 16,
              left: 16,
              objectFit: 'cover',
              width: 'calc(20% - 32px)',
              height: 'calc(20% - 32px)',
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} sx={{ position: 'relative' }}>
      <Box
        component="img"
        alt="auth"
        src={IMAGES.OVERLAY}
        sx={{
          top: 16,
          left: 16,
          objectFit: 'cover',
          position: 'absolute',
          width: 'calc(100% - 32px)',
          height: 'calc(100% - 32px)',
        }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/auth.jpg)',
        },
      }}
    >
      {upMd && renderSection}
      {renderContent}
      {renderAbout}
      {renderContact}
    </Stack>
  );
}



