import { useSelector } from 'react-redux';
import { RootState, store } from '../../index';
import { actions } from './options.slice';

const setOptions = (payload: any) => store.dispatch(actions.setOptions(payload));
const resetOptions = () => store.dispatch(actions.resetOptions());

export const useOptions = () => {
  const options = useSelector((state: RootState) => state.options);
  return {
    ...options,
    setOptions,
    resetOptions,
  };
};
